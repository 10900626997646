import Layout from "../../../components/Layout";
import Table from "../../../components/Table/Table";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../../hooks/useValidator";
import Badge from "../../../components/Badge";
import PageTitle from "../../../components/PageTitle";
import ButtonCount from "../../../components/ButtonCount";
import { useNavigate } from "react-router-dom";

const SESSION_ENDPOINT = "/api/v1/school-plan/session";

const SchoolSession = () => {
  const [addSessionModal, setAddSessionModal] = useState(false);
  const [editSessionModal, setEditSessionModal] = useState(false);
  const [deleteSessionModal, setDeleteSessionModal] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isOptionsFetching, setIsOptionsFetching] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [sessionValidator, showSessionValidator] = useValidator();
  const [editSessionValidator, showEditSessionValidator] = useValidator();
  const [sessionForm, setSessionForm] = useState({
    session: "",
    start_date: "",
    end_date: "",
    is_current: false
  });

  const [editSession, setEditSession] = useState({
    session: "",
    start_date: "",
    end_date: "",
    is_current: false
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSessionForm({ ...sessionForm, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditSession({ ...editSession, [name]: value });
  };

  const handleIsCurrent = (e) => {
    const { name, checked } = e.target;
    setSessionForm({ ...sessionForm, [name]: checked });
  };

  const handleDeleteModal = (row) => {
    setDeleteSessionModal(true);
    setCurrentDelete(row);
  };

  const handleCloseDeleteModal = () => {
    setDeleteSessionModal(false);
    setCurrentDelete({});
  };

  const handleAddSessionModal = () => {
    setAddSessionModal(!addSessionModal);
  };

  const handleEditOptionsModal = (row) => {
    setCurrentRow(row);
    setEditSession({ ...editSession, session: row?.session, start_date: row?.start_date, end_date: row?.end_date, is_current: row?.is_current });
    setEditSessionModal(true);
  };

  const handleEditIsCurrent = (e) => {
    const { name, checked } = e.target;
    setEditSession({ ...editSession, [name]: checked });
  };

  const handleCloseEditOptionsModal = () => {
    setCurrentRow({});
    setEditSession({ ...editSession, session: "", start_date: "", end_date: "", status: "", is_current: false });
    setEditSessionModal(false);
  };

  const getAllSession = () => {
    setIsOptionsFetching(true);
    httprequest.get(SESSION_ENDPOINT).then((res) => {
      setIsOptionsFetching(false);
      setOptionsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsOptionsFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleCreateSession = () => {
    if (sessionValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(SESSION_ENDPOINT, sessionForm).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllSession();
        setSessionForm({ ...sessionForm, session: "", start_date: "", end_date: "" });
        handleAddSessionModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showSessionValidator(true);
    }
  };

  const handleDeleteSession = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${SESSION_ENDPOINT}/${currentDelete?.id}`).then(() => {
      setIsDeleteLoading(false);
      toast.success("Session Deleted Successfully");
      getAllSession();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleEditSession = () => {
    if (editSessionValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${SESSION_ENDPOINT}/${currentRow?.id}`, editSession).then(() => {
        setIsEditLoading(false);
        toast.success("Session Edited");
        getAllSession();
        setEditSession({ ...editSession, session: "", end_date: "", start_date: "" });
        handleCloseEditOptionsModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditOptionsModal();
      });
    } else {
      showEditSessionValidator(true);
    }
  };

  // --| Filter Sessions table using type, number of days, who can access
  const handleSearchSession = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = optionsData?.filter((data) => valArray?.every(
      (word) => data?.type
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
            data?.access?.toLowerCase().includes(word.toLowerCase()) ||
              data?.status?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAllSession();
  }, []);

  const columns = [
    {
      name: "Session Name",
      selector: (row) => row?.session
    },
    {
      name: "Session Start Date",
      selector: (row) => row?.start_date?.substring(0, 10)
    },
    {
      name: "Session End Date",
      selector: (row) => row?.end_date?.substring(0, 10)
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: " ",
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => {
            handleEditOptionsModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const { session, start_date, end_date } = sessionForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="School Session"
            subtitle="Create and manage your school sessions"
            button={
              <div className="flex gap-10">
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={() => {
                    navigate("/schoolterm");
                  }}
                  buttonName="View Terms"
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleAddSessionModal}
                  buttonName="Add School Session"
                />
              </div>
            }
          />
          <div className="button-count-container">
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <ButtonCount
                  title="All School Sessions"
                  count={optionsData?.length}
                />
              </Grid>
            </Grid>
          </div>
          <Table
            data={searchResult}
            columns={columns}
            subHeader={true}
            isLoading={isOptionsFetching}
            subHeaderComponent={<TableSearch placeholder="Search here..." searchTableFunc={handleSearchSession} />}
            pagination
          />
          <Modal
            title="Create Session"
            subtitle="Better manage your school sessions by creating one"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup inputName="session" type="text" label="Session Name" placeholder="e.g 2021/2022" value={session} onChange={handleChange} />
                    {sessionValidator.message("Session", session, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="start_date" inputType="date" label="Start Date" value={start_date} onChange={handleChange} />
                    {sessionValidator.message("Start Date", start_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="end_date" inputType="date" label="End Date" value={end_date} onChange={handleChange} />
                    {sessionValidator.message("Start Date", end_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <input type="checkbox" name="is_current" id="current_term" onChange={handleIsCurrent} />
                    <label className="checkbox-label" for="current_term">Current Session</label>
                  </Grid>

                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleCreateSession}
                  variant="primary"
                  buttonName="Create New Session"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isLoading}
                />
              </>
            }
            isopen={addSessionModal}
            closeModal={handleAddSessionModal}
          />
          <Modal
            title="Delete Current Session Data"
            subtitle={`Are you sure you want to delete this  ${currentDelete?.session} ?. If you delete it, it becomes unavaiable for the whole school.`}
            modalContent={
              <>
                <div>
                </div>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  variant="danger"
                  buttonName="Delete Selected Session"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleDeleteSession}
                  isLoading={isDeleteLoading}
                />
              </>
            }
            isopen={deleteSessionModal}
            closeModal={handleCloseDeleteModal}
          />

          {/* Modal to edit session */}
          <Modal
            title="Edit Session Information"
            subtitle="Edit your current Session"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="session" type="text" label="Session" defaultValue={editSession?.session} onChange={handleEditChange} />
                    {editSessionValidator.message("Session", editSession?.session, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="start_date" inputType="date" label="Start Date" defaultValue={editSession?.start_date?.slice(0, 10)} onChange={handleChange} />
                    {editSessionValidator.message("Start Date", editSession?.start_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="end_date" inputType="date" defaultValue={editSession?.end_date?.slice(0, 10)} label="End Date" onChange={handleChange} />
                    {editSessionValidator.message("Start Date", editSession?.end_date, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <input type="checkbox" name="is_current" id="current_term" defaultChecked={editSession?.is_current} onChange={handleEditIsCurrent} />
                    <label className="checkbox-label" for="current_term">Current Session</label>
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup label="Status" children={
                      <select onChange={handleEditChange} defaultValue={editSession?.status} name="status">
                        <option value="">---Select---</option>
                        <option value="active">Active</option>
                        <option value="inactive">InActive</option>
                      </select>
                    }
                    />
                    {editSessionValidator.message("Status", editSession?.status, "required")}
                  </Grid> */}
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleEditSession}
                  variant="primary"
                  buttonName="Edit Session"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isEditLoading}
                />
              </>
            }
            isopen={editSessionModal}
            closeModal={handleCloseEditOptionsModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default SchoolSession;

import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";

export default makeStyles((theme) => ({
  calendarWrapper: {
    border: "1px solid #E4E7EC",
    padding: "16px",
    borderRadius: "16px",
    marginTop: "50px",
    backgroundColor: "white"
  },
  cardwrapper: {
    positive: "relative",
    padding: "16px",
    background: "white",
    borderRadius: "16px",
    width: "100%",
    textAlign: "left",
    zIndex: "9999",
    border: `1px solid ${tinycolor(theme.palette.primary.main).lighten(45).toHexString()}`
  },
  iconcontainer: {
    padding: "5px 8px",
    background: tinycolor(theme.palette.primary.main).lighten(45).toHexString(),
    borderRadius: "4px",
    color: theme.palette.primary.main,
    marginBottom: "5px"
  },
  content: {
    marginBlock: "8px",
    fontSize: "16px",
    lineHeight: "19.2px",
    letterSpacing: "-2"
  },
  cardheader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#667185",
    fontSize: "14px"
  },
  date: {
    marginBottom: 0,
    marginTop: 0
  },
  detailsHeading: {
    width: "300px",
    fontSize: 16
  }
}));

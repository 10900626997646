import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useValidator from "../../../hooks/useValidator";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Button from "../../../components/Button";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import { axiosrequest } from "../../../data/api";

const CHANGE_PASSWORD = "/api/v1/applicant/password";
const GET_APPLICANT = "/api/v1/applicant/profile";

const Password = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordValidator, showValidationMessage] = useValidator();
  const [isLoading, setIsLoading] = useState(false);
  const [applicantData, setApplicantData] = useState({});
  const [changePasswordForm, setChangePasswordForm] = useState({
    new_password: "",
    old_password: ""
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setChangePasswordForm({ ...changePasswordForm, [name]: value });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const getApplicant = () => {
    setIsLoading(true);
    axiosrequest.get(GET_APPLICANT).then((res) => {
      setIsLoading(false);
      setApplicantData(res?.data?.data);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (passwordValidator.allValid()) {
      setIsLoading(true);
      axiosrequest.patch(`${CHANGE_PASSWORD}`, changePasswordForm).then(() => {
        setIsLoading(false);
        toast.success("Password Changed Successfully");
        setTimeout(() => navigate("/auth/applicant"), 2000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  const { old_password, new_password } = changePasswordForm;

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toggleCurrentPassword = () => {
    if (currentPasswordType === "password") {
      setCurrentPasswordType("text");
    } else {
      setCurrentPasswordType("password");
    }
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  const checkEqualPassword = () => {
    if (new_password !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getApplicant();
  }, []);

  return (
    <div className="page-content">
      <div className={`${classes.allcontent}`}>
        <div className="text-align">
          <h4 className={`${classes.changepassword}`}>Change Password</h4>
          <p className={`${classes.passwordtext}`}>Change the password for better security to this application.</p>
        </div>
        <div className={`${classes.nopadding}`}>
          <Grid className="input-padding" container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup
                label="Application Number"
                margin="normal"
                placeHolder="***********"
                inputType="text"
                value={applicantData.application_no}
              />
            </Grid>
          </Grid>
          <Grid className="input-padding" container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup
                label="Current Password"
                margin="normal"
                placeHolder="***********"
                inputName="old_password"
                inputType={currentPasswordType}
                onChange={handlePasswordChange}
              />
              <div className="password-eye-90">
                {currentPasswordType === "password" ? (
                  <Icon icon="fluent:eye-16-regular" onClick={toggleCurrentPassword} />
                ) : (
                  <Icon icon="iconamoon:eye-off-light" onClick={toggleCurrentPassword} />
                )}
              </div>
              {passwordValidator.message("Current Password", old_password, "required")}
            </Grid>
          </Grid>
          <Grid className="input-padding" container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup
                label="New Password"
                margin="normal"
                value={new_password}
                placeHolder="***********"
                inputType={passwordType}
                inputName="new_password"
                onChange={handlePasswordChange}
              />
              <div className="password-eye-90">
                {passwordType === "password" ? (
                  <Icon icon="fluent:eye-16-regular" onClick={togglePassword} />
                ) : (
                  <Icon icon="iconamoon:eye-off-light" onClick={togglePassword} />
                )}
              </div>
              {passwordValidator.message("New Password", new_password, "required")}
            </Grid>
          </Grid>
          <Grid className="input-padding" container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup
                label="Confirm Password"
                onChange={handleChange}
                placeHolder="***********"
                margin="normal"
                inputType={confirmPasswordType}
              />
              <div className="password-eye-90">
                {confirmPasswordType === "password" ? (
                  <Icon icon="fluent:eye-16-regular" onClick={toggleConfirmPassword} />
                ) : (
                  <Icon icon="iconamoon:eye-off-light" onClick={toggleConfirmPassword} />
                )}
              </div>
              {passwordValidator.message("Confirm Password", confirmPassword, "required")}
            </Grid>
          </Grid>
        </div>
        <div className="input-padding">
          <Button
            variant="primary"
            buttonSize="small"
            color="btndefault"
            buttonName="Save Changes"
            isLoading={isLoading}
            disabled={checkEqualPassword()}
            onClick={handleChangePassword}
          />
        </div>
        {checkEqualPassword() ? <span className="text-danger">Password and confirm password not equal</span> : null}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Password;

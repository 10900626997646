import useStyles from "../styles";
import { Icon } from "@iconify/react";
// import { useState } from "react";

const WelfareCard = (props) => {
  // const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.cardwrapper}`}>
        <div className={`${classes.cardheader}`}>
          <div className={`${classes.iconcontainer}`}>
            <Icon icon="majesticons:ship" width="15" height="15" />
          </div>
        </div>
        <div>
          <h4>{props.title}</h4>
          <p className={`${classes.content}`}>{props.subtitle}</p>
        </div>
        <div className={`${classes.cardheader}`}>
          <p className={classes.date}>{props.date}</p>

        </div>
      </div>
    </>
  );
};

export default WelfareCard;

import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { ToastContainer } from "react-toastify";
import CreateAccess from "../component/CreateAccess";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";

// --| The control center component renderes the create access component
const ControlCenter = () => {
  const navigate = useNavigate();

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/accesscontrol/codes"
              link_one_name="Access Codes"
              active="Create Access Code"
              description="Access codes are useful for pickups and dropoffs"
            />
          </div>
          <Button
            variant="primary"
            buttonSize="fluid"
            color="btndefault"
            onClick={() => {
              navigate("/accesscontrol/validate");
            }}
            buttonName="Validate Code"
          />
        </div>
        <div className="page-content">
          <CreateAccess />
          <ToastContainer />
        </div>
      </>
    }
    />
  );
};

export default ControlCenter;

import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import BreadCrumb from "../../../components/BreadCrumb";
import SchedulerCalendar from "../../../components/Scheduler";
import { useNavigate } from "react-router-dom";
import styles from "./Guardian.module.css";

const Timetable = () => {
  const navigate = useNavigate();

  const calendarEvents = [
    {
      id: "event-1",
      label: "Medical consultation",
      groupLabel: "Dr Shaun Murphy",
      user: "Dr Shaun Murphy",
      // color: "#f28f6a",
      startHour: "04:00 AM",
      endHour: "09:00 AM",
      date: "2024-03-19",
      createdAt: new Date(),
      createdBy: "Kristina Mayer"
    },
    {
      id: "event-2",
      label: "Medical consultation",
      groupLabel: "Dr Claire Brown",
      user: "Dr Claire Brown",
      // color: "#099ce5",
      startHour: "09:00 AM",
      endHour: "10:00 AM",
      date: "2022-05-09",
      createdAt: new Date(),
      createdBy: "Kristina Mayer"
    },
    {
      id: "event-3",
      label: "Medical consultation",
      groupLabel: "Dr Menlendez Hary",
      user: "Dr Menlendez Hary",
      // color: "#263686",
      startHour: "13 PM",
      endHour: "14 PM",
      date: "2022-05-10",
      createdAt: new Date(),
      createdBy: "Kristina Mayer"
    },
    {
      id: "event-4",
      label: "Consultation prénatale",
      groupLabel: "Dr Shaun Murphy",
      user: "Dr Shaun Murphy",
      color: "#f28f6a",
      startHour: "08:00 AM",
      endHour: "09:00 AM",
      date: "2022-05-11",
      createdAt: new Date(),
      createdBy: "Kristina Mayer"
    }
  ];
  const handleCellClick = (event, row, day) => {
    // eslint-disable-next-line no-console
    console.log("day", day);
    // eslint-disable-next-line no-console
    console.log("event", event);
    // Do something...
  };

  const handleEventClick = (event, item) => {
    // eslint-disable-next-line no-console
    console.log(event);
    // eslint-disable-next-line no-console
    console.log(item);
    // Do something...
  };

  const handleEventsChange = () => {
    // Do something...
  };

  const handleAlertCloseButtonClicked = () => {
    // Do something...
  };

  return (
    <Layout
      children={
        <>
          <BreadCrumb
            link_one="/guardian"
            link_one_name="Guardian"
            link_two="/guardian/:id"
            link_two_name="About Child"
            active="Ojo Williams Daniel"
            description="See the details of your child"
          />
          <div className="margin-bottom">
            <PageTitle
              title="I am the Child Timetable"
              subtitle={
                <div className="flex align-items-center gap-5">
                  <p onClick={() => navigate("/classes/createclass")} className={`${styles.color}`}>JSS 1</p>
                  <p>|</p>
                  <p>MALE </p>
                </div>}
              button={
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    //   onClick={handleSendMessageModal}
                    buttonName="Make a suggestion"
                  />
                  {/* <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    buttonName="Send report to parent"
                  /> */}
                </div>
              }
            />
          </div>
          <SchedulerCalendar
            events={calendarEvents}
            onEventsChange={handleEventsChange}
            onCellClick={handleCellClick}
            onTaskClick={handleEventClick}
            onAlertCloseButtonClicked={handleAlertCloseButtonClicked}
          />
        </>
      }
    />
  );
};

export default Timetable;

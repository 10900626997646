import ExamBook from "../../../assets/images/book.svg";
import useStyles from "../styles";
import PropTypes from "prop-types";

const ApplicantResultCard = ({ resulttitle, result }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.cardcont} text-align-left`}>
      <img src={ExamBook} alt="book" />
      <h3>{resulttitle}</h3>
      <div className="flex gap-10 justifyRight margin-top-12">
        <h2>{result}</h2>
      </div>
    </div>
  );
};
ApplicantResultCard.PropTypes = {
  resulttitle: PropTypes.string,
  result: PropTypes.string
};

export default ApplicantResultCard;

import Layout from "../../components/Layout";
import Grades from "./student/Grades";

const Assessments = () => {
  return (
    <Layout children={
      <>
        <Grades />
      </>
    }
    />
  );
};

export default Assessments;

export const Genders = [
  {
    key: "male",
    text: "Male"
  },
  {
    key: "female",
    text: "Female"
  }
];

export const Gender = [
  { key: "male", text: "Male" },
  { key: "female", text: "Female" }
];
export const VenueType = [
  { key: "physical", text: "Physical" },
  { key: "virtual", text: "Virtual" }
];
export const LibraryItemType = [
  { key: "book", text: "Book" },
  { key: "video", text: "Video" }
];

export const Countries = [
  { value: "DZ", label: "ALGERIA" },
  { value: "AO", label: "ANGOLA" },
  { value: "BJ", label: "BENIN" },
  { value: "BW", label: "BOTSWANA" },
  { value: "BF", label: "BURKINA FASO	" },
  { value: "BI", label: "BURUNDI" },
  { value: "CM", label: "CAMEROON" },
  { value: "CV", label: "CAPE VERDE" },
  { value: "CF", label: "CENTRAL AFRICAN REPUBLIC" },
  { value: "TD", label: "CHAD" },
  { value: "KM", label: "COMOROS" },
  { value: "CG", label: "CONGO" },
  { value: "CI", label: "COTE D’IVOIRE" },
  { value: "DJ", label: "DJIBOUTI" },
  { value: "EG", label: "EGYPT" },
  { value: "GQ", label: "EQUATORIAL GUINEA" },
  { value: "ER", label: "ERITREA" },
  { value: "ET", label: "ETHIOPIA" },
  { value: "GA", label: "GABON" },
  { value: "GM", label: "GAMBIA" },
  { value: "GH", label: "GHANA" },
  { value: "GN", label: "GUINEA" },
  { value: "GW", label: "GUINEA-BISSAU" },
  { value: "KE", label: "KENYA" },
  { value: "LS", label: "LESOTHO" },
  { value: "LR", label: "LIBERIA" },
  { value: "LY", label: "LIBYAN ARAB JAMAHIRIYA" },
  { value: "MG", label: "MADAGASCAR" },
  { value: "ML", label: "MALI" },
  { value: "MW", label: "MALAWI" },
  { value: "MR", label: "MAURITANIA" },
  { value: "MU", label: "MAURITIUS" },
  { value: "YT", label: "MAYOTTE" },
  { value: "MA", label: "MOROCCO" },
  { value: "MZ", label: "MOZAMBIQUE" },
  { value: "NA", label: "NAMIBIA" },
  { value: "NE", label: "NIGER" },
  { value: "NG", label: "NIGERIA" },
  { value: "RE", label: "REUNION ISLAND" },
  { value: "RW", label: "RWANDA" },
  { value: "SN", label: "SENEGAL" },
  { value: "SC", label: "SEYCHELLES" },
  { value: "SL", label: "SIERRA LEONE" },
  { value: "SO", label: "SOMALIA" },
  { value: "ZA", label: "SOUTH AFRICA" },
  { value: "SS", label: "SOUTH SUDAN" },
  { value: "SD", label: "SUDAN" },
  { value: "SZ", label: "SWAZILAND" },
  { value: "TZ", label: "TANZANIA" },
  { value: "TG", label: "TOGO" },
  { value: "TN", label: "TUNISIA" },
  { value: "UG", label: "UGANDA" },
  { value: "EH", label: "WESTERN SAHARA" },
  { value: "ZM", label: "ZAMBIA" },
  { value: "ZW", label: "ZIMBABWE" }
];

export const Currency = [
  { value: "ngn", label: "NGN (Naira)", symbol: "₦" },
  { value: "cad", label: "CAD (Canadian Dollar)", symbol: "CAD" },
  { value: "xof", label: "CFA (West African CFA Franc)", symbol: "CFA" },
  { value: "cny", label: "CNY (Chinese Yuan)", symbol: "¥" },
  { value: "egp", label: "EGP (Egyptian Pound)", symbol: "ج.م" },
  { value: "eur", label: "EUR (Euro)", symbol: "€" },
  { value: "gbp", label: "GBP (British Pound)", symbol: "£" },
  { value: "ghs", label: "GHS (Ghanian Cedi)", symbol: "GH₵" },
  { value: "jpy", label: "JPY (Japanese Yuan)", symbol: "¥" },
  { value: "kes", label: "KES (Kenyan Shilling)", symbol: "Ksh" },
  { value: "usd", label: "USD (United States Dollar)", symbol: "$" },
  { value: "zar", label: "ZAR (South African Rand)", symbol: "R" }
];

export const Roles = [
  { key: "accounts", text: "Accounts" },
  { key: "clerical", text: "Clerical" },
  { key: "head_teacher", text: "Head Teacher" },
  { key: "teacher", text: "Teacher" },
  { key: "receptionist", text: "Receptionist" }
];

export const Access = [
  { key: "all employees", text: "All Employees" },
  { key: "accounts", text: "Accounts" },
  { key: "clerical", text: "Clerical" },
  { key: "headteacher", text: "Head Teacher" },
  { key: "teacher", text: "Teacher" },
  { key: "receptionist", text: "Receptionist" }
];

export const Employees = [
  { key: "1er2u382783782", text: "Logan Dann" },
  { key: "juiu983908r903", text: "Daniel Paul" },
  { key: "iieuvisiwuhifo", text: "Shirley Matt" },
  { key: "090dhidiwoiufd", text: "Grandley Jandor" },
  { key: "oioeugis90i8e9", text: "Taylor Randy" }
];

export const Grade = [
  { key: "dkenjdvmd", text: "A" },
  { key: "dkenidvmd", text: "B" },
  { key: "dkenjdumd", text: "C" },
  { key: "dkeejdvmd", text: "D" },
  { key: "dkenjdvsd", text: "E" },
  { key: "dkenjuvmd", text: "F" }
];

export const ScoreLevel = [
  { key: "fdgshsdjxx", text: "70" },
  { key: "fdgshsdjex", text: "60" },
  { key: "fdgsesdjex", text: "50" },
  { key: "fdgsestjex", text: "40" },
  { key: "fdgs5vfmjd", text: "30" }
];

export const GoalCategory = [
  { key: "rkfngkjrgewrjg", text: "School Project" },
  { key: "kfnghjfnhfnjfs", text: "Class Project" },
  { key: "iijdjdduidvjvf", text: "Individual Project" }
];

export const Category = [
  { key: "knbvrburewvjb45kj", text: "Library Computer" },
  { key: "knbvrburewvjb45kj", text: "Laboratory Computer" },
  { key: "fdjnfjjedewvjb45kj", text: "Music Equipment" }
];

export const Quantity = [
  { key: "jbfve732yrinfkvnkj", text: "1" },
  { key: "jbfve732yrinffvhbd", text: "2" },
  { key: "jfnbjsfbjewbfghbvh", text: "3" },
  { key: "jbfve7323343232312", text: "4" },
  { key: "jbfve7jdvbjde3kjd3", text: "5" },
  { key: "jbfve732yrinf38373", text: "6" },
  { key: "jbfve73238783ihfdf", text: "7" },
  { key: "jbfve732y276289223", text: "8" },
  { key: "jbfve73238373y73rn", text: "9" },
  { key: "jbfve732262627293c", text: "10" }
];

export const Condition = [
  { key: "rklvnf3knmjkdjfd", text: "Used" },
  { key: "rklvnf3djdhgdjfd", text: "Un-Used" }
];

export const Department = [
  { key: "fjnurie4g3g4384", text: "Learning Materials" },
  { key: "fjnurie4g3eeee4", text: "School Store" },
  { key: "sdkfnsjkf328832", text: "Health Center" }
];

export const ClassTeacher = [
  { key: "dkslvnkjfdvnjfvjkmfvjvj", text: "BolajiiOlayinkz" },
  { key: "dkslvnkjfdvnjfyubhghhvj", text: "Yinka" }
];

export const StartTime = [
  { key: "660d3103145b5886a1c928f0", text: "8:00am" },
  { key: "660d31030641ce197e4deafe", text: "9:00am" },
  { key: "660d3103079d81efd0838bc6", text: "10:00am" },
  { key: "660d31030117d354b0de4fa0", text: "11:00am" },
  { key: "660d310320ee2df042cbf9a3", text: "12:00pm" },
  { key: "660d31039690ccf08ef687f2", text: "1:00pm" },
  { key: "660d3103563a2d5ac10ebc06", text: "2:00pm" }
];

export const EndTime = [
  { key: "660d31ba456a96929f3747bb", text: "9:00am" },
  { key: "660d31ba6a69552f20b86040", text: "10:00am" },
  { key: "660d31bac09cb7b563b78cb8", text: "11:00am" },
  { key: "660d31ba8634d9a6bbe13437", text: "12:00pm" },
  { key: "660d31ba2894c80f71ba591e", text: "1:00pm" },
  { key: "660d31ba6a5ca9beb65f830a", text: "2:00pm" }
];

export const ClassType = [
  { key: "dkslvhhjhfvjkmfvjvj.", text: "Virtual" },
  { key: "dkslvnfdvnjfyubhghhvj.", text: "Physical" }
];

export const ClassLevel = [
  { key: "660ca63df5dd4337249be676", text: "JSS 1" },
  { key: "660ca63d8de5e359989bba89", text: "JSS 2" },
  { key: "660ca63d03c9822299c55c66", text: "JSS 3" },
  { key: "660ca63da0407ba57d782d2b", text: "SSS 1" },
  { key: "660ca63d205572c2337ea7d0", text: "SSS 2" },
  { key: "660ca63d8932ebaef4926bc4", text: "SSS 3" }
];

export const EnrollmentForm = [
  { key: "dfbvkngjrnfgjergj4lyxds", text: "Custom Form" },
  { key: "dfbvkngjrnfgjergjhy4lds", text: "Enrollment Form" }
];

export const Session = [
  { key: "jsdfbvjdsbvhjdsvjdhbvjb", text: "2023/2024" },
  { key: "jsdfbvjdsbvhjdsvjdhwvjb", text: "2024/2025" }
];

export const Weekdays = [
  { key: "660ca7df46e574381c27b5f8", text: "Monday" },
  { key: "660ca7df46fd8c62f6aaa6aa", text: "Tuesday" },
  { key: "660ca7df2a6fa5b2be8449e8", text: "Wednesday" },
  { key: "660ca7df2fa667a15af2c4ad", text: "Thursday" },
  { key: "660ca7df355298b087326a9f", text: "Friday" }
];

export const Weekday = ["mon", "tue", "wed", "thu", "fri"].map((day, index) => ({
  key: `day-${index + 1}`,
  text: day
}));

export const Numbers = [
  { key: "660ca7df46e574312381c27b5f8", text: "1" },
  { key: "660ca7df46fd8c62233f6aaa6aa", text: "2" },
  { key: "660ca7df2a6fa5b2be2348449e8", text: "3" },
  { key: "660ca7df2fa667a145555af2c4ad", text: "4" },
  { key: "660ca7df355298b0345387326a9f", text: "5" },
  { key: "660ca7df35529433345387326a9f", text: "6" }
];

export const Subject = [
  { key: "660ca4d52f42c7d47330614a", text: "Mathematics" },
  { key: "660ca4d5bfd06df138774cec", text: "Economics" },
  { key: "660ca4d530761ca389edb5bd", text: "English Language" },
  { key: "660ca4d5da040c5ef182b9c9", text: "Civic Eduction" },
  { key: "660ca4d53612171997563d13", text: "Biology" },
  { key: "660ca4d5bb53c86b3759ec8f", text: "Agricultural Science" },
  { key: "660ca4d500eae6add8aaf1e5", text: "Technical Drawing" },
  { key: "660ca4d5172b6da627201724", text: "Home Economics" }
];

export const ManagerScore = [
  { key: "jghuweghu383riib2", text: "1" },
  { key: "2234wwjsbhchshvhd", text: "2" },
  { key: "22kmekferhchshvhd", text: "3" },
  { key: "fvlkdsnjksdknjdkv", text: "4" },
  { key: "jfnjvnejvjej323324", text: "5" },
  { key: "dfgjndjgbref4485u8", text: "6" },
  { key: "dfbnjgbrjugurug4453", text: "7" },
  { key: "dfbnjgbrjgjgurug4453", text: "8" },
  { key: "dfbnjgdfgmugurug4453", text: "9" },
  { key: "dfbnjndhfdhgkgurug4453", text: "10" }
];

export const StaffScore = [
  { key: "vahfgfhururhyure993", text: "1" },
  { key: "jkbfvjhbvghreaj447y", text: "2" },
  { key: "88783hdfjhdfjbdjfbd", text: "3" },
  { key: "kmsjrkgjregre8u8ehr", text: "4" },
  { key: "kmsjrkgfgdffgngnehr", text: "5" },
  { key: "rkgnrgjrngjrgj43333", text: "6" },
  { key: "sfbnjervbnew3883382", text: "7" },
  { key: "mnvndjvsjdvjdv233298", text: "8" },
  { key: "vwjvbe8738y48338474r", text: "9" },
  { key: "kjsvbnbev3774234vnhd", text: "10" }
];

export const Term = [
  { key: "dgnsjkgnjkdsnjsdfbnjsfbnf", text: "First Term" },
  { key: "dgnsjkgnjkdsnjsdfbnndndnf", text: "Second Term" },
  { key: "dgnsjkgnjkddkjjdjdjndndnf", text: "Third Term" }
];

export const AgreedScore = [
  { key: "vjhbervrfgbg47ry534bv", text: "1" },
  { key: "ssdjgnjrebgu4tr4494j", text: "2" },
  { key: "kdnfgjrfjgnr48i8i449", text: "3" },
  { key: "vjhbervhfbvheery534bv", text: "4" },
  { key: "vjhberdfjgfb47ry534bv", text: "5" },
  { key: "vjhberv8474444ry534bv", text: "6" },
  { key: "vj3868973ncf47ry534bv", text: "7" },
  { key: "vj344vdfdfdf47ry534bv", text: "8" },
  { key: "vjhb449545gvv7ry534bv", text: "9" },
  { key: "vjhber384u4834ry534bv", text: "10" }
];

export const TestLabels = [
  { key: "educational", text: "Educational" },
  { key: "school_plan", text: "School Plan" }
];

export const MaritalStatus = [
  {
    key: "single",
    text: "Single"
  },
  {
    key: "married",
    text: "Married"
  },
  {
    key: "living-together",
    text: "Living Together"
  },
  {
    key: "divorced-separated",
    text: "Divorced/Separated"
  },
  {
    key: "widowed",
    text: "Widowed"
  }
];

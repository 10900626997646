import useStyles from "../styles";
import { useState } from "react";
import { Grid } from "@mui/material";
import Modal from "../../../components/Modal";
import { Icon } from "@iconify/react";
import Button from "../../../components/Button";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import ExaminationCard from "../../../components/ExaminationCard";
// import Table from "../../../components/Table/Table";
// import { TableSearch } from "../../../components/Table/TableActions";
import PageTitle from "../../../components/PageTitle";
import { Grade, ScoreLevel } from "../../../utils/DropDown";

// --| Dummy Data
// import ExaminationData from "../../../dummy_data/examination.json";

const Examination = () => {
  const classes = useStyles();
  const [grademodal, setGradeModal] = useState();
  const handleGradeModal = () => {
    setGradeModal(!grademodal);
  };

  // const [searchResult, setSearchResult] = useState([]);

  // const columns = [
  //   {
  //     name: "Name",
  //     selector: row => row?.name
  //   },
  //   {
  //     name: "Description",
  //     selector: row => row?.description
  //   },
  //   {
  //     name: "Issue No.",
  //     selector: row => row?.issue_no
  //   },
  //   {
  //     name: "Category",
  //     selector: row => row?.category
  //   },
  //   {
  //     name: "Created At",
  //     selector: row => row?.created_at?.substring(0, 10)
  //   },
  //   {
  //     name: "Status",
  //     center: "true",
  //     cell: (row) => (<Badge status={row?.status} />)
  //   },
  //   {
  //     name: "",
  //     center: true,
  //     sortable: false,
  //     cell: (row) => (
  //       <div key={row?.id} className="table-action-container">
  //         <button
  //           // onClick={() => {
  //           //   handleOpenInventoryModal(row);
  //           // }}
  //           className="table-action-icon"
  //         >
  //           <Icon icon="fluent:eye-16-regular" />
  //         </button>
  //         <button className="table-action-icon">
  //           <Icon icon="heroicons:pencil-square-solid" />
  //         </button>
  //         <button
  //           // onClick={() => handleDeleteModal(row)}
  //           className="table-action-icon delete-icon"
  //         >
  //           <Icon icon="mingcute:delete-2-line" />
  //         </button>
  //       </div>
  //     )
  //   }
  // ];
  // --| Filter Inventory table using name, email and class
  // const handleSearchInventory = (e) => {
  //   const { value } = e.target;

  //   const valArray = value.split(" ");
  //   // --| Filter data by partial match onchange in the search input box
  //   const result = ExaminationData?.filter((data) => valArray?.every(
  //     (word) => data?.name
  //       ?.toLowerCase()
  //       .includes(word.toLowerCase()) ||
  //                         data?.description
  //                           ?.toLowerCase()
  //                           .includes(word.toLowerCase()) ||
  //                         data?.category
  //                           ?.toLowerCase()
  //                           .includes(word.toLowerCase()) ||
  //                           data?.status
  //                             ?.toLowerCase()
  //                             .includes(word.toLowerCase())
  //   )
  //   );
  //   setSearchResult(result);
  // };

  return (
    <>
      <PageTitle
        title="Examination"
        subtitle="See all the examination question papers submitted pending approval."
        button={
          <div className="flex gap-10">
            <Button onClick={handleGradeModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Grading System" />
          </div>
        }
      />
      <Grid container spacing={2} columnSpacing={2}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="JSS 1" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="JSS 2" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="JSS 3" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="Mock Examination 1" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="SSS 1" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="SSS 2" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="SSS 3" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ExaminationCard Classlevel="Mock Examination 2" paragraph="Get notification from google drive on your shelf21 webapp." />
        </Grid>
      </Grid>
      {/* School Grade Modal */}
      <Modal
        title="Set Grading System"
        subtitle={
          <div>You can set the grading system for the school.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Grade" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {Grade?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Score" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {ScoreLevel?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Grade" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {Grade?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Score" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {ScoreLevel?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <div className={`${classes.mail} flex align-items-center justifyRight`}>
                <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                <p>Add another system</p>
              </div>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Save grading system" />
            </div>
          </>
        }
        isopen={grademodal}
        closeModal={handleGradeModal}
      />
    </>
  );
};

export default Examination;

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import useStyles from "../../styles";
import Layout from "../../../../components/Layout";
import BreadCrumb from "../../../../components/BreadCrumb";
import { httprequest } from "../../../../data/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const TimeTableCalender = () => {
  const [myLeave, setMyLeave] = useState([]);
  const classes = useStyles();

  const getMyLeave = () => {
    httprequest.get(`${LEAVE_MGT_ENDPOINT}`).then((res) => {
      setMyLeave(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };
  // eslint-disable-next-line no-console
  console.log(myLeave);

  const events = myLeave.map((leave) => ({
    title: leave.type,
    start: leave.start_date,
    end: leave.end_date,
    last_name: leave?.last_name,
    first_name: leave?.first_name,
    role_name: leave?.role_name
  }));
  // eslint-disable-next-line no-console
  console.log(events);

  const renderEventContent = (eventInfo) => {
    return (
      <>
        {/* <b className="padding-inline-10">{`${eventInfo.event.extendedProps.last_name} ${eventInfo.event.extendedProps.first_name}`}</b>
        <p className="no-margin-block padding-inline-10">{eventInfo.event.title}</p> */}
        <p className="no-margin-block padding-inline-10">Role: {eventInfo.event.extendedProps.role_name}</p>
      </>
    );
  };

  useEffect(() => {
    getMyLeave();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one={"/time-table"}
                link_one_name="School TimeTable"
                active="Timetable Calender"
                description="See the timetable of your school"
              />
            </div>
          </div>
          <div className={classes.calender}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              weekends={false}
              headerToolbar={
                {
                  start: "today prev,next",
                  center: "title",
                  end: "dayGridMonth,timeGridWeek,timeGridDay"
                }
              }
              events={events}
              eventContent={renderEventContent}
            />
          </div>
        </>
      }
    />
  );
};

export default TimeTableCalender;

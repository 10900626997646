import PropTypes from "prop-types";
import useStyles from "../../styles";

const SyllabusCard = ({ week, topic, sub_topics }) => {
  const classes = useStyles();

  return (
    <div className={classes.syllabuscont}>
      <h3>{`Week ${week} : ${topic}`}</h3>
      <ul>
        {sub_topics.map((subTopic, index) => (
          <li key={index}>{subTopic}</li>
        ))}
      </ul>
    </div>
  );
};
SyllabusCard.propTypes = {
  week: PropTypes.string,
  topic: PropTypes.string,
  sub_topics: PropTypes.any
};

export default SyllabusCard;

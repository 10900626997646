import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Password from "../components/Password";
import RolesandPermission from "../components/RolesandPermission";
// import Apikeys from "../components/Apikeys";
import { Grid } from "@mui/material";
import Modal from "../../../components/Modal";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { useState } from "react";
import styles from "../settings.module.css";

// --| Dummy Data
import { Roles } from "../../../utils/DropDown";

const Settings = () => {
  const [createapimodal, setCreateApiModal] = useState(false);
  const [createdapimodal, setCreatedApiModal] = useState(false);

  const handleCreatedApiModal = () => {
    setCreatedApiModal(!createdapimodal);
  };
  const handleCreateApiModal = () => {
    setCreateApiModal(!createapimodal);
  };

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <PageTitle
        title="Settings"
        subtitle="You can make changes to your organisation’s account."
        button={
          <div className="flex gap-10">
            <Button onClick={handleCreateApiModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Profile Settings" />
          </div>
        }
      />
      <TabContext value={value}>
        <div className="tablist-container">
          <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
            <Tab label="Password" value="1" />
            <Tab label="Roles and Permissions" value="2" />
            {/* <Tab label="Api Keys" value="3" /> */}
          </Tabs>
        </div>
        <div className={styles.pagecontent}>
          <div>
            <TabPanel value="1" className="">
              <Password />
            </TabPanel>
            <TabPanel value="2" className="tabpanel-overide"><RolesandPermission /></TabPanel>
            {/* <TabPanel value="3" className="tabpanel-overide"><Apikeys /></TabPanel> */}
          </div>
        </div>
      </TabContext>
      {/* Create API Modal */}
      <Modal
        title="Create API Keys"
        subtitle={
          <div>Here you can save and embedded the code for email templates.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  margin="normal"
                  placeHolder="Api title."
                  inputType="text"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectGroup label="Assign To" children={
                  <select>
                    <option value="">--- Select School ---</option>
                    {Roles?.map((label) => {
                      return (
                        <option key={label.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <Button onClick={handleCreatedApiModal} variant="primary" buttonSize="full" color="btndefault" buttonName="Create API keys" />
          </>
        }
        isopen={createapimodal}
        closeModal={handleCreateApiModal}
      />
      {/* Created API Modal */}
      <Modal
        title="API key created"
        modalContent={
          <>
            <Grid container spacing={2} className={`${styles.copyinput}`}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  margin="normal"
                  placeHolder="Api created"
                  inputType="text"
                />
                <div className={`${styles.copyicon}`}>
                  <Icon icon="bxs:copy" width="16" height="16" />
                </div>
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <Button onClick={handleCreateApiModal} variant="primary" buttonSize="full" color="btndefault" buttonName="Create API keys" />
          </>
        }
        isopen={createdapimodal}
        closeModal={handleCreatedApiModal}
      />
    </div>
  );
};

export default Settings;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import close_btn from "../../assets/images/close-btn.svg";
// import AddSubject_btn from "../../assets/images/plus-circle.svg";
import classes from "./ModalStyles.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const Modal = (props) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={props.isopen}
      >
        <div className={`${classes.modalpopup}`}>
          <div className={`${classes.modalinfo}`}>
            <div className={classes.modalheadcontainer}>
              <div className={`${classes.addclassheader}`}>
                <h4 className={classes.modaltitle}>{props.title}</h4>
                <img
                  onClick={props.closeModal}
                  src={close_btn}
                  alt=""
                />
              </div>
            </div>
            <div className={`${classes.texttag}`}>
              {props.subtitle}
            </div>
            <DialogContent className={`${classes.css1t1j96hMuiPaperrootMuiDialogpaper}`} dividers>
              {props.modalContent}
            </DialogContent>
            <div>
              {props.modalFooter}
            </div>
          </div>
        </div>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default Modal;

const useTruncate = (str, length, ending) => {
  if (length == null) {
    length = 15;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str?.length > 0) {
    if (str?.length > length) {
      return (
        <div
          style={{ cursor: "pointer" }}
        >
          {str.substring(0, length - ending?.length) + ending}
        </div>
      );
    } else {
      return (
        <div
          style={{ cursor: "pointer" }}
        >
          {str}
        </div>
      );
    }
  } else {
    return "";
  }
};

export { useTruncate };

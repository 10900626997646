import useStyles from "./styles";
import { Icon } from "@iconify/react";
// import { useState } from "react";

const SchoolCards = (props) => {
  // const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.overall}`}>
        <div className={`${classes.header}`}>
          <div className={`${classes.ForBCG}`}>
            <Icon icon="majesticons:ship" width="15" height="15" />
          </div>
          {/* <Icon onClick={() => setOpen(!open)} icon="pepicons-pencil:dots-y" width="30" height="30" /> */}
        </div>
        <div>
          <h4>{props.title}</h4>
          <p className={`${classes.content}`}>{props.paragraphs}</p>
        </div>
        <div className={`${classes.header}`}>
          <p>{props.role}</p>
          <div className="table-action-container">
            <button className="table-action-icon" onClick={props.viewClick}>
              <Icon icon="fluent:eye-16-regular" />
            </button>
            <button className="table-action-icon">
              <Icon icon="heroicons:pencil-square-solid" onClick={props.EditClick} />
            </button>
            <button
              className="table-action-icon delete-icon"
              onClick={props.deleteClick}
            >
              <Icon icon="mingcute:delete-2-line" />
            </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default SchoolCards;

import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    // height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingTop: "10px"
  },
  contentpadding: {
    paddingLeft: 10
  },
  paddingleftmodal: {
    paddingLeft: "3%",
    marginBlock: "21px 26px",
    display: "flex",
    gap: "10px",
    width: "100%"
  }
}));

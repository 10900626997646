/* eslint-disable no-console */
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

// styles
const useStyles = makeStyles((theme) => ({
  dotBase: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    transition: theme.transitions.create("background-color")
  },
  dotSmall: {
    width: 8,
    height: 8
  },
  dotLarge: {
    width: 11,
    height: 11
  }
}));

const index = ({ size, color }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotLarge]: size === "large",
        [classes.dotSmall]: size === "small"
      })}
      style={{
        backgroundColor: color
      }}
    />
  );
};

export default index;

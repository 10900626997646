import NotFounditem from "./NotFound";
import Layout from "../../components/Layout";
import access from "../../assets/images/svg/access.svg";

const Permission = () => (
  <Layout children={
    <NotFounditem img={access} title="No Access to this Page" subtitle="You dont have access to this page, Please reach out to your admin for more permissions" />
  }
  />
);

export default Permission;

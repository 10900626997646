import CameraAltIcon from "@mui/icons-material/CameraAlt";
import InputGroup from "../../../components/FormComponent/InputGroup";
import PageTitle from "../../../components/PageTitle";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Grid } from "@mui/material";
import useStyles from "../styles";
import { axiosrequest } from "../../../data/api";
import { useUpload, useEditUpload } from "../../../hooks/useUpload";

const ApplicantDashboard = ({ profile }) => {
  const classes = useStyles();
  const [uploadForm, setUploadForm] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const [fileDisplay, setFileDisplay] = useState();
  const [profilePic, setProfilePic] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [profileForm, setProfileForm] = useState({});

  const APPLICANT_PROFILE_ENDPOINT = "/api/v1/applicant/profile";

  // FOR FILE UPLOAD
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfileForm({ ...profileForm, [name]: value });
  };

  const handleGuardianChange = (e) => {
    const { name, value } = e.target;

    let guardian_info = {};
    guardian_info = { ...guardian_info, [name]: value };

    setProfileForm({ ...profileForm, guardian_info });
  };

  const handleDPChange = (event) => {
    setFileDisplay(event.target.files[0]);
    setProfilePic(URL.createObjectURL(event.target.files[0]));
  };

  const handleEditProfile = async () => {
    setIsUpdating(true);
    if (fileDisplay) {
      // --| If an image with an upload id already exist, edit that image else, upload a new image
      const response = profileDetails?.display_pic?.upload_id ? await useEditUpload(fileDisplay, profileDetails?.display_pic?.upload_id) : await useUpload(fileDisplay);

      if (response.status === 200 && response.data.data) {
        const upload_id = response.data.data?.[0]?.upload_id;
        const file_url = response.data.data?.[0]?.file_url;
        const data = {
          ...profileForm,
          display_pic: { ...profileForm?.display_pic, file_url, upload_id }
        };

        axiosrequest
          .patch(`${APPLICANT_PROFILE_ENDPOINT}`, data)
          .then((res) => {
            setIsUpdating(false);
            toast.success(res?.data?.message);
            window.location.reload();
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(err?.response?.data?.message);
          });
      } else {
        setIsUpdating(false);
        toast.error("Unable to Upload Picture");
      }
    } else {
      // const data = { ...profileDetails };
      // --| Some details wont be needed to run a patch request
      // delete data["id"];
      // delete data["school_id"];
      // delete data["application_no"];
      // delete data["administrator_comment"];
      axiosrequest
        .patch(APPLICANT_PROFILE_ENDPOINT, profileForm)
        .then((res) => {
          setIsUpdating(false);
          toast.success(res?.data?.message);
          window.location.reload();
        })
        .catch((err) => {
          setIsUpdating(false);
          toast.error(err?.response?.data?.message);
        });
    }
    setIsUpdating(false);
  };

  const handleRemoveFile = () => setSelectedFile("");

  useEffect(() => {
    setProfileDetails(profile);
  }, [profile]);

  return (
    <div className={`${classes.border} page-content`}>
      <PageTitle
        title={`${profile?.first_name}'s Dashboard`}
        subtitle="These are some of the details on your application form"
        button={
          <div className="flex gap-10 btn-margin-top-10">
            <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Saves Changes" onClick={handleEditProfile} isLoading={isUpdating} />
          </div>
        }
      />
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className={`${classes.border} left-container`}>
            <h4>Display Picture</h4>
            <div className={classes.profilecontainer}>
              <div className={classes.imageviewer} style={{ backgroundImage: `url(${profilePic || profileDetails?.display_pic?.file_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                {/* <img src={profilePic || profileDetails?.display_pic?.file_url} alt="" /> */}
                <div className={classes.pickercontainer}>
                  <input type="file" name="profile_picture" className={classes.profilepicker} onChange={handleDPChange} />
                  <CameraAltIcon className={classes.camera} />
                </div>
              </div>
            </div>
            <div className={classes.applicationdetails}>
              <h6>Application Number</h6>
              <h4>{profile.application_no}</h4>
            </div>
          </div>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={`${classes.border} right-container`}>
            <Grid container spacing={2} className={classes.profileform}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="First name" inputName="first_name" defaultValue={profile?.first_name} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Last name" inputName="last_name" defaultValue={profile?.last_name} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="email" disabled label="Email address" inputName="email" defaultValue={profile?.email} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Class Applying to" defaultValue={profile?.class?.name} disabled />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="tel" label="Contact" inputName="phone" defaultValue={profile?.phone} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Home Address" inputName="address" defaultValue={profile?.address} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Guardian First Name" inputName="first_name" defaultValue={profile?.guardian_info?.first_name} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Guardian Last Name" inputName="last_name" defaultValue={profile?.guardian_info?.last_name} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="email" label="Guardian Email" inputName="email" defaultValue={profile?.guardian_info?.email} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Guardian Address" inputName="address" defaultValue={profile?.guardian_info?.address} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="tel" label="Guardian Phone Number" inputName="phone" defaultValue={profile?.guardian_info?.phone} onChange={handleGuardianChange} />
              </Grid>
            </Grid>
            <ToastContainer />
          </div>
        </Grid>
      </Grid>
      <div className="margin-top-40 text-align-left">
        <Grid container spacing={2}>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <label className={`${classes.labels}`}>Uploads</label>
            <div className={classes.pickercontain}>
              <input type="file" className={classes.pickers} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />
              <div className={classes.uploadflexs}>
                <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicons} /></div>
                {selectedFile?.name ? (
                  <div>
                    <h2 className={classes.uploadtitles}>{selectedFile?.name}</h2>
                  </div>
                ) :
                  <div>
                    <h2 className={classes.uploadtitles}>Tap to Upload</h2>
                    <h6 className={classes.uploadsubtitles}>SVG,PNG,JPG,GIF| 10MB max.
                    </h6>
                  </div>}
              </div>
              <div className={classes.rightpicker}>
                {selectedFile?.name ? (
                  <div className={classes.uploadbuttons} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} sx={{ paddingTop: "0px" }}>
            {profile?.administrator_comment?.map((comment, i) => (
              <div className="margin-top-12" key={i}>
                <TextAreaGroup
                  label="Administrator's comment"
                  margin="normal"
                  placeholder=""
                  value={comment}
                  disabled
                />
              </div>
            )
            )}
          </Grid>
        </Grid>
      </div>
      <div className="text-align-left">
        <Grid container spacing={2}>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <label className={`${classes.labels}`}>Additional Uploads</label>
            <div className={classes.pickercontain}>
              <input type="file" className={classes.pickers} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

              <div className={classes.uploadflexs}>
                <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicons} /></div>
                {selectedFile?.name ? (
                  <div>
                    <h2 className={classes.uploadtitles}>{selectedFile?.name}</h2>
                  </div>
                ) :
                  <div>
                    <h2 className={classes.uploadtitles}>Tap to Upload</h2>
                    <h6 className={classes.uploadsubtitles}>SVG,PNG,JPG,GIF| 10MB max.
                    </h6>
                  </div>}
              </div>
              <div className={classes.rightpicker}>
                {selectedFile?.name ? (
                  <div className={classes.uploadbuttons} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="margin-top-12">
              <TextAreaGroup
                label="Administrator's comment"
                margin="normal"
                placeholder=""
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ApplicantDashboard;

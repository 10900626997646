import Layout from "../../components/Layout";
import { Grid } from "@mui/material";
import useStyles from "./styles";
import Button from "../../components/Button";
import { useState, useEffect } from "react";
import InputGroup from "../../components/FormComponent/InputGroup";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import BreadCrumb from "../../components/BreadCrumb";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import { useParams } from "react-router-dom";

const INVENTORY_ENDPOINT = "/api/v1/inventory";
const INVENTORY_CATEGORY_ENDPOINT = "/api/v1/inventory/category";
const EMPLOYEES_ENDPOINT = "/api/v1/employees";

const EditInventory = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryCategory, setInventoryCategory] = useState([]);
  const [inventoryValidator, showInventoryValidator] = useValidator();
  const [employeeData, setEmployeesData] = useState([]);
  const { id } = useParams();

  const [editInventoryForm, setEditInventoryForm] = useState({
    name: "",
    description: "",
    category: "",
    employee_in_charge: "",
    quantity: "",
    condition: "",
    status: ""
  });

  const getAllInventoryCategory = () => {
    httprequest.get(INVENTORY_CATEGORY_ENDPOINT).then((res) => {
      setInventoryCategory(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getCurentInventory = () => {
    httprequest.get(`${INVENTORY_ENDPOINT}/${id}`).then((res) => {
      setEditInventoryForm({ ...editInventoryForm,
        name: res?.data?.data?.name,
        description: res?.data?.data?.description,
        category: res?.data?.data?.category,
        employee_in_charge: res?.data?.data?.employee_in_charge,
        quantity: res?.data?.data?.quantity,
        status: res?.data?.data?.status,
        condition: res?.data?.data?.condition });
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllEmployees = () => {
    httprequest.get(EMPLOYEES_ENDPOINT).then((res) => {
      setEmployeesData(res?.data?.data?.employees);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleEditInventory = () => {
    if (inventoryValidator.allValid()) {
      setIsLoading(true);
      httprequest.patch(`${INVENTORY_ENDPOINT}/${id}`, editInventoryForm).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        setEditInventoryForm({ ...editInventoryForm, name: "", description: "", category: "", employee_in_charge: "", quantity: "", condition: "" });
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showInventoryValidator(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditInventoryForm({ ...editInventoryForm, [name]: value });
  };

  useEffect(() => {
    getAllInventoryCategory();
    getAllEmployees();
    getCurentInventory();
  }, []);

  const { name, description, category, employee_in_charge, quantity, condition, status } = editInventoryForm;

  return (
    <Layout children={
      <>
        <BreadCrumb
          link_one="/manage/inventory"
          link_one_name="Inventory"
          active="Edit Inventory"
          description="Fill in the details to edit current inventory."
        />
        <Grid container spacing={2} my={4} className={classes.createFormContainer}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputGroup
              label="Product Name"
              margin="normal"
              placeHolder="Enter product name"
              inputType="text"
              inputName="name"
              value={name}
              onChange={handleChange}
            />
            {inventoryValidator.message("Product Name", name, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputGroup
              label="Description"
              margin="normal"
              inputType="text"
              inputName="description"
              value={description}
              onChange={handleChange}
            />
            {inventoryValidator.message("Description", description, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup label="Category" children={
              <select onChange={handleChange} name="category" value={category}>
                <option value="">--- Select ---</option>
                {inventoryCategory?.map((data) => {
                  return (
                    <option key={data?.id} value={data?.id}>{data?.category}</option>
                  );
                })}
              </select>
            }
            />
            {inventoryValidator.message("Category", category, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputGroup
              label="Quantity"
              margin="normal"
              placeHolder=""
              inputType="number"
              inputName="quantity"
              value={quantity}
              onChange={handleChange}
            />
            {inventoryValidator.message("Quantity", quantity, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup label="Condition" children={
              <select name="condition" onChange={handleChange} value={condition}>
                <option value="">--- Select ---</option>
                <option value="new">New</option>
                <option value="used">Used</option>
              </select>
            }
            />
            {inventoryValidator.message("Condition", condition, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup label="Teacher in Charge" children={
              <select onChange={handleChange} name="employee_in_charge" value={employee_in_charge}>
                <option value="">--- Select ---</option>
                {employeeData?.map((employee) => {
                  return (
                    <option key={employee?.member_id} value={employee?.member_id}>{employee?.first_name}{" "}{employee?.last_name}</option>
                  );
                })}
              </select>
            }
            />
            {inventoryValidator.message("Employee", employee_in_charge, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup label="Status" children={
              <select onChange={handleChange} name="status" value={status}>
                <option value="">--- Select ---</option>
                <option value="archived">Archived</option>
                <option value="available">Available</option>
                <option value="broken">Broken</option>
              </select>
            }
            />
            {inventoryValidator.message("Status", status, "required")}
          </Grid>
        </Grid>
        <Button variant="primary" buttonSize="medium" color="btndefault" buttonName="Edit Inventory" onClick={handleEditInventory} isLoading={isLoading} />
        <ToastContainer />
      </>
    }
    />
  );
};

export default EditInventory;

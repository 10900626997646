/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import InputGroup from "../../components/FormComponent/InputGroup";
import ButtonCount from "../../components/ButtonCount";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modal";
import { Gender } from "../../utils/DropDown";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import { TableSearch } from "../../components/Table/TableActions";
import { useNavigate } from "react-router-dom";
import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import Select from "react-select";
import useValidator from "../../hooks/useValidator";

const GET_GUARDIAN_ENDPOINT = "/api/v1/guardians";
const CREATE_GUARDIAN_ENDPOINT = "/api/v1/guardian";
const STUDENTS_ENDPOINT = "/api/v1/students";

const Guardian = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState({});
  const [broadcastModal, setBroadcastModal] = useState(false);
  const [createguardian, setCreateGuardian] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [guardianData, setGuardianData] = useState([]);
  const [multipleStudents, setMultipleStudents] = useState([]);
  const [guardianValidator, showCreateValidationMessage] = useValidator();
  const [isCreating, setIsCreating] = useState(false);
  const [guardianArr, setGuardianArr] = useState([{
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    contact: "",
    address: "",
    type: "primary"
  }]);
  const [guardianForm, setGuardianForm] = useState({
    wards: multipleStudents, guardians: guardianArr
  });

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleBroadcastModal = () => {
    setBroadcastModal(!broadcastModal);
  };

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    const allGuardian = [...guardianArr];
    allGuardian[index][name] = value;
    setGuardianArr(allGuardian);
    setGuardianForm({ ...guardianForm, guardians: allGuardian });
  };

  const handleIsType = (e, index) => {
    const { name, checked } = e.target;
    const allGuardian = [...guardianArr];
    const value = checked && "primary";

    // loop through all the array and set types to secondary
    allGuardian[index][name] = value;
    const updatedGuardian = allGuardian.map((obj, ind) => {
      if (ind !== index) {
        return { ...obj, type: "secondary" };
      }

      return obj;
    });

    setGuardianForm({ ...guardianForm, guardians: updatedGuardian });
  };

  const AddAdditionalGuardian = () => {
    const newGuardian = {
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      contact: "",
      address: "",
      type: "secondary"
    };
    setGuardianArr([...guardianArr, newGuardian]);
    setGuardianForm({ ...guardianForm, guardians: [...guardianArr, newGuardian] });
  };

  const handleDeleteGuardianItem = (e, index) => {
    e.preventDefault();
    const newGuardian = guardianArr.filter((item) => {
      return index !== guardianArr.indexOf(item);
    });

    setGuardianArr(newGuardian);
    setGuardianForm({ ...guardianForm, guardians: newGuardian });
  };

  const handleMultipleWardChange = (event) => {
    const selectedValue = event.map((item) => item.value);

    setGuardianForm({ ...guardianForm, wards: selectedValue });
  };

  const handleCreateGuardianModal = () => {
    setCreateGuardian(!createguardian);
  };

  const handleCloseGuardianModal = () => {
    setCreateGuardian(false);

    // --| reloading the page to clear the fields
    window.location.reload();
  };

  const getAllGuardian = () => {
    setIsPageFetching(true);
    httprequest.get(GET_GUARDIAN_ENDPOINT).then((res) => {
      setIsPageFetching(false);
      setGuardianData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsPageFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const getAllStudents = () => {
    httprequest.get(STUDENTS_ENDPOINT).then((res) => {
      setMultipleStudents(res?.data?.data?.map((student) => {
        return {
          label: student?.first_name + " " + student?.last_name,
          value: student.student_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCreateGuardian = () => {
    if (guardianValidator.allValid()) {
      setIsCreating(true);
      httprequest.post(CREATE_GUARDIAN_ENDPOINT, guardianForm).then(() => {
        setIsCreating(false);
        toast.success("Guardian Created and Invite sent successfully");
        getAllGuardian();
        handleCloseGuardianModal();
      }).catch((err) => {
        setIsCreating(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showCreateValidationMessage(true);
    }
  };

  useEffect(() => {
    // The data will be from the Guardian Api Endpoint
    getAllGuardian();
    // Get all student data to prefill the multi student drop down
    getAllStudents();
  }, []);

  const columns = [
    {
      name: "Guardian Name",
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email(s)",
      selector: (row) => row?.email
    },
    {
      name: "Number of wards",
      selector: (row) => row?.no_of_wards
    },
    {
      name: "Contact",
      selector: (row) => row?.contact
    },
    {
      name: "Type",
      center: "true",
      cell: (row) => (<Badge status={row?.type} />)
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            // onClick={() => {
            //   handleAddscoreModal(row);
            // }}
            className="table-action-icon"
          >
            <Icon icon="ic:outline-folder" />
          </button>
          <button onClick={() => navigate(`/users/guardians/${row?.guardian_id}`)} className="table-action-icon">
            <Icon icon="codicon:eye" />
          </button>
          <button onClick={handleDeleteModal} className="table-action-icon delete-icon">
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Guardian table using name, email and class
  const handleSearchStudent = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = guardianData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.description
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.category
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.status
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Guardians"
            subtitle="View all the guardians in your school"
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={handleBroadcastModal}
                  buttonName="Send Broadcast to parents"
                />
                <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  onClick={handleCreateGuardianModal}
                  buttonName="Create Guardian"
                />
              </div>
            }
          />
          <div className={classes.pageContent}>
            {isPageFetching ? (
              <PageLoader />
            ) : (
              <>
                <div className="button-count-container">
                  <ButtonCount func={() => navigate("")} title="Guardian Count" count={guardianData?.length} />
                </div>
                <Table
                  data={searchResult}
                  columns={columns}
                  subHeader={true}
                  pagination
                  subHeaderComponent={
                    <TableSearch
                      placeholder="Search here..."
                      searchTableFunc={handleSearchStudent}
                    />
                  }
                />
              </>
            )}
          </div>
          {/* Send Broadcast Modal */}
          <Modal
            title="Send Broadcast to parents."
            subtitle={
              <div>Send a broadcast message to the parents of the students in this class
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Broadcast To:"
                      margin="normal"
                      placeHolder="Parent Email"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Broadcast Message"
                      margin="normal"
                      placeholder="Enter the message you will like to share with the parents."
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information</label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                </div>
              </>
            }
            isopen={broadcastModal}
            closeModal={handleBroadcastModal}
          />
          {/* Create a Guardian Modal */}
          <Modal
            title="Create Guardian"
            subtitle={
              <div>Create Guardian
              </div>}
            modalContent={
              <>
                <div>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="multiple-select">
                      <p>Wards / Children</p>
                      <Select isMulti name="wards"
                        closeMenuOnSelect={true} options={multipleStudents} onChange={handleMultipleWardChange}
                      />
                    </div>
                    {guardianValidator.message(
                      "wards",
                      guardianForm.wards,
                      "required"
                    )}
                  </Grid>
                  {guardianArr?.map((item, i) => {
                    return (
                      <Grid container spacing={2} className="input-padding" key={i}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Guardian First Name"
                            margin="normal"
                            inputType="text"
                            inputName="first_name"
                            value={item.first_name}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "first_name",
                            guardianArr[i]["first_name"],
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Guardian Last Name"
                            margin="normal"
                            inputType="text"
                            inputName="last_name"
                            value={item.last_name}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "last_name",
                            guardianArr[i]["last_name"],
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <SelectGroup label="Gender" children={
                            <select name="gender" onChange={(e) => handleChange(e, i)}>
                              <option value="">--- Select Gender ---</option>
                              {Gender?.map((label) => {
                                return (
                                  <option key={label.key}>{label.text}</option>
                                );
                              })}
                            </select>
                          }
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Email"
                            margin="normal"
                            inputType="email"
                            inputName="email"
                            value={item.email}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "email",
                            guardianArr[i]["email"],
                            "required|email"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Contact"
                            margin="normal"
                            placeHolder="Enter contact info"
                            inputType="tel"
                            inputName="contact"
                            value={item.contact}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "contact",
                            guardianArr[i]["contact"],
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Guardian Address"
                            margin="normal"
                            placeHolder="Enter Address"
                            inputType="address"
                            inputName="address"
                            value={item.address}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <>
                            <input type="radio" name="type" defaultChecked={item?.type === "primary"} id="type" onChange={(e) => {
                              handleIsType(e, i);
                            }}
                            />
                            <label className="checkbox-label" htmlFor="current_term">Primary Guardian</label>
                          </>
                          {guardianValidator.message(
                            "Type",
                            guardianArr[i]["type"],
                            "required"
                          )}
                        </Grid>
                        {guardianArr?.length > 1 && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className={`${classes.remove} flex align-items-center`} onClick={(e) => handleDeleteGuardianItem(e, i)}>
                              <Icon icon="zondicons:minus-solid" width="16" height="16" />
                              <p>Remove this Guardian</p>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                  {guardianArr?.length < 2 && (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={`${classes.addextra} flex align-items-center`} onClick={AddAdditionalGuardian}>
                        <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                        <p>Add Secondary Guardian</p>
                      </div>
                    </Grid>
                  )}
                </div>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Create Guardian" onClick={handleCreateGuardian} isLoading={isCreating} />
                </div>
              </>
            }
            isopen={createguardian}
            closeModal={handleCloseGuardianModal}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Guardian Information"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. Deleting is a permanent action`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Inventory" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default Guardian;

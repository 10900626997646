import Layout from "../../components/Layout";
import AccessActivities from "./admin/AccessActivities";

const Access = () => {
  return (
    <Layout children={
      <>
        <AccessActivities />
      </>
    }
    />
  );
};

export default Access;

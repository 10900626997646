import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, Link } from "react-router-dom";
import { useTruncate } from "../../hooks/useTruncate";
import Dot from "../../components/Dot";
import MenuIcon from "@mui/icons-material/Menu";
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import classNames from "classnames";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/images/logo.svg";

// styles
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { httprequest } from "../../data/api";
import PageLoader from "../PageLoader";
import { toast } from "react-toastify";

// All Notification
const ALL_NOTIFICATION = "/api/v1/notifications";

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { appLogo } = useUserState();
  const [anchorEl, setAnchorEl] = useState();
  const notificationTab = Boolean(anchorEl);
  const [allNotification, setAllNotification] = useState([]);
  const [unReadNotifications, setUnReadNotifications] = useState([]);
  const [isNotificationFetching, setIsNotificationFetching] = useState(false);
  const { isSidebarOpened } = useLayoutState();
  const theme = useTheme();
  const windowWidth = window.innerWidth;
  const breakpointWidth = theme.breakpoints.values.md;
  const isSmallScreen = windowWidth < breakpointWidth;

  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  const layoutDispatch = useLayoutDispatch();

  const handleNotificationClicked = (e, id) => {
    e.preventDefault();
    navigate(`/notifications/${id}`);
    handleCloseNotification();
  };

  const handleClickNotificationIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getAllNotifications = () => {
    setIsNotificationFetching(true);
    httprequest.get(ALL_NOTIFICATION).then((res) => {
      setIsNotificationFetching(false);
      setAllNotification(res?.data?.data);
      setUnReadNotifications(res?.data?.data?.filter((notif) => !notif?.read));
    })
      .catch((err) => {
        setIsNotificationFetching(false);
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>

        <div className={classes.logotype}>
          <img src={appLogo || logo} alt="App logo" className={classes.logo} />
        </div>
        <Icon icon="icon-park-outline:avatar" />
        <div className="flex gap-10">
          <Link className={classes.footerlink} to="/profile">
            <div className={classes.footercontainer}>
              <div className={classes.initials}>
                <Icon icon="icon-park-outline:avatar" width="17" height="17" />
              </div>
            </div>
          </Link>
          <div onClick={() => navigate("/site-map")} className={classes.initials}>
            <Icon icon="ic:round-dashboard" width="17" height="17" />
          </div>
          <IconButton
            color="inherit"
            aria-haspopup="true"
            aria-controls="mail-menu"
            open={notificationTab}
            aria-expanded="false"
            onClick={handleClickNotificationIcon}
            className={classes.headerMenuButton}
            style={{ padding: 0 }}
          >
            <div className={classes.alertbadge}>{unReadNotifications?.length || 0}</div>
            <div className={classes.initials}>
              <Icon icon="clarity:notification-solid" width="17" height="17" />
            </div>
          </IconButton>
          {isSmallScreen && !isSidebarOpened && (
            <div className={classes.mobileBackButton}>
              <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                <MenuIcon
                  classes={{
                    root: classNames(classes.headerIcon, classes.headerIconCollapse)
                  }}
                />
              </IconButton>
            </div>
          )}
          <Menu
            id="notifications-menu"
            open={notificationTab}
            anchorEl={anchorEl}
            onClose={handleCloseNotification}
            className={classes.headerMenu}
            disableAutoFocusItem
            disabled
          >
            <MenuItem>
              <div className={`${classes.notifyheader} flex gap-10 align-items-center justify-items-space`}>
                <h3>Notification</h3>
                <div className={classes.notificationbadge}>
                  {unReadNotifications?.length} unread
                </div>
              </div>
            </MenuItem>
            {isNotificationFetching ? (
              <PageLoader />
            ) :
              <div>
                {allNotification
                  .sort((a, b) => (a.read === b.read ? 0 : a.read ? 1 : -1))
                  .map((notification) => {
                    const Activity = notification?.read === true ? <Dot size="small" color="#98A2B3" /> : <Dot size="small" color="green" />;

                    return (

                      <MenuItem
                        key={notification.id}
                        disableRipple
                        onClick={(e) => handleNotificationClicked(e, notification.id)}
                        className={classes.headerMenuItem}
                        style={{ justifyContent: "space-between" }}
                      >

                        <div className={`${classes.notifyitems}`}>
                          <div className="flex justify-items-space align-items-center flex-wrap-items-426 w-100">
                            <div className={`${classes.flexresponsive}`}>
                              <h4>{useTruncate(notification?.message, 38)}</h4>
                              <p>{notification?.created_at?.substring(0, 10)}</p>
                            </div>
                            <div className={`${classes.notificationtime}`}>
                              <div className="margin-left">{Activity}</div>
                              {/* <p>10mins ago</p> */}
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
              </div>
            }
            <MenuItem style={{ justifyContent: "flex-end" }}>
              <div onClick={() => navigate("/notifications")} className={`${classes.notifyfooter} flex gap-10 align-items-center justify-flex-end`}>
                <p>See all notification</p>
                <div><Icon icon="clarity:notification-solid" width="17" height="17" color="#0065FF" /></div>
              </div>
            </MenuItem>
          </Menu>
        </div>

      </Toolbar>
    </AppBar>
  );
};

export default Header;

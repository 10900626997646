/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
// import PageTitle from "../../components/PageTitle/PageTitle";
import BreadCrumb from "../../components/BreadCrumb";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import { Icon } from "@iconify/react";
import Button from "../../components/Button";
import { Grid } from "@mui/material";
import Modal from "../../components/Modal";
import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import styles from "./studentApplication.module.css";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import EnrolBanner from "../../assets/images/enrolbanner.jpg";

const EnrolmentDetails = () => {
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState({});
  const [addinformationModal, setInformationModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [enrolmentInfoData, setEnrolmentInfoData] = useState([]);
  const [applicantsData, setApplicantsData] = useState([]);
  const [applicantsFilter, setApplicantsFilter] = useState([]);
  const { id } = useParams();
  const ENROLMENT_ENDPOINT = "/api/v1/enrolment";

  const getEnrolmentDetails = () => {
    httprequest.get(`${ENROLMENT_ENDPOINT}/${id}`).then((res) => {
      setEnrolmentInfoData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getApplicantInEnrolment = () => {
    httprequest.get(`${ENROLMENT_ENDPOINT}/applicants/${id}`).then((res) => {
      setApplicantsData(res?.data?.data);
      setApplicantsFilter(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleViewModal = (row) => {
    setInformationModal(!addinformationModal);
    setCurrentRow(row);
  };

  const handleDownloadModal = (row) => {
    setDownloadModal(!downloadModal);
    setCurrentRow(row);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    getEnrolmentDetails();
    getApplicantInEnrolment();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: row => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Class",
      selector: row => row?.class?.name
    },
    {
      name: "Parent/Guardian",
      selector: row => row?.guardian_info?.first_name + row?.guardian_info?.last_name
    },
    {
      name: "Contact",
      selector: row => row?.phone
    },
    {
      name: "Application No",
      selector: row => row?.application_no
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.application_status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              handleViewModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            onClick={() => {
              handleDownloadModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="ic:outline-folder" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = applicantsData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                      data?.last_name
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                      data?.guardian_info?.first_name
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                        data?.guardian_info?.first_name
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                        data?.application_status
                          ?.toLowerCase()
                          .includes(word.toLowerCase())
    )
    );
    setApplicantsFilter(result);
  };

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/enrolments"
              link_one_name="Enrolments"
              active="Enrolment Information"
              description={
                <>
                  <div>See all the details about your enrolment and applications in it</div>
                  <div style={{ marginTop: "5px" }}>Classes: {enrolmentInfoData?.classes?.map((clss, i) => <span className={classes.enrolinfoclass} key={i}> {clss?.class_name}</span>)}</div>
                </>}
            />
          </div>
        </div>
        <div className="page-content">
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.enrolmentdetailsheader} style={{ backgroundImage: `url(${enrolmentInfoData?.banner?.file_url || EnrolBanner})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
              </div>
            </Grid>

          </Grid>
          <Table data={applicantsFilter} columns={columns} subHeader={true} pagination subHeaderComponent={
            <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
          }
          />

          {/* Application Information Modal */}
          <Modal
            title="Application Information"
            subtitle={
              <div>You can edit the information of the applicant below.
              </div>}
            modalContent={
              <>
                <div className="flex gap-10 align-items-center margin-top">
                  {/* <div className={classes.applicationdisplaypic}> */}
                  <div className={classes.displaypic}>
                    {currentRow?.photo || <Icon icon="radix-icons:person" />}
                  </div>
                  <div>
                    <h5 className={`${styles.noMargin}`}>{`${currentRow?.first_name + " " + currentRow?.last_name}`}</h5>
                    <p className={`${styles.noMargin}`}>{`Application No: ${currentRow?.application_number}`}</p>
                  </div>
                  {/* </div> */}
                </div>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>

                    <div className="key-pair-container">
                      <div className="key-pair-content">
                        <p className="key-pair-name">Full Name</p>
                        <h3 className="key-pair-value">{currentRow?.first_name + " " + currentRow?.last_name}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Class Applying to</p>
                        <h3 className="key-pair-value">{currentRow?.last_name}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Email Address</p>
                        <h3 className="key-pair-value">{currentRow?.email}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Phone Number</p>
                        <h3 className="key-pair-value">{currentRow?.phone}</h3>
                      </div>
                      <h3>Guardian Information</h3>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Guardian Name</p>
                        <h3 className="key-pair-value">{currentRow?.guardian_info?.first_name + " " + currentRow?.guardian_info?.last_name}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Guardian Phone</p>
                        <h3 className="key-pair-value">{currentRow?.guardian_info?.phone}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Relationship</p>
                        <h3 className="key-pair-value">{currentRow?.guardian_info?.relationship}</h3>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Reject Application" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Invite for Examniation" />
                  </Grid>

                </Grid>
              </>
            }
            isopen={addinformationModal}
            closeModal={handleViewModal}
          />
          {/* Result Modal */}
          <Modal
            title="Application Results"
            subtitle={
              <div>See the result submitted by the candidate.
              </div>}
            modalContent={
              <>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Download Receipt" />
                </div>
              </>
            }
            isopen={downloadModal}
            closeModal={handleDownloadModal}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Student Information"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.name}</span>. If deleted, employee will no longer have access to this Informations.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Information" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default EnrolmentDetails;

/* eslint-disable nonblock-statement-body-position */
import { useRef, useState, useEffect } from "react";
import PageTitle from "../../../../components/PageTitle";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import useStyles from "../../styles";
// import { Numbers } from "../../../../utils/DropDown";
import QuestionText from "../component/QuestionText";
import PageLoader from "../../../../components/PageLoader";
import ProgressBar from "@ramonak/react-progress-bar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import { axiosrequest } from "../../../../data/api";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";

const APPLICANT_EXAM = "/api/v1/applicant/exams";

const Examination = () => {
  const { id } = useParams();
  // const navigate = useNavigate();
  const [applicantExam, setApplicantExam] = useState({});
  const [percentage, setPercentage] = useState(100);
  const [timer, setTimer] = useState("00:00:00");
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [activeQuestion, setActiveQuestion] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedAnswer, setSelectedAnswer] = useState({
    answers: []
  });
  // eslint-disable-next-line no-unused-vars
  // const [allAnswers, setAllAnswers] = useState([]);

  const countdownWarningTime = 20;
  const totalTime = (applicantExam?.duration) * 0;

  const Ref = useRef(null);

  // get Applicant Exam
  const getApplicantExam = () => {
    setIsLoading(true);
    axiosrequest.get(`${APPLICANT_EXAM}/${id}`).then((res) => {
      setIsLoading(false);
      setApplicantExam(res?.data?.data);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const getTimeRemaining = (e) => {
    const total =
          Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
      (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24
    );

    return {
      total,
      hours,
      minutes,
      seconds
    };
  };

  // for Submit Exam
  const handleFinalSubmit = () => {
    axiosrequest
      .patch(`${APPLICANT_EXAM}/${id}`, {
        answers: selectedAnswer.answers
      })
      .then(() => {
        toast.success("Answers submitted successfully");
        window.location.href = "/student-application";
      })
      .catch(() => {
        toast.error("Failed to submit answers");
      });
  };

  const startTimer = (e) => {
    const { total, hours, minutes, seconds } =
          getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        `${hours > 9 ? hours : `0${hours}`
        }:${
          minutes > 9
            ? minutes
            : `0${minutes}`
        }:${
          seconds > 9 ? seconds : `0${seconds}`}`
      );

      // Calculate time in percentage
      const elapsedTime = (totalTime - (total / 1000));
      const percentRemaining = Math.round((elapsedTime / totalTime) * 100);
      setPercentage(percentRemaining);

      if (total / 1000 <= countdownWarningTime) {
        handleFinalSubmit();
      }
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:00");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + totalTime);

    return (deadline);
  };

  // eslint-disable-next-line no-console
  // console.log(applicantExam);

  // post each answer that's clicked
  const handleAnswerClick = (questionIndex, answer, type) => {
    // eslint-disable-next-line no-console
    console.log(type);
    // const updatedAnswers = [...allAnswers];
    // eslint-disable-next-line no-console
    // console.log(updatedAnswers);

    const submitAnswers = (body) => {
      axiosrequest.patch(`${APPLICANT_EXAM}/${id}`, {
        answers: body
      });
    };

    if (type === "checkbox") {
      setSelectedAnswer((prev) => {
        const updatedAnswers = [...prev.answers];
        const existingAnswer = updatedAnswers.find(
          (a) => a.question_index === questionIndex
        );

        if (existingAnswer) {
        // Check if the answer already exists in the array
          const answerIndex = existingAnswer?.answer.indexOf(answer);
          if (answerIndex !== -1) {
          // If the answer exists, remove it (uncheck)
            if (existingAnswer.answer?.length > 1) {
              existingAnswer.answer.splice(answerIndex, 1);
            }
          } else {
          // If the answer does not exist, add it (check)
            existingAnswer.answer.push(answer);
          }
        } else {
          updatedAnswers.push({ question_index: questionIndex, answer: [answer] });
        }

        submitAnswers(updatedAnswers);

        return { answers: updatedAnswers };
      });
    } else if (type === "multiple_choice") {
      setSelectedAnswer((prev) => {
        const updatedAnswers = [...prev.answers];
        const existingAnswer = updatedAnswers.find(
          (a) => a.question_index === questionIndex
        );

        if (existingAnswer) {
          existingAnswer["answer"] = [`${answer}`];
        } else {
          updatedAnswers.push({ question_index: questionIndex, answer: [answer] });
        }

        submitAnswers(updatedAnswers);

        return { answers: updatedAnswers };
      });
    }
  };

  useEffect(() => {
    getApplicantExam();
  }, []);

  useEffect(() => {
    clearTimer(getDeadTime());

    // Clean up the timer when the component unmounts
    return () => {
      if (Ref.current) clearInterval(Ref.current);
    };
  }, []);

  // Navigate to clicked question
  const handleNavigateToQuestion = (index) => {
    setActiveQuestion(index);
    const idElement = document.getElementById(`question_${index}`);
    if (idElement) {
      idElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isQuestionAnswered = (questionIndex) => {
    return selectedAnswer?.answers.some(
      (a) => a?.question_index === questionIndex
    );
  };

  return (
    <div className={`${classes.mainpage} page-content`}>
      <PageTitle
        title="Mathematics Examination for 2023/2024 Academic Session"
        subtitle="Please answer all the information below, all the lorem ipsum, lorem ipsum lorem ipsum.."
      />
      <div className="margin-top-30">
        <Grid container spacing={2} sx={{
          flexWrap: isSmallScreen ? "wrap-reverse" : "nowrap"
        }}
        >
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <div className={`${classes.progressbar} padding-inline-10`}>
              <ProgressBar completed={100 - percentage} baseBgColor="#F0F2F5" bgColor="#28AE00" labelSize="12px" height="14px" />
              <div className={classes.timeleft}>
                <h2>Time Remaining: {timer}</h2>
              </div>
            </div>
            <div className="container-y-overflow padding-inline-10">
              <div>
                {isLoading ? (
                  <PageLoader />
                ) : (
                  <>
                    {applicantExam && applicantExam?.questions && applicantExam?.questions.map((question, index) => (
                      <QuestionText
                        id={`question_${index + 1}`}
                        key={index + 1}
                        item={question}
                        onAnswerClick={(answer) => handleAnswerClick(question?.question_index, answer, question?.type)}
                      />
                    ))}
                  </>
                )}
              </div>
              <div className={classes.submitbtn}>
                <Button onClick={handleFinalSubmit} variant="primary" buttonName="Submit Exam" buttonSize="full" color="btndefault" />
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className={`${classes.rightcont}`}>
              <div className={classes.timercont}>
                <h5>FINISH BEFORE</h5>
                <div className={classes.times}>
                  <CircularProgressbar counterClockwise background value={100 - percentage} text={timer} strokeWidth={5}
                    styles={buildStyles({
                      textColor: "#000000",
                      pathColor: "#28AE00",
                      trailColor: "#FFF",
                      textSize: "20px",
                      strokeLinecap: "butt",
                      textWeight: "400"
                    })}
                  />
                </div>
              </div>
              <div className={`${classes.questioncont}`}>
                <h5>QUESTIONS</h5>
                <Grid container spacing={1}>
                  {applicantExam && applicantExam?.questions ? (
                    applicantExam?.questions.map((queNumber, index) => (
                      <Grid item key={index} lg={4} md={4} sm={1} xs={2}>
                        <p onClick={() => handleNavigateToQuestion(index + 1)}
                          style={{
                            backgroundColor: isQuestionAnswered(queNumber?.question_index) ? "#d4edda" : (activeQuestion === index + 1 ? "#F0F2F5" : "transparent"),
                            fontWeight: activeQuestion === index + 1 ? "bold" : "normal"
                          }}
                        >{queNumber.question_index}
                        </p>
                      </Grid>
                    ))
                  ) : (
                    <p>Please Wait ...</p>
                  )}
                </Grid>
                <Button onClick={handleFinalSubmit} variant="primary" buttonName="Submit Exam" buttonSize="full" color="btndefault" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Examination;

import useStyles from "./styles";
import { Link } from "react-router-dom";

const QuickCard = ({ icon, title, backgroundColor, borderColor, to }) => {
  const classes = useStyles({ backgroundColor, borderColor });

  return (
    <Link className={classes.linkto} to={to}>
      <div className={classes.quickcard}>
        <div className={classes.icon}>{icon}</div>
        <p>{title}</p>
      </div>
    </Link>
  );
};

export default QuickCard;

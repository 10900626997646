import Layout from "../../components/Layout";
import Table from "../../components/Table/Table";
import { Typography } from "@mui/material";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useState } from "react";
import useStyles from "../perfeval/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TableSearch } from "../../components/Table/TableActions";
import BreadCrumb from "../../components/BreadCrumb";

const LeaveMgtSetting = () => {
  const [modalisopen, setModalIsOpen] = useState(false);
  const classes = useStyles();
  const [leave, setLeave] = useState("");
  const handleChange = (e) => {
    setLeave(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name
    },
    {
      name: "Role",
      selector: (row) => row?.role
    },
    {
      name: "Level",
      selector: (row) => row?.level
    },
    {
      name: "Leave Status",
      selector: (row) => row?.levelStatus
    },
    {
      name: "Leave Type",
      selector: (row) => row?.leaveType
    },
    {
      name: " ",
      selector: (row) => row?.contact
    }
  ];
  const data = [];

  return (
    <Layout
      children={
        <>
          <div className="flex justify">
            <div>
              <BreadCrumb
                link_one="/manage/leave"
                link_one_name="Leave Management"
                active="Leave Management Setting"
                description="See the leave status off your employees and team members."
              />
            </div>
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={() => {
                setModalIsOpen(true);
              }}
              buttonName="Add Leave Options"
            />
          </div>
          <Table
            data={data}
            columns={columns}
            subHeader={true}
            subHeaderComponent={<TableSearch placeholder="Search here..." />}
          />
          <Modal
            title="Add New Leave option for your teachers"
            subtitle="Setting leave slots give your employees leave options to choose."
            modalContent={
              <>
                <Typography gutterBottom>
                  <div>
                    <label
                      htmlFor=""
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        marginTop: "16px",
                        marginBottom: "6px"
                      }}
                    >
                      Leave Type
                    </label>
                    <FormControl fullWidth>
                      <InputLabel>Leave Type</InputLabel>
                      <Select
                        value={leave}
                        label="Leave Type"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Annual Leave</MenuItem>
                        <MenuItem value={20}>Emergency Leave</MenuItem>
                        <MenuItem value={30}>Food Leave</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Typography>
                <Typography className="flex gap-10" gutterBottom>
                  <div style={{ width: "100%" }}>
                    <label
                      htmlFor=""
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        marginTop: "16px",
                        marginBottom: "6px"
                      }}
                    >
                      Number of Days
                    </label>
                    <FormControl fullWidth>
                      <InputLabel>Leave Type</InputLabel>
                      <Select
                        value={leave}
                        label="Leave Type"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>5</MenuItem>
                        <MenuItem value={20}>10</MenuItem>
                        <MenuItem value={30}>14</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{ marginBottom: "16px", width: "100%" }}
                    className={`${classes.subject_divide_items}`}
                  >
                    <div>
                      <label
                        htmlFor=""
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          marginTop: "16px",
                          marginBottom: "6px"
                        }}
                      >
                        Who can take leave
                      </label>
                      <FormControl fullWidth>
                        <InputLabel>Who can take leave</InputLabel>
                        <Select
                          value={leave}
                          label="Number Of Days"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Employee</MenuItem>
                          <MenuItem value={20}>Director</MenuItem>
                          <MenuItem value={30}>CEO</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Typography>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  variant="primary"
                  buttonName="Send Leave Request"
                  buttonSize="full"
                  color="btndefault"
                />
              </>
            }
            isopen={modalisopen}
            closeModal={() => {
              setModalIsOpen(false);
            }}
          />
        </>
      }
    />
  );
};

export default LeaveMgtSetting;

import useStyles from "../styles";

const DetailsText = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailstext}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
    </div>
  );
};

export default DetailsText;

import { createContext, useReducer, useContext } from "react";

const LayoutStateContext = createContext();
const LayoutDispatchContext = createContext();

const windowWidth = window.innerWidth;
const breakpointWidth = 900;
const isSmallScreen = windowWidth < breakpointWidth;

function layoutReducer(state, action) {
  switch (action.type) {
  case "TOGGLE_SIDEBAR":
    return { ...state, isSidebarOpened: !state.isSidebarOpened };
  case "TOGGLE_SIDEBAR_ON":
    return { ...state, isSidebarOpened: true };
  case "TOGGLE_SIDEBAR_OFF":
    return { ...state, isSidebarOpened: false };
  case "TOGGLE_PERMANENT":
    return { ...state, isPermanent: !state.isPermanent };
  default: {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  }
}

function LayoutProvider({ children }) {
  const [state, dispatch] = useReducer(layoutReducer, {
    isPermanent: true,
    isSidebarOpened: !isSmallScreen
  });

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  const context = useContext(LayoutStateContext);
  // eslint-disable-next-line no-undefined
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }

  return context;
}

function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR"
  });
}

function toggleSidebarOn(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR_ON"
  });
}

function toggleSidebarOff(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR_OFF"
  });
}

function togglePermamence(dispatch) {
  dispatch({
    type: "TOGGLE_PERMANENT"
  });
}

function useLayoutDispatch() {
  const context = useContext(LayoutDispatchContext);
  // eslint-disable-next-line no-undefined
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }

  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, toggleSidebarOff, toggleSidebarOn, togglePermamence };

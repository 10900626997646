import "./Table.css";
import { Search } from "@mui/icons-material";
import { Icon } from "@iconify/react";

// --| Input Search to filter the table based on the search function prop passed
const TableSearch = ({ searchTableFunc, placeholder, value }) => (
  <div className="search_container">
    <Search />
    <input
      type="search"
      placeholder={placeholder}
      onChange={searchTableFunc}
      className="table_search"
      value={value}
    />{" "}
  </div>
);

/*
A select drop dropdown to dynamically hide columns in the table
It has 5 props passed onto it
options array is the data mapped into the options element, it should have name and value ad properties in the object
Option default to show if there is a default option different from an element in the columns array
selectName, selectValue, Onchange are renamed default properties of a select dropdown
*/
const FilterSelect = ({ onChange, selectIcon, selectName, optionsDefaultValue, selectValue, optionsArray, optionsDefaultName }) => (
  <div className="table_select_container">
    {selectIcon ? <Icon icon={selectIcon} /> : null}
    <select className="table_select"
      onChange={onChange}
      value={selectValue}
      name={selectName}
    >
      {optionsDefaultName ? <option value={ optionsDefaultValue }>{optionsDefaultName}</option> : null}
      {optionsArray?.map((option, i) => (
        <option key={i} value={option?.value}>
          {option?.name}
        </option>
      ))}
    </select>
  </div>
);

export { TableSearch, FilterSelect };

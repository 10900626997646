import { Link } from "react-router-dom";
import img from "../../assets/images/paysuccess.png";
import "./style.css";

const Success = () => {
  return (
    <div className="pay_redirect">
      <div>
        <img src={img} alt="Background" />
        <p>Payment Successful</p>
        <span>Check your email for your applicant’s login credentials</span>
        <Link to="/auth/login">Click here to login</Link>
      </div>
    </div>
  );
};

export default Success;

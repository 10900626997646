import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";
const lightenRate = 45;

export default makeStyles((theme) => ({
  cardcount: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    minHeight: 96,
    // backgroundColor: "white",
    padding: "16px 24px",
    border: "1px solid transparent"
  },
  left: {
    textAlign: "left"
  },
  title: {
    fontSize: 16,
    fontWeight: 400,
    color: "#475367",
    letterSpacing: -0.5,
    marginBottom: 0,
    marginTop: 0
  },
  count: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 0,
    marginTop: 0,
    color: "#344054"
  },
  icon: {
    border: (props) => `1px solid ${props.borderColor}`,
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
  },
  overallcont: {
    minWidth: 242,
    border: "1px solid #E4E7EC",
    borderRadius: 8,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: tinycolor(theme.palette.primary.main)
        .lighten(lightenRate)
        .toHexString(),
      border: `1px solid ${theme.palette.primary.main}`
    },
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    }
  },
  bottomcont: {
    display: "flex",
    padding: "0px 24px",
    justifyContent: "space-between"
  },
  quickcard: {
    minWidth: "128px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "121px",
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: "8px",
    padding: "9px",
    "& p": {
      marginBlock: "7px"
    }
  },
  linkto: {
    textDecoration: "none",
    color: "black"
  }
}));

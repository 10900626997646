/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import useStyles from "./styles";
import { Grid } from "@mui/material";
import CardCount from "../../components/CardCount";
import { Group as StudentIcon, Folder, CalendarToday } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import WidgetTitle from "./components/WidgetTitle/WidgetTitle";
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import { httprequest } from "../../data/api";
import { toast } from "react-toastify";
import { useUserState } from "../../context/UserContext";
import useWindowSize from "../../hooks/useWindow";
import Calendar from "../../components/Calendar";
import Quote from "inspirational-quotes";

const SUBJECT_ENDPOINT = "/api/v1/subjects";
const ASSIGNMENT_ENDPOINT = "/api/v1/student/assignment";
const STUDENT_PERIOD = "/api/v1/period";
const TERM_ENDPOINT = "/api/v1/school-plan/term";

const StudentDashboard = () => {
  const { profile } = useUserState();
  const classes = useStyles();
  const [subjectCount, setSubjectCount] = useState([]);
  const [periodData, setPeriodData] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [activeTerm, setActiveTerm] = useState([]);
  const [quote, setQuote] = useState({ text: "", author: "" });

  const CLASS_ID = profile?.class_id;
  // eslint-disable-next-line no-console
  console.log(CLASS_ID, "CLASS_ID");

  const width = useWindowSize();
  const mobile = width < 576;

  const renderView = () => {
    if (mobile) {
      return "timeGridDay";
    } else {
      return "timeGridWeek";
    }
  };

  const getSubject = () => {
    httprequest.get(`${SUBJECT_ENDPOINT}`)
      .then((res) => {
        setSubjectCount(res?.data?.data);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Get All Terms
  const getAllTerms = () => {
    httprequest.get(TERM_ENDPOINT).then((res) => {
      setActiveTerm(res?.data?.data.find((active_term) => active_term?.status === "active"));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAssignments = () => {
    httprequest.get(`${ASSIGNMENT_ENDPOINT}`)
      .then((res) => {
        setAssignment(res?.data?.data);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };
  // eslint-disable-next-line no-console
  console.log(profile, "PROFILE");

  // Get all Period for a School Teacher
  const getAllPeriods = () => {
    httprequest
      .get(`${STUDENT_PERIOD}?class_id=${CLASS_ID}`)
      .then((res) => {
        setPeriodData(res?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // eslint-disable-next-line no-console
  console.log(periodData, "periodData");

  // Set Start and End Date
  const startDate = new Date(activeTerm?.start_date);
  const EndDate = new Date(activeTerm?.end_date);

  const generateEvents = () => {
    const allEvents = [];
    const currentDate = new Date(startDate);

    while (currentDate <= EndDate) {
      const weekDay = currentDate
        // Get day and convert to lowercase abbreviat
        .toLocaleDateString("en-US", { weekday: "short" })
        .toLowerCase();

      periodData?.data?.days.forEach((day) => {
        if (weekDay === day?.day) {
          day?.periods.forEach((periodItem) => {
            const event = {
              type: periodItem?.type,
              title: periodItem?.subject?.name || periodItem?.title,
              start: `${currentDate.toISOString().split("T")[0]}T${periodItem?.start_time}`,
              end: `${currentDate.toISOString().split("T")[0]}T${periodItem?.end_time}`,
              teacher: periodItem?.teachers?.[0]?.name,
              subject_name: periodItem?.subject?.name,
              subject_id: periodItem?.subject?.id,
              day: day?.day,
              class: periodData?.data?.class?.name,
              class_id: periodData?.data?.class?.id,
              term: periodData?.data?.term?.name,
              term_id: periodData?.data?.term?.id,
              period_id: periodItem?.id,
              className: "general-events"
            };
            allEvents.push(event);
          });
        }
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return allEvents;
  };

  const events = generateEvents();

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.title}</p>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.extendedProps?.teacher}</p>
      </>
    );
  };

  useEffect(() => {
    getSubject();
    getAssignments();
    getAllTerms();
    const fetchedQuote = Quote.getQuote();
    setQuote(fetchedQuote);
  }, []);

  useEffect(() => {
    if (CLASS_ID) {
      getAllPeriods(CLASS_ID);
    }
  }, []);

  // eslint-disable-next-line no-console
  console.log(Quote?.getQuote());

  const assignmentsColumns = [
    {
      name: "Subject",
      selector: row => row?.subject_name
    },
    {
      name: "Instruction",
      selector: row => row?.instruction
    },
    {
      name: "Questions",
      selector: row => row?.questions?.length
    },
    {
      name: "Total Score",
      selector: row => row?.total_score
    },
    {
      name: "Submission Date",
      selector: row => row?.submission_date
    }
  ];

  return (
    <Layout
      children={
        <>
          <PageTitle
            title={`Hello ${profile?.profile?.last_name},`}
            subtitle={
              <div className={classes.quoteContainer}>
                <h6 className={classes.quoteText}>{quote.text}</h6>
                <h5 className={classes.quoteAuthor}> - {quote.author}</h5>
              </div>
            }
          />
          <Grid container spacing={8}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Subject"
                count={subjectCount.length}
                icon={<StudentIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Learning Materials"
                count={0}
                icon={<Folder />}
              />
            </Grid>
          </Grid>
          <WidgetTitle
            title="My TimeTable"
            subtitle=""
            // link={<Link className={classes.widgetlink} to="/teachers"><CalendarToday /> See full schedule</Link>}
          />

          <div className={classes.calendar}>
            <Calendar events={events} renderEventContent={renderEventContent} defaultView={renderView()} />
          </div>

          <WidgetTitle
            title="My Assignments"
            link={<Link className={classes.widgetlink} to="/teachers">View all assignments</Link>}
          />
          <Table data={assignment} columns={assignmentsColumns} />
        </>
      }
    />
  );
};

export default StudentDashboard;

import Scheduler from "react-mui-scheduler";
import { useState } from "react";

const SchedulerCalendar = ({ events, onEventsChange, onCellClick, onTaskClick, onAlertCloseButtonClicked }) => {
  const [state] = useState({
    options: {
      transitionMode: "zoom", // or fade
      startWeekOn: "mon",     // or sun
      defaultMode: "month",    // or week | day | timeline
      minWidth: 540,
      maxWidth: 540,
      minHeight: 540,
      maxHeight: 540
    },
    alertProps: {
      open: true,
      color: "info",          // info | success | warning | error
      severity: "info",       // info | success | warning | error
      // message: "🚀 Let's start with awesome react-mui-scheduler 🔥 🔥 🔥",
      showActionButton: true,
      showNotification: true,
      delay: 1500
    },
    toolbarProps: {
      showSearchBar: true,
      showSwitchModeButtons: true,
      showDatePicker: true
    }
  });

  return (
    <Scheduler
      locale="en"
      events={events}
      legacyStyle={false}
      options={state?.options}
      // alertProps={state?.alertProps}
      toolbarProps={state?.toolbarProps}
      onEventsChange={onEventsChange}
      onCellClick={onCellClick}
      onTaskClick={onTaskClick}
      onAlertCloseButtonClicked={onAlertCloseButtonClicked}
    />
  );
};

export default SchedulerCalendar;

import "../auth/auth.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import close_btn from "../../assets/images/close-btn.svg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Pageloader from "../../components/PageLoader";
import logo from "../../assets/images/logo.svg";

const VERIFY_EMAIL_ENDPOINT = "/api/v1/verify-email";

const AuthVerification = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const handleVerify = () => {
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}${VERIFY_EMAIL_ENDPOINT}/${id}`).then(() => {
      setIsLoading(false);
      setIsError(false);
      setIsVerified(false);
      toast.success("Account Verified Successfully");
      setIsVerified(true);
      setIsError(false);
      setTimeout(() => navigate("/auth/login"), 1000);
    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      toast.error(err?.message);
    });
  };

  useEffect(() => {
    handleVerify();
  }, []);

  return (
    <div className="verfication-container">
      <img src={logo} alt="logo" />
      {isLoading ? (
        <Pageloader />
      ) : (
        <div className="overlay">
          <div className="pop-contain">
            {isVerified ? (
              <div className="popup-content">
                <div className="verif">
                  <h4>Verification Successful</h4>
                  <img src={close_btn} alt="" height="32px" />
                </div>
                <p>
            Your email has successfully been verified, click to visit your
            dashboard to start using Shelf21 fully.
                </p>
                <Link to="/dashboard">
                  <button className="auth-create-button white bg-app-primary">
              Go to Dashboard
                  </button>
                </Link>
              </div>
            ) : null}
            {isError ? (
              <div className="popup-content">
                <div className="verif">
                  <h4 style={{ color: "red" }}>Verification Not Successful</h4>
                  <img src={close_btn} alt="" height="32px" />
                </div>
                <p>
            Your email cannot be verified, click to go home.
                </p>
                <Link to="/auth/login">
                  <button className="auth-create-button white bg-app-danger">
              Go home
                  </button>
                </Link>
              </div>
            ) : null}
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default AuthVerification;

import useStyles from "../styles";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { httprequest } from "../../../data/api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Calendar from "../../../components/Calendar";
import Modal from "../../../components/Modal";
import CardCount from "../../../components/CardCount";
import { Grid } from "@mui/material";
import {
  Group as StudentIcon,
  School as GraduateIcon,
  Class as ClassIcon,
  EmojiPeople as TeacherIcon
} from "@mui/icons-material";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave/employee";

const LeaveCalender = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [myLeave, setMyLeave] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  const getMyLeave = () => {
    httprequest.get(`${LEAVE_MGT_ENDPOINT}/${id}`).then((res) => {
      setMyLeave(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const events = myLeave?.leaveRequests?.map((leave) => ({
    title: leave.type,
    start: leave.start_date,
    end: leave.end_date,
    last_name: leave?.last_name,
    first_name: leave?.first_name,
    role_name: leave?.role_name,
    status: leave?.leave_status,
    comment: leave?.comment,
    className: leave?.leave_status === "approved" ? "approved-events" : leave?.leave_status === "pending" ? "pending-events" : leave?.leave_status === "declined" ? "declined-events" : "general-events"
  }));

  const handleEventClick = (args) => {
    setOpenModal(!openModal);
    const startDate = `${args?.event?.start.getFullYear()}-${(args?.event?.start.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.start.getDate().toString().padStart(2, "0")}`;
    const endDate = `${args?.event?.end.getFullYear()}-${(args?.event?.end.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.end.getDate().toString().padStart(2, "0")}`;
    setCurrentEvent(args?.event);
    setCurrentEndDate(endDate);
    setCurrentStartDate(startDate);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  useEffect(() => {
    getMyLeave();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one={"/manage/leave"}
                link_one_name="Leave Management"
                active={`${myLeave?.first_name} Leave Calenar`}
                description={`See the calender details of ${myLeave?.first_name} ${myLeave?.last_name}`}
              />
            </div>
          </div>
          <Grid container spacing={4} marginBottom={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Days off"
                count={myLeave?.totalDaysOff}
                icon={<StudentIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Days Taken"
                count={myLeave?.totalDaysTaken}
                icon={<GraduateIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Days Rolled Over"
                count={0}
                icon={<ClassIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Mandatory Off Days"
                count={0}
                icon={<TeacherIcon />}
              />
            </Grid>
          </Grid>
          <div className={classes.calendar}>
            <Calendar events={events} renderEventContent={renderEventContent} defaultView="dayGridMonth" handleEventClick={handleEventClick} />
            <Modal
              title={`${currentEvent?.extendedProps?.first_name} ${currentEvent?.extendedProps?.last_name}`}
              modalContent={
                <>
                  <p><b>Leave Type</b>: {currentEvent?.title}</p>
                  <p><b>Range</b>: {currentStartDate} to {currentEndDate}</p>
                  <p><b>Status</b>: {currentEvent?.extendedProps?.status}</p>
                  <p><b>Comments</b>: {currentEvent?.extendedProps?.comment}</p>

                </>}
              isopen={openModal}
              closeModal={handleCloseModal}
            />
          </div>
        </>
      }
    />
  );
};

export default LeaveCalender;

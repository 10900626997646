import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Badge from "../../components/Badge";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import useStyles from "./styles";
import ButtonCount from "../../components/ButtonCount";
import { httprequest } from "../../data/api";
import InputGroup from "../../components/FormComponent/InputGroup";
import { toast, ToastContainer } from "react-toastify";

const INVENTORY_CATEGORY_ENDPOINT = "/api/v1/inventory/category";
const INVENTORY_ENDPOINT = "/api/v1/inventory";

const Inventory = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [categoryCount, setCategoryCount] = useState(0);
  const [currentRow, setCurrentRow] = useState({});
  const [addinventoryModal, setInventoryModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);

  const handleOpenInventoryModal = (row) => {
    setInventoryModal(true);
    setCurrentRow(row);
  };

  const handleCloseInventoryModal = () => {
    setInventoryModal(false);
    setCurrentRow({});
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setCurrentRow({});
  };

  const getAllInvCategory = () => {
    httprequest.get(INVENTORY_CATEGORY_ENDPOINT).then((res) => {
      setCategoryCount(res?.data?.data?.length);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllInventory = () => {
    setIsLoading(true);
    httprequest.get(INVENTORY_ENDPOINT).then((res) => {
      setIsLoading(false);
      setInventoryData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleDeleteInventory = () => {
    setIsDeleting(true);
    httprequest.delete(`${INVENTORY_ENDPOINT}/${currentRow?.id}`).then(() => {
      setIsDeleting(false);
      handleCloseDeleteModal();
      getAllInventory();
      toast.success("Inventory Deleted");
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data);
    });
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    // The data will be from the inventory get api endpint
    setSearchResult(inventoryData);
    getAllInvCategory();
    getAllInventory();
  }, []);
  const columns = [
    {
      name: "Name",
      selector: row => row?.name
    },
    {
      name: "Description",
      selector: row => row?.description
    },
    {
      name: "Issue No.",
      selector: row => row?.issue_no
    },
    {
      name: "Category",
      selector: row => row?.category?.category
    },
    {
      name: "Created At",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              handleOpenInventoryModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <Link to={`/inventory/${row?.id}`} className="table-action-icon">
            <button className="table-action-icon">
              <Icon icon="heroicons:pencil-square-solid" />
            </button>
          </Link>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Inventory table using name, email and class
  const handleSearchInventory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = inventoryData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                    data?.description
                      ?.toLowerCase()
                      .includes(word.toLowerCase()) ||
                    data?.category?.category
                      ?.toLowerCase()
                      .includes(word.toLowerCase()) ||
                      data?.status
                        ?.toLowerCase()
                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Inventory"
            subtitle="See the inventory of all items gotten."
            button={
              <div className="flex gap-10">
                <Button onClick={() => {
                  navigate("/manage/inventory/archive-inventory");
                }} variant="primaryoutline" buttonSize="small" color="btnfontprimary" buttonName="View Archive"
                />
                <Button onClick={() => {
                  navigate("/manage/inventory/create");
                }} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create Inventory"
                />
              </div>
            }
          />
          <div className="page-content">
            <div className="button-count-container">
              <ButtonCount func={() => navigate("/manage/inventory/categories")} title="Inventory Categories" count={categoryCount} />
            </div>
            <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchInventory} isLoading={isLoading} />
            }
            />
            {/*  */}
            <Modal
              title={`Library Order Inventory ${currentRow?.name}`}
              subtitle={
                <div>See the details of the item selected.
                </div>}
              modalContent={
                <>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Product Name"
                        margin="normal"
                        placeHolder="Enter product name"
                        inputType="text"
                        value={currentRow?.name}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Employee in Charge"
                        margin="normal"
                        inputType="text"
                        value={currentRow?.employee_in_charge}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Status"
                        margin="normal"
                        inputType="text"
                        value={currentRow?.status}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Issue Number"
                        margin="normal"
                        inputType="text"
                        value={currentRow?.issue_no}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Category"
                        margin="normal"
                        inputType="text"
                        value={currentRow?.category}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Quantity"
                        margin="normal"
                        inputType="text"
                        value={currentRow?.quantity}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <div className={classes.modalFooterBtn}>
                    <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Download Inventory" />
                    <Button variant="primaryoutline" buttonSize="small" color="btnfontprimary" buttonName="Share inventory" />
                  </div>
                </>
              }
              isopen={addinventoryModal}
              closeModal={handleCloseInventoryModal}
            />
            {/* Delete Modal */}
            <Modal
              title="Delete Inventory"
              subtitle={
                <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. If deleted, employee will no longer have access to this Inventory.`
                </div>}
              modalContent={
                <>

                </>
              }
              modalFooter={
                <>
                  <Button variant="danger" buttonName="Delete Inventory" buttonSize="full" color="btndefault" onClick={handleDeleteInventory} isLoading={isDeleting} />
                </>
              }
              isopen={deleteModal}
              closeModal={handleCloseDeleteModal}
            />
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default Inventory;

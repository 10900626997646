/* eslint-disable prefer-template */
import Layout from "../../../components/Layout";
import useStyles from "../styles";
import Button from "../../../components/Button";
import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/BreadCrumb";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../../components/Modal";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import Badge from "../../../components/Badge";
import { httprequest } from "../../../data/api";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WidgetTitle from "../../dashboard/components/WidgetTitle/WidgetTitle";

const ASSIGNMENT_ENDPOINT = "/api/v1/assignment/submission";

const AssignmentSubmission = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const [addreportModal, setReportModal] = useState(false);
  const [assignmentData, setAssignmentData] = useState([]);

  const handleMessageModal = () => {
    setMessageModal(!messageModal);
  };

  const handleReportModal = () => {
    setReportModal(!addreportModal);
  };

  const getAssignmentSubmissions = () => {
    httprequest.get(`${ASSIGNMENT_ENDPOINT}/${id}`).then((res) => {
      setAssignmentData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    getAssignmentSubmissions();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/assesments/assignments"
                link_one_name="Assignments"
                link_two="/assignments"
                link_two_name="Assignment Detail"
                active="Submissions"
                description="See the submissions of this asignment"
              />
            </div>
          </div>
          <div className="page-content">
            <PageTitle className={classes.inline}
              title={`${assignmentData?.title}`}
              subtitle={`Created By:${assignmentData?.created_by?.name} | Class: ${assignmentData?.class?.name} `}
              // button={
              //   <div className="flex gap-10">
              //     <Button
              //       variant="primaryoutline"
              //       buttonSize="fluid"
              //       color="btnfontprimary"
              //       onClick={handleMessageModal}
              //       buttonName="Edit Assignment"
              //     />
              //   </div>
              // }
            />
            <Grid container spacing={1}>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <div className="key-pair-container">
                  <div className="key-pair-content">
                    <p className="key-pair-name">Subject Name</p>
                    <h3 className="key-pair-value">{assignmentData?.subject?.name}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Title</p>
                    <h3 className="key-pair-value">{assignmentData?.title}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Term</p>
                    <h3 className="key-pair-value">{assignmentData?.term?.name}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Publish Date</p>
                    <h3 className="key-pair-value">{assignmentData?.publish_date?.substring(0, 10)}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Submission Date</p>
                    <h3 className="key-pair-value">{assignmentData?.submission?.substring(0, 10)}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Created By</p>
                    <h3 className="key-pair-value">{assignmentData?.created_by?.name}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Created At</p>
                    <h3 className="key-pair-value">{assignmentData?.created_at?.substring(0, 10)}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Updated At</p>
                    <h3 className="key-pair-value">{assignmentData?.updated_at?.substring(0, 10)}</h3>
                  </div>
                  <div className="key-pair-content">
                    <p className="key-pair-name">Status</p>
                    <h3 className="key-pair-value"> <Badge status={assignmentData?.status} /></h3>
                  </div>
                </div>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <WidgetTitle
                  title="Questions"
                />
                <Grid container spacing={2}>
                  {assignmentData?.questions?.map((item, i) => (

                    <Grid item lg={5} md={5} sm={12} xs={12} key={i}>
                      <div className="question-preview-container">
                        <h3 className="question-preview-heading">
                      Question {item?.question_index}
                        </h3>
                        <h3 className="question-preview-heading">
                          {item?.question} {item?.required ? <span className="input-required">*</span> : null}
                        </h3>
                        {item?.type === "multiple_choice" ? (
                          <>
                            {item?.options?.map((option, i) => {
                              return (
                                <div key={i} className="options-preview">
                                  <input type="radio" name={item?.question_index} />
                                  <label>{option}</label>
                                </div>
                              );
                            })}

                          </>
                        ) : item?.type === "checkbox" ? (
                          <>
                            {item?.options?.map((option, i) => {
                              return (
                                <div key={i} className="options-preview">
                                  <input type="checkbox" />
                                  <label>{option}</label>
                                </div>
                              );
                            })}

                          </>
                        ) : null}
                      </div>
                    </Grid>

                  ))}
                </Grid>
              </Grid>
            </Grid>
            <ToastContainer />
          </div>
          {/* Send Report Modal */}
          <Modal
            title="Send Student Report"
            subtitle={
              <div>You are about to share the computed result sheets to the parent/guardian of Ojo William.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Enter guardian mail"
                      inputType="text"
                    />
                  </Grid>
                  <div className={`${classes.mail} flex align-items-center`}>
                    <Icon icon="fluent:mail-12-filled" width="24" height="24" />
                    <p>Use an alternative email</p>
                  </div>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Send Report" />
                </div>
              </>
            }
            isopen={addreportModal}
            closeModal={handleReportModal}
          />
          {/* Send Broadcast Modal */}
          <Modal
            title="Message Parent/Guardian"
            subtitle={
              <div>Send a message to the parents of the students in this class
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent Name"
                      margin="normal"
                      placeHolder="Parent Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Parent Email"
                      inputType="email"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Broadcast Message"
                      margin="normal"
                      placeholder="Enter the message you will like to share with the parents."
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information</label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                </div>
              </>
            }
            isopen={messageModal}
            closeModal={handleMessageModal}
          />
        </>
      }
    />
  );
};

export default AssignmentSubmission;

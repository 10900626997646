import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logo: {
    // width: 150
    height: 40
  },
  maincontainer: {
    backgroundColor: "#F5F6F7"
  },
  container: {
    width: "1314px",
    background: "#F5F6F7",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "90%"
    }
  },
  uploadcontainer: {
    /* background: #F5F6F7; */
    marginBlock: "12%",
    marginInline: "10.5%"
    /* height: 100vh; */
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "6.5%",
    paddingTop: "45px"
  },
  hero: {
    marginBlock: "46px"
  },
  li: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19.2px",
    letterSpacing: "-2%"
  },
  heading: {
    fontWeight: 800,
    fontSize: 48,
    letterSpacing: "-4%",
    marginBlock: 0,
    color: "#40444B",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      lineHeight: "40px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "32px"
    }
  },
  herospan: {
    fontWeight: 700
  },
  piclabel: {
    fontWeight: "500",
    fontSize: 16,
    textAlign: "left"
  },
  subheading: {
    fontWeight: 400,
    maxWidth: "700px",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    margin: "24px auto",
    letterSpacing: "-2%"
  },
  bannerimg: {
    width: "100%"
  },
  imagecontent: {
    position: "relative",
    height: 300,
    overflow: "hidden",
    borderRadius: "10px 10px 0px 0px",
    [theme.breakpoints.down("md")]: {
      height: 200
    },
    [theme.breakpoints.down("sm")]: {
      height: 150
    }
  },
  pointer: {
    cursor: "pointer"
  },
  sectionheader: {
    color: "#40444B",
    fontSize: "18px",
    lineHeight: "19.2px",
    letterSpacing: "-2%",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subsection: {
    color: "black",
    fontSize: "16px",
    letterSpacing: "0.4px",
    textAlign: "left"
  },
  profilecontainer: {
    backgroundColor: "#F5F6F7",
    width: "100%",
    height: 258,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 22
  },
  pickercontainer: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    bottom: 0,
    right: 0,
    color: "white",
    borderRadius: "50%"
  },
  camera: {
    color: "white",
    width: "14px!important"
  },
  profilepicker: {
    opacity: 0,
    width: 25,
    height: 25,
    cursor: "pointer",
    position: "absolute"
  },
  imageviewer: {
    width: "120px",
    height: "120px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  filepicker: {
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "absolute",
    left: 0,
    top: 0
  },
  editpencil: {
    fontSize: 20
  },
  selectimage: {
    position: "relative",
    width: 200,
    height: 45,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 32,
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5
  },
  btncontainer: {
    width: 300,
    margin: "auto",
    marginTop: 20,
    marginBottom: 20
  },
  uploadwrapper: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    maxHeight: 70,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20,
    marginTop: 15
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 0,
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    marginTop: 0
  },
  remove: {
    color: "red",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "6px 8px",
    boxSizing: "border-box",
    marginLeft: 3,
    zIndex: 50,
    backgroundColor: "white",
    border: "1px solid #98A2B3",
    borderRadius: 4,
    display: "flex",
    fontWeight: "bold"
  },
  modaltitle: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: -0.4
  }
}));

import Layout from "../../components/Layout";
import AdminLibrary from "./admin/AdminLibrary";

const index = () => {
  return (
    <Layout children={
      <>
        <AdminLibrary />
      </>
    }
    />
  );
};

export default index;

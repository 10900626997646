import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";

const drawerWidth = 272;

export default makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth
    }
  },
  sidebarLogo: {
    // ...theme.mixins.toolbar,
    width: drawerWidth,
    paddingTop: 32,
    paddingLeft: 30,
    position: "fixed",
    top: 0,
    backgroundColor: "white",
    zIndex: 30,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  logoContent: {
    // width: "160px",
    height: "40px"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  sidebarList: {
    paddingTop: "24px !important",
    width: drawerWidth,
    paddingBottom: "80px!important",
    top: 60
  },
  mobileBackButton: {
    position: "absolute",
    top: "20px",
    right: "10px",
    backgroundColor: theme.palette.background.light,
    borderRadius: "8px",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
    zIndex: 31,
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625)
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  headerIcon: {
    color: theme.palette.primary.light
  },
  footer: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    width: drawerWidth,
    gap: 25,
    padding: 15,
    backgroundColor: "white"

  },
  footerlink: {
    textDecoration: "none"
  },
  footercontainer: {
    display: "flex",
    alignItems: "center",
    gap: 10
  },
  footertextwrapper: {
    textAlign: "left",
    width: 140,
    overflow: "hidden"
  },
  initials: {
    backgroundColor: tinycolor(theme.palette.primary.main).lighten(30).toHexString(),
    color: "white",
    fontSize: 22,
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

  },
  name: {
    marginBottom: 0,
    color: "#101928",
    fontSize: 12,
    fontWeight: 500,
    marginTop: 0,
    letterSpacing: "-0.2px"
  },
  email: {
    marginBottom: 0,
    marginTop: 0,
    fontSize: 12,
    color: "#475367",
    letterSpacing: "-0.2px"
  },
  logout: {
    color: "#CB1A14",
    marginRight: 10,
    "&:hover": {
      cursor: "pointer"
    }
  }
}));

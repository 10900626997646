import useStyles from "./styles";
/*
  title and count props are strings
*/

const ButtonCount = ({ title, count, func }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardcount} onClick={func}>
      <p className={classes.title}>{title}</p><p className={classes.count}>{count}</p>
    </div>
  );
};

export default ButtonCount;

import useStyles from "../styles";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Button from "../../../components/Button";
import { ToastContainer, toast } from "react-toastify";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import { httprequest } from "../../../data/api";
import logo from "../../../assets/images/logo.svg";
import { useUpload } from "../../../hooks/useUpload";

const PROFILE_ENDPOINT = "api/v1/profile";

const BrandIdentity = ({ data }) => {
  const classes = useStyles();

  const [brandColor, setBrandColor] = useState(data?.branding?.brand_color || "#0065FF");

  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [schoolLogo, setSchoolLogo] = useState(null);
  // const profile = data;
  // const [branding, setBrandingDetails] = useState({
  //   description: data?.branding?.description,
  //   brand_color: brandColor,
  //   website_url: data?.branding?.website_url,
  //   linkedin_url: data?.branding?.linkedin_url,
  //   facebook_url: data?.branding?.facebook_url,
  //   instagram_url: data?.branding?.instagram_url,
  //   brand_logo: data?.branding?.brand_logo?.file_url || ""
  // });

  const [branding, setBrandingDetails] = useState({});

  const handleChangeBrandColor = (color) => {
    setBrandColor(color);
    setBrandingDetails({ ...branding, brand_color: color });
  };

  const handleIdentityChange = (e) => {
    const { name, value } = e.target;
    setBrandingDetails({ ...branding, [name]: value });
  };

  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    const binaryData = [];
    binaryData.push(e.target.files[0]);
    setSchoolLogo(URL.createObjectURL(new Blob(binaryData)));
  };

  const handleRemoveFile = () => {
    setSelectedFile("");
    setSchoolLogo(null);
  };

  const getUserProfile = () => {
    httprequest.get(PROFILE_ENDPOINT).then((res) => {
      localStorage.setItem("brand_color", res?.data?.data?.branding?.brand_color);
    }).catch((err) => {
      toast.error(err?.response?.message);
    });
  };

  const handleBrandUpdate = async () => {
    setIsEditing(true);
    if (selectedFile) {
      const brand_logo = {};
      // --| If an image with an upload id already exist, edit that image else, upload a new image
      const response = branding?.brand_logo?.upload_id ? await useUpload(selectedFile, branding?.brand_logo?.upload_id) : await useUpload(selectedFile);

      // eslint-disable-next-line no-console
      console.log(response?.data?.data);
      if (response.status === 200 && response.data.data) {
        brand_logo.file_url = response.data.data?.[0]?.file_url;
        brand_logo.upload_id = response.data.data?.[0]?.upload_id;
      } else {
        toast.error("Unable to upload profile picture");
        setIsEditing(false);
      }
      const newBrandingData = { ...branding, brand_logo };
      httprequest.patch(PROFILE_ENDPOINT, { branding: newBrandingData }).then((res) => {
        setIsEditing(false);
        toast.success(res?.data?.message);
        getUserProfile();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.message);
      });
    } else {
      httprequest.patch(PROFILE_ENDPOINT, { branding }).then((res) => {
        setIsEditing(false);
        toast.success(res?.data?.message);
        getUserProfile();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.message);
      });
    }
  };

  useEffect(() => {
    setBrandingDetails(data?.branding);
  }, []);

  return (
    <Grid container className={classes.bankingformbg}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <div className="page-content">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.brandflex}>
                <div className={classes.logouploadcontainer} style={{ backgroundImage: `url(${schoolLogo || branding?.brand_logo?.file_url || logo})` }}>
                </div>
                <div className={classes.brandesc}>
                  <h4 className={classes.title}>School Logo</h4>
                  <h6 className={classes.subtitle}>This Logo will be displayed on the dashboard</h6>
                  <button className={classes.selectimage}>
                    <Icon icon="heroicons:pencil-square-solid" className={classes.editpencil} />
                    Change Brand Image
                    <input type="file" className={classes.filepicker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpg,image/png" />
                  </button>
                  {selectedFile?.name && (
                    <div>
                      <p className={classes.uploadtitle}>{selectedFile?.name}</p>
                    </div>
                  ) }
                  {selectedFile?.name ? (
                    <div className={classes.removefile} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Description" inputName="description" value={branding?.description} onChange={handleIdentityChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputGroup inputType="text" label="Website url" inputName="website_url" value={branding?.website_url} onChange={handleIdentityChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputGroup inputType="text" label="Facebook Url" inputName="facebook_url" value={branding?.facebook_url} onChange={handleIdentityChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputGroup inputType="text" label="Likedin Url" inputName="linkedin_url" value={branding?.linkedin_url} onChange={handleIdentityChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputGroup inputType="text" label="Instagram Url" inputName="instagram_url" value={branding?.instagram_url} onChange={handleIdentityChange} />
            </Grid>
          </Grid>
          <div className={classes.btnspace}>
            <Button variant="primary" color="btndefault" buttonSize="medium" buttonName="Save Changes" onClick={handleBrandUpdate} isLoading={isEditing} />
          </div>
        </div>
      </Grid>
      <Grid item lg={6} md={4} sm={12} xs={12}>
        <div className={classes.brandingcontainer}>
          <h4 className={classes.title}>Choose a Brand Color</h4>
          <div className={classes.palettecontainer}>
            <div className={classNames(classes.palettewrapper, {
              [classes.blueselected]: brandColor === "#0065FF"
            })}
            onClick={() => {
              handleChangeBrandColor("#0065FF");
            }}
            >
              <div className={classNames(classes.colorpalette, classes.paletteblue)} />
            </div>

            {/* Palette Pink */}
            <div className={classNames(classes.palettewrapper,
              { [classes.pinkselected]: brandColor === "#B00074" }
            )}
            onClick={() => {
              handleChangeBrandColor("#B00074");
            }}
            >
              <div className={classNames(classes.colorpalette, classes.palettepink)} />
            </div>

            {/* Green Palette */}
            <div className={classNames(classes.palettewrapper,
              {
                [classes.greenselected]: brandColor === "#099137"
              }
            )}
            onClick={() => {
              handleChangeBrandColor("#099137");
            }}
            >
              <div className={classNames(classes.colorpalette, classes.palettegreen)} />
            </div>

            <div className={classNames(classes.palettewrapper,
              {
                [classes.yellowselected]: brandColor === "#AD6F07"
              }
            )}
            onClick={() => {
              handleChangeBrandColor("#AD6F07");
            }}
            >
              <div className={classNames(classes.colorpalette, classes.paletteyellow)} />
            </div>
            <div className={classNames(classes.palettewrapper,
              {
                [classes.blackselected]: brandColor === "#000000"
              }
            )}
            onClick={() => {
              handleChangeBrandColor("#000000");
            }}
            >
              <div className={classNames(classes.colorpalette, classes.paletteblack)} />
            </div>
          </div>
        </div>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default BrandIdentity;

// import useStyles from "../styles";
import { Grid } from "@mui/material";
import OrganizationChart from "@dabeng/react-orgchart";
import MyNode from "../../../components/OrgNode";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";

const ORGANOGRAM_ENDPOINT = "/api/v1/organogram";
const UserOrganogram = () => {
  const [currentOrg, setCurrentOrg] = useState({});
  const getCompanyOrganogram = () => {
    httprequest.get(ORGANOGRAM_ENDPOINT).then((res) => {
      setCurrentOrg(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.message);
    });
  };

  useEffect(() => {
    getCompanyOrganogram();
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <div className="left-container">
          <h4>Organogram</h4>
          <h6>See the Organisation Structure in Your Organisation</h6>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <div className="right-container">
          <OrganizationChart
            datasource={currentOrg}
            chartClass="myChart"
            NodeTemplate={MyNode}
            pan={true}
          />
          {/* <div className={classes.btnspace}>
            <Button variant="primary" color="btndefault" buttonSize="medium" buttonName="Save Changes" />
          </div> */}
        </div>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default UserOrganogram;

import PageTitle from "../../../components/PageTitle";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import { useUserState } from "../../../context/UserContext";
import { toast } from "react-toastify";
import Table from "../../../components/Table/Table";
import { TableSearch, FilterSelect } from "../../../components/Table/TableActions";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
// import Tabs from "@mui/lab/TabList";
// import Tab from "@mui/material/Tab";
import PageLoader from "../../../components/PageLoader";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const SYLLABUS = "/api/v1/syllabus/all";

const StudentSyllabus = () => {
  const navigate = useNavigate();
  const [syllabus, setSyllabus] = useState([]);
  //   const [value, setValue] = useState("");
  const { userRole } = useUserState();
  const [searchResult, setSearchResult] = useState([]);
  const [classRecord, setClassRecord] = useState({});
  //   const [terms, setTerms] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  // eslint-disable-next-line no-console
  console.log(userRole?.id);
  const getSyllabus = () => {
    setIsPageLoading(true);
    httprequest.get(`${SYLLABUS}`).then((res) => {
      setIsPageLoading(false);
      setSearchResult(res?.data?.data);
      setSyllabus(res?.data?.data);
      setClassRecord(res?.data?.data);
    //   setTerms(res?.data?.data?.term);
    }).catch((err) => {
      setIsPageLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const columns = [
    {
      name: "Subject Name",
      selector: row => row?.subject_name
    },
    {
      name: "Term",
      selector: row => row?.term
    },
    {
      name: "Session",
      selector: row => row?.session
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Last Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            className="table-action-icon"
            onClick={() => navigate(`/class-activities/syllabus/${row?.id}`)}
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
        </div>
      )
    }
  ];

  // eslint-disable-next-line no-console
  console.log(classRecord);

  const handleSearchGrade = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = syllabus?.filter((data) => valArray?.every(
      (word) => data?.term
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                    data?.session
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase()) ||
                                    data?.category
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const handleFilterByTerm = () => {

  };

  useEffect(() => {
    getSyllabus();
  }, []);

  return (
    <div>
      <PageTitle
        title="Syllabus"
        subtitle="View Subject Syllabus"
      />
      {isPageLoading ? (<PageLoader />) :
        <div className="page-content">
          <TabContext value="">
            {/* <div className="tablist-container">
              <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                {syllabus &&
                (<Tab label={classRecord?.name} value="" />)
                }
              </Tabs>
            </div> */}
            <TabPanel value="">
              <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                <>
                  <TableSearch placeholder="Search here..." searchTableFunc={handleSearchGrade} />
                  <FilterSelect onChange={handleFilterByTerm} optionsDefaultName={syllabus?.[0]?.term} optionsDefaultValue={syllabus?.[0]?.term_id} selectIcon="ei:calendar" />
                </>
              }
              />
            </TabPanel>
          </TabContext>
        </div>}
    </div>
  );
};

export default StudentSyllabus;

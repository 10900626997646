import useStyles from "./styles";
import { Grid } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "../../../components/Button";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
// import { useNavigate } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
// import Select from "react-select";
import useValidator from "../../../hooks/useValidator";
import { useUpload } from "../../../hooks/useUpload";
import { Gender } from "../../../utils/DropDown";

// API ENDPOINT
const ACCESS_CODES_ENDPOINT = "/api/v1/access";
// GET STUDENT
const GET_ALL_STUDENT = "/api/v1/students";

const AssignAccess = () => {
  const classes = useStyles();
  // const navigate = useNavigate();
  // const [selectedOption, setSelectedOption] = useState("permanent");
  const [codegenerator, setCodeGenerator] = useState(false);
  // const [permanentAccessBtn, setPermanentAccessBtn] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [accessValidator, showAccessValidator] = useValidator();
  const [allStudents, setAllStudents] = useState([]);
  const ShortUniqueId = require("short-unique-id");
  const [displayPic, setDisplayPic] = useState({});
  const [profilePic, setProfilePic] = useState(null);

  const [accessForm, setAccessForm] = useState({
    visitor_name: "",
    type: "permanent",
    student_id: "",
    access_code: "",
    contact: "",
    gender: "",
    email: ""
  });

  const handleProfileChange = (event) => {
    setDisplayPic(event.target.files[0]);
    const binaryData = [];
    binaryData.push(event.target.files[0]);
    setProfilePic(URL.createObjectURL(new Blob(binaryData)));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccessForm({ ...accessForm, [name]: value });
  };

  const getAllStudents = () => {
    httprequest.get(GET_ALL_STUDENT).then((res) => {
      setAllStudents(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // GENERATE PASSCODE
  const handleGeneratePasscode = () => {
    const uid = new ShortUniqueId({ length: 8 });
    const newPassCode = uid.rnd();
    setAccessForm({ ...accessForm, access_code: newPassCode });
  };

  useEffect(() => {
    getAllStudents();
    handleGeneratePasscode();
  }, []);

  const handleConfirmModal = () => {
    if (accessValidator.allValid()) {
      setShowConfirmationModal(true);
    } else {
      showAccessValidator(true);
    }
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmationModal(false);
  };

  const handleCodeGeneratorModal = () => {
    setCodeGenerator(!codegenerator);
  };

  const handleCloseCodeGenerateModal = () => {
    setCodeGenerator(false);
    setAccessForm({
      visitor_name: "",
      type: "permanent",
      student_id: "",
      access_code: "",
      contact: "",
      gender: "",
      email: ""
    });
    setProfilePic(null);
  };

  // Handle Create Access
  const handleCreateAccess = async () => {
    setIsCreating(true);
    try {
      const display_pic = {};
      if (displayPic) {
        const displayResponse = await useUpload(displayPic);
        if (displayResponse?.status === 200 && displayResponse.data.data) {
          display_pic.file_url = displayResponse.data.data?.[0].file_url;
          display_pic.upload_id = displayResponse.data.data?.[0].upload_id;
        } else {
          toast.error("Unable to upload profile picture");
          handleCloseConfirmModal();
          setIsCreating(false);
        }
      }
      const res = await httprequest.post(ACCESS_CODES_ENDPOINT, { ...accessForm, display_pic });
      if (res.status === 201) {
        toast.success(res?.data?.message);
        setIsCreating(false);
        handleCloseConfirmModal();
        handleCodeGeneratorModal();
      } else {
        toast.error("Failed to create access");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      handleCloseConfirmModal();
      setIsCreating(false);
    } finally {
      handleCloseConfirmModal();
      setIsCreating(false);
    }
  };

  // HAndle copy passcode
  const handleCopyPasscode = () => {
    navigator.clipboard.writeText(accessForm?.access_code)
      .then(() => {
        toast.success(`${accessForm?.access_code} Copied Successsfully`);
      })
      .catch((err) => {
        toast.error("Unable to copy passcode", err);
      });
    handleCloseCodeGenerateModal();
  };

  // This handles The Selected Option
  const handleToggleAccess = (param) => {
    setAccessForm({ ...accessForm, type: param });
  };

  // --| Object destructuring from access form
  const { type, visitor_name, student_id, email, contact, gender, access_code } = accessForm;

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div style={{ textAlign: "left" }}>{accessValidator.message("display_pic", profilePic, "required")}</div>
          <div className={`${classes.border} left-container`}>
            <h4>Visitor Information</h4>
            <h6>Personal Information of the Visitor</h6>
            <div className={classes.profilecontainer}>
              <div className={classes.imageviewercontainer}>
                <div className={classes.imageviewer} style={{ backgroundImage: `url(${profilePic})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                  {/* <img src={profilePic} alt="" /> */}

                </div>
                <div className={classes.pickercontainer}>
                  <input
                    type="file"
                    name="profile_picture"
                    className={classes.profilepicker}
                    onChange={handleProfileChange}
                  />
                  <CameraAltIcon className={classes.camera} />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={`${classes.border} right-container`}>
            <div className="flex align-items-center gap-24 margin-bottom-18">
              <div>
                <label onClick={() => handleToggleAccess("permanent")}>
                  <input
                    type="radio"
                    name="type"
                    id="access"
                    defaultChecked={type === "permanent"}
                    checked={type === "permanent"}
                    onChange={() => handleToggleAccess("permanent")}
                  />
                  Permanent Access
                </label>
              </div>
              <div>
                <label onClick={() => handleToggleAccess("one-time")}>
                  <input
                    type="radio"
                    name="type"
                    id="access"
                    defaultChecked={type === "one-time"}
                    checked={type === "one-time"}
                    onChange={() => handleToggleAccess("one-time")}
                  />
                  One Time Access
                </label>
              </div>
            </div>
            <hr />
            <Grid container spacing={2} className={classes.profileform}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  inputType="text"
                  label="Visitor's Name"
                  inputName="visitor_name"
                  value={visitor_name}
                  onChange={handleChange}
                />
                {accessValidator.message("Visitor's Name", visitor_name, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SelectGroup label="Student" children={
                  <select onChange={handleChange} name="student_id" value={student_id}>
                    <option value="">--- Select ---</option>
                    {allStudents?.map((student) => {
                      return (
                        <option key={student?.student_id} value={student?.student_id}>{student?.first_name}{" "}{student?.last_name}</option>
                      );
                    })}
                  </select>
                }
                />
                {accessValidator.message("Student", student_id, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup
                  inputType="email"
                  label="Visitor's Email"
                  inputName="email"
                  value={email}
                  onChange={handleChange}
                />
                {accessValidator.message("Visitor's Email", email, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup
                  inputType="tel"
                  label="Visitor's Contact"
                  inputName="contact"
                  value={contact}
                  onChange={handleChange}
                />
                {accessValidator.message("Contact", contact, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SelectGroup label="Visitor's Gender" children={
                  <select onChange={handleChange} value={gender} name="gender">
                    <option value="">--- Select ---</option>
                    {Gender?.map((gend, i) => {
                      return (
                        <option key={i} value={gend?.value}>{gend?.text}</option>
                      );
                    })}
                  </select>
                }
                />
                {accessValidator.message("Gender", gender, "required")}
              </Grid>

              {type === "one-time" && (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      inputType="datetime-local"
                      inputMin={new Date().toISOString().slice(0, -8)}
                      label="Access Start Date"
                      inputName="start_date"
                      value={accessForm?.start_date}
                      onChange={handleChange}
                      // value={start_date}
                    />
                    {accessValidator.message("Start Date", accessForm?.start_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      inputType="datetime-local"
                      inputMin={accessForm?.start_date}
                      label="Access End Date"
                      inputName="end_date"
                      value={accessForm?.end_date}
                      onChange={handleChange}
                    />
                    {accessValidator.message("End Date", accessForm?.end_date, "required")}
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.foraccessbtnwide}>
                  <Button
                    variant="primary"
                    color="btndefault"
                    buttonSize="fluid"
                    buttonName="Create Access Code"
                    onClick={() => {
                      handleConfirmModal();
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* Access Confirmation MOdAL */}
      {showConfirmationModal && (
        <>
          <Modal
            title="Add New Access Confirmation"
            subtitle=""
            modalContent={
              <>
                <p>
              You are about to add {visitor_name} as a new access, This means they can come and pick up the
              students in situation when the primary or secondary guardian are not available.
                </p>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="full"
                    color="btnfontprimary"
                    buttonName="Cancel"
                    onClick={handleCloseConfirmModal}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    buttonName="Send"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isCreating}
                    onClick={() => {
                      handleCreateAccess();
                    }}
                  />
                </div>
              </>
            }
            isopen={showConfirmationModal}
            closeModal={handleCloseConfirmModal}
          />
        </>
      )}

      {/* Access Code */}
      <Modal
        title="Your one time  access code"
        subtitle="This code has been sent to your visitor"
        modalContent={
          <>
            <div className={classes.codegenerator}>
              <p>{access_code}</p>
            </div>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                type="submit"
                variant="primary"
                buttonName="Copy"
                buttonSize="full"
                color="btndefault"
                onClick={() => {
                  handleCopyPasscode();
                }}
              />
            </div>
          </>
        }
        isopen={codegenerator}
        closeModal={handleCloseCodeGenerateModal}
      />
    </div>
  );
};

export default AssignAccess;

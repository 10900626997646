import PageTitle from "../../../components/PageTitle";
import Password from "../components/Password";
import styles from "../settings.module.css";

const StudentSettings = () => {
  return (
    <div>
      <PageTitle
        title="Settings"
        subtitle="You can make changes to your organisation’s account."
      />
      <div className={styles.pagecontent}>
        <Password />
      </div>
    </div>
  );
};

export default StudentSettings;

import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  widgetTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 0,
    marginTop: 20
  },
  typo: {
    textAlign: "left"
  },
  title: {
    color: "black",
    fontSize: 18,
    marginBottom: 0,
    letterSpacing: -0.3,
    marginTop: 0,
    fontWeight: 500
  },
  subtitle: {
    color: "#667185",
    fontWeight: 400,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 20
  }
}));

/* eslint-disable max-len */
import tinycolor from "tinycolor2";

// --| Handled instance when brand color becomes undefined as a type or as string or null
// const primary = (typeof (localStorage.getItem("brand_color")) !== "undefined" && !(localStorage.getItem("brand_color")) && localStorage.getItem("brand_color") !== "undefined" && localStorage.getItem("brand_color") !== null) ? localStorage.getItem("brand_color") : "#0065FF";

const defaultTheme = (primary) => {
  const secondary = "#E6F0FF";
  const warning = "#FFC260";
  const success = "#3CD4A0";
  const info = "#9013FE";
  const ash = "#F9FAFB";
  const black = "101928";

  const lightenRate = 7.5;
  const darkenRate = 15;

  return {
    palette: {
      primary: {
        main: primary,
        light: tinycolor(primary).lighten(lightenRate).toHexString(),
        dark: tinycolor(primary).darken(darkenRate).toHexString()
      },
      secondary: {
        main: secondary,
        light: tinycolor(secondary).lighten(lightenRate).toHexString(),
        dark: tinycolor(secondary).darken(darkenRate).toHexString(),
        contrastText: "#FFFFFF"
      },
      warning: {
        main: warning,
        light: tinycolor(warning).lighten(lightenRate).toHexString(),
        dark: tinycolor(warning).darken(darkenRate).toHexString()
      },
      success: {
        main: success,
        light: tinycolor(success).lighten(lightenRate).toHexString(),
        dark: tinycolor(success).darken(darkenRate).toHexString()
      },
      info: {
        main: info,
        light: tinycolor(info).lighten(lightenRate).toHexString(),
        dark: tinycolor(info).darken(darkenRate).toHexString()
      },
      ash: {
        main: ash,
        light: tinycolor(info).lighten(lightenRate).toHexString(),
        dark: tinycolor(info).darken(darkenRate).toHexString()
      },
      black: {
        main: black,
        light: tinycolor(info).lighten(lightenRate).toHexString(),
        dark: tinycolor(info).darken(darkenRate).toHexString()
      },
      color: {
        black900: "#101928",
        black800: "#1D2739",
        black700: "#344054",
        black600: "#475367",
        black500: "#667185",
        black400: "#98A2B3",
        black300: "#D0D5DD",
        black200: "#E4E7EC",
        black100: "#F0F2F5",
        black75: "#F7F9FC",
        black50: "#F9FAFB",
        brown900: "#3E3838",
        brown800: "#514A4A",
        brown700: "#645D5D",
        brown600: "#787070",
        brown500: "#8D8484",
        brown400: "#A29999",
        brown300: "#B7AFAF",
        brown200: "#CDC4C4",
        brown100: "#E4DBDB",
        brown75: "#F0E6E6",
        brown50: "#FBF1F1",
        grey100: "#F9FAFB",
        grey50: "#F0F2F5",
        primary: "#0065FF",
        primary500: "#003E9C",
        secondary: "#6E6E6E",
        hint: "#B9B9B9"
      },
      background: {
        default: "#F6F7FF",
        light: "#E6F0FF"
      }
    },
    typography: {
      fontFamily: "Trip Sans"
    },
    customShadows: {
      widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
    },
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: "#4A4A4A1A"
        }
      },
      MuiMenu: {
        paper: {
          boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
        }
      },
      overrides: {
        MuiSvgIcon: {
          root: {
            fontSize: 14
          }
        }
      },
      // MuiTabs: {
      //   root: {
      //     backgroundColor: "lightblue" // Customize the background color of the tabs
      //   }
      // },
      // MuiTab: {
      //   root: {
      //     fontWeight: "bold", // Customize the font weight of the tabs
      //     color: "red",
      //     textTransform: "none"
      //   },
      //   wrapper: {
      //     // Customize the text alignment of the tab labels
      //     textAlign: "center"
      //   }
      // },
      MuiSelect: {
        icon: {
          color: "#B9B9B9"
        }
      },
      MuiTouchRipple: {
        child: {
          backgroundColor: "white"
        }
      },
      MuiTableRow: {
        root: {
          height: 56
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: "1px solid rgba(224, 224, 224, .5)",
          paddingLeft: 24
        },
        head: {
          fontSize: "0.95rem"
        },
        body: {
          fontSize: "0.95rem"
        }
      },
      PrivateSwitchBase: {
        root: {
          marginLeft: 10
        }
      }
    }
  };
};

export default defaultTheme;

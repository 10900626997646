import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { Grid, Pagination } from "@mui/material";
import Modal from "../../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import useStyles from "../styles";
import PageLoader from "../../../components/PageLoader";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
// import EventBanner from "../../../assets/images/eventbanner.jpg";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import LibraryItemCard from "../component/LibraryItemCard";
import { CardSearch } from "../../../components/CardSearch";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import ButtonCount from "../../../components/ButtonCount";
import { TableSearch } from "../../../components/Table/TableActions";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
// import useUpload from "../../../hooks/useUpload";
// import { LibraryItemType } from "../../../utils/DropDown";

// All Library item
const ALL_LIBRARY_ITEMS = "/api/v1/library";
// To Upload Item
const UPLOAD_ENDPOINT = "/api/v1/upload";

const AdminLibrary = () => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [forBookSearch, setForBookSearch] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLibraryItemFetching, setIsLibraryItemFetching] = useState(false);
  const [allLibraryItem, setAllLibraryitem] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [showBookOption, setShowBookOption] = useState(false);
  const [showVideoOption, setShowVideoOption] = useState(false);
  const [libraryItemvalidator, showLibraryItemValidator] = useValidator();
  const [editlibraryItemvalidator, showEditlibraryItemvalidator] = useValidator();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLibraryItemDeleting, setIsLibraryItemDeleting] = useState(false);
  const [addLibraryItemModal, setAddLibraryItemModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [editLibraryItemModal, setEditLibraryItemModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadForm, setUploadForm] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  // --| Card Pagination
  const itemsPerPage = 6;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [libraryForm, setLibraryForm] = useState({
    title: "",
    type: "",
    link: "",
    file: {},
    description: ""
  });

  const [editlibraryForm, setEditLibraryForm] = useState({
    title: "",
    type: "",
    file: {},
    link: "",
    description: ""
  });

  // FOR FILE UPLOAD
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleRemoveFile = () => setSelectedFile("");

  const handleLibraryChange = (e) => {
    const { name, value } = e.target;
    setLibraryForm({ ...libraryForm, [name]: value });
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setLibraryForm({ ...libraryForm, type: value });
    setShowBookOption(e.target.value === "book");
    setShowVideoOption(e.target.value === "video");
  };

  const handleCloseEditLibraryModal = () => {
    setCurrentRow({});
    setEditLibraryItemModal(!editLibraryItemModal);
    setEditLibraryForm({
      ...editlibraryForm,
      title: "",
      type: "",
      file: {},
      link: "",
      description: ""
    });
  };

  const handleEditLibraryitemModal = (row) => {
    setCurrentRow(row);
    setEditLibraryForm({
      ...editlibraryForm, title: row?.title,
      description: row?.description, file: row?.file_url, type: row?.type
    });
    setEditLibraryItemModal(!editLibraryItemModal);
  };

  const handleCloseEditLibraryItemModal = () => {
    setEditLibraryItemModal(false);
  };

  const handleAddLibraryItemModal = () => {
    setAddLibraryItemModal(!addLibraryItemModal);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleCloseAddLibraryItemModal = () => {
    setAddLibraryItemModal(false);
  };

  // get all Library Item in the School
  const getAllLibraryItems = () => {
    setIsLibraryItemFetching(true);
    httprequest.get(ALL_LIBRARY_ITEMS).then((res) => {
      setIsLibraryItemFetching(false);
      setAllLibraryitem(res?.data?.data);
      setSearchResult(res?.data?.data);
      setCurrentItems(res?.data?.data);
      setForBookSearch(res?.data?.data?.filter(allbooks => allbooks?.type === "book"));
    }).catch((err) => {
      setIsLibraryItemFetching(false);
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log("currentItems", currentItems);

  // -- Create Library item
  const handleCreateLibraryItem = async () => {
    if (libraryItemvalidator.allValid()) {
      setIsCreating(true);
      try {
        let updatedLibraryForm = { ...libraryForm };

        if (libraryForm?.type === "book") {
          const formData = new FormData();
          formData?.append("file", selectedFile);
          const response = await httprequest.post(`${UPLOAD_ENDPOINT}`, formData);
          if (response?.status === 200 && response?.data?.data) {
            const upload_id = response?.data?.data?.[0]?.upload_id;
            const file_url = response?.data?.data?.[0]?.file_url;

            updatedLibraryForm = {
              ...updatedLibraryForm,
              file: { upload_id, file_url }
            };
          } else {
            toast.error(response?.data?.message);
            setIsCreating(false);
          }
        }

        const res = await httprequest.post(ALL_LIBRARY_ITEMS, updatedLibraryForm);
        if (res.status === 201) {
          setIsCreating(false);
          toast.success(res?.data?.message);
          setLibraryForm({ title: "", type: "", description: "" });
          handleCloseAddLibraryItemModal();
          getAllLibraryItems();
        } else {
          setIsCreating(false);
          toast.error("Failed to Create Library Item");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
        setIsCreating(false);
      }
    } else {
      showLibraryItemValidator(true);
    }
  };

  // -- Edit Library item
  const handleEditLibraryItem = async () => {
    if (editlibraryItemvalidator.allValid()) {
      setIsEditLoading(true);
      try {
        let upload_id = null;
        let file_url = null;

        if (libraryForm.type === "book") {
          const formData = new FormData();
          formData.append("file", selectedFile);
          const response = await httprequest.patch(`${UPLOAD_ENDPOINT}/${currentRow?.file?.upload_id}`, formData);
          if (response.status === 200 && response.data.data) {
            upload_id = response.data.data.upload_id;
            file_url = response.data.data.file_url;
          } else {
            toast.error(response?.data?.message);
            setIsEditLoading(false);
          }
        }

        const updatedLibraryForm = {
          ...editlibraryForm,
          // eslint-disable-next-line no-undefined
          file: editlibraryForm.type === "book" ? { upload_id, file_url } : undefined
        };

        const res = await httprequest.patch(`${ALL_LIBRARY_ITEMS}/${currentRow?.id}`, updatedLibraryForm);
        if (res.status === 201) {
          setIsEditLoading(false);
          toast.success(res?.data?.message);
          getAllLibraryItems();
          setEditLibraryForm({ title: "", type: "", description: "" });
          handleCloseEditLibraryModal();
        } else {
          setIsEditLoading(false);
          toast.error("Failed to Create Library Item");
        }
      } catch (err) {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
      }
    } else {
      showEditlibraryItemvalidator(true);
    }
  };

  // Delete Event
  const handleDeleteEvent = () => {
    setIsLibraryItemDeleting(true);
    httprequest.delete(`${ALL_LIBRARY_ITEMS}/${currentRow?.id}`).then((res) => {
      setIsLibraryItemDeleting(false);
      toast.success(res?.data?.message);
      handleCloseDeleteModal();
      getAllLibraryItems();
    }).catch((err) => {
      setIsLibraryItemDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const allVideos = allLibraryItem?.filter((library) => library?.type === "video");

  const filteredLibrary = allVideos.filter((e) => {
    const matchesSearch = e.title?.toLowerCase().includes(searchQuery.toLowerCase()) || e?.description?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesTab = value === "video";

    return matchesSearch && matchesTab;
  });

  const totalPages = Math.ceil(filteredLibrary.length / itemsPerPage);

  const paginatedLibrary = filteredLibrary.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleVideoSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const NoLibraryFound = () => {
    return (
      <NotFounditem img={nosearch}
        title="No Video Found"
        subtitle="The Library that matches this query cannot be found"
      />
    );
  };

  useEffect(() => {
    setForBookSearch(allLibraryItem);
    setSearchResult(allLibraryItem);
    getAllLibraryItems();
  }, []);

  const columns = [
    {
      name: "Book Title",
      selector: (row) => row?.title
    },
    {
      name: "Book Description",
      selector: (row) => row?.description
    },
    {
      name: "Type",
      selector: (row) => row?.type
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Updated at",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/library/${row?.id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          <button
            className="table-action-icon"
            onClick={() => handleEditLibraryitemModal(row)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            className="table-action-icon delete-icon"
            onClick={handleDeleteModal}
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter all cards using name and description
  const handleLibraryItemSearch = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allLibraryItem?.filter((data) => valArray?.every(
      (word) => data?.title
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
              data?.description
                ?.toLowerCase()
                .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
    setForBookSearch(result);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
    if (newValue === "book") {
      setCurrentItems(allLibraryItem?.filter((library) => library?.type === "book"));
    } else {
      setCurrentItems(allLibraryItem);
    }
  };

  const RenderLibraryItem = () => (
    <Grid container spacing={2}>
      {paginatedLibrary?.map((item) => {
        // const itemImage = item?.file?.file_img || EventBanner;

        return (
          <Grid item lg={4} md={6} sm={6} xs={12} key={item.id}>
            <LibraryItemCard
              // libImage={itemImage}
              libTitle={item.title}
              libDesc={item.description}
              libLink={item.link}
              status={item.type}
              // viewClick={() => navigateEventDetail(event.id)}
              editClick={() => handleEditLibraryitemModal(item)}
              deleteClick={() => handleDeleteModal(item)}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  // Object Destructuring from Library Form
  const { title, description, type } = libraryForm;

  return (
    <div>
      <PageTitle
        title="Library"
        subtitle="Enrich Your Learning: Add Essential Resources to Your Library"
        button={
          <div className="flex gap-10">
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={handleAddLibraryItemModal}
              buttonName="Add to your Library"
            />
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            <Tabs indicatorColor="none" className="overide-tablist" onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="All Library Items" value="" />
              <Tab label="Books" value="book" />
              <Tab label="Videos" value="video" />
            </Tabs>
          </div>
          <>

            {/* <CardSearch placeholder="Search here ..." onChange={handleLibrarySearch} /> */}
            <TabPanel value="" className="tabpanel-overide">
              {isLibraryItemFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount title="All Library Items" count={allLibraryItem?.length} />
                  </div>
                  <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                    <TableSearch placeholder="Search here..." searchTableFunc={handleLibraryItemSearch} />
                  }
                  />
                </>
              )}
            </TabPanel>
            <TabPanel value="book" className="tabpanel-overide">
              {isLibraryItemFetching ? (
                <PageLoader />
              ) :
                <>
                  <div className="button-count-container">
                    <ButtonCount title="All Books" count={forBookSearch?.length} />
                  </div>
                  <Table data={forBookSearch} columns={columns} subHeader={true} pagination subHeaderComponent={
                    <TableSearch placeholder="Search here..." searchTableFunc={handleLibraryItemSearch} />
                  }
                  />
                </>

              }
            </TabPanel>
            <TabPanel value="video" className="tabpanel-overide">
              {isLibraryItemFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount title="All Library Items" count={paginatedLibrary?.length} />
                  </div>
                  <div className="margin-bottom-10">
                    <CardSearch placeholder="Search your Video" onChange={handleVideoSearch} />
                  </div>
                  {paginatedLibrary?.length > 1 ? <RenderLibraryItem /> : <NoLibraryFound /> }

                  <div className="pagination-container">
                    <Pagination count={Math.ceil(totalPages)} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                  </div>
                </>
              )}
            </TabPanel>
          </>
        </TabContext>
        <ToastContainer />

      </div>
      {/* Add Resources To your Library */}
      <Modal
        title="Add Resource to your library"
        subtitle={
          <div>Add Resource to your library.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Book Title"
                  margin="normal"
                  placeHolder="Enter Book Title"
                  inputType="text"
                  inputName="title"
                  onChange={handleLibraryChange}
                />
                {libraryItemvalidator.message("title", title, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Type" children={
                  <select name="type" onChange={handleSelectChange}>
                    <option value="select">--- Select ---</option>
                    <option value="book">Book</option>
                    <option value="video">Video</option>
                  </select>
                }
                />
                {libraryItemvalidator.message("type", type, "required")}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Description"
                  margin="normal"
                  placeHolder="Enter Book Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleLibraryChange}
                />
                {libraryItemvalidator.message("title", description, "required")}
              </Grid>
              { showVideoOption && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Link"
                      margin="normal"
                      placeHolder="Enter Video link"
                      inputType="text"
                      inputName="link"
                      onChange={handleLibraryChange}
                    />
                    {libraryItemvalidator.message("link", libraryForm?.link, "required")}
                  </Grid>
                </>
              )}
              { showBookOption && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        {selectedFile?.name ? (
                          <div>
                            <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                          </div>
                        ) :
                          <div>
                            <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                            <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                            </h6>
                          </div>}
                      </div>
                      <div className={classes.rightpicker}>
                        {selectedFile?.name ? (
                          <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                        ) : null}
                      </div>
                    </div>
                    {libraryItemvalidator.message("file", libraryForm?.file, "required")}
                  </Grid>
                </>
              ) }
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
              <Button variant="primary" buttonName="Add to Library" buttonSize="full" color="btndefault" isLoading={isCreating} onClick={handleCreateLibraryItem} />
            </div>
          </>
        }
        isopen={addLibraryItemModal}
        closeModal={handleCloseAddLibraryItemModal}
      />
      {/* Edit Library Item */}
      <Modal
        title="Edit a resource in your library"
        subtitle={
          <div>Edit a resource in your library.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Book Title"
                  margin="normal"
                  placeHolder="Enter Book Title"
                  inputType="text"
                  inputName="title"
                  onChange={handleLibraryChange}
                  defaultValue={editlibraryForm?.title}
                />
                {editlibraryItemvalidator.message("title", editlibraryForm?.title, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Type" children={
                  <select name="type" onChange={handleSelectChange}>
                    <option value="select">--- Select ---</option>
                    <option value="book">Book</option>
                    <option value="video">Video</option>
                  </select>
                }
                />
                {editlibraryItemvalidator.message("type", editlibraryForm?.type, "required")}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Description"
                  margin="normal"
                  placeHolder="Enter Book Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleLibraryChange}
                  defaultValue={editlibraryForm?.description}
                />
                {editlibraryItemvalidator.message("description", editlibraryForm?.description, "required")}
              </Grid>
              { showVideoOption && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Link"
                      margin="normal"
                      placeHolder="Enter Video link"
                      inputType="text"
                      inputName="link"
                      onChange={handleLibraryChange}
                      defaultValue={editlibraryForm?.link}
                    />
                    {/* {editlibraryItemvalidator.message("link", libraryForm?.link, "required")} */}
                  </Grid>
                </>
              )}
              { showBookOption && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        {selectedFile?.name ? (
                          <div>
                            <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                          </div>
                        ) :
                          <div>
                            <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                            <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                            </h6>
                          </div>}
                      </div>
                      <div className={classes.rightpicker}>
                        {selectedFile?.name ? (
                          <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                        ) : null}
                      </div>
                    </div>
                    {/* {editlibraryItemvalidator.message("file", libraryForm?.file, "required")} */}
                  </Grid>
                </>
              ) }
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleCloseEditLibraryItemModal} />
              <Button variant="primary" buttonName="Edit Library Item" buttonSize="full" color="btndefault" isLoading={isEditLoading} onClick={handleEditLibraryItem} />
            </div>
          </>
        }
        isopen={editLibraryItemModal}
        closeModal={handleCloseEditLibraryModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Library Item"
        subtitle={
          <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.title}</span>. from one of your Library Item? Removing it will permanently clear this Item.`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="dangeroutline"
                buttonSize="full"
                color="btndanger"
                buttonName="Cancel"
                onClick={handleCloseDeleteModal}
              />
              <Button variant="danger" buttonName="Remove Library Item" buttonSize="full" color="btndefault" onClick={handleDeleteEvent} isLoading={isLibraryItemDeleting} />
            </div>
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
    </div>
  );
};

export default AdminLibrary;

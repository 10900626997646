import useStyles from "./PersonalStyle";
import { Grid } from "@mui/material";
import styles from "../user/student.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "../../../components/Button";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { Gender, Employees } from "../../../utils/DropDown";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";

const CLASSES_ENDPOINT = "/api/v1/class";
const STUDENTS_ENDPOINT = "/api/v1/students";

// --| This Personal Info page is needed on the student info in the user directory
const StudentProfile = ({ data }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [personalInfoForm, setPersonalInfoForm] = useState({
    // first_name: data?.first_name,
    // last_name: data?.last_name,
    // admission_date: data?.admission_date,
    // class_admitted: data?.class_id,
    student_email: data?.student_email,
    student_contact: data?.student_contact,
    guardian: data?.guardian,
    upload_id: data?.upload_id,
    gender: data?.gender,
    age: data?.age
    // address: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfoForm({ ...personalInfoForm, [name]: value });
  };

  const handleUpdateInfo = () => {
    setIsLoading(true);
    httprequest.patch(`${STUDENTS_ENDPOINT}/${id}`, personalInfoForm).then((res) => {
      setIsLoading(false);
      toast.success(res?.data?.message);
      window.location.reload();
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const getAllClasses = () => {
    httprequest.get(CLASSES_ENDPOINT).then((res) => {
      setClassData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    // The data will be from the Student get api endpint
    setPersonalInfoForm({ ...personalInfoForm,
      // first_name: data?.first_name,
      // last_name: data?.last_name,
      student_email: data?.student_email,
      student_contact: data?.student_contact,
      guardian: data?.guardian,
      upload_id: data?.upload_id,
      gender: data?.gender,
      age: data?.age });
    getAllClasses();
  }, [data]);

  const { student_contact, student_email, age, gender, guardian } = personalInfoForm;

  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <div className={`${styles.border} left-container`}>
          <h4>Personal Information</h4>
          <h6>Personal Information of Student</h6>
          <div className={classes.profilecontainer}>
            <div className={classes.imageviewer}>
              <div className={classes.pickercontainer}>
                <input type="file" name="profile_picture" className={classes.profilepicker} onChange={handleChange} />
                <CameraAltIcon className={classes.camera} />
              </div>
            </div>
          </div>
          <Button variant="primaryoutline" color="btnfontprimary" buttonSize="full" buttonName="Update Personal Information" onClick={handleUpdateInfo} isLoading={isLoading} />
        </div>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <div className={`${styles.border} right-container`}>
          <Grid container spacing={2} className={classes.profileform}>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="First name" disabled inputName="first_name" value={data?.first_name} onChange={handleChange}
                defaultValue={data?.first_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Last name" disabled inputName="last_name" value={data?.last_name} onChange={handleChange}
                defaultValue={data?.last_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="email" disabled label="Email address" inputName="student_email" value={student_email} onChange={handleChange}
                defaultValue={data?.student_email}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Age" inputName="age" value={age} onChange={handleChange} defaultValue={data?.age} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectGroup label="Gender" children={
                <select value={gender} name="gender" onChange={handleChange}>
                  <option value="">---Select  ---</option>
                  {Gender?.map((gend) => {
                    return (
                      <option key={gend?.key} value={gend?.key}>{gend?.text}</option>
                    );
                  })}
                </select>
              }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="tel" label="Contact" inputName="student_contact" value={student_contact} onChange={handleChange} defaultValue={data?.student_contact} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="date" label="Admission Date" inputName="admission_date" value={data?.admission_date} onChange={handleChange} defaultValue={data?.admission_date} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <SelectGroup label="Class Admitted to" children={
                <select disabled value={data?.class_id} name="class_admitted" onChange={handleChange} defaultValue={data?.id}>
                  <option value="">---Select Class ---</option>
                  {classData?.map((data) => {
                    return (
                      <option key={data?.id} value={data?.id}>{data?.class_name}</option>
                    );
                  })}
                </select>
              }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <SelectGroup label="Parent/Guardian" children={
                <select value={guardian} name="guardian" onChange={handleChange} defaultValue={data?.guardian_id}>
                  <option value="">---Select  ---</option>
                  {Employees?.map((parent) => {
                    return (
                      <option key={parent?.key} value={parent?.key}>{parent?.text}</option>
                    );
                  })}
                </select>
              }
              />
            </Grid>
          </Grid>
          <ToastContainer />
        </div>
      </Grid>
    </Grid>
  );
};

export default StudentProfile;

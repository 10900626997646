import { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
// import Button from "../../../components/Button";
// import Modal from "../../../components/Modal";
// import useStyles from "../styles";
// import SelectGroup from "../../../components/FormComponent/SelectGroup";
// import { Grid } from "@mui/material";
// import { ClassType, Weekdays, Subject, ClassLevel } from "../../../utils/DropDown";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import PageLoader from "../../../components/PageLoader";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useUserState } from "../../../context/UserContext";
import ButtonCount from "../../../components/ButtonCount";
// import InputGroup from "../../components/FormComponent/InputGroup";

const STUDENT_SUBJECTS = "/api/v1/student/subjects";

const Student = () => {
  const { userRole } = useUserState();
  // const [searchResult, setSearchResult] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [studentSubject, setStudentSubject] = useState([]);

  // eslint-disable-next-line no-console
  console.log(userRole?.id);
  const getAllStudentSubject = () => {
    setIsPageLoading(true);
    httprequest.get(STUDENT_SUBJECTS).then((res) => {
      setIsPageLoading(false);
      setStudentSubject(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsPageLoading(false);
      toast.error(err?.response?.message);
    });
  };

  // eslint-disable-next-line no-console
  console.log(studentSubject);

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    setSearchResult(studentSubject);
    getAllStudentSubject();
  }, []);

  const columns = [
    {
      name: "Subject Name",
      selector: (row) => row?.name
    },
    {
      name: "Teacher Name",
      selector: row => row?.teachers?.[0]?.name || "----------------------------"
    },
    {
      name: "Teacher Email",
      selector: row => row?.teachers?.[0]?.email || "----------------------------"
    },
    {
      name: "Teacher Contact",
      selector: row => row?.teachers?.[0]?.contact || "----------------------------"
    },
    {
      name: "Class Note",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/class-activities/subjects/${row?.subject_id}`} className="table-action-icon">
            <Icon icon="ci:external-link" />
          </Link>
        </div>
      )
    },
    {
      name: "Class Material",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={"/library"} className="table-action-icon">
            <Icon icon="carbon:text-link" />
          </Link>
        </div>
      )
    }
  ];

  // --| Filter Student subject using name, email and teacher's name
  const handleSubject = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = studentSubject?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
          data?.teachers?.[0]?.email
            ?.toLowerCase()
            .includes(word.toLowerCase()) ||
                      data?.teachers?.[0]?.name
                        ?.toLowerCase()
                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <PageTitle
        title="Subject"
        subtitle="View all your Subject as a student"
      />
      {isPageLoading ? (<PageLoader />) :
        <>
          <>
            <div className="page-content">
              <div className="margin-bottom">
                <ButtonCount count={studentSubject?.length} title="Number of Subjects" />
              </div>
              <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                <TableSearch placeholder="Search here..." searchTableFunc={handleSubject} />
              }
              />
            </div>
          </>
        </>
      }
      <ToastContainer />
    </>
  );
};

export default Student;

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Button from "../../components/Button";
import useValidator from "../../hooks/useValidator";
import InputGroup from "../../components/FormComponent/InputGroup";
import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
// logo
import logo from "../../assets/images/logo.svg";
import loginBG from "../../assets/images/signupBG.png";

const RESET_PWD_ENDPOINT = "/api/v1/reset-password";

const ResetPassword = () => {
  const [resetForm, setResetForm] = useState({
    password: "",
    token: ""
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const { id } = useParams();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [resetValidator, showValidationMessage] = useValidator();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { value } = e.target;
    setResetForm({ ...resetForm, password: value });
  };

  const handleConfirmChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  useEffect(() => {
    setResetForm({ ...resetForm, token: id });
  }, [id]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (resetValidator.allValid()) {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_URL}${RESET_PWD_ENDPOINT}`, resetForm).then(() => {
        setIsLoading(false);
        toast.success("Password Reset Successfully");
        setTimeout(() => navigate("/auth/login"), 1000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  const { password } = resetForm;

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  const checkEqualPassword = () => {
    if (password !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div className="auth-flex">
          <div className="auth-bg-container">
            <img src={loginBG} className="auth-bg-img" alt="login BG" />
          </div>
          <div className="auth-form-container">
            <div className="auth-logo-container">
              <Link to="/auth/login">
                <img src={logo} alt="logo" className="auth-logo" />
              </Link>
            </div>
            <div className="auth-form">
              <React.Fragment>
                <div className="auth-heading black900">Reset Password</div>
                <div className="auth-subheading black900">
                Put in your new password to update your authentication
                </div>
                <Grid container className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="New Password"
                      value={password}
                      name="password"
                      onChange={handleChange}
                      margin="normal"
                      inputType={passwordType}
                    />
                    <div className="password-eye">
                      {passwordType === "password" ? (
                        <Icon icon="fluent:eye-16-regular" onClick={togglePassword} />
                      ) : (
                        <Icon icon="iconamoon:eye-off-light" onClick={togglePassword} />
                      )}
                    </div>
                    {resetValidator.message("password", password, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Confirm Password"
                      value={confirmPassword}
                      name="confirmpassword"
                      onChange={handleConfirmChange}
                      margin="normal"
                      inputType={confirmPasswordType}
                    />
                    <div className="password-eye">
                      {passwordType === "password" ? (
                        <Icon icon="fluent:eye-16-regular" onClick={toggleConfirmPassword} />
                      ) : (
                        <Icon icon="iconamoon:eye-off-light" onClick={toggleConfirmPassword} />
                      )}
                    </div>
                    {resetValidator.message("password", password, "required")}
                  </Grid>
                </Grid>
                <Button variant="primary" color="btndefault" buttonSize="full" disabled={checkEqualPassword()}
                  buttonName="Continue" isLoading={isLoading} onClick={handleResetPassword}
                />
                {checkEqualPassword() ? <span className="text-danger">Password and confirm password not equal</span> : null}
                <ToastContainer />
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import { useEffect, useState } from "react";
import {
  Drawer,
  IconButton
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import { Icon } from "@iconify/react";
import { ToastContainer } from "react-toastify";
import SettingsImg from "../../../assets/images/settings.svg";
import MenuLogo from "../../../assets/images/dashboadlogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import ApplicantExamination from "../components/ApplicantExamination";
// import ApplicantResult from "../components/ApplicantResult";
// import ApplicantPassword from "../components/Password";
// import ApplicantDashboard from "../components/ApplicantDashboard";
import useStyles from "../styles";
import { getCurrentRole } from "../../../data/roles";
import { useUserDispatch, useUserState, logOut } from "../../../context/UserContext";
// import TabPanel from "@mui/lab/TabPanel";

const DrawerComp = ({ value, handleChange }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const { userRole, profile } = useUserState();

  const userDispatch = useUserDispatch();

  // --| This is to return the role, whether admin or user
  const role = getCurrentRole(userRole);

  const Initial = profile?.first_name?.split("")[0].toUpperCase();

  // const handleTabClick = (tabIndex) => {
  //   setSelectedTab(tabIndex);
  // };

  // --| Re-render the page if role changes
  useEffect(() => {
  }, [role]);

  //   For Vertical Tab
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`
    };
  }

  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ "& .MuiPaper-root": { width: "65%", paddingRight: "16px", top: "60px", left: "30px", borderRadius: "10px", height: "320px" } }}
      >
        <TabContext>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onClick={() => setOpenDrawer(false)}
            onChange={handleChange}
            aria-label="drawer tabs"
            sx={{ borderRight: 1, borderColor: "divider", padding: "8px 0px", border: "1px solid #E4E7EC", borderRadius: "9px", marginTop: "24px", marginLeft: "20px", background: "white" }}
          >
            <Tab sx={{ paddingLeft: "16px" }} label={<div className="flex gap-10 justify-item-left"><img src={MenuLogo} alt="" /><h5 className={`${classes.ForFonts} margin-block-3`}>Dashboard</h5></div>} {...a11yProps(0)} />
            <Tab label={<div className="flex gap-10 justify-item-left"><Icon icon="mingcute:paper-line" width="20" height="20" style={{ color: "#6C737F" }} /><h5 className={`${classes.ForFonts} margin-block-3`}>Examination</h5></div>} {...a11yProps(1)} />
            <Tab label={<div className="flex gap-10 justify-item-left"><Icon icon="pajamas:search-results" width="20" height="20" style={{ color: "#6C737F" }} /><h5 className={`${classes.ForFonts} margin-block-3`}>Results</h5></div>} {...a11yProps(2)} />
            <Tab label={<div className="flex gap-10 justify-item-left"><img src={SettingsImg} alt="" /><h5 className={`${classes.ForFonts} margin-block-3`}>Settings</h5></div>} {...a11yProps(3)} />
            <Tab label={
              <div className={classes.footer}>
                <Link className={classes.footerlink} to="/profile">
                  <div className={classes.footercontainer}>
                    <div className={classes.initials}>{Initial}</div>
                    <div className={classes.footertextwrapper}>
                      <p className={classes.name}>{profile?.first_name}{" "}{profile?.last_name}</p>
                      <p className={classes.email}>{profile?.email}</p>
                    </div>
                  </div>
                </Link>
                <div className={classes.logout} onClick={() => logOut(userDispatch, navigate)}>
                  <Logout />
                </div>
              </div>} {...a11yProps(4)}
            />
          </Tabs>
        </TabContext>
        <ToastContainer />
      </Drawer>
      {/* <Box>
        <TabPanel value={value} index={0}>
          <ApplicantDashboard />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ApplicantExamination />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ApplicantResult />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ApplicantPassword />
        </TabPanel>
      </Box> */}
      <div className={`${classes.hamburger}`}>
        <IconButton
          sx={{ color: "#000000", marginLeft: "auto" }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon className={`${classes.menuicon}`} color="#000000" />
        </IconButton>
      </div>
    </>
  );
};

export default DrawerComp;

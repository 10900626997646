import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { useEffect, useState } from "react";
import { TableSearch } from "../../../components/Table/TableActions";
import Table from "../../../components/Table/Table";
// import { Icon } from "@iconify/react";
import Badge from "../../../components/Badge";
// import Modal from "../../../components/Modal";
// import Button from "../../../components/Button/Button";
// import { Grid } from "@mui/material";
// import CardCount from "../../../components/CardCount/CardCount";
// import { Folder as FolderIcon } from "@mui/icons-material";

// --Dummy Data
import ApiUsageData from "../../../dummy_data/apiUsage.json";

const ApiUsage = () => {
  const [searchResult, setSearchResult] = useState([]);
  //   const [currentRow, setCurrentRow] = useState({});
  //   const [deleteModal, setDeleteModal] = useState(false);

  //   const handleDeleteModal = (row) => {
  //     setCurrentRow(row);
  //     setDeleteModal(!deleteModal);
  //   };
  const columns = [
    {
      name: "Endpoints",
      selector: row => row?.endpoint
    },
    {
      name: "EndPoint status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "Assigned To",
      selector: row => row?.assigned_to
    },
    {
      name: "Date Created",
      selector: row => row?.date_created
    },
    {
      name: "Last Update",
      selector: row => row?.last_update
    }
  ];

  useEffect(() => {
    // The data will be from the APIUSAGE get api endpint
    setSearchResult(ApiUsageData);
  }, []);

  // --| Filter APIUSAGE table using name, email and class
  const handleAdminSchools = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = ApiUsageData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                      data?.description
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase()) ||
                                      data?.category
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase()) ||
                                        data?.status
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <BreadCrumb
            link_one="/settings"
            link_one_name="Settings"
            link_two="/settings"
            link_two_name="Api Keys"
            active="API Keys and Webhooks"
            description="See API details"
          />
          {/* <div className="margin-bottom">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <CardCount
                  title="Api Usage Count"
                  count={1458}
                  icon={<FolderIcon />}
                />
              </Grid>
            </Grid>
          </div> */}
          <div className="margin-bottom">
            <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
              <TableSearch placeholder="Search here..." searchTableFunc={handleAdminSchools} />
            }
            />
          </div>
          {/* Delete Modal */}
          {/* <Modal
            title="Delete API Call"
            subtitle={
              <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.contractor}</span>. from one of your calls? Removing it will permanently clear all this records.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                  />
                  <Button variant="danger" buttonName="Remove Contractor" buttonSize="full" color="btndefault" />
                </div>
              </>
            }
            isopen={deleteModal}
            closeModal={handleDeleteModal}
          /> */}
        </>
      }
    />
  );
};

export default ApiUsage;

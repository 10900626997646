import Layout from "../../components/Layout";
import { useUserState } from "../../context/UserContext";
import Settings from "./admin/Settings";
import StudentSettings from "./student/StudentSettings";
import UserSettings from "./user/UserSettings";

const index = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <Settings />
        ) : userRole?.role === "teacher" ? <UserSettings /> : <StudentSettings />}
      </>
    }
    />
  );
};

export default index;

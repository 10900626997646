import useStyles from "../styles";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";

const PROFILE_ENDPOINT = "api/v1/profile";

const AdditionalFields = ({ data }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [customFields, setCustomFields] = useState({
    custom_a: { label: "", value: "" },
    custom_b: { label: "", value: "" },
    custom_c: { label: "", value: "" },
    custom_d: { label: "", value: "" },
    custom_e: { label: "", value: "" },
    custom_f: { label: "", value: "" },
    custom_g: { label: "", value: "" },
    custom_h: { label: "", value: "" },
    custom_i: { label: "", value: "" },
    custom_j: { label: "", value: "" }
  });

  useEffect(() => {
    setCustomFields({
      ...customFields,
      custom_a: data?.custom_a || { label: "", value: "" },
      custom_b: data?.custom_b || { label: "", value: "" },
      custom_c: data?.custom_c || { label: "", value: "" },
      custom_d: data?.custom_d || { label: "", value: "" },
      custom_e: data?.custom_e || { label: "", value: "" },
      custom_f: data?.custom_f || { label: "", value: "" },
      custom_g: data?.custom_g || { label: "", value: "" },
      custom_h: data?.custom_h || { label: "", value: "" },
      custom_i: data?.custom_i || { label: "", value: "" },
      custom_j: data?.custom_j || { label: "", value: "" }
    });
  }, [data]);

  const handleCustomChange = (e, fieldName, key) => {
    const { value } = e.target;
    setCustomFields((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], [key]: value }
    }));
  };

  const handleUpdateCustom = () => {
    setIsLoading(true);
    const payload = Object.keys(customFields).reduce((acc, field) => {
      if (customFields[field].label || customFields[field].value) {
        acc[field] = customFields[field];
      }

      return acc;
    }, {});
    httprequest.patch(PROFILE_ENDPOINT, payload)
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        // window.location.reload();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.message);
      });
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <div className="left-container">
          <h4>Update Custom Fields</h4>
          <h6>Add Custom Fields that you need and has not been provided by your organisation</h6>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <div className="right-container">
          {Object.keys(customFields).map((field) => (
            <Grid container spacing={2} className={classes.profileform} key={field}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <InputGroup inputType="text" label={`${field.toUpperCase()} Name`} inputName="label" value={customFields[field]?.label || ""} onChange={(e) => handleCustomChange(e, field, "label")} />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <InputGroup inputType="text" label={`${field.toUpperCase()} Value`} inputName="value" value={customFields[field]?.value || ""} onChange={(e) => handleCustomChange(e, field, "value")} />
              </Grid>
            </Grid>
          ))}
          <div className={classes.btnspace}>
            <Button variant="primary" color="btndefault" buttonSize="medium" buttonName="Save Changes" isLoading={isLoading} onClick={handleUpdateCustom} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AdditionalFields;

import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { getAllISOCodes } from "iso-country-currency";
import { httprequest } from "../../../data/api";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import Button from "../../../components/Button";
import useValidator from "../../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";

const CONTRACTOR_ENDPOINT = "/api/v1/contractor";
const PROCUREMENT_ENDPOINT = "/api/v1/procurement";

const Service = () => {
  const classes = useStyles();
  const [orderValidator, showOrderValidator] = useValidator();
  const [uploadForm, setUploadForm] = useState({});
  const [contractorData, setContractorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceOrder, setServiceOrder] = useState({
    order_title: "",
    type: "service",
    order_id: `${Date.now()}`,
    contractor: "",
    creation_date: "",
    amount: "0",
    currency: "GBP",
    supply_date: "",
    description: ""
  });

  const allCurrency = getAllISOCodes();

  const getAllContractors = () => {
    httprequest
      .get(CONTRACTOR_ENDPOINT)
      .then((res) => {
        setContractorData(res?.data?.data);
      })
      .catch(() => {});
  };

  const [selectedFile, setSelectedFile] = useState("");
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleCreateOrder = () => {
    if (orderValidator.allValid()) {
      setIsLoading(true);
      httprequest
        .post(PROCUREMENT_ENDPOINT, serviceOrder)
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message);
          setServiceOrder({
            ...serviceOrder,
            order_title: "",
            type: "service",
            order_id: "",
            contractor: "",
            creation_date: "",
            amount: "",
            currency: "GBP",
            supply_date: "",
            description: ""
          });
        //   handleCloseSchoolPlanModal();
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data);
        });
    } else {
      showOrderValidator(true);
    }
  };

  const handleOrderChange = (e) => {
    const { name, value } = e.target;
    setServiceOrder({ ...serviceOrder, [name]: value });
  };

  const { order_title, contractor, creation_date, order_id, supply_date, amount, description, currency } = serviceOrder;

  const handleRemoveFile = () => setSelectedFile("");

  useEffect(() => {
    getAllContractors();
  }, []);

  return (
    <>
      <Grid container className="input-padding">
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={classes.pickercontainer}>
            <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

            <div className={classes.uploadflex}>
              <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
              {selectedFile?.name ? (
                <div>
                  <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                </div>
              ) :
                <div>
                  <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                  <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                  </h6>
                </div>}
            </div>
            <div className={classes.rightpicker}>
              {selectedFile?.name ? (
                <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
              ) : null}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="input-padding">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <InputGroup
            label="Order Title"
            margin="normal"
            inputType="text"
            onChange={handleOrderChange}
            inputName="order_title"
            value={order_title}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <SelectGroup
            label="Contractor"
            children={
              <select
                name="contractor"
                value={contractor}
                onChange={handleOrderChange}
              >
                <option value="">---Select Contractor ---</option>
                {contractorData?.map((data) => {
                  return (
                    <option key={data?.id} value={data?.id}>
                      {data?.name}
                    </option>
                  );
                })}
              </select>
            }
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Order ID"
            margin="normal"
            onChange={handleOrderChange}
            inputType="text"
            inputName="order_id"
            value={order_id}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <InputGroup
            label="Creation Date"
            margin="normal"
            inputType="date"
            inputName="creation_date"
            onChange={handleOrderChange}
            value={creation_date}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Supply Date"
            margin="normal"
            inputType="date"
            inputName="supply_date"
            onChange={handleOrderChange}
            value={supply_date}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Amount Charge"
            margin="normal"
            inputType="number"
            onChange={handleOrderChange}
            value={amount}
            inputName="amount"
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <SelectGroup
            label="Currency"
            children={
              <select
                name="currency"
                defaultValue="GBP"
                onChange={handleOrderChange}
              >
                <option value="">---Select Currency ---</option>
                {allCurrency?.map((data, i) => {
                  return (
                    <option key={i} value={data?.currency}>
                      {data?.currency} {data?.countryName}
                    </option>
                  );
                })}
              </select>
            }
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextAreaGroup
            label="Description"
            margin="normal"
            placeholder="Enter the message you will like to share with the parents."
            value={description}
            inputName="description"
            onChange={handleOrderChange}
          />
        </Grid>
      </Grid>
      <div className={classes?.ordertotal}>
        <div>
          <p className={classes.totaltitle}>Total Amount</p>
          <h2 className={classes.orderamount}>{currency} {amount}</h2>
        </div>
      </div>
      <div>
        <Button
          variant="primary"
          onClick={handleCreateOrder}
          buttonSize="fluid"
          color="btndefault"
          buttonName="Create Purchase Order"
          isLoading={isLoading}
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default Service;

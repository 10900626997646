// --| Moduel.css was used for this file because the styling would be non-resusable
// --| Used over material ui because material ui might be hard to customise for the design
// --| Used over plain css because we dont want the styling to be injected globally
// --| No thorough thought for the theme color, might need to be revisited with other public pages.

import styles from "./Error.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const Error = () => {
  return (
    <div className={styles.errorwrapper}>
      <div className={styles.errorcontainer}>
        <div className={styles.exclamationcircle}>
        </div>
      </div>
      <h1 className={styles.exclamationmark}>!</h1>
      <div className={styles.backgroundclip}>

      </div>
      <div className={styles.textcontainer}>
        <h1 className={styles.textbold}>Oops, we cannot find this page.</h1>
        <p className={styles.textsubtitle}>Sorry, it appears that the page you are looking for does not exist or has been taken off</p>
        <div>

          <Link to="/auth/login" className={styles.homelink}>
            <div>Home</div>
            <img src={logo} className={styles.logocontainer} alt="shelf21 logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;

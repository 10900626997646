import useStyles from "./styles";
import { Icon } from "@iconify/react";

const UploadFile = ({ onChange, selectedFile, handleRemoveFile, uploadTitle, uploadSubtitle, accept }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pickercontainer}>
        <input type="file" className={classes.picker} onChange={onChange} name="file" accept={accept} />

        <div className={classes.uploadflex}>
          <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
          {selectedFile?.name ? (
            <div>
              <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
            </div>
          ) :
            <div>
              <h2 className={classes.uploadtitle}>{uploadTitle}</h2>
              <h6 className={classes.uploadsubtitle}>{uploadSubtitle}
              </h6>
            </div>}
        </div>
        <div className={classes.rightpicker}>
          {selectedFile?.name ? (
            <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UploadFile;

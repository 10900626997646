import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";

const EMPLOYEES_ENDPOINT = "api/v1/employees";
const TerminatedEmployees = ({ data }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [termindatedData, setTerminatedData] = useState({});
  const [reprovisionModal, setReprovisionModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const handleReprovisionModal = (row) => {
    setCurrentRow(row);
    setReprovisionModal(!reprovisionModal);
  };

  const handleCloseReprovisionModal = () => {
    setReprovisionModal(false);
  };

  const getTerminatedData = () => {
    httprequest.get(`${EMPLOYEES_ENDPOINT}?terminated=true`).then((res) => {
      setTerminatedData(res?.data?.data?.employees);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getTerminatedData();
    setSearchResult(data);
  }, [data]);

  const columns = [
    {
      name: "Code",
      selector: row => row?.code
    },
    {
      name: "Name",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Role",
      selector: row => row?.role?.name
    },
    {
      name: "Active",
      cell: (row) => (<Badge status={row?.is_active} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/users/employees/${row?.employee_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          <Link to={`/users/employees/${row?.employee_id}`} className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </Link>
          <button
            onClick={() => handleReprovisionModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="lets-icons:box-refresh-alt-right" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = termindatedData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
        data?.first_name
          ?.toLowerCase()
          .includes(word.toLowerCase()) ||
                    data?.email
                      ?.toLowerCase()
                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
      }
      />
      <Modal
        title="Reprovision employee"
        subtitle={
          <div>Are you sure you want to reprovision this employee <span className="modal-name-highlight">{currentRow?.name}</span>. If reprovisioned, this user will be sent an invite email`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <Button variant="primary" buttonName="Reprovision Member" buttonSize="full" color="btndefault" />
          </>
        }
        isopen={reprovisionModal}
        closeModal={handleCloseReprovisionModal}
      />
      <ToastContainer />
    </>
  );
};

export default TerminatedEmployees;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  assignmentcontainerform: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingBottom: 10
    }
  },
  quizcontainer: {
    padding: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
    width: "66%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  wrapper: {
    width: "66%",
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  questioncontainer: {
    padding: 10,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
    width: "66%",
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  divider: {
    paddingTop: 5,
    paddingBottom: 5
  },
  inputmargin: {
    paddingTop: 10
  },
  camera: {
    color: "rgb(96, 168, 255)",
    width: "30px !important",
    fontSize: "40px !important"
  },
  profilepicker: {
    opacity: 0,
    width: "40px",
    height: "40px",
    cursor: "pointer",
    position: "absolute",
    paddingTop: "10px !important"
  },
  nocenteritem: {
    textAlign: "left"
  },
  createAssignmentContent: {
    padding: "15px 20px",
    border: "1px solid #E4E7EC",
    borderRadius: "15px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  correctlabel: {
    marginTop: 3
  },
  correctcontainer: {
    // color: "#667185",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  questionfooter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  pointsInput: {
    width: "180px",
    height: "30px",
    boxSizing: "border-box",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid hsl(0, 0%, 80%)",
    marginTop: "4px",
    borderRadius: "6px",
    backgroundColor: "white",
    color: "#667185",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

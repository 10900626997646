import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingTop: "10px",
    marginTop: 20
  },
  contentpadding: {
    paddingLeft: 10,
    paddingBottom: 10
  },
  detailstext: {
    textAlign: "left"
  },
  pagedivider: {
    marginTop: 40,
    marginBottom: 40,
    color: "#DFE2E7"
  },
  title: {
    color: "#98A2B3",
    fontSize: 14,
    marginBottom: 3
  },
  subtitle: {
    color: "#40444B",
    fontSize: 14,
    fontWeight: 500
  },
  calendar: {
    border: "1px solid #E4E7EC",
    padding: "16px",
    borderRadius: "16px",
    marginTop: "50px",
    backgroundColor: "white"
  } }));

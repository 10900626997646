import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import Calendar from "../../../components/Calendar";
import useStyles from "../styles";

const SCHOOL_PLAN = "/api/v1/school-plan";
const SchoolCalendar = () => {
  const [schoolPlans, setSchoolPlans] = useState([]);

  const classes = useStyles();

  const getSchoolPlans = () => {
    httprequest.get(SCHOOL_PLAN).then((res) => {
      setSchoolPlans(res?.data?.data);
    });
  };

  const planList = schoolPlans.map((plan) => ({
    title: plan?.title,
    start: plan?.start_date,
    end: plan?.end_date,
    description: plan?.description,
    className: "general-events"
  }));

  useEffect(() => {
    getSchoolPlans();
  }, []);

  const renderPlanContent = (planInfo) => {
    return (
      <>
        <div className={classes.calendarbase}>
          <p className="no-margin-block padding-inline-10">{planInfo.event.title}</p>
          <p className="no-margin-block padding-inline-10"> {planInfo.event.extendedProps.description}</p>
        </div>
      </>
    );
  };

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/schoolplans"
              link_one_name="School Plans"
              active="School Calendar"
              description="See the plans you have made for your school laid out in a calendar."
            />
          </div>
          <div className="flex gap-10">
          </div>
        </div>
        <div className={classes.calendar}>
          <Calendar events={planList} renderEventContent={renderPlanContent} defaultView="dayGridMonth" />
        </div>
      </>
    }
    />
  );
};

export default SchoolCalendar;

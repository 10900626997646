import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  detailscont: {
    "& h2": {
      color: theme.palette.primary.main,
      marginBlock: "8px"
    }
  },
  syllabuscont: {
    background: "#F9FAFB",
    borderRadius: "24px",
    padding: "24px 21px",
    textAlign: "left",
    height: "225px",
    "& h3": {
      marginBlock: "8px"
    },
    "& ul": {
      listStyle: "inside",
      textAlign: "left",
      marginBlock: "8px"
    }
  }

}));

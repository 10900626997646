import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  seperator: {
    fontSize: "14px !important",
    color: "#1C1B1F",
    marginTop: "1px"
  },
  breadcrumb_link: {
    color: "#667185",
    textDecoration: "none",
    letterSpacing: "-0.4px"
  },
  active_breadcrumb: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    letterSpacing: "-0.2px",
    fontWeight: "bold"
  },
  breadcrumb_subtitle: {
    textAlign: "left",
    color: "#667185",
    fontSize: 14
  }
}));

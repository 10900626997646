import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import useStyles from "./styles";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CardCount from "../../components/CardCount";
import { Group as StudentIcon, MenuBook as Book, Folder, Class as ClassIcon, AddCircle } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import InputGroup from "../../components/FormComponent/InputGroup";
import Modal from "../../components/Modal/index";
import WidgetTitle from "./components/WidgetTitle/WidgetTitle";
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import Calendar from "../../components/Calendar";
import { httprequest } from "../../data/api";
import useWindowSize from "../../hooks/useWindow";

const CLASS_ENDPOINT = "/api/v1/teacher/classes";
const SCHOOL_PERIOD = "/api/v1/period/teacher";
const TERM_ENDPOINT = "/api/v1/school-plan/term";

const EmployeeDashboard = () => {
  const classes = useStyles();
  const [allClassesData, setAllClassesData] = useState([]);
  const [periodData, setPeriodData] = useState([]);
  const [modalisopen, setModalIsOpen] = useState(false);
  // const [termsData, setTermsData] = useState([]);
  const [activeTerm, setActiveTerm] = useState([]);
  const [value, setValue] = useState("");
  // eslint-disable-next-line no-console
  console.log(value, "Class ID");

  const width = useWindowSize();
  const mobile = width < 576;

  const renderView = () => {
    if (mobile) {
      return "timeGridDay";
    } else {
      return "timeGridWeek";
    }
  };

  // Get All Periods of classes I teach as a teacher
  const getClassByTeacher = () => {
    httprequest.get(`${CLASS_ENDPOINT}`).then((res) => {
      setAllClassesData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(allClassesData);

  // Get all Terms
  const getAllTerms = () => {
    httprequest.get(TERM_ENDPOINT).then((res) => {
      // setTermsData(res?.data?.data);
      setActiveTerm(res?.data?.data.find((active_term) => active_term?.status === "active"));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // Get all Period for a School Teacher
  const getAllPeriods = (params) => {
    httprequest
      .get(`${SCHOOL_PERIOD}`, { params })
      .then((res) => {
        setPeriodData(res?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };
  // eslint-disable-next-line no-console
  console.log(periodData, "periodData");

  // Set Start and End Date
  const startDate = new Date(activeTerm?.start_date);
  const EndDate = new Date(activeTerm?.end_date);

  const generateEvents = () => {
    const allEvents = [];
    const currentDate = new Date(startDate);

    while (currentDate <= EndDate) {
      const weekDay = currentDate
      // Get day and convert to lowercase abbreviat
        .toLocaleDateString("en-US", { weekday: "short" })
        .toLowerCase();

      periodData?.data?.days.forEach((day) => {
        if (weekDay === day?.day) {
          day?.periods.forEach((periodItem) => {
            const event = {
              type: periodItem?.type,
              title: periodItem?.subject?.name || periodItem?.title,
              start: `${currentDate.toISOString().split("T")[0]}T${periodItem?.start_time}`,
              end: `${currentDate.toISOString().split("T")[0]}T${periodItem?.end_time}`,
              teacher: periodItem?.teachers?.[0]?.name,
              subject_name: periodItem?.subject?.name,
              subject_id: periodItem?.subject?.id,
              day: day?.day,
              class: periodData?.data?.class?.name,
              class_id: periodData?.data?.class?.id,
              term: periodData?.data?.term?.name,
              term_id: periodData?.data?.term?.id,
              period_id: periodItem?.id,
              className: "general-events"
            };
            allEvents.push(event);
          });
        }
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return allEvents;
  };

  const events = generateEvents();

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.title}</p>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.extendedProps?.teacher}</p>
      </>
    );
  };

  // --| handle change for tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const params = {
      class_id: newValue
    };
    getAllPeriods(params);
  };

  useEffect(() => {
    getClassByTeacher();
    getAllPeriods();
    getAllTerms();
  }, []);

  useEffect(() => {
    if (allClassesData?.length > 0 && !value) {
      setValue(allClassesData[0]?.class?.id);
      const params = {
        class_id: allClassesData[0]?.class?.id
      };
      getAllPeriods(params);
    }
  }, [allClassesData]);

  const studentData = [];

  const studentColumns = [
    {
      name: "Name",
      selector: row => row?.name
    },
    {
      name: "Class",
      selector: row => row?.role
    },
    {
      name: "Gender",
      selector: row => row?.role
    },
    {
      name: "Parent",
      selector: row => row?.role
    },
    {
      name: "Contact Info",
      selector: row => row?.role
    }
  ];

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Dashboard"
            subtitle="Keep up with everything going on"
            button={
              <Button
                variant="primary"
                buttonSize="small"
                color="btndefault"
                onClick={() => {
                  setModalIsOpen(true);
                }}
                buttonName="Schedule Class"
              />
            }
          />
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Students"
                count={1450}
                icon={<StudentIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Subjects"
                count={1450}
                icon={<Book />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Classes"
                count={14}
                icon={<ClassIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Lesson Note"
                count={50}
                icon={<Folder />}
              />
            </Grid>
          </Grid>
          <div className="cover-page">
            <WidgetTitle
              title="My TimeTable Schedule"
              subtitle=""
              // link={<Link className={classes.widgetlink} to="/teachers"><CalendarToday /> See full schedule</Link>}
            />

            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label="class tabs"
                >
                  {allClassesData?.map((data) => {
                    return (
                      <Tab
                        key={data?.class?.id}
                        value={data?.class?.id}
                        label={data?.class?.name}
                      />
                    );
                  })}
                </Tabs>
              </div>
              {allClassesData?.map((data) => {
                return (
                  <TabPanel key={data?.class?.id} value={data?.class?.id}>
                    <div className={classes.calendar}>
                      <Calendar events={events} renderEventContent={renderEventContent} defaultView={renderView()} />
                    </div>
                  </TabPanel>
                );
              })}
            </TabContext>
          </div>
          <WidgetTitle
            title="Students"
            subtitle="See all the students in your class"
            link={<Link className={classes.widgetlink} to="/teachers">View all students</Link>}
          />
          <Table data={studentData} columns={studentColumns} />

          <Modal
            title="Add a class"
            subtitle="You can add a new class and assign a teacher to the class."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Name"
                      margin="normal"
                      placeHolder="Enter Class Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Teacher"
                      margin="normal"
                      placeHolder="Select Class Teacher"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                </Grid>
                <div className={`${classes.extrasubject}`}>
                  <p className={classes.subject_add}>Add another Subject</p>
                  <AddCircle className={classes.addicon} />
                </div>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Add Class" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={modalisopen}
            closeModal={() => {
              setModalIsOpen(false);
            }}
          />

        </>
      }
    />
  );
};

export default EmployeeDashboard;

import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  fullname: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  },
  position: {
    color: theme.palette.primary.main
  }
}));

import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageTitle from "../../components/PageTitle";
import IntegrationCard from "./IntegrationCard";
import PayStack from "../../assets/images/paystack.jpeg";
import Stripe from "../../assets/images/stripe.png";
import Flutterwave from "../../assets/images/flutterwave.jpeg";
import PayPal from "../../assets/images/paypal.jpeg";
import MailChimp from "../../assets/images/mailchimp.jpeg";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../components/Modal/index";
import InputGroup from "../../components/FormComponent/InputGroup";
import Button from "../../components/Button";
import useStyles from "./styles";
import { httprequest } from "../../data/api";
// import SelectGroup from "../../components/FormComponent/SelectGroup";

const SCHOOL_INTEGRATION = "/api/v1/integrations/school";

const Marketplace = () => {
  const [value, setValue] = useState("1");
  const classes = useStyles();
  const [integrateModal, setIntegrateModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({ title: "" });
  // const [integratedCards, setIntegratedCards] = useState({});
  const [viewSecretKey, setViewSecretKey] = useState("password");
  const [allIntegration, setAllIntegration] = useState([]);
  const [integration, setIntegration] = useState({
    secret_key: ""
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleSecretKey = () => {
    setViewSecretKey(viewSecretKey === "password" ? "text" : "password");
  };

  const handleIntegrateModal = (card) => {
    setSelectedCard(card);
    setIntegrateModal(!integrateModal);
  };
  // eslint-disable-next-line no-console
  console.log(selectedCard);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegration({
      ...integration, [name]: value
    });
  };

  const getIntegration = () => {
    httprequest.get(SCHOOL_INTEGRATION).then((res) => {
      setAllIntegration(res.data.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(allIntegration);
  useEffect(() => {
    getIntegration();
  }, []);

  const checkIsIntegrated = (title) => {
    const existingIntegration = allIntegration.find(
      (integration) => integration?.provider === title
    );

    // eslint-disable-next-line no-console
    console.log("title", title);
    // eslint-disable-next-line no-console
    console.log("existingIntegration", existingIntegration);

    return existingIntegration;
  };

  const handleSchIntegration = async (title) => {
    const integrationName = title.toUpperCase();
    const existingIntegration = allIntegration.find(
      (integration) => integration?.integration_name === integrationName
    );

    // eslint-disable-next-line no-console
    console.log("handleSchIntegration", handleSchIntegration());
    // eslint-disable-next-line no-console
    console.log(allIntegration);

    if (existingIntegration) {
      // Handle DELETE request
      try {
        const res = await httprequest.delete(`${SCHOOL_INTEGRATION}/${existingIntegration?.id}`);
        if (res.status === 200) {
          toast.success("Integration removed successfully.");
          // setIntegratedCards((prev) => ({
          //   ...prev,
          //   [selectedCard?.title]: false
          // }));
          localStorage.removeItem(selectedCard?.title);
          setIntegrateModal(false);
        } else {
          toast.error("Failed to remove integration.");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Error removing integration.");
      }
    } else {
      // Handle POST request
      const schInfo = {
        credentials: {
          secret_key: integration.secret_key
        },
        provider: integrationName,
        category: selectedCard?.type
      };

      try {
        const res = await httprequest.post(SCHOOL_INTEGRATION, schInfo);
        if (res.status === 201) {
          toast.success(res?.data?.message);
          // setIntegratedCards((prev) => ({
          //   ...prev,
          //   [selectedCard?.title]: true
          // }));
          localStorage.setItem(selectedCard?.title, "integrated");
          setIntegrateModal(false);
        } else {
          toast.error("Failed to integrate.");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Error integrating.");
      }
    }
  };

  const MarketCards = [
    {
      title: "PAYSTACK",
      logo: PayStack,
      type: "Payment",
      subtitle: "You can add paystack as a primary external way to make payment."
    },
    {
      title: "STRIPE",
      logo: Stripe,
      type: "Payment",
      subtitle: "You can add stripe as a primary external way to make payment."
    },
    {
      title: "FLUTTERWAVE",
      logo: Flutterwave,
      type: "Payment",
      subtitle: "You can add flutterwave as a primary external way to make payment."
    },
    {
      title: "PAYPAL",
      logo: PayPal,
      type: "Payment",
      subtitle: "You can add paypal as a primary external way to make payment."
    },
    {
      title: "MAILCHIMP",
      logo: MailChimp,
      type: "Messaging",
      subtitle: "You can add mailChimp as a primary external way to send messages."
    }
  ];

  const paymentCard = MarketCards.filter(card => card.type === "Payment");
  const mailingCard = MarketCards.filter(card => card.type === "Messaging");

  return (
    <Layout children={
      <>
        <PageTitle
          title="Integrations"
          subtitle="View all Integrations."
        />
        <div className="page-content">
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label=""
              >
                <Tab label="All" value="1" />
                <Tab label="Payment" value="payment" />
                <Tab label="Mailing" value="mailing" />
              </Tabs>
            </div>
            <TabPanel value="1" className="tabpanel-overide">
              <div className={`${classes.maincont} margin-top text-align-left`}>
                <h4>Payment</h4>
                <Grid container spacing={2}>
                  {paymentCard.map((card, index) => (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                      <IntegrationCard
                        title={card.title}
                        logo={card.logo}
                        type={card.type}
                        subtitle={card.subtitle}
                        integrate={() => handleIntegrateModal(card)}
                        isIntegrated={checkIsIntegrated(card.title)}
                        handleIntegrateModal={handleIntegrateModal}
                        handleSchIntegration={() => handleSchIntegration(card.provider)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div className={`${classes.maincont} margin-top text-align-left`}>
                <h4>Messaging</h4>
                <Grid container spacing={2}>
                  {mailingCard.map((card, index) => (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                      <IntegrationCard
                        title={card.title}
                        logo={card.logo}
                        type={card.type}
                        subtitle={card.subtitle}
                        integrate={() => handleIntegrateModal(card)}
                        isIntegrated={checkIsIntegrated(card.title)}
                        handleIntegrateModal={handleIntegrateModal}
                        handleSchIntegration={() => handleSchIntegration(card.provider)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value="payment" className="tabpanel-overide">
              <Grid container spacing={1}>
                {paymentCard.map((card, index) => (
                  <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                    <IntegrationCard
                      title={card.title}
                      logo={card.logo}
                      type={card.type}
                      subtitle={card.subtitle}
                      integrate={() => handleIntegrateModal(card)}
                      isIntegrated={checkIsIntegrated(card.title)}
                      handleIntegrateModal={handleIntegrateModal}
                      handleSchIntegration={() => handleSchIntegration(card.provider)}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value="mailing" className="tabpanel-overide">
              <Grid container spacing={1}>
                {mailingCard.map((card, index) => (
                  <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                    <IntegrationCard
                      title={card.title}
                      logo={card.logo}
                      type={card.type}
                      subtitle={card.subtitle}
                      integrate={() => handleIntegrateModal(card)}
                      isIntegrated={checkIsIntegrated(card.title)}
                      handleIntegrateModal={handleIntegrateModal}
                      handleSchIntegration={() => handleSchIntegration(card.provider)}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </TabContext>
          <ToastContainer />
        </div>
        <Modal
          title={`${selectedCard.title} Integration`}
          subtitle={ selectedCard.subtitle }
          modalContent={
            <>
              {selectedCard && (
                <>
                  <div>
                    {selectedCard.title === "Paystack" ? (
                      <>
                        <Grid container spacing={2} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputType={viewSecretKey}
                              inputName="secret_key"
                              onChange={handleInputChange}
                            />
                            <div className="password-eye-90">
                              {viewSecretKey === "password" ? (
                                <Icon icon="fluent:eye-16-regular" onClick={toggleSecretKey} />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" onClick={toggleSecretKey} />
                              )}
                            </div>
                          </Grid>
                          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Public Key"
                              margin="normal"
                              placeHolder="Enter public key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Callback URL"
                              margin="normal"
                              placeHolder="paste callback url here"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Webhook URL"
                              margin="normal"
                              inputType="text"
                              placeHolder="paste webhook url here"
                            />
                          </Grid> */}
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" onClick={handleSchIntegration} color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "Stripe" ? (
                      <>
                        <Grid container spacing={2} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Public Key"
                              margin="normal"
                              placeHolder="Enter public key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Callback URL"
                              margin="normal"
                              placeHolder="paste callback url here"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Webhook URL"
                              margin="normal"
                              inputType="text"
                              placeHolder="paste webhook url here"
                            />
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "Flutterwave" ? (
                      <>
                        <Grid container spacing={2} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Public Key"
                              margin="normal"
                              placeHolder="Enter public key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Callback URL"
                              margin="normal"
                              placeHolder="paste callback url here"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Webhook URL"
                              margin="normal"
                              inputType="text"
                              placeHolder="paste webhook url here"
                            />
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "PayPal" ? (
                      <>
                        <Grid container spacing={2} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Public Key"
                              margin="normal"
                              placeHolder="Enter public key"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Callback URL"
                              margin="normal"
                              placeHolder="paste callback url here"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Webhook URL"
                              margin="normal"
                              inputType="text"
                              placeHolder="paste webhook url here"
                            />
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "MailChimp" ? (
                      <>
                        <Grid container spacing={2} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Redirect URL"
                              margin="normal"
                              placeHolder="Enter account sld"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Client ID"
                              margin="normal"
                              placeHolder="paste auth token"
                              inputType="text"
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Client Secret"
                              margin="normal"
                              inputType="text"
                            />
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
                            <Button variant="primary" buttonName="Integrate MailChimp" buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </>
          }
          isopen={integrateModal}
          closeModal={handleIntegrateModal}
        />
      </>
    }
    />
  );
};

export default Marketplace;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  enrolpaginate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: "20px",
    gap: "10px",
    width: "100%"
  },
  enrolmentcontent: {
    paddingBottom: "60px",
    width: "600px",
    textAlign: "left"
  },
  imagecontent: {
    height: 400,
    overflow: "hidden",
    borderRadius: "16px 16px 8px 8px"
  },
  enrollmentImg: {
    width: "100%"
  },
  previewcontent: {
    textAlign: "center",
    height: "600px",
    overflow: "scroll",
    paddingBottom: "70px"
  },
  enrolmentuploadsection: {
    width: "500px",
    margin: "auto"
  },
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    // height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingTop: "10px"
  },
  contentpadding: {
    paddingLeft: 10
  },
  paddingleftmodal: {
    paddingLeft: "3%",
    marginBlock: "14px 6px",
    display: "flex",
    gap: "10px",
    width: "100%"
  },
  paddingmodal: {
    paddingLeft: "3%",
    marginBlock: "14px 6px",
    display: "flex",
    gap: "10px",
    width: "100%"
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  flexOnly: {
    display: "flex",
    gap: "16px",
    width: "100%",
    justifyContent: "center",
    marginBlock: "12px 32px"
  },
  mail: {
    paddingLeft: "3%",
    gap: "2px",
    color: "#0065FF",
    fontWeight: "500",
    lineHeight: "17.4px"
  },
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pickercontainer: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    height: 113,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20
  },
  label: {
    fontWeight: "500",
    fontSize: "16px"
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: theme.palette.primary.main,
    width: 86,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  enrolmenthero: {
    marginBlock: "46px",
    textAlign: "center"
  },
  previewtitle: {
    marginTop: "0px",
    marginBottom: "0px"
  },
  previewsub: {
    fontSize: "20px",
    fontWeight: 400,
    marginTop: "0px",
    marginBottom: "10px"
  },
  enrolbtn: {
    display: "flex",
    justifyContent: "center"
  },
  uploadtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#475367",
    marginBottom: 0,
    marginTop: 0
  },
  columnItems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    textAlign: "center"
  },
  clickUpload: {
    color: "#0065FF",
    fontWeight: 600,
    fontSize: 14
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    alignItems: "center",
    marginTop: 0
  },
  ribbon: {
    position: "absolute",
    top: 150,
    color: "#fff",
    padding: ".1em 1.8em",
    background: theme.palette.primary.main,
    borderBottom: "15px solid #0007",
    clipPath: "polygon(100% calc(100% - 15px),100% 100%,calc(100% - 15px) calc(100% - 15px),15px calc(100% - 15px), 0 100%,0 calc(100% - 15px),999px calc(100% - 15px - 999px),calc(100% - 999px) calc(100% - 15px - 999px))",
    left: 0,
    transform: "translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg)",
    transformOrigin: "100% 100%",
    zIndex: 40

  },
  enrolmentdetailsheader: {
    height: 200,
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px 8px 0px 0px",
    position: "relative"
  },
  enrolmentinfotext: {
    position: "absolute",
    backgroundColor: "red",
    bottom: 0
  },
  enrolinfoclass: {
    padding: "2px 4px 2px 4px",
    borderRadius: 6,
    marginLeft: 2,
    marginRight: 2,
    color: "white",
    background: theme.palette.primary.main
  },
  displaypic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    border: "1px solid #8C94A6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 40,
    color: "#8C94A6"
  },
  applicationdisplaypic: {
    display: "flex",
    gap: "10px"
  }
}));

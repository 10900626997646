import { Icon } from "@iconify/react";
import useStyles from "../styles";
import styles from "../user/student.module.css";
import Button from "../../../components/Button";
import Table from "../../../components/Table/Table";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCount from "../../../components/ButtonCount";
import { TableSearch } from "../../../components/Table/TableActions";

// --| Dummy Data
// import StudentReportData from "../../../dummy_data/studentReportData.json";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
const GRADE_RECORD = "/api/v1/grade";

const AcademicInfo = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { id } = useParams();
  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const getAllGrades = () => {
    httprequest.get(`${GRADE_RECORD}/${id}`)
      .then((res) => {
        setAllGrades(res?.data?.data?.grades || []);
        setSearchResult(res?.data?.data?.grades || []);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject
    },
    {
      name: "CA Score",
      selector: row => row?.CA_score
    },
    {
      name: "Exam Score",
      selector: row => row?.exam_score
    },
    {
      name: "Total",
      selector: row => row?.total
    },
    {
      name: "Grade",
      selector: row => row?.grade
    },
    // {
    //   name: "Date",
    //   selector: row => row?.date
    // },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    // The data will be from the inventory get api endpint
    getAllGrades();
  }, []);

  // --| Filter Student table using name, email and class
  const handleSearchInventory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allGrades?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                data?.description
                                  ?.toLowerCase()
                                  .includes(word.toLowerCase()) ||
                                data?.category
                                  ?.toLowerCase()
                                  .includes(word.toLowerCase()) ||
                                  data?.status
                                    ?.toLowerCase()
                                    .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <div>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        <TableSearch placeholder="Search here..." searchTableFunc={handleSearchInventory} />
      }
      />
      <div className="flex justify-items-space Blockspacing">
        <div className={classes.contentpadding}>
          <ButtonCount func={() => navigate("")} title="Student Count" />
        </div>
        <div className={`${styles.footer} flex align-items-center`}>
          <p>Class Teacher: </p>
          <p onClick={() => navigate("/employees")} className={`${styles.color}`}>Euguen Adaovore</p>
        </div>
      </div>
      {/* Delete Modal */}
      <Modal
        title="Delete Subject"
        subtitle={
          <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. Deleting is a permanent action`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <Button variant="danger" buttonName="Delete Inventory" buttonSize="full" color="btndefault" />
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
    </div>
  );
};

export default AcademicInfo;

import "../onboarding/Onboarding.css";
import { Link } from "react-router-dom";
import close_btn from "../../assets/images/close-btn.svg";

const WelcomeToShelf = () => {
  return (
    <div>
      <div className="overlay">
        <div className="pop-contain">
          <div className="verify-content">
            <div className="verif">
              <h4>Invitation to join Shelf21 Employee workspace.</h4>
              <img src={close_btn} alt="" height="32px" />
            </div>
            <p>
              You have been invited to join Shelf21 employee workspace, by
              clicking on join now you will be required to fill in all the
              necessary information to join the team.
            </p>
            {/* stlying to match selected theme will be done soon */}
            <div className="nav-to">
              <div className="btn-item">
                <Link to="">
                  <button className="auth-create-button outline white bg-app-primary">
                    Cancel
                  </button>
                </Link>
              </div>
              <div className="btn-item">
                <Link to="">
                  <button className="auth-create-button white bg-app-primary">
                    Accept Invite
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeToShelf;

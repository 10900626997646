import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  calendar: {
    "& h1": {
      marginBlock: "5px",
      fontSize: "18px",
      fontWeight: "600"
    },
    "& button": {
      backgroundColor: "white",
      boxShadow: "#D0D5DD 1px 1px 1px 1px",
      border: "0px solid black"
    },
    "& .fc-button-group": {
      gap: "4px"
    },
    "@media (max-width: 600px)": {
      "& h1": {
        fontSize: "14px"
      },
      "& .fc-toolbar": {
        flexWrap: "wrap",
        gap: "5px",
        justifyContent: "space-between"
      },
      "& .fc .fc-toolbar-title": {
        fontSize: "14px"
      }
    },
    "& .fc-button-primary": {
      backgroundColor: "white",
      color: "black",
      border: "none"
    },
    "& .fc-button-primary:disabled": {
      backgroundColor: "#F9FAFB",
      border: "none",
      color: "black"
    },
    "& .fc-button-primary:not(:disabled):active": {
      backgroundColor: "#F9FAFB",
      borderColor: "transparent",
      color: "black",
      outline: 0,
      border: "1px solid transparent"
    },
    "& .fc-button-primary:focus": {
      boxShadow: "#D0D5DD 1px 1px 1px 1px"
    },
    "& .fc-scrollgrid": {
      borderTop: "0px solid transparent",
      borderLeftWidth: "0px"
    },
    "& .fc table tr:first-child th": {
      border: "none",
      borderSpacing: 0,
      backgroundColor: "#F9FAFB",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
      marginLeft: "-1px"
    },
    "& .fc-theme-standard td:first-child": {
      borderLeft: "1px solid #ddd"
    },
    "& .fc-button-primary:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main
    },
    "& .fc-button-primary:active": {
      backgroundColor: "#F9FAFB",
      color: theme.palette.primary.main,
      outline: 0
    },
    "& .fc .fc-now-indicator-line": {
      borderColor: "red",
      height: "2px",
      borderWidth: "2px 0 0 0"
    },
    "& .fc .fc-daygrid-event": {
      borderLeftWidth: "3px",
      borderBottomLeftRadius: "8px",
      borderTopLeftRadius: "8px"
    },
    "& .fc .fc-now-indicator-arrow,.fc .fc-now-indicator-dots": {
      display: "none"
    }
  }
}));

import { useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OrganisationDetails from "./components/OrganisationDetails";
import AdminOrganogram from "./components/AdminOrganogram";
import AdditionalFields from "./components/AdditionalFields";
import BankingInfo from "./components/BankingInfo";
import BrandIdentity from "./components/BrandIdentity";
import ContactDetails from "./components/ContactDetails";
import { useUserState } from "../../context/UserContext";

const AdminProfile = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { profile } = useUserState();

  return (
    <>
      <BreadCrumb link_one="/profile" link_one_name="Organisation Profile" active="Euguene" description="You can make changes to your profile" />
      <TabContext value={value}>
        <div className="tablist-container">
          <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
            <Tab label="Organisation Details" value="1" />
            <Tab label="Contact Details" value="2" />
            <Tab label="Organogram" value="3" />
            <Tab label="Brand Identity" value="4" />
            <Tab label="Banking information" value="5" />
            <Tab label="Additional Information" value="6" />
          </Tabs>
        </div>
        <TabPanel value="1" className="tabpanel-overide">
          <OrganisationDetails data={profile} />
        </TabPanel>
        <TabPanel value="2" className="tabpanel-overide"><ContactDetails data={profile} /></TabPanel>
        <TabPanel value="3" className="tabpanel-overide"><AdminOrganogram data={profile} /></TabPanel>
        <TabPanel value="4" className="tabpanel-overide"><BrandIdentity data={profile} /></TabPanel>
        <TabPanel value="5" className="tabpanel-overide"><BankingInfo data={profile} /></TabPanel>
        <TabPanel value="6" className="tabpanel-overide"><AdditionalFields data={profile} /></TabPanel>
      </TabContext>
    </>
  );
};

export default AdminProfile;

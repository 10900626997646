import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Circle1 from "../../assets/images/Circle1.svg";
import Circle2 from "../../assets/images/Circle2.svg";
import { ToastContainer, toast } from "react-toastify";
import InputGroup from "../../components/FormComponent/InputGroup";
import Button from "../../components/Button";
import useValidator from "../../hooks/useValidator";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import axios from "axios";

const STUDENT_APP_LOGIN_ENDPOINT = "/api/v1/applicant/login";

const ApplicantLogin = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    application_no: "",
    password: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loginValidator, showValidationMessage] = useValidator();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (loginValidator.allValid()) {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_URL}${STUDENT_APP_LOGIN_ENDPOINT}`, loginForm).then((res) => {
        setIsLoading(false);
        toast.success("Logged in successfully");
        localStorage.setItem("enrol-auth", res.data.token);
        setTimeout(() => navigate("/student-application"), 1000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  return (
    <div className={styles.signupcontainer}>
      <div className={styles.signupwrapper}>
        <div className={styles.container}>

          <div className={`${styles.firstCircle}`}>
            <img src={Circle1} alt="" />
          </div>
          <div className={`${styles.maincontainer} auth-form`}>
            <React.Fragment>
              <div className={styles.loginHeading}>Applicant's Login</div>
              <div className={`${styles.inputGroupStyling} input-padding`}>
                <InputGroup
                  label="Application Number"
                  onChange={handleChange}
                  inputValue={loginForm.application_no}
                  margin="normal"
                  inputName="application_no"
                  // placeHolder="Applicants Number"
                  inputType="email"
                />
              </div>
              <div className={`${styles.inputGroupStyling} input-padding`}>
                <InputGroup
                  label="Password"
                  onChange={handleChange}
                  inputValue={loginForm.password}
                  inputName="password"
                  inputType={passwordType}
                  // placeHolder="Applicants Password"
                />
                <div className={`${styles.passwordeye} black900`}>
                  {passwordType === "password" ? (
                    <Icon icon="fluent:eye-16-regular" onClick={togglePassword} />
                  ) : (
                    <Icon icon="iconamoon:eye-off-light" onClick={togglePassword} />
                  )}
                </div>
              </div>
              <div className={`${styles.Footer1}auth-form-buttons`}>
                <Button buttonName="Login" variant="primary" buttonSize="full"
                  color="btndefault" isLoading={isLoading} onClick={handleForgotPassword}
                />
              </div>
            </React.Fragment>
          </div>
          <div className={`${styles.secondCircle}`}>
            <img src={Circle2} alt="" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ApplicantLogin;

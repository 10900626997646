// import { Link } from "react-router-dom";
import useStyles from "./styles";
import { Icon } from "@iconify/react/dist/iconify.js";

const AdminLeaveCard = ({ t_name, t_role, start_date, end_date, approveClick, declineClick }) => {
  const classes = useStyles();

  return (

    <div className={classes.leavecard}>
      <div className={classes.leavecardinfo}>
        <div className="text-align-left">
          <h5>{t_name}</h5>
          <p>{t_role}</p>
        </div>
        <div className="text-align-right">
          <Icon onClick={approveClick} icon="tabler:square-check-filled" width="22" height="22" style={{ color: "green" }} />
          <Icon onClick={declineClick} icon="material-symbols:cancel" width="22" height="22" style={{ color: "#D42620" }} />
        </div>
      </div>
      <hr />
      <div className={`${classes.datecont} flex justify-items-space align-items-center`}>
        <p>Start Date: <strong>{start_date}</strong></p>
        <p>End Date: <strong>{end_date}</strong></p>
      </div>
    </div>

  );
};

export default AdminLeaveCard;

import useStyles from "../styles";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import Badge from "../../../components/Badge";

const LessonNoteCard = ({ title, subtitle, date, status, viewRoute, editState, editPath, handleDelete, onClick }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(viewRoute);
  };

  return (
    <div className={classes.overallcont}>
      <div onClick={onClick} className={`${classes.cardheader} flex gap-10 justify-items-space align-items-center`}>
        <div>
          <h3>{title}</h3>
          <div className={classes.cardcontent}>{subtitle}</div>
        </div>
        <div>
          <Icon icon="iconamoon:arrow-right-2-light" width="16" height="16" style={{ color: "#6C737F" }} />
        </div>
      </div>
      <div className={`${classes.cardfooter} flex justify-items-space`}>
        <div><h4>{date}</h4></div>
        <div className="flex gap-10">
          <div><Badge status={status} /></div>
        </div>
      </div>
      <div className="flex gap-16 justify-item-right margin-top-13 margin-right-10">
        <Icon icon="fluent:eye-16-regular" width="20" height="20" style={{ color: "#545962" }} onClick={handleView} />
        <Link to={editPath} state={editState}>
          <Icon icon="lucide:edit" width="20" height="20" style={{ color: "#545962" }} />
        </Link>
        <Icon icon="mingcute:delete-2-line" width="20" height="20" style={{ color: "#DD514D" }} onClick={handleDelete} />
      </div>
      <hr />
    </div>
  );
};
LessonNoteCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any,
  date: PropTypes.string,
  status: PropTypes.string
};

export default LessonNoteCard;

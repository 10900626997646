import SchoolPlans from "./Employer/SchoolPlans";
import Layout from "../../components/Layout";
import { useUserState } from "../../context/UserContext";
import EmployeeSchoolPlan from "./Employee/EmployeeSchoolPlan";
// import Box from "@mui/material/Box";
// import useStyles from "./styles";

const ForSchoolPlan = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <SchoolPlans />
        ) : <EmployeeSchoolPlan />}
      </>
    }
    />
  );
};

export default ForSchoolPlan;

import Layout from "../../components/Layout";
import Table from "../../components/Table/Table";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableSearch } from "../../components/Table/TableActions";
import BreadCrumb from "../../components/BreadCrumb";
import { Icon } from "@iconify/react";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import InputGroup from "../../components/FormComponent/InputGroup";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import Select from "react-select";
import Badge from "../../components/Badge";

const LEAVE_OPTIONS_ENDPOINT = "/api/v1/leaveoptions";
const ROLES_ENDPOINT = "/api/v1/roles";

const LeaveOption = () => {
  const [leaveOptionModal, setLeaveOptionModal] = useState(false);
  const [editLeaveModal, setEdiLeaveModal] = useState(false);
  const [deleteLeaveModal, setDeleteLeaveModal] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isOptionsFetching, setIsOptionsFetching] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [optionsValidator, showOptionsValidator] = useValidator();
  const [editOptionsValidator, showEditOptionsValidator] = useValidator();
  const [multipleAccess, setMultipleAccess] = useState([]);
  const [leaveOptions, setLeaveOptions] = useState({
    type: "",
    duration: "",
    access: "",
    status: "active"
  });

  const [editLeaveOptions, setEditLeaveOptions] = useState({
    type: "",
    duration: "",
    access: "",
    status: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveOptions({ ...leaveOptions, [name]: value });
  };

  const handleMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setLeaveOptions({ ...leaveOptions, access: selectedValue });
  };

  const handleEditMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setEditLeaveOptions({ ...editLeaveOptions, access: selectedValue });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditLeaveOptions({ ...editLeaveOptions, [name]: value });
  };

  const handleDeleteModal = (row) => {
    setDeleteLeaveModal(true);
    setCurrentDelete(row);
  };

  const handleCloseDeleteModal = () => {
    setDeleteLeaveModal(false);
    setCurrentDelete({});
  };

  const handleLeaveOptionModal = () => {
    setLeaveOptionModal(!leaveOptionModal);
  };

  const handleEditOptionsModal = (row) => {
    setCurrentRow(row);
    setEditLeaveOptions({ ...editLeaveOptions, type: row?.type, duration: row?.duration, access: row?.access, status: row?.status });
    setEdiLeaveModal(true);
  };

  const handleCloseEditOptionsModal = () => {
    setCurrentRow({});
    setEditLeaveOptions({ ...editLeaveOptions, type: "", duration: "", access: "", status: "" });
    setEdiLeaveModal(false);
  };

  const getAllRoles = () => {
    httprequest.get(ROLES_ENDPOINT).then((res) => {
      const access = res?.data?.data?.filter((res) => res?.role_type !== "school_admin" && res?.role_type !== "guardian");
      setMultipleAccess(access?.map((role) => {
        return {
          label: role?.role_name,
          value: role?.role_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAlLeaveOptions = () => {
    setIsOptionsFetching(true);
    httprequest.get(LEAVE_OPTIONS_ENDPOINT, leaveOptions).then((res) => {
      setIsOptionsFetching(false);
      setOptionsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsOptionsFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleCreateLeaveOption = () => {
    if (optionsValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(LEAVE_OPTIONS_ENDPOINT, leaveOptions).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAlLeaveOptions();
        setLeaveOptions({ ...leaveOptions, type: "", duration: "", access: "", status: "active" });
        handleLeaveOptionModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showOptionsValidator(true);
    }
  };

  const handleDeleteLeaveOption = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${LEAVE_OPTIONS_ENDPOINT}/${currentDelete?.id}`).then(() => {
      setIsDeleteLoading(false);
      toast.success("Leave Option Deleted Successfully");
      getAlLeaveOptions();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleEditLeaveOption = () => {
    if (editOptionsValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${LEAVE_OPTIONS_ENDPOINT}/${currentRow?.id}`, editLeaveOptions).then(() => {
        setIsEditLoading(false);
        toast.success("Leave Option Edited");
        getAlLeaveOptions();
        setEditLeaveOptions({ ...editLeaveOptions, type: "", duration: "", access: "", status: "" });
        handleCloseEditOptionsModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditOptionsModal();
      });
    } else {
      showEditOptionsValidator(true);
    }
  };

  // --| Filter Leave Options table using type, number of days, who can access
  const handleSearchLeaveOptions = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = optionsData?.filter((data) => valArray?.every(
      (word) => data?.type
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
            data?.access?.toLowerCase().includes(word.toLowerCase()) ||
              data?.status?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAlLeaveOptions();
    getAllRoles();
  }, []);

  const columns = [
    {
      name: "Leave Type",
      selector: (row) => row?.type
    },
    {
      name: "Number of Days",
      selector: (row) => row?.duration
    },
    {
      name: "Who can access",
      selector: (row) => row?.access?.map((acc, i) => <div key={i}>{acc?.name}</div>)
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: " ",
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => {
            handleEditOptionsModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const { type, duration, access } = leaveOptions;

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/manage/leave"
                link_one_name="Leave Management"
                active="Leave Options"
                description="See the leave status of your employees and team members."
              />
            </div>
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={handleLeaveOptionModal}
              buttonName="Add Leave Option"
            />
          </div>

          <Table
            data={searchResult}
            columns={columns}
            subHeader={true}
            isLoading={isOptionsFetching}
            subHeaderComponent={<TableSearch placeholder="Search here..." searchTableFunc={handleSearchLeaveOptions} />}
            pagination
          />
          <Modal
            title="Add new leave option for your employees"
            subtitle="Setting leave slots give your employees leave options to choose."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p>Who can take Leave</p>
                      <Select isMulti name="access"
                        closeMenuOnSelect={true} options={multipleAccess} onChange={handleMultipleAccessChange}
                      />
                    </div>
                    {optionsValidator.message("Access", access, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup inputName="type" type="text" label="Leave Type" placeholder="e.g Annual Leave" value={type} onChange={handleChange} />
                    {optionsValidator.message("Leave Type", type, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup inputName="duration" type="number" label="Duration (in days)" value={duration} placeholder="e.g Annual Leave" onChange={handleChange} />
                    {optionsValidator.message("Duration", duration, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleCreateLeaveOption}
                  variant="primary"
                  buttonName="Add Leave Option"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isLoading}
                />
              </>
            }
            isopen={leaveOptionModal}
            closeModal={handleLeaveOptionModal}
          />
          <Modal
            title="Delete Current Leave Option"
            subtitle={`Are you sure you want to delete this  ${currentDelete?.type} leave option?. If you delete it, it becomes unavaiable for the whole school.`}
            modalContent={
              <>
                <div>
                </div>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  variant="danger"
                  buttonName="Delete Leave Option"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleDeleteLeaveOption}
                  isLoading={isDeleteLoading}
                />
              </>
            }
            isopen={deleteLeaveModal}
            closeModal={handleCloseDeleteModal}
          />

          {/* Modal to edit leave options */}
          <Modal
            title="Edit leave option"
            subtitle="Edit your current leave option"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="type" type="text" label="Leave Type" placeholder="e.g Annual Leave" defaultValue={editLeaveOptions?.type} onChange={handleEditChange} />
                    {editOptionsValidator.message("Leave Type", editLeaveOptions?.type, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p>Who can take Leave</p>
                      <Select isMulti name="access"
                        closeMenuOnSelect={true} options={multipleAccess} onChange={handleEditMultipleAccessChange}
                        getOptionValue={(option) => option?.id}
                      />
                    </div>
                    {editOptionsValidator.message("Access", editLeaveOptions?.access, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup inputName="duration" type="number" label="Duration (in days)" defaultValue={editLeaveOptions?.duration} placeholder="e.g Annual Leave" onChange={handleEditChange} />
                    {editOptionsValidator.message("Duration", editLeaveOptions?.duration, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup label="Status" children={
                      <select onChange={handleEditChange} defaultValue={editLeaveOptions?.status} name="status">
                        <option value="">---Select---</option>
                        <option value="active">Active</option>
                        <option value="inactive">InActive</option>
                      </select>
                    }
                    />
                    {editOptionsValidator.message("Status", editLeaveOptions?.status, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleEditLeaveOption}
                  variant="primary"
                  buttonName="Edit leave option"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isEditLoading}
                />
              </>
            }
            isopen={editLeaveModal}
            closeModal={handleCloseEditOptionsModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default LeaveOption;

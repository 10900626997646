import styles from "./ExaminationCard.module.css";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

const ExaminationCard = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`${styles.ExamItems}`}>
        <h4>{props.Classlevel}</h4>
        <p>{props.paragraph}</p>
        <Button onClick={() => {
          navigate("/examination/jssone-examination-papers");
        }} buttonName="View papers" buttonSize="full" color="btnfontprimary" variant="primaryoutline"
        />
      </div>
    </>
  );
};

export default ExaminationCard;

import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";

export default makeStyles((theme) => ({
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    // height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingTop: "10px"
  },
  mail: {
    paddingLeft: "3%",
    gap: "2px",
    color: "#0065FF",
    fontWeight: "500",
    lineHeight: "17.4px"
  },
  bankingformbg: {
    backgroundColor: "white"
  },
  brandingcontainer: {
    backgroundColor: "white",
    height: "calc(100vh - 300px)",
    boxSizing: "border-box",
    padding: "24px 15px",
    [theme.breakpoints.down("lg")]: {
      height: "100%"
    }
  },
  title: {
    color: "#101928",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "-0.5px",
    textAlign: "left",
    marginTop: "0px",
    marginBottom: "4px"
  },
  subtitle: {
    color: "#667185",
    letterSpacing: "-0.4px",
    textAlign: "left",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "0px"
  },
  imagecontainer: {
    width: 120,
    height: 120,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: tinycolor(theme.palette.primary.main)
      .lighten(45)
      .toHexString(),
    fontSize: 32,
    fontWeight: "bold"
  },
  profileform: {
    // width: "70%!important",
    [theme.breakpoints.up("lg")]: {
      width: "70%!important"
    }
  },
  brandflex: {
    display: "flex",
    gap: 20,
    flexWrap: "wrap"
  },
  brandesc: {
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%!important"
    }
  },
  filepicker: {
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "absolute",
    left: 0,
    top: 0
  },
  editpencil: {
    fontSize: 20
  },
  selectimage: {
    position: "relative",
    width: 200,
    height: 45,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 32,
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5
  },
  palettecontainer: {
    display: "flex",
    gap: 10,
    marginTop: 10
  },
  palettewrapper: {
    width: 55,
    height: 55,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "50%"
  },
  blueselected: {
    border: "1px solid #0065FF"
  },
  pinkselected: {
    border: "1px solid #B00074"
  },
  greenselected: {
    border: "1px solid #099137"
  },
  yellowselected: {
    border: "1px solid #AD6F07"
  },
  colorpalette: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    border: "2px solid #E4E7EC",
    "&:hover, &:focus": {
      cursor: "pointer"
    }
  },
  paletteblue: {
    backgroundColor: theme.palette.primary.main
  },
  palettepink: {
    backgroundColor: "#B00074"
  },
  palettegreen: {
    backgroundColor: "#099137"
  },
  paletteyellow: {
    backgroundColor: "#AD6F07"
  },
  btnspace: {
    marginTop: 15
  },
  downloadbutton: {
    marginTop: 20,
    textAlign: "center"
  },
  profilecontainer: {
    backgroundColor: "#F5F6F7",
    width: "100%",
    height: 258,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 22
  },
  pickercontainer: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    bottom: 0,
    right: 0,
    color: "white",
    borderRadius: "50%"
  },
  camera: {
    color: "white",
    width: "14px!important"
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  profilepicker: {
    opacity: 0,
    width: 25,
    height: 25,
    cursor: "pointer",
    position: "absolute"
  },
  imageviewer: {
    width: "120px",
    height: "120px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "relative"
  },
  keycontents: {
    "& a": {
      color: "#0065FF",
      fontWeight: "800"
    }
  }
}));

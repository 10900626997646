import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";

export default makeStyles((theme) => ({
  overall: {
    positive: "relative",
    padding: "16px",
    background: "white",
    borderRadius: "16px",
    width: "100%",
    textAlign: "left",
    zIndex: "9999"
  },
  ForBCG: {
    padding: "5px 8px",
    background: tinycolor(theme.palette.primary.main).lighten(45).toHexString(),
    borderRadius: "4px",
    color: "#0065FF"
  },
  content: {
    marginBlock: "8px",
    fontSize: "16px",
    lineHeight: "19.2px",
    letterSpacing: "-2"
  },
  Item: {
    background: "#0000000D",
    marginTop: "-185px",
    marginLeft: "75px",
    borderRadius: "8px",
    paddingInline: "10px",
    cursor: "pointer",
    position: "absolute",
    zIndex: "999999"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    color: "#667185",
    fontSize: "14px"
  }
}));

import { useUserState } from "../../../context/UserContext";
import { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { ClassLevel, Session, Term } from "../../../utils/DropDown";

// --| Dummy Data
import EmployeeExaminationData from "../../../dummy_data/employee_examination_data.json";

const EmployeeExaminationProfile = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { profile } = useUserState();
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [submitmodal, setSubmitModal] = useState(false);

  const handleSubmitModal = () => {
    setSubmitModal(!submitmodal);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setCurrentRow({});
  };

  const columns = [
    {
      name: "Classes",
      selector: row => row?.class_level
    },
    {
      name: "No Of Subjects",
      selector: row => row?.no_of_subject
    },
    {
      name: "Students",
      selector: row => row?.students
    },
    {
      name: "Exam Questions",
      selector: row => row?.exam_questions
    },
    {
      name: "Date Submitted",
      selector: row => row?.date_submitted
    },
    {
      name: "Date of Exam",
      selector: row => row?.date_of_exam
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button onClick={() => {
            navigate("/examination/employee-jssone");
          }} className="table-action-icon"
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    // The data will be from the Examination data get api endpint
    setSearchResult(EmployeeExaminationData);
  }, []);

  // --| Filter Examination Data table using name, email and class
  const handleSearchInventory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = EmployeeExaminationData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.description
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.category
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.status
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <PageTitle
        title="Examination"
        subtitle="See your examination papers and everything else."
        button={
          <div data={profile} className="flex gap-10 margin-bottom">
            <Button onClick={handleSubmitModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Submit Examination Paper" />
          </div>
        }
      />
      <div className={classes.pageContent}>
        <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
          <TableSearch placeholder="Search here..." searchTableFunc={handleSearchInventory} />
        }
        />
      </div>
      {/* Submit Examination Modal */}
      <Modal
        title="Submit Exam Paper."
        subtitle={
          <div> Submit the examination paper for your subject
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Name of Subject"
                  margin="normal"
                  placeHolder="Enter the name of the subject"
                  inputType="text"
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Class" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {ClassLevel?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Number of Student"
                  margin="normal"
                  placeHolder="Enter the total number of Students"
                  inputType="text"
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Session" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {Session?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Session" children={
                  <select>
                    <option value="">---Select Labels ---</option>
                    {Term?.map((label) => {
                      return (
                        <option key={label?.key}>{label?.text}</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Date"
                  margin="normal"
                  placeHolder=""
                  inputType="date"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <label className={`${classes.label}`}>Additional Information</label>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} />

                  <div className={classes.uploadflex}>
                    <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                    <div>
                      <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                      <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                    </div>
                  </div>
                  <div className={classes.rightpicker}>
                    <div className={classes.uploadbutton}>Upload</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Submit Examination Paper" />
            </div>
          </>
        }
        isopen={submitmodal}
        closeModal={handleSubmitModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Subject Exam"
        subtitle={
          <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. If deleted, employee will no longer have access to this Examination Info.`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <Button variant="danger" buttonName="Delete Inventory" buttonSize="full" color="btndefault" />
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
    </>
  );
};

export default EmployeeExaminationProfile;

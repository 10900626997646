import Breadcrumbs from "@mui/material/Breadcrumbs";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";

// --| These breadcrumb takes a maximum of 3 depths, depth1> depth1 > depth3
// --| description refers to the text below the breadcrumb stack
// --| active is the current page
// --| link_one is the first link on the bread crumb
// --| link_one_name is the name of the first link on the bread crumb
// --| link_two is the second link on the bread crumb
// --| link_two_name is the name of the second link on the breadcrumb

const BreadCrumb = ({ description, active, link_one, link_one_name, link_two, link_two_name }) => {
  const classes = useStyles();
  const breadcrumbs = [
    <Link key="1" to={link_one} className={classes.breadcrumb_link}>
      {link_one_name}
    </Link>,
    link_two &&
    <Link key="1" to={link_two} className={classes.breadcrumb_link}>
      {link_two_name}
    </Link>,
    <div key="2" className={classes.active_breadcrumb}>
      {active}
    </div>
  ];

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className={classes.breadcrumb_subtitle}>{description}</div>
    </>
  );
};
export default BreadCrumb;

import PropTypes from "prop-types";
import "./node.css";
import useStyles from "./style";

const propTypes = {
  nodeData: PropTypes.object.isRequired
};

const MyNode = ({ nodeData }) => {
  const classes = useStyles();
  const selectNode = () => {
    // eslint-disable-next-line no-alert
    alert(`Hi All. I'm ${nodeData.name}. I'm a ${nodeData.title}.`);
  };

  return (
    <div onClick={selectNode}>
      <div className={`fullname ${classes.fullname}`}>{nodeData.name}</div>
      <div className={`position ${classes.position}`}>{nodeData.title}</div>
    </div>
  );
};

MyNode.propTypes = propTypes;

export default MyNode;

import { useState } from "react";
// import TabContext from "@mui/lab/TabContext";
import { Grid } from "@mui/material";
// import Tab from "@mui/material/Tab";
// import Tabs from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import { ToastContainer } from "react-toastify";
import PageTitle from "../../../components/PageTitle";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
import Button from "../../../components/Button";
import NoExamFound from "../../../assets/images/noexam.svg";
import ApplicantResultCard from "./ApplicantResultCard";

const ApplicantResult = () => {
  const classes = useStyles();
  // const [value, setValue] = useState("");
  const [noResultModal, setNoResultModal] = useState(false);
  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleNoResultModal = () => {
    setNoResultModal(!noResultModal);
  };

  return (
    <div className={`${classes.border} page-content`}>
      <PageTitle
        title="Your Exam Results"
        subtitle="These are all the examinations you can take in this enrolment"
        button={
          <div className="flex gap-10">
            <Button onClick={handleNoResultModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Check for Result" />
          </div>
        }
      />
      <div className="page-content height-full">
        {/* <TabContext value={value}>
          <div className="tablist-container">
            <Tabs indicatorColor="none" className="overide-tablist" onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="All Results" value="" />
            </Tabs>
          </div>
          <>
            <TabPanel value="" className="tabpanel-overide">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <ApplicantResultCard resulttitle="Mathematics" result="60%" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <ApplicantResultCard resulttitle="Quantum Physics" result="90%" />
                </Grid>
              </Grid>
            </TabPanel>
          </>
        </TabContext> */}
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <ApplicantResultCard resulttitle="Mathematics" result="60%" />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <ApplicantResultCard resulttitle="Quantum Physics" result="90%" />
          </Grid>
        </Grid>
        <ToastContainer />
        {/* <div className="pagination-container">
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
      </div> */}
      </div>
      {/* No Result Found MOdal */}
      <Modal
        title="Your Results"
        subtitle=""
        modalContent={
          <>
            <div>
              <div className={classes.noexamimg}>
                <img src={NoExamFound} alt="Not Found" />
              </div>
              <div>
                <p className={classes.nocodeheader}>No Result Found</p>
                <p className={classes.nocodesentence}>The school admin has not uploaded any result for you to see.</p>
              </div>
            </div>
          </>
        }
        modalFooter={
          <>
          </>
        }
        isopen={noResultModal}
        closeModal={handleNoResultModal}
      />
    </div>
  );
};

export default ApplicantResult;

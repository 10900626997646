import Layout from "../../components/Layout";
import TabContext from "@mui/lab/TabContext";
import PageTitle from "../../components/PageTitle";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import CreateEnrolment from "./components/CreateEnrolment";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../components/Table/Table";
import Badge from "../../components/Badge";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import useStyles from "./styles";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";

const ENROLMENT_ENDPOINT = "/api/v1/enrolment";

const Index = () => {
  const classes = useStyles();
  // const [value, setValue] = useState("1");
  const [enrolmentData, setEnrolmentData] = useState([]);
  const [isEnrolmentFetching, setIsEnrolmentFetching] = useState(false);
  const [editEnrolmentModal, setEditEnrolmentModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEnrolment, setCurrentEnrolment] = useState({});
  const navigate = useNavigate();

  const handleEditEnrolmentModal = (row) => {
    setEditEnrolmentModal(!editEnrolmentModal);
    setCurrentEnrolment(row);
  };

  const handleCloseEditEnrolmentModal = () => {
    setEditEnrolmentModal(false);
    setCurrentEnrolment({});
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const columns = [
    {
      name: "Code",
      selector: (row) => row?.code
    },
    {
      name: "Classes Open",
      selector: (row) => row?.classes?.map((clss) => (
        <span key={clss?.clss_id}>
          {clss?.class_name}
          {" , "}
        </span>
      ))
    },
    {
      name: "Status",
      cell: (row) => <Badge status={row?.open} />
    },
    {
      name: "Closing Date",
      selector: (row) => row?.closing_date?.substring(0, 10)
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link
            className="table-action-icon"
            to={`/enrol/${row?.id}`}
            target="_blank"
          >
            <Icon icon="majesticons:open" />
          </Link>
          <Link to={`/enrolments/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button
            className="table-action-icon"
            onClick={() => handleEditEnrolmentModal(row)}
          >
            <Icon icon="lucide:edit" />
          </button>
          <button
            // onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const getAllEnrolments = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .get(ENROLMENT_ENDPOINT)
      .then((res) => {
        setIsEnrolmentFetching(false);
        setEnrolmentData(res?.data?.data);
      })
      .catch((err) => {
        setIsEnrolmentFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const handleEditEnrolment = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .patch(`${ENROLMENT_ENDPOINT}/${currentEnrolment?.id}`, {
        open: !currentEnrolment?.open
      })
      .then(() => {
        setIsEditing(false);
        setCurrentEnrolment({});
        setEditEnrolmentModal(false);
        getAllEnrolments();
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    getAllEnrolments();
  }, []);

  return (
    <Layout children={
      <>
        <PageTitle
          title="Enrolments"
          subtitle="Manage all students applications and enroments in your school"
          button={
            <div className="flex gap-10">
              <Button onClick={() => {
                navigate("/enrolments/viewenrolment");
              } } variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="View Enrolments"
              />
              <Button onClick={() => {
                navigate("/enrolments/create");
              } } variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create Enrolment"
              />
            </div>
          }
        />
        <div className="page-content">
          <TabContext>
            <TabPanel className="tabpanel-overide">
              <Table
                data={enrolmentData}
                columns={columns}
                pagination
                isLoading={isEnrolmentFetching}
              />
            </TabPanel>
            <TabPanel value="2" className="tabpanel-overide">
              <CreateEnrolment />
            </TabPanel>
          </TabContext>

          {/* Edit Enrolment (Close/Open Enrolment) */}
          <Modal
            title="Open or Close the Enrolment Process"
            subtitle={
              <div>
                  Note that opening/closing this manages your users access to
                  the application page.
              </div>
            }
            modalContent={<></>}
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {currentEnrolment?.open ? (
                        <Button
                          variant="danger"
                          buttonSize="full"
                          color="btndefault"
                          disabled={!currentEnrolment?.open}
                          buttonName="Close this Enrolment"
                          isLoading={isEditing}
                          onClick={handleEditEnrolment}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          buttonSize="full"
                          color="btndefault"
                          disabled={currentEnrolment?.open}
                          buttonName="Re-Open this enrolment"
                          isLoading={isEditing}
                          onClick={handleEditEnrolment}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            }
            isopen={editEnrolmentModal}
            closeModal={handleCloseEditEnrolmentModal}
          />
        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default Index;

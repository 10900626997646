import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../components/Table/Table";
import Badge from "../../components/Badge";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import useStyles from "./styles";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";
import PageTitle from "../../components/PageTitle";

const APPLICATION_ENDPOINT = "/api/v1/enrolment/applications";

const Applications = () => {
  const classes = useStyles();
  const [applicationsData, setApplicationsData] = useState([]);
  const [isApplicationFetching, setIsApplicationFetching] = useState(false);
  const [editEnrolmentModal, setEditEnrolmentModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEnrolment, setCurrentEnrolment] = useState({});

  const navigate = useNavigate();

  const handleEditEnrolmentModal = (row) => {
    setEditEnrolmentModal(!editEnrolmentModal);
    setCurrentEnrolment(row);
  };

  const handleCloseEditEnrolmentModal = () => {
    setEditEnrolmentModal(false);
    setCurrentEnrolment({});
  };

  const columns = [
    {
      name: "Code",
      selector: (row) => row?.code
    },
    {
      name: "Classes Open",
      selector: (row) => row?.classes?.map((clss) => (
        <span key={clss?.clss_id}>
          {clss?.class_name}
          {" , "}
        </span>
      ))
    },
    {
      name: "Status",
      cell: (row) => <Badge status={row?.open} />
    },
    {
      name: "Closing Date",
      selector: (row) => row?.closing_date?.substring(0, 10)
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/classes/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button
            className="table-action-icon"
            onClick={() => handleEditEnrolmentModal(row)}
          >
            <Icon icon="lucide:edit" />
          </button>
          <button
            // onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const getAllApplications = () => {
    setIsApplicationFetching(true);
    httprequest
      .get(APPLICATION_ENDPOINT)
      .then((res) => {
        setIsApplicationFetching(false);
        setApplicationsData(res?.data?.data);
      })
      .catch((err) => {
        setIsApplicationFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const handleEditEnrolment = () => {
    setIsApplicationFetching(true);
    httprequest
      .patch(`${Applications}/${currentEnrolment?.id}`, {
        open: !currentEnrolment?.open
      })
      .then(() => {
        setIsEditing(false);
        setCurrentEnrolment({});
        setEditEnrolmentModal(false);
        getAllApplications();
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    getAllApplications();
  }, []);

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Applications"
            subtitle="See all applications in your school."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={() => {
                    navigate("/enrolments");
                  }}
                  buttonName="View All Enrolments"
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  buttonName="Create Application"
                />
              </div>
            }
          />
          <div className="page-content">
            <Table
              data={applicationsData}
              columns={columns}
              pagination
              isLoading={isApplicationFetching}
            />
            {/* Edit Enrolment (Close/Open Enrolment) */}
            <Modal
              title="Open or Close the Enrolment Process"
              subtitle={
                <div>
                  Note that opening/closing this manages your users access to
                  the application page.
                </div>
              }
              modalContent={<></>}
              modalFooter={
                <>
                  <div className={classes.modalFooterBtn}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {currentEnrolment?.open ? (
                          <Button
                            variant="danger"
                            buttonSize="full"
                            color="btndefault"
                            disabled={!currentEnrolment?.open}
                            buttonName="Close this Enrolment"
                            isLoading={isEditing}
                            onClick={handleEditEnrolment}
                          />
                        ) : (
                          <Button
                            variant="primary"
                            buttonSize="full"
                            color="btndefault"
                            disabled={currentEnrolment?.open}
                            buttonName="Re-Open this enrolment"
                            isLoading={isEditing}
                            onClick={handleEditEnrolment}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </>
              }
              isopen={editEnrolmentModal}
              closeModal={handleCloseEditEnrolmentModal}
            />
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default Applications;

import { Grid } from "@mui/material";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "../../../components/Button";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../data/api";
import { useUserState } from "../../../context/UserContext";
import { toast } from "react-toastify";
import PageLoader from "../../../components/PageLoader";

const PROFILE_ENDPOINT = "/api/v1/profile";
const PersonalInformation = () => {
  const classes = useStyles();
  const [personalData, setPersonalData] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);

  const { userRole } = useUserState();

  const getStudentProfile = () => {
    setIsPageLoading(true);
    httprequest.get(`${PROFILE_ENDPOINT}`)
      .then((res) => {
        setIsPageLoading(false);
        setPersonalData(res?.data?.data);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    getStudentProfile();
  }, []);

  return (
    <>
      {isPageLoading ? (<PageLoader />) :
        <Grid container spacing={4}>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <div className="left-container">
              <h4>Personal Information</h4>
              <h6>Update your personal details here</h6>
              <div className={classes.profilecontainer}>
                <div className={classes.imageviewer}>
                  <div className={classes.pickercontainer}>
                    <input type="file" name="profile_picture" value={personalData?.display_pic?.file_url} className={classes.profilepicker} />
                    <CameraAltIcon className={classes.camera} />
                  </div>
                </div>
              </div>
            </div>

          </Grid>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <div className="right-container">
              <Grid container spacing={2} className={classes.profileform}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Full Name" name="" value={`${personalData?.first_name} ${personalData?.last_name}`} disabled />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Role" name="" value={userRole?.role} disabled />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Email Address" name="" value={personalData?.email} disabled />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Gender" name="" value={personalData?.gender} disabled />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Phone Number" name="" value={personalData?.contact || "---"} disabled />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Country" name="" value={personalData?.country || "---"} disabled />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup inputType="text" label="Home Address" name="" value={personalData?.home_address || "---"} disabled />
                </Grid>
              </Grid>
              <div className={classes.btnspace}>
                <Button variant="secondary" color="btnfontprimary" buttonSize="medium" buttonName="Save Changes" disabled />
              </div>
            </div>

          </Grid>
        </Grid>
      }
    </>
  );
};

export default PersonalInformation;

import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import { Grid, Tooltip } from "@mui/material";
// import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import { ToastContainer, toast } from "react-toastify";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import { useParams, Link } from "react-router-dom";
import VerticalLine from "../../../assets/images/verticallines.svg";
import Button from "../../../components/Button";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import Modal from "../../../components/Modal";

const SUBJECT_NOTE_ENDPOINT = "/api/v1/lesson-outlines";
const COMMENT_API = "/api/v1/lesson-outlines/comments";

const ViewSubjectLessonNote = () => {
  const classes = useStyles();
  const [value, setValue] = useState("0");
  // eslint-disable-next-line no-console
  console.log(value, "VALUE");
  const [subjectNote, setSubjectNote] = useState([]);
  // const navigate = useNavigate();
  const { id } = useParams();
  // const [isLoading, setIsLoading] = useState(false);
  const [versionNote, setVersionNote] = useState([]);
  const [comment, setComment] = useState({
    lesson_outline_version_id: "",
    comment: ""
  });
  const [commentToDelete, setCommentToDelete] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInitialTabLoad = async () => {
    try {
      const res = await httprequest.get(`${SUBJECT_NOTE_ENDPOINT}/${id}`);
      setSubjectNote(res?.data?.data);
      setValue("0");
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  const handleGetNote = async () => {
    // setIsLoading(true);
    await httprequest
      .get(`${SUBJECT_NOTE_ENDPOINT}/${id}`)
      .then((res) => {
        setSubjectNote(res?.data?.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };
  // eslint-disable-next-line no-console
  console.log(subjectNote, "subjectNote");

  const handleVersionNote = async (version_id) => {
    try {
      const res = await httprequest.get(`${SUBJECT_NOTE_ENDPOINT}/${id}?version_id=${version_id}`);
      setVersionNote(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };
    // eslint-disable-next-line no-console
  console.log(versionNote, "versionNote");

  // Table Tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    handleVersionNote(newValue);
  };

  useEffect(() => {
    handleGetNote();
    // handleVersionNote();
  }, []);

  // Handle comment change
  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment((prev) => ({ ...prev, comment: value }));
  };

  // Handle Comment Post request
  const handlePostComment = (versionId) => {
    // setIsLoading(true);
    httprequest.post(COMMENT_API, { ...comment, lesson_outline_version_id: versionId })
      .then((res) => {
        toast.success(res?.data.message);
      }).catch((err) => {
        // setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  // Handle comment Delete
  const handleCommentDelete = async (comment_id) => {
    try {
      await httprequest.delete(`${COMMENT_API}/${comment_id}`);
      setIsLoading(false);
      handleGetNote();
      toast.success("Comment deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDeleteModal = (commentId) => {
    setCommentToDelete(commentId);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div className="flex justify-items-space flex-wrap-items-426">
              <div>
                {subjectNote && (
                  <BreadCrumb
                    link_one="/lesson-notes"
                    link_one_name="Lesson Notes"
                    link_two={`/lesson-notes/${subjectNote?.lesson_note_id
                    }`}
                    link_two_name="View All Lesson Outline"
                    active="View Lesson Outline"
                    description="View lesson outline for each lesson note"
                  />
                )}
              </div>
              {subjectNote && (
                <div className="margin-top-16-items-426">
                  <Link to={{ pathname: `/lesson-notes/edit-lesson-outline/${subjectNote?.lesson_outline_id}` }} state={ subjectNote } style={{ textDecoration: "none" }}>
                    <Button
                      variant="primary"
                      buttonSize="fluid"
                      color="btndefault"
                      buttonName="Edit Lesson Outline"
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
          <TabContext value={value}>
            <Grid
              container
              spacing={4}
              sx={{
                "@media (max-width: 1000px)": {
                  flexWrap: "wrap-reverse"
                }
              }}
            >
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <TabPanel value="0" sx={{ padding: "0px" }}>
                  <div className={`${classes.paddingcont}`}>
                    <div className={`${classes.parsercont} left-container`}>
                      {parse(subjectNote?.lesson_outline || "")}
                    </div>
                  </div>
                </TabPanel>
                {subjectNote?.updates?.map((update) => (
                  <TabPanel key={update?.version_id} value={update?.version_id} sx={{ padding: "0px" }}>
                    <div className={`${classes.paddingcont}`}>
                      <div className={`${classes.parsercont} left-container`}>
                        {parse(versionNote?.lesson_outline || "")}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <div className={`${classes.paddingcont}`}>
                  <div className={`${classes.rightcont} right-container`}>
                    <Tooltip title="This will load the current note outline" arrow>
                      <div className={classes.reload} onClick={handleInitialTabLoad}>
                        <Icon icon="ooui:reload" width="20" height="20" />
                      </div>
                    </Tooltip>
                    <div className={`${classes.notedetails}`}>
                      <div className={`${classes.notespan}`}>
                        <h5>Subject</h5>
                        <p>{subjectNote?.subject?.name}</p>
                      </div>
                      <div className={`${classes.notespan}`}>
                        <h5>Topic</h5>
                        <p>{subjectNote?.topic}</p>
                      </div>
                      <div className={`${classes.notespan}`}>
                        <h5>Method of Teaching</h5>
                        <p>{subjectNote?.training_method}</p>
                      </div>

                      {subjectNote && (
                        <Tabs
                          indicatorColor="none"
                          orientation="vertical"
                          value={value}
                          onChange={handleTabChange}
                          aria-label="subject note updates"
                        >
                          {subjectNote?.updates?.map((update) => (
                            <Tab
                              key={update?.version_id}
                              value={update?.version_id}
                              sx={{
                                padding: "0px",
                                alignItems: "unset",
                                "& .MuiTab-wrapper": {
                                  alignItems: "unset"
                                }
                              }}
                              className="tab-left"
                              label={
                                <>
                                  <div className="flex gap-10 align-items-center margin-top-10 margin-bottom-10 cursor-pointer">
                                    <Icon
                                      icon="lets-icons:check-fill"
                                      width="32"
                                      height="32"
                                      style={{ color: "#3FAC64" }}
                                    />
                                    <div>
                                      <h5>Updated at</h5>
                                      <p>{update?.updated_at?.substring(0, 10)}</p>
                                    </div>
                                  </div>
                                  <div className={classes.verticalline}>
                                    <img src={VerticalLine} alt="Vertical Line" />
                                  </div>
                                </>
                              }
                            />
                          ))}
                        </Tabs>
                      )}

                      <div className="flex gap-10 align-items-center margin-bottom-10">
                        <Icon
                          icon="mdi:cloud-clock-outline"
                          width="25"
                          height="25"
                          style={{
                            color: "#0e9504",
                            border: "1px solid #0e9504",
                            borderRadius: "20px",
                            padding: "5px",
                            marginLeft: "5px"
                          }}
                        />
                        <div>
                          <h5>Created at</h5>
                          <p>{subjectNote?.created_at?.substring(0, 10)}</p>
                        </div>
                      </div>

                      {/* Comments Section */}
                      <div className="margin-top-40">
                        <div className="text-align-left">
                          <Icon
                            icon="iconamoon:comment-fill"
                            width="20"
                            height="20"
                            style={{ color: "#111111" }}
                          />
                        </div>
                        <div className="margin-bottom-20">
                          <h4>Comment</h4>
                          <p>Review the lesson note by dropping your comment</p>
                        </div>
                        {subjectNote?.comments?.map((comment, index) => (
                          <div key={index} className={`${classes.commentarea}`}>
                            <div>{comment?.comment}</div>
                            <div className="flex gap-16 justifyRight">
                              <div className="flex gap-10 align-items-center">
                                <Icon
                                  icon="lucide:edit"
                                  width="18"
                                  height="18"
                                  style={{ color: "#545962" }}
                                />
                                <Icon
                                  icon="material-symbols:delete-outline"
                                  width="18"
                                  height="18"
                                  style={{ color: "#DD514D" }}
                                  onClick={() => handleDeleteModal(comment?.id)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        {subjectNote && (
                          <div className={`${classes.fortextarea} margin-top-12`}>
                            <textarea
                              name=""
                              className={`${classes.textarea}`}
                              id=""
                              value={comment?.comment}
                              onChange={handleCommentChange}
                            >
                            </textarea>
                            <div className="flex gap-16 justifyRight">
                              <div
                                className={`${classes.submitreview} flex gap-10 align-items-center cursor-pointer`}
                                onClick={() => handlePostComment(subjectNote?.version_id)}
                              >
                                <p>Submit Review</p>
                                <Icon
                                  icon="iconamoon:send-fill"
                                  width="20"
                                  height="20"
                                  style={{ color: "#0065FF" }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <ToastContainer />
            </Grid>
          </TabContext>
          <Modal
            title="Delete Lesson Outline"
            subtitle="Are you sure you want to permanently delete this comment?"
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="danger"
                    buttonName="Delete Comment"
                    buttonSize="full"
                    color="btndefault"
                    onClick={() => handleCommentDelete(commentToDelete)}
                    isLoading={isLoading}
                  />
                </div>
              </>
            }
            isopen={isDeleteModalOpen}
            closeModal={handleDeleteModal}
          />
        </>
      }
    />
  );
};

export default ViewSubjectLessonNote;

import { makeStyles } from "@mui/styles";
// import tinycolor from "tinycolor2";

export default makeStyles(() => ({
  searchcontainer: {
    width: "25%",
    marginTop: 20,
    position: "relative",
    "@media (max-width: 1200px)": {
      width: "30%"
    }
  },
  searchicon: {
    position: "absolute !important",
    left: "8px",
    top: "13px",
    color: "#667185",
    fontSize: "20px",
    fontWeight: "bold"
  },
  search: {
    height: "40px",
    boxSizing: "border-box",
    padding: "5px 5px 5px 30px",
    borderRadius: "6px",
    border: "1px solid #D0D5DD",
    backgroundColor: "transparent",
    position: "relative",
    outline: 0,
    width: "100% !important",
    fontSize: "bold"
  }
}));

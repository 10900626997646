// styles
import useStyles from "./styles";

// --| Page Title takes title, subtitle and button as props
const PageTitle = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <div className={classes.typo}>
        <h6 className={classes.title}>
          {props.title}
        </h6>
        <h6 className={classes.subtitle}>{props.subtitle}</h6>
      </div>
      {props.button && props.button}
    </div>
  );
};
export default PageTitle;

import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import DetailsText from "./components/DetailsText";
import useStyles from "./styles";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";
import Badge from "../../components/Badge";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { Icon } from "@iconify/react";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const LeaveDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [isApproving, setIsApproving] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [myLeave, setMyLeave] = useState([]);

  const handleApproveModal = () => {
    setApproveModal(true);
  };

  const handleCloseDeclineModal = () => {
    setDeclineModal(false);
  };

  const handleDeclineModal = () => {
    setDeclineModal(true);
  };

  const handleCloseApproveModal = () => {
    setApproveModal(false);
  };

  const getMyLeave = () => {
    httprequest.get(`${LEAVE_MGT_ENDPOINT}/${id}`).then((res) => {
      setMyLeave(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const handleApproveLeave = () => {
    setIsApproving(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${id}`, { leave_status: "declined" })
      .then((res) => {
        setIsApproving(false);
        handleCloseApproveModal();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setIsApproving(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDeclineLeave = () => {
    setIsDeclining(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${id}`, { leave_status: "declined" })
      .then(() => {
        setIsDeclining(false);
        handleCloseApproveModal();
        toast.success("Leave status has been declined");
      })
      .catch((err) => {
        setIsDeclining(false);
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getMyLeave();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/manage/leave"
                link_one_name="Leave Management"
                active="Leave Details"
                description={`See the leave details of ${myLeave?.leave_requester?.name}`}
              />
            </div>
            <div className="flex gap-10">
              <Button
                variant="primary"
                buttonSize="fluid"
                color="btndefault"
                onClick={() => navigate(`/manage/leave/leave-calender/${id}`)}
                buttonName="View in Calender"
              />
            </div>
          </div>
          <hr className={classes.pagedivider} />
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText title="Name" subtitle={`${myLeave?.leave_requester?.name}`} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <DetailsText
                  title="Status"
                  subtitle={<Badge status={myLeave?.leave_status} />}
                />
              </Grid>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText title="From" subtitle={myLeave?.start_date?.substring(0, 10)} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText title="To" subtitle={myLeave?.end_date?.substring(0, 10)} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText title="Title" subtitle={myLeave?.role?.name || "-----"} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText
                title="Approved By"
                subtitle={myLeave?.approved_by?.name || "-----"}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText title="Leave Type" subtitle={myLeave?.type || "----"} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DetailsText title="No of Days" subtitle={myLeave?.number_of_days || "----"} />
            </Grid>
            <Grid item lg={6} md={8} sm={8} xs={12}>
              <DetailsText
                title="Comment"
                subtitle={myLeave?.comment || "----"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} marginTop={1}>
            {/* <Grid item lg={3} md={4} sm={6} xs={12}>
              <Button buttonName="Reschedule leave" variant="dangeroutline" buttonIcon={<Icon icon="carbon:close-filled" />} color="btndanger" buttonSize="full" />
            </Grid> */}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Button
                buttonName="Approve Request"
                variant="success"
                buttonIcon={<Icon icon="mdi:checkbox-marked-circle-outline" />}
                color="btndefault"
                buttonSize="full"
                onClick={handleApproveModal}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Button
                buttonName="Decline Request"
                variant="danger"
                buttonIcon={<Icon icon="carbon:close-filled" />}
                color="btndefault"
                buttonSize="full"
                onClick={handleDeclineLeave}
                isLoading={isDeclining}
              />
            </Grid>
          </Grid>
          <Modal
            title="Approve Current Leave"
            subtitle=""
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Approve Leave"
                    onClick={handleApproveLeave}
                    isLoading={isApproving}
                  />
                </div>
              </>
            }
            isopen={approveModal}
            closeModal={() => {
              setApproveModal(false);
            }}
          />
          <Modal
            title="Decline Current Leave"
            subtitle=""
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Decline Leave"
                    onClick={handleDeclineModal}
                    isLoading={isDeclining}
                  />
                </div>
              </>
            }
            isopen={declineModal}
            closeModal={handleCloseDeclineModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default LeaveDetails;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  label: {
    fontWeight: "500",
    fontSize: "16px"
  },
  pickercontainer: {
    width: "100%",
    border: "1px dashed #D0D5DD",
    // maxHeight: 70,
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FBFBFB",
    marginLeft: "16px",
    // display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "35px 35px",
    marginBottom: 20,
    marginTop: 15
  },
  picker: {
    opacity: 0,
    width: "100%",
    height: "100%",
    zIndex: "1",
    cursor: "pointer",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: "100%",
    justifyContent: "center"
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#FFFFFF",
    fontWeight: "400",
    textAlign: "center",
    backgroundColor: "#0065FF",
    padding: "12px 40px",
    marginBottom: 0,
    borderRadius: "5px",
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#000000",
    fontsize: 16,
    fontWeight: "400",
    marginBottom: 0,
    marginTop: 7,
    textAlign: "center"
  },
  remove: {
    color: "red",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "6px 8px",
    boxSizing: "border-box",
    marginLeft: 3,
    zIndex: 50,
    backgroundColor: "white",
    border: "1px solid #98A2B3",
    borderRadius: 4,
    display: "flex",
    fontWeight: "bold"
  },
  selectedfile: {
    display: "flex",
    position: "relative",
    zIndex: "99",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer",
    "& p": {
      marginBlock: "5px"
    }
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  btn: {
    marginLeft: "16px"
  }
}));

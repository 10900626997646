import Layout from "../../../components/Layout";
import { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { Icon } from "@iconify/react";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import BreadCrumb from "../../../components/BreadCrumb";
import InputGroup from "../../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";

//  --| Dummy Data
import StudentReportData from "../../../dummy_data/studentReportData.json";

const StudentReport = () => {
  const [value, setValue] = useState("2");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [searchResult, setSearchResult] = useState([]);
  const classes = useStyles();
  const [addreportModal, setReportModal] = useState(false);
  const [sendmessageModal, setSendMessagemodal] = useState(false);

  const handleSendMessageModal = () => {
    setSendMessagemodal(!sendmessageModal);
  };

  const handleReportModal = () => {
    setReportModal(!addreportModal);
  };

  useEffect(() => {
    // The data will be from the knowledgebase get api endpint
    setSearchResult(StudentReportData);
  }, []);

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject
    },
    {
      name: "CA Score",
      selector: row => row?.ca_score
    },
    {
      name: "Exam Score",
      selector: row => row?.exam_score
    },
    {
      name: "Total Score",
      selector: row => row?.total_score
    },
    {
      name: "Grade",
      selector: row => row?.grade
    },
    {
      name: "Date",
      selector: row => row?.date
    }
  ];
  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = StudentReportData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                        data?.title
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                        data?.label
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                          data?.type
                            ?.toLowerCase()
                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <BreadCrumb
            link_one="/classes"
            link_one_name="My Classes"
            link_two="/classes/employeecreateclass"
            link_two_name="My Students"
            active="Ojo Williams Daniel"
            description="See the details of the students in your care"
          />
          <PageTitle
            title="Ojo Williams Daniel"
            subtitle="JSS 1  |  Male"
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={handleSendMessageModal}
                  buttonName="Send a message to parent"
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleReportModal}
                  buttonName="Send report to parent"
                  //   onClick={handleCreateModal}
                />
              </div>
            }
          />
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="JSS 1" value="2" />
                <Tab label="JSS 2" value="3" />
                <Tab label="JSS 3" value="4" />
                <Tab label="SSS 1" value="5" />
                <Tab label="SSS 2" value="6" />
                <Tab label="SSS 3" value="7" />
              </Tabs>
            </div>
            <TabPanel value="1" className="tabpanel-overide"></TabPanel>
            <TabPanel value="2" className="tabpanel-overide">
              {" "}
            </TabPanel>
            <TabPanel value="3" className="tabpanel-overide">
              {" "}
            </TabPanel>
            <TabPanel value="4" className="tabpanel-overide"></TabPanel>
            <TabPanel value="5" className="tabpanel-overide">
              {" "}
            </TabPanel>
            <TabPanel value="6" className="tabpanel-overide">
              {" "}
            </TabPanel>
            <TabPanel value="7" className="tabpanel-overide">
              {" "}
            </TabPanel>
          </TabContext>
          <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
            <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
          }
          />
          {/* Send Report Modal */}
          <Modal
            title="Send Student Report"
            subtitle={
              <div>You are about to share the computed result sheets to the parent/guardian of Eugene ADAVORE.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Enter guardian mail"
                      inputType="text"
                    />
                  </Grid>
                  <div className={`${classes.mail} flex align-items-center`}>
                    <Icon icon="fluent:mail-12-filled" width="24" height="24" />
                    <p>Use an alternative email</p>
                  </div>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Send Report" />
                </div>
              </>
            }
            isopen={addreportModal}
            closeModal={handleReportModal}
          />
          {/* Send Message Modal */}
          <Modal
            title="Message Parent/Guardian"
            subtitle={
              <div>"Send a message to the parents of the students in this class."
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent Name"
                      margin="normal"
                      placeHolder="Eugene Adavore"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      inputType="text"
                      placeHolder="@gmail.com"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Message"
                      margin="normal"
                      placeholder="Enter the message you will like to share with the parents."
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information</label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                </div>
              </>
            }
            isopen={sendmessageModal}
            closeModal={handleSendMessageModal}
          />
        </>
      }
    />
  );
};

export default StudentReport;

import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
import { ToastContainer, toast } from "react-toastify";
import LessonNoteCard from "./LessonNoteCard";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import VerticalLine from "../../../assets/images/verticallines.svg";
import { CardSearch } from "../../../components/CardSearch";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
// import parse from "html-react-parser";
import Modal from "../../../components/Modal";
import NotFounditem from "../../error/NotFound";
import Nosearch from "../../../assets/images/no-search.svg";

const ALL_LESSON_NOTES = "/api/v1/lesson-notes";
const DRAFT_ENDPOINT = "/api/v1/notes/draft";
const LessonNoteDraft = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [drafts, setDrafts] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [draftToDelete, setdraftToDelete] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  // Handle Fetch Note drafts
  const fetchNoteDrafts = async () => {
    setIsLoading(true);
    httprequest
      .get(`${ALL_LESSON_NOTES}/${id}?status=draft`)
      .then((res) => {
        setDrafts(res?.data?.data?.lesson_outlines);
        setSearchResult(res?.data?.data?.lesson_outlines);
        setTimeline(res?.data?.data?.update_timeline);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    fetchNoteDrafts();
  }, []);

  // Delete Drafts Modal Trigger
  const handleDeleteModal = (noteId) => {
    setdraftToDelete(noteId);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  // delete drafts request
  const deleteNote = async (version_id) => {
    try {
      await httprequest.delete(`${DRAFT_ENDPOINT}/${version_id}`);
      toast.success("Lesson note deleted successfully");
      setDrafts((prevNotes) => prevNotes.filter((note) => note.version_id !== version_id));
      setIsDeleteModalOpen(!isDeleteModalOpen);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleNoteSearch = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = drafts?.filter((data) => valArray?.every(
      (word) => data?.topic?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/lesson-notes"
                link_one_name="Lesson Notes"
                active="View all Lesson Outline Drafts"
                description="View all temporarily saved lesson outlines for a subject"
              />
            </div>
          </div>
          { searchResult && searchResult.length > 0 ? (
            <div>
              <div className="margin-bottom-10 margin-top-30">
                <CardSearch placeholder="Search Lesson Note" onChange={handleNoteSearch} />
              </div>
              <Grid container spacing={4} sx={{
                "@media (max-width: 1000px)": {
                  flexWrap: "wrap-reverse"
                }
              }}
              >
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <div className={`${classes.paddingcont}`}>
                    <div className="left-container">
                      <div>
                        {searchResult?.map((draft, index) => {
                          return (
                            <LessonNoteCard
                              key={index}
                              onClick={() => navigate(`/lesson-notes/view-subject-note/${draft?.note_id}`)}
                              title={draft?.topic}
                              subtitle={(draft?.lesson_outlines)}
                              date={draft?.created_at.substring(0, 10)}
                              status={draft?.status}
                              viewRoute={`/lesson-notes/view-subject-note/${draft?.note_id}`}
                              handleDelete={() => handleDeleteModal(draft?.version_id)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className={`${classes.paddingcont}`}>
                    <div className={`${classes.rightcont} right-container`}>
                      <div className={`${classes.notedetails}`}>
                        {timeline?.map((time, index) => {
                          return (
                            <>
                              <div key={index} className="flex gap-10 align-items-center margin-bottom-10">
                                <Icon icon="lets-icons:check-fill" width="32" height="32" style={{ color: "#3FAC64" }} />
                                <div>
                                  <h5>
                                  Updated at
                                  </h5>
                                  <p>{time?.updated_at?.substring(0, 10)}</p>
                                </div>
                              </div>
                              <div className={classes.verticalline}>
                                <img src={VerticalLine} alt="" />
                              </div>
                            </>
                          );
                        })}
                        {searchResult && (
                          <div className="flex gap-10 align-items-center margin-bottom-10">
                            <Icon icon="mdi:cloud-clock-outline" width="25" height="25" style={{ color: "#0e9504", border: "1px solid #0e9504", borderRadius: "20px", padding: "5px", marginLeft: "5px" }} />
                            <div>
                              <h5>
                          Created at
                              </h5>
                              <p>{drafts?.created_at}</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={`${classes.exportbtn}`}>
                        <Button variant="secondaryOutline" color="secondary" buttonSize="full" buttonName="Export All Draft" />
                      </div>
                    </div>
                  </div>
                </Grid>
                <ToastContainer />
              </Grid>
            </div>
          ) : (<NotFounditem title="No Lesson Outline Drafts Created" subtitle="You have not created any lesson outline yet." img={Nosearch} />)}
          {/* Delete Modal */}
          <Modal
            title="Delete Draft"
            subtitle="Are you sure you want to permanently delete this draft?"
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="danger"
                    buttonName="Delete Draft"
                    buttonSize="full"
                    color="btndefault"
                    onClick={() => deleteNote(draftToDelete)}
                    isLoading={isLoading}
                  />
                </div>
              </>
            }
            isopen={isDeleteModalOpen}
            closeModal={handleDeleteModal}
          />
        </>
      }
    />
  );
};

export default LessonNoteDraft;

import Layout from "../../../components/Layout";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";
import Modal from "../../../components/Modal";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table/Table";
import Badge from "../../../components/Badge";
import { TableSearch } from "../../../components/Table/TableActions";
import BreadCrumb from "../../../components/BreadCrumb";

// --| Dummy Data
import EmployeeJSSOneData from "../../../dummy_data/employee_jssone_scores.json";

const EmployeeJnrSec = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchResult, setSearchResult] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setCurrentRow({});
  };

  useEffect(() => {
    // The data will be from the inventory get api endpint
    setSearchResult(EmployeeJSSOneData);
  }, []);
  const columns = [
    {
      name: "Name of Student",
      selector: row => row?.student_name
    },
    {
      name: "Subject",
      selector: row => row?.subject
    },
    {
      name: "Grade in Exam",
      selector: row => row?.grade_exam
    },
    {
      name: "Grade in C",
      selector: row => row?.grade_in_c
    },
    {
      name: "Total Grade Poi",
      selector: row => row?.total_grade
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              navigate("/examination/jssone-examination-papers/employee-exam-paper-review");
            }}
            className="table-action-icon"
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Inventory table using name, email and class
  const handleSearchInventory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = EmployeeJSSOneData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.description
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.category
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.status
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/examination"
                link_one_name="Examination"
                active="Junior Secondary School one Exam Papers"
                description="See the details of the students in your care"
              />
            </div>
          </div>
          <div className={classes.pageContent}>
            <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchInventory} />
            }
            />
          </div>
          {/* Delete Modal */}
          <Modal
            title="Delete Subject Exam"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. If deleted, employee will no longer have access to this Examination Info.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Inventory" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
        </>
      }
    />
  );
};

export default EmployeeJnrSec;

import useStyles from "./styles";

const AttendanceCard = ({ icon, type, total }) => {
  const classes = useStyles();

  return (
    <div className="flex gap-10 margin-bottom-14">
      <div className={classes.icon}>{icon}</div>
      <div className={classes.typeinfo}>
        <p>{type}</p>
        <h4>{total}</h4>
      </div>
    </div>
  );
};

export default AttendanceCard;

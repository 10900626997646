import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";
const lightenRate = 45;

export default makeStyles((theme) => ({
  cardcount: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    width: 250,
    height: 41,
    borderRadius: 8,
    gap: 16,
    backgroundColor: tinycolor(theme.palette.primary.main)
      .lighten(lightenRate)
      .toHexString(),
    padding: "16px 24px",
    border: "1px solid transparent",
    "&:hover, &:focus": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -0.5,
    marginBottom: 0,
    marginTop: 0,
    color: theme.palette.primary.main
  },
  count: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 0,
    marginTop: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 25,
    height: 25,
    borderRadius: "50%",
    color: "white",
    backgroundColor: theme.palette.primary.main
  }
}));

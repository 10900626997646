import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import useValidator from "../../../hooks/useValidator";
import UploadFile from "../../../components/UploadFile";
import { useUpload } from "../../../hooks/useUpload";

const ENROLMENT_ENDPOINT = "/api/v1/enrolment";
const CLASS_ENDPOINT = "/api/v1/class";
const BANNER_ENDPOINT = "/api/v1/banner";

const CreateEnrolment = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [multipleClasses, setMultipleClasses] = useState([]);
  const [enrolmentValidator, showEnrolmentValidator] = useValidator();
  const [isCreating, setIsCreating] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [previewClasses, setPreviewClasses] = useState([]);
  const [banner, setBanner] = useState("");
  const [bannerPic, setBannerPic] = useState(null);
  const [enrolmentForm, setEnrolmentForm] = useState({
    closing_date: "",
    classes: [],
    banner: {}
  });
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
      setBannerPic(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile("");
    setBannerPic(null);
  };
  // Get Random banner if no banner image is provided,
  // Ge Name of the school
  // Get Expiry of the enrolment

  const handleMultipleClassesChange = (event) => {
    const selectedValue = event.map((item) => item.value);

    setPreviewClasses({ ...previewClasses, event });
    setEnrolmentForm({ ...enrolmentForm, classes: selectedValue });
  };

  const handleCustomFieldCheck = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
  };

  const handleCustomFieldsChange = (e, custom) => {
    const { name, value } = e.target;
    setEnrolmentForm({
      ...enrolmentForm,
      [custom]: { ...enrolmentForm[custom], [name]: value }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEnrolmentForm({ ...enrolmentForm, [name]: value });
  };

  const getAllClasses = () => {
    httprequest
      .get(CLASS_ENDPOINT)
      .then((res) => {
        setMultipleClasses(
          res?.data?.data
            ?.filter((cls) => cls?.class_type === "physical")
            ?.map((clss) => {
              return {
                label: clss?.class_name,
                value: clss?.id
              };
            })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getRandomBanner = () => {
    httprequest
      .get(BANNER_ENDPOINT)
      .then((res) => {
        setEnrolmentForm({
          ...enrolmentForm,
          banner: { ...enrolmentForm?.banner, file_url: res?.data?.data }
        });
        setBanner(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    if (!banner && activeStep === 1) {
      getRandomBanner();
    }
  }, [activeStep]);

  const handleNext = () => {
    if (enrolmentValidator.allValid()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      showEnrolmentValidator(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ["Create Enrollment", "Preview & Create Enrollment Form"];

  const handleCreateEnrolment = async () => {
    if (enrolmentValidator.allValid()) {
      setIsCreating(true);
      if (selectedFile) {
        const response = await useUpload(selectedFile);

        if (response.status === 200 && response.data.data) {
          const upload_id = response.data.data?.upload_id;
          const file_url = response.data.data?.file_url;
          const data = {
            ...enrolmentForm,
            banner: { ...enrolmentForm?.banner, file_url, upload_id }
          };

          httprequest
            .post(ENROLMENT_ENDPOINT, data)
            .then((res) => {
              setIsCreating(false);
              toast.success(res?.data?.message);
              window.location.reload();
            })
            .catch((err) => {
              setIsCreating(false);
              toast.error(err?.response?.data?.message);
            });
        } else {
          setIsCreating(false);
          toast.error("Unable to Upload Banner");
        }
      } else {
        httprequest
          .post(ENROLMENT_ENDPOINT, enrolmentForm)
          .then((res) => {
            setIsCreating(false);
            toast.success(res?.data?.message);
            window.location.reload();
          })
          .catch((err) => {
            setIsCreating(false);
            toast.error(err?.response?.data?.message);
          });
      }
    } else {
      showEnrolmentValidator(true);
    }
  };

  useEffect(() => {
    getAllClasses();
    if (!enrolmentForm?.upload_id && activeStep === 1) {
      getRandomBanner();
    }
  }, []);

  return (
    <div>
      <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === 0 ? (
          <div className={classes.enrolmentcontent}>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="multiple-select">
                  <p>
                    Classes <span className="input-required">*</span>
                  </p>
                  <Select
                    isMulti
                    name="classes"
                    defaultValue={previewClasses.event}
                    required
                    closeMenuOnSelect={true}
                    options={multipleClasses}
                    onChange={handleMultipleClassesChange}
                  />
                </div>
                {enrolmentValidator.message(
                  "classes",
                  enrolmentForm?.classes,
                  "required"
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Closing Date"
                  margin="normal"
                  inputType="date"
                  inputName="closing_date"
                  defaultValue={enrolmentForm?.closing_date}
                  onChange={handleChange}
                  required
                />
                {enrolmentValidator.message(
                  "closing date",
                  enrolmentForm?.closing_date,
                  "required"
                )}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p className="input-picker-title">Upload Banner</p>
                <UploadFile
                  uploadTitle="Tap to Upload"
                  uploadSubtitle="PNG or JPG"
                  accept="image/jpeg,image/jpg,image/png"
                  selectedFile={selectedFile}
                  onChange={handleFileChange}
                  handleRemoveFile={handleRemoveFile}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <>
                  <input
                    type="checkbox"
                    name="custom_form"
                    value={isChecked}
                    onChange={handleCustomFieldCheck}
                  />
                  <label className="checkbox-label" htmlFor="custom_form">
                    Add Custom Fields
                  </label>
                </>
              </Grid>
              {isChecked ? (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <InputGroup
                      label="Custom A Label"
                      margin="normal"
                      inputType="text"
                      inputName="label"
                      defaultValue={enrolmentForm?.custom_a?.label}
                      onChange={(e) => {
                        handleCustomFieldsChange(e, "custom_a");
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <SelectGroup
                      label="Custom A Type"
                      children={
                        <select
                          name="type"
                          onChange={(e) => {
                            handleCustomFieldsChange(e, "custom_a");
                          }}
                          defaultValue={enrolmentForm?.custom_a?.type}
                        >
                          <option value="">--Select Options---</option>
                          <option value="input_field">input field</option>
                          <option value="date">date</option>
                          <option value="time">time</option>
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <InputGroup
                      label="Custom B Label"
                      margin="normal"
                      inputType="text"
                      inputName="label"
                      defaultValue={enrolmentForm?.custom_b?.label}
                      onChange={(e) => {
                        handleCustomFieldsChange(e, "custom_b");
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <SelectGroup
                      label="Custom B Type"
                      children={
                        <select
                          name="type"
                          onChange={(e) => {
                            handleCustomFieldsChange(e, "custom_b");
                          }}
                          defaultValue={enrolmentForm?.custom_c?.type}
                        >
                          <option value="">--Select Options---</option>
                          <option value="input_field">input field</option>
                          <option value="date">date</option>
                          <option value="time">time</option>
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <InputGroup
                      label="Custom C Label"
                      margin="normal"
                      inputType="text"
                      inputName="label"
                      defaultValue={enrolmentForm?.custom_c?.label}
                      onChange={(e) => {
                        handleCustomFieldsChange(e, "custom_c");
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <SelectGroup
                      label="Custom C Type"
                      children={
                        <select
                          name="type"
                          onChange={(e) => {
                            handleCustomFieldsChange(e, "custom_b");
                          }}
                          defaultValue={enrolmentForm?.custom_c?.type}
                        >
                          <option value="">--Select Options---</option>
                          <option value="input-field">input field</option>
                          <option value="date">date</option>
                          <option value="time">time</option>
                        </select>
                      }
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
        ) : activeStep === 1 ? (
          <div className={classes.previewcontent}>
            {/* Preview Enrollment Form */}
            <div className={classes.ribbon}>
              Sample Application Form that will be visible to your users
            </div>
            <div className={`${classes.enrolmenthero}`}>
              <h2 className={classes.previewtitle}>
                School Enrolment and Pre-examination.
              </h2>
              <h6 className={classes.previewsub}>
                Be a part of the students that get to be enrolment in the
                prestigious <span>Shelf21</span> academy. Registration is
                ongoing and will last until{" "}
                {enrolmentForm?.closing_date?.substring(0, 10)}.
              </h6>
              <div className={classes.enrolbtn}>
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  buttonName="Enroll for examination"
                  disabled
                />
              </div>
            </div>
            <div className={`${classes.imagecontent}`}>
              <img
                className={`${classes.enrollmentImg}`}
                src={bannerPic || banner}
                alt=""
              />
            </div>

            <Grid item lg={11} md={11} sm={11} xs={11}>
              <p className={`${classes.HeaderPara}`}>APPLICATION FORM</p>
              <h5 className={classes.previewsub}>
                Fill in the required details to complete your application to
                Umbrella Academy.
              </h5>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <h5 className={classes.previewsub}>
                  Upload your last session results from your previous school for
                  assessment.
                </h5>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <h5 className={classes.previewsub}>Passport Photograph</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} disabled />

                  <div className={classes.columnItems}>
                    <div>
                      <Icon
                        icon="icon-park-outline:upload-two"
                        className={classes.uploadicon}
                      />
                    </div>
                    <div>
                      <h2 className={classes.uploadtitle}>
                        Drag & Drop .PDF files of your results or click to
                        upload here.
                      </h2>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} disabled />

                  <div className={classes.columnItems}>
                    <div>
                      <Icon
                        icon="icon-park-outline:upload-two"
                        className={classes.uploadicon}
                      />
                    </div>
                    <div>
                      <h2 className={classes.uploadtitle}>
                        Upload your passport here
                      </h2>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <h4 className={`${classes.RightHeader}`}>
              Application Information
            </h4>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Full Name"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <SelectGroup
                  label="Class Applying to"
                  children={
                    <select>
                      <option value="">--- Select ---</option>
                      {previewClasses?.event?.map((cls) => {
                        return (
                          <option key={cls?.value} value={cls?.value}>
                            {cls?.label}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Email address"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Phone Number"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Home Address"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Full Name"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Guardian Name:"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Guardian Email address"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Guardian Phone Number"
                  name=""
                  disabled
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <InputGroup
                  inputType="text"
                  label="Guardian Home Address"
                  name=""
                  disabled
                />
              </Grid>
              {enrolmentForm?.custom_a?.label ? (
                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <InputGroup
                    inputType="text"
                    label={enrolmentForm?.custom_a?.label}
                    name=""
                    disabled
                  />
                </Grid>
              ) : null}
              {enrolmentForm?.custom_b?.label ? (
                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <InputGroup
                    inputType="text"
                    label={enrolmentForm?.custom_b?.label}
                    name=""
                    disabled
                  />
                </Grid>
              ) : null}
              {enrolmentForm?.custom_c?.label ? (
                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <InputGroup
                    inputType="text"
                    label={enrolmentForm?.custom_c?.label}
                    name=""
                    disabled
                  />
                </Grid>
              ) : null}
            </Grid>
          </div>
        ) : activeStep === 2 ? (
          <>
            <div> Three</div>
          </>
        ) : null}
      </div>
      <Fragment>
        <div className={classes.enrolpaginate}>
          <Button
            variant="primaryoutline"
            buttonSize="fluid"
            color="btnfontprimary"
            onClick={handleBack}
            disabled={activeStep === 0}
            buttonName="Back"
          />

          {activeStep === 0 && (
            <Button
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              onClick={handleNext}
              buttonName={activeStep !== steps.length - 1 && "Next"}
            />
          )}

          {activeStep === 1 && (
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={handleCreateEnrolment}
              isLoading={isCreating}
              buttonName="Create Enrollment"
            />
          )}
        </div>
      </Fragment>
      <ToastContainer />
    </div>
  );
};

export default CreateEnrolment;

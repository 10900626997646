import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";

const drawerWidth = 272;

export default makeStyles((theme) => ({
  logo: {
    height: "40px"
  },
  profilecontainer: {
    backgroundColor: "#F5F6F7",
    width: "100%",
    height: 258,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 22
  },
  pickercontainer: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    bottom: 0,
    right: 0,
    color: "white",
    borderRadius: "50%"
  },
  camera: {
    color: "white",
    width: "14px!important"
  },
  profilepicker: {
    opacity: 0,
    width: 25,
    height: 25,
    cursor: "pointer",
    position: "absolute"
  },
  imageviewer: {
    width: "120px",
    height: "120px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  border: {
    border: "0.84px solid #F0F2F5",
    borderRadius: "8px"
  },
  pickercontain: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    maxHeight: 70,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20,
    marginTop: 15,
    "@media (max-width: 900px)": {
      marginBottom: 0
    }
  },
  pickers: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadflexs: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicons: {
    fontSize: 16,
    color: "#101928"
  },
  labels: {
    fontWeight: "500",
    fontSize: "16px",
    textAlign: "left !important"
  },
  uploadtitles: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 0,
    marginTop: 0
  },
  uploadsubtitles: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    marginTop: 0
  },
  uploadbuttons: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  remove: {
    color: "red",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "6px 8px",
    boxSizing: "border-box",
    marginLeft: 3,
    zIndex: 50,
    backgroundColor: "white",
    border: "1px solid #98A2B3",
    borderRadius: 4,
    display: "flex",
    fontWeight: "bold"
  },
  applicationdetails: {
    "& h6": {
      margin: 0
    }
  },
  footer: {
    display: "flex",
    alignItems: "center",
    width: drawerWidth,
    gap: 10,
    padding: 15,
    "@media (max-width: 425px)": {
      padding: "0px 15px",
      gap: 3,
      justifyContent: "space-between",
      maxWidth: "206px"
    },
    "@media (max-width: 900px)": {
      padding: "0px",
      marginRight: "auto"
    }
  },
  overviewgridcont: {
    "@media (max-width: 1000px)": {
      paddingTop: "75px"
    }
  },
  contheader: {
    paddingBlock: "15px",
    "@media (max-width: 1000px)": {
      position: "fixed",
      zIndex: "20",
      paddingBlock: "9px",
      backgroundColor: "white"
    }
  },
  noexamcont: {
    margin: "20px auto"
  },
  questioncont: {
    padding: "26px",
    border: "1px solid #F0F2F5 !important",
    borderRadius: "8px",
    boxShadow: "0px 0px 3px 0px #00000040",
    marginTop: "23px",
    "& h5": {
      color: "#6C737F",
      fontSize: "18px",
      fontWeight: "400",
      marginBlock: "0px 23px"
    },
    "& p": {
      color: "#6C737F",
      fontSize: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      borderRadius: "9px",
      border: "1px solid #00000033",
      marginBlock: "0px 8px",
      cursor: "pointer"
    },
    "& p:active": {
      backgroundColor: "#F5F6F7"
    },
    "@media (max-width: 900px)": {
      marginTop: "0px"
    },
    "@media (max-width: 530px)": {
      marginTop: "20px"
    }
  },
  timercont: {
    padding: "26px",
    border: "1px solid #F0F2F5",
    borderRadius: "8px",
    boxShadow: "0px 0px 3px 0px #00000040",
    "& h5": {
      color: "#6C737F",
      fontSize: "18px",
      fontWeight: "400",
      marginBlock: "0px 23px"
    },
    "@media (max-width: 900px)": {
      display: "none"
    },
    "@media (min-with: 900px) and (max-width: 1199px)": {
      display: "block"
    }
  },
  questiontext: {
    padding: "30px",
    textAlign: "left",
    border: "1px solid #F0F2F5",
    borderRadius: "8px",
    marginBottom: "16px",
    boxShadow: "0px 0px 3px 0px #00000040",
    "@media (max-width: 500px)": {
      "& h4": {
        fontSize: "14px"
      }
    }
  },
  queimg: {
    "& img": {
      width: "60%"
    },
    "@media (max-width: 700px)": {
      "& img": {
        width: "100%"
      }
    }
  },
  imgans: {
    display: "flex",
    gap: "8px",
    alignItems: "start",
    "& img": {
      width: "60%"
    },
    "@media (max-width: 900px)": {
      "& img": {
        width: "60%"
      }
    }
  },
  timeleft: {
    "& h2": {
      fontWeight: "500",
      fontSize: "16px",
      color: theme.palette.primary.main
    }
  },
  time: {
    padding: "40px 20px",
    border: "9px solid #28AE00",
    borderRadius: "50%",
    "& h2": {
      fontWeight: "400",
      fontSize: "20px"
    },
    "@media (max-width: 1055px)": {
      padding: "25px 20px",
      "& h2": {
        fontSize: "16px"
      }
    }
  },
  progressbar: {
    marginBlock: "20px"
  },
  rightcont: {
    "@media (min-with: 900px) and (max-width: 1199px)": {
      display: "flex",
      gap: "20px"
    }
  },
  gridcont: {
    "@media (max-width: 1050px)": {
      flexWrap: "wrap-reverse"
    }
  },
  submitbtn: {
    maxWidth: "250px",
    margin: "auto"
  },
  footerlink: {
    textDecoration: "none"
  },
  footercontainer: {
    display: "flex",
    alignItems: "center",
    gap: 10
  },
  footertextwrapper: {
    textAlign: "left",
    width: 140,
    overflow: "hidden",
    "@media (max-width: 425px)": {
      maxwidth: 140
    }
  },
  initials: {
    backgroundColor: tinycolor(theme.palette.primary.main).lighten(30).toHexString(),
    color: "white",
    fontSize: 22,
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 425px)": {
      fontSize: 14,
      width: 25,
      height: 25
    }

  },
  name: {
    marginBottom: 0,
    color: "#101928",
    fontSize: 12,
    fontWeight: 500,
    marginTop: 0,
    letterSpacing: "-0.2px",
    "@media (max-width: 425px)": {
      fontSize: 12
    }
  },
  email: {
    marginBottom: 0,
    marginTop: 0,
    fontSize: 12,
    color: "#475367",
    letterSpacing: "-0.2px",
    "@media (max-width: 425px)": {
      fontSize: 12
    }
  },
  logout: {
    color: "#CB1A14",
    marginRight: 10,
    "&:hover": {
      cursor: "pointer"
    }
  },
  //   For Applicant Examination & Result Card
  examlink: {
    textDecoration: "none"
  },
  cardcont: {
    width: "100%",
    border: "1px solid #E4E7EC",
    borderRadius: "9px",
    padding: 16,
    "& h3": {
      fontSize: "24px",
      fontWeight: 700,
      marginBlock: "0px",
      color: "#101928"
    },
    "& p": {
      fontSize: 16,
      fontWeight: "400",
      marginBlock: "0px",
      color: "#101928"
    },
    "& h2": {
      fontSize: "64px",
      fontWeight: 700,
      marginBlock: "0px",
      color: "#101928"
    }
  },
  forerrorimg: {
    marginTop: "16px",
    margin: "auto",
    maxWidth: "245px"

  },
  // FOR COUNTDOWN
  timerContainer: {
    position: "relative",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    color: "black"
  },
  staticBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: "2px solid lightgrey",
    boxSizing: "border-box"
  },
  dynamicBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: "2px solid green",
    boxSizing: "border-box",
    clip: "rect(0px, 100px, 100px, 50px)",
    transform: "rotate(0deg)",
    transition: "transform 1s linear"
  },
  dynamicBorderAfter: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: "2px solid green",
    boxSizing: "border-box",
    clip: "rect(0px, 50px, 100px, 0px)",
    transform: "rotate(0deg)",
    transition: "transform 1s linear"
  },
  timerText: {
    position: "relative",
    zIndex: 1
  },

  // For Password
  allcontent: {
    maxWidth: "509px",
    margin: "50px auto",
    textAlign: "left",
    border: "1px solid #E4E7EC",
    borderRadius: "9px",
    padding: "30px"
  },
  changepassword: {
    fontSize: "16px",
    fontWeight: "700",
    marginBlock: "6px"
  },
  passwordtext: {
    fontSize: "14px",
    color: "667185",
    marginBlock: "0px"
  },
  people: {
    fontSize: "16px",
    fontWeight: "800",
    marginBlock: "6px"
  },
  dashcontent: {
    fontSize: "14px",
    color: "667185",
    marginBlock: "8px 18px"
  },
  optioninput: {
    marginLeft: "16px"
  },
  pagecontent: {
    marginBlock: "20.8px",
    backgroundColor: "white",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: "10px 16px"
  },
  picks: {
    fontSize: "14px",
    color: "667185"
  },
  copyicon: {
    position: "absolute",
    color: "black",
    right: "35px",
    top: "48%"
  },
  noexamimg: {
    marginTop: "60px",
    margin: "auto",
    maxWidth: "155px"

  },
  nocodeheader: {
    fontWeight: 500,
    fontSize: "14px",
    textAlign: "center",
    color: "#000000",
    marginBlock: "0px"
  },
  nocodesentence: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#475367",
    textAlign: "center",
    marginBlock: "0px"
  },
  // menuicon: {
  //   "@media (max-width: 430px)": {
  //     filter: "invert(100%)"
  //   }
  // },
  hamburger: {
    "@media (max-width: 900px)": {
      display: "flex",
      justifyContent: "flex-end",
      width: "899px",
      padding: "0px 24px"
    }
  },
  studentcontainer: {
    "@media (min-width: 1300px)": {
      position: "relative",
      width: "1200px",
      margin: "auto"
    }
  },

  // --| For the Applicant login Page
  signupcontainer: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // backgroundColor: "#101928",
    backgroundColor: "#F6F7FF",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  passwordeye: {
    width: "26px",
    top: "-32px",
    position: "relative",
    marginLeft: "auto"
  },
  loginHeading: {
    fontSize: "20px",
    letterSpacing: "-2%",
    textAlign: "left",
    marginBottom: "6px",
    fontWeight: "500",
    color: "rgba(14, 50, 94, 0.75) !important"
  },
  maincontainer: {
    // backgroundColor: "#1B2331",
    backgroundColor: "#F5F6F7",
    padding: "55px",
    borderRadius: "10px",
    boxSizing: "border-box",
    "@media (max-width: 768px)": {
      padding: "35px"
    },
    "@media (max-width: 576px)": {
      padding: "20px"
    }
  },
  inputPadding: {
    paddingTop: "0px !important"
  },
  signupwrapper: {
    width: "100%",
    color: "rgba(255, 255, 255, 0.7) !important",
    position: "relative"
  },
  container: {
    width: "521px",
    margin: "auto",
    position: "relative",
    zIndex: 100,
    "@media (max-width: 768px)": {
      width: "400px"
    },
    "@media (max-width: 576px)": {
      width: "300px"
    }
  },

  signuplink: {
    color: "rgba(255, 255, 255, 0.7) !important",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-2%",
    fontWeight: 400,
    textDecoration: "none"
  },

  inputGroupStyling: {
    "& p": {
      // color: "#DFE2E7!important"
      color: "rgba(14, 50, 94, 0.75)"
    }
  },
  secondCircle: {
    position: "absolute",
    right: "-40px",
    bottom: "-40px",
    zIndex: "-1",
    "@media (max-width: 576px)": {
      right: "-30px",
      bottom: "-30px"
    }
  },
  firstCircle: {
    position: "absolute",
    left: "-40px",
    top: "-40px",
    zIndex: "-1",
    "@media (max-width: 576px)": {
      left: "-30px",
      top: "-30px"
    }
  }

}));

import { Link } from "react-router-dom";
// import styles from "./SiteMap.module.css";
import { Grid } from "@mui/material";
import logo from "../../assets/images/logo.svg";
import useStyles from "./styles";

const SiteMap = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.sitecontent}`}>
        <div className={`${classes.sitemapheader}`}>
          <div>
            <div className={`${classes.shelflogo}`}>
              <img src={logo} alt="" />
            </div>
            <h4>Site Map</h4>
          </div>
          <p>For easy navigation, these are quick links to take you to different pages on the app</p>
        </div>
        <Grid container spacing={2}>
          <Grid item lg={2} md={2} sm={4} xs={6}>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/classes">Add Class</Link>
            <Link to="/students">Manage Students</Link>
            <Link to="/employees">Manage Teachers</Link>
            <h4>Guardian</h4>
            <Link to="/guardian">Manage Parents</Link>
            <Link to="/students">Send Braoadcasts</Link>
            <Link to="/students">Send Reports to Parents</Link>
            <Link to="/event">Events</Link>
            <Link to="/event">Create Events</Link>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <h4 className={classes.sitemapsubheader}>Personnel Management</h4>
            <Link to="/goals">Performance Evaluation</Link>
            {/* <Link>Goal Planning</Link>
            <Link>Review and Performance Evaluation</Link> */}
            <h4 className={classes.sitemapsubheader}>Leave Administration</h4>
            <Link to="/leavemgt">Leave Management</Link>
            <Link to="/leaveoptions">Add Leave Option</Link>
            <Link to="/leavemgt">See Leave Request</Link>
            <Link to="leavemgt">See Leave Schedule</Link>
            <h4>Knowledge Base</h4>
            <Link to="/resources">Knowledge Base</Link>
            {/* <Link>Add New Info</Link>
            <Link>Edit Knowledge Base</Link>
            <Link>View Document</Link> */}
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <h4 className={classes.sitemapsubheader}>Profiles</h4>
            <Link to="/profile">Organisational Profile</Link>
            {/* <Link>Send Employee Invite</Link> */}
            <Link to="/profile">Organogram</Link>
            <Link to="/profile">Contact Information</Link>
            <Link to="/profile">Banking Details</Link>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <h4 className={classes.sitemapsubheader}>Students</h4>
            <Link to="/subjects">Subjects</Link>
            <Link to="/subjects">Create Subjects</Link>
            {/* <Link to="">Assign Teacher</Link> */}
            <Link to="/subjects">Assign Subjects</Link>
            <Link to="/classes">Classes</Link>
            <Link to="/classes/createclass">Create Classes</Link>
            <Link to="/classes">View TimeTable</Link>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <h4 className={classes.sitemapsubheader}>Planning</h4>
            <Link to="/schoolplans">School Plan</Link>
            <Link to="/schoolplans">Create School Plan</Link>
            {/* <Link>View School Calendar</Link> */}
            <h4>Resource Management</h4>
            <Link to="/inventory">Inventory</Link>
            <Link to="/inventory">Create Inventory</Link>
            {/* <Link>Archives</Link> */}
            <Link to="/procurements">Procurement</Link>
            <Link to="/procurements">Add Contractors</Link>
            {/* <Link>Manage Contractors</Link> */}
            <Link to="/procurements/new">Create Purchase Order</Link>
            <Link to="/contractors">View Purchases</Link>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <h4 className={classes.sitemapsubheader}>Examinations</h4>
            <Link to="/examination">Examination</Link>
            {/* <Link>Set Grading System</Link> */}
            <Link to="/examination/jssone-examination-papers/exam-paper-review">View Exam Papers</Link>
            <h4 className={classes.sitemapsubheader}>Student Application</h4>
            <Link to="/applications">Student Application</Link>
            <Link to="/applications">Open Enrollment</Link>
            <Link to="/applications">View Application</Link>
            {/* <Link>Archive Application</Link> */}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SiteMap;

import Layout from "../../components/Layout";
import AdminAttendance from "./admin/AdminAttendance";
import { useUserState } from "../../context/UserContext";
import UserAttendance from "./user/UserAttendance";
// import Permission from "../error/Permission";
import StudentAttendance from "./student/StudentAttendance";

const index = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <AdminAttendance />
        ) : userRole?.role === "teacher" ? <UserAttendance /> : <StudentAttendance />}
      </>
    }
    />
  );
};

export default index;

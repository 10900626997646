import Layout from "../../components/Layout";
import AdminLessonNote from "./admin/AdminLessonNote";
import { useUserState } from "../../context/UserContext";
import UserLessonNote from "./user/UserLessonNote";

const index = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <AdminLessonNote />
        ) : <UserLessonNote />}
      </>
    }
    />
  );
};

export default index;

// styles
import useStyles from "./styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Loader from "../../assets/images/loader.gif";
/*
    Reusable button with background color, width and height.
    buttonSize props are small, medium, large, full and fluid
    variant prop is either of primary, danger, primaryoutline and dangerousoutline
    colors are btndefault, btnblack and btnfontprimary
    buttonIcon is a material UI icon
    onClick prop is onClick & disabled is boolean
*/
const Button = ({ buttonName, buttonIcon, color, variant, buttonSize, onClick, disabled, isLoading }) => {
  const classes = useStyles();
  const getButtonSize = () => {
    switch (buttonSize) {
    case "small":
      return classes.buttonsm;
    case "medium":
      return classes.buttonmd;
    case "large":
      return classes.buttonlg;
    case "fluid":
      return classes.buttonfluid;
    case "full":
      return classes.buttonfull;
      // Add more cases for additional variants
    default:
      return "";
    }
  };

  const getButtonVariant = () => {
    switch (variant) {
    case "primary":
      return classes.primary;
    case "danger":
      return classes.danger;
    case "warning":
      return classes.warning;
    case "secondary":
      return classes.secondary;
    case "primaryoutline":
      return classes.primaryOutline;
    case "warningoutline":
      return classes.warningOutline;
    case "secondaryoutline":
      return classes.secondaryOutline;
    case "dangeroutline":
      return classes.dangerOutline;
    case "success":
      return classes.success;
    case "default":
      return classes.default;
    default:
      return "";
    }
  };

  const getButtonColor = () => {
    switch (color) {
    case "btndefault":
      return classes.btnwhite;
    case "btnblack":
      return classes.btnblack;
    case "btnfontprimary":
      return classes.btnfontprimary;
    case "btnfontsecondary":
      return classes.btnfontsecondary;
    case "btndanger":
      return classes.btndanger;
    case "btnsuccess":
      return classes.btnsuccess;
    default:
      return "";
    }
  };

  return (
    <button className={classNames(classes.button, getButtonVariant(), getButtonColor(), getButtonSize())} onClick={ onClick } disabled={ disabled }>
      {isLoading ? <img src={Loader} alt="" className={classes.loader} /> : <>{buttonIcon}{buttonName}</>}
    </button>
  );
};

Button.propTypes = {
  buttonSize: PropTypes.oneOf(["small", "medium", "large", "fluid", "full"]),
  variant: PropTypes.oneOf(["primary", "danger", "primaryoutline", "dangeroutline", "success"]),
  buttonName: PropTypes.string,
  buttonIcon: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default Button;

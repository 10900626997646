import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageTitle from "../../components/PageTitle";
import PersonalInformation from "./components/PersonalInformation";
import FinancialInformation from "./components/FinancialInformation";
import UserOrganogram from "./components/UserOrganogram";
import { useUserState } from "../../context/UserContext";

const Subjects = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { profile } = useUserState();

  return (
    <>
      <PageTitle
        title={`${profile?.first_name} ${profile?.last_name} Profile`}
        subtitle="View/Update your profile details "
      />
      <TabContext value={value}>
        <div className="tablist-container">
          <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
            <Tab label="Personal Information" value="1" />
            <Tab label="Financial Information" value="2" />
            <Tab label="Organogram" value="3" />
            {/* <Tab label="Work Information" value="4" /> */}
          </Tabs>
        </div>
        <TabPanel value="1" className="tabpanel-overide"><PersonalInformation data={profile} /></TabPanel>
        <TabPanel value="2" className="tabpanel-overide"><FinancialInformation data={profile} /></TabPanel>
        <TabPanel value="3" className="tabpanel-overide" data={profile}><UserOrganogram data={profile} /></TabPanel>
        {/* <TabPanel value="4" className="tabpanel-overide">4</TabPanel> */}
      </TabContext>
    </>
  );
};

export default Subjects;

import styles from "./NotFound.module.css";

const NotFounditem = (props) => {
  return (
    <>
      <div className={`${styles.erroritems}`}>
        <img src={props.img} alt="Not Found Image" className={styles.errorimg} />
        <div className={`${styles.errorspan}`}>
          <h4>{props.title}</h4>
          <p>{props.subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default NotFounditem;

import { useUserState } from "../../context/UserContext";
import Permission from "../error/Permission";
import StudentSyllabus from "./student/StudentSyllabus";
import Layout from "../../components/Layout";

const index = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "student" ? (
          <StudentSyllabus />) : <Permission />}
      </>
    }
    />
  );
};

export default index;

import useStyles from "./styles";
import { Icon } from "@iconify/react/dist/iconify.js";

const NoticeBoardCard = ({ title, date, teacher }) => {
  const classes = useStyles();

  return (
    <div className={classes.noticecard}>
      <div className="text-align-left">
        <h5>{title}</h5>
        <p><Icon icon="uil:calender" width="10px" height="10px" style={{ color: "black" }} />Added on: {date}</p>
      </div>
      <div className="text-align-right">
        <p>Added By:</p>
        <h5>{teacher}</h5>
      </div>
    </div>
  );
};

export default NoticeBoardCard;

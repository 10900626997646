/* eslint-disable prefer-template */
import PageTitle from "../../../components/PageTitle";
// import Button from "../../../components/Button/Button";
import styles from "./Guardian.module.css";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "@mui/material";
import Badge from "../../../components/Badge";

const GUARDIAN_ENDPOINT = "/api/v1/guardian";

const Ward = () => {
  const { id } = useParams();
  const [wardData, setWardData] = useState([]);

  useEffect(() => {
    httprequest.get(`${GUARDIAN_ENDPOINT}/wards/${id}`).then((res) => {
      setWardData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  }, []);

  return (
    <div>

      <PageTitle
        title="Your Wards"
        subtitle={
          <>

            <div className={`${styles.footer} flex align-items-center gap-5`}>
            </div>

          </>}
        // button={
        //   <div className="flex gap-10">
        //     <Button
        //       variant="primaryoutline"
        //       buttonSize="fluid"
        //       color="btnfontprimary"
        //       //   onClick={handleBroadcastModal}
        //       buttonName="Send Broadcast to parents"
        //     />
        //     <Button
        //       variant="primary"
        //       buttonSize="fluid"
        //       color="btndefault"
        //       //   onClick={handleReportModal}
        //       buttonName="Send Report to Parent"
        //     />
        //   </div>
        // }
      />
      <Grid container spacing={4}>
        {wardData?.map((data, i) => {
          return (
            <Grid key={i} item lg={5} md={5} sm={12} xs={12}>
              <div className="key-pair-container">
                <div className="key-pair-content">
                  <p className="key-pair-name">Name</p>
                  <h3 className="key-pair-value"><Link to={`/students/${data?.student_id}`}>{data?.first_name + " " + data?.last_name}</Link></h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Class</p>
                  <h3 className="key-pair-value">{data?.class}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Age</p>
                  <h3 className="key-pair-value">{data?.age || "---"} Years</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Email</p>
                  <h3 className="key-pair-value">{data?.student_email}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Contact</p>
                  <h3 className="key-pair-value">{data?.student_contact}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Gender</p>
                  <h3 className="key-pair-value">{data?.gender}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Admission Date</p>
                  <h3 className="key-pair-value">{data?.admission_date?.substring(0, 10)}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Active Status</p>
                  <h3 className="key-pair-value"> <Badge status={data?.is_active} /></h3>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <ToastContainer />
    </div>
  );
};

export default Ward;

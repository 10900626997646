import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
// import NotificationCard from "./component/NotificationCard";
import { httprequest } from "../../data/api";
import useStyles from "./styles";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const NOTIFICATIONS_ENDPOINT = "/api/v1/notifications";

const NotificationDetails = () => {
  const { id } = useParams();
  const classes = useStyles();

  const [notification, setNotification] = useState({});

  const getNotifications = async () => {
    await httprequest.get(`${NOTIFICATIONS_ENDPOINT}/${id}`).then((res) => {
      setNotification(res?.data?.data);
    }).catch((err) => {
      toast.error(err.response?.data?.message);
    });

    await httprequest.patch(`${NOTIFICATIONS_ENDPOINT}/${id}`, { read: true });
  };

  useEffect(() => {
    getNotifications();
  }, [id]);

  return (
    <Layout children={
      <>
        <PageTitle
          title="Notification"
          subtitle="See every information that comes to your desk. Never miss an alert."
        />
        <div className="page-content">
          <div className="margin-top">
            <div>
              <div className={`${classes.notificationheader} flex gap-10 justify-items-space flex-wrap-items`}>
                <div>
                  <h3>{notification?.title}</h3>
                  <p>{notification?.created_at?.substring(0, 10)}</p>
                </div>
              </div>
              <div className={`${classes.notificationcontent}`}>
                <div className={`${classes.contentheader} flex gap-10 align-items-center`}>
                  <h4>{notification?.employees_name}</h4>
                </div>
                <p>{notification?.employees_role}</p>
                <h6>
                  {notification?.message}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </>
    }
    />
  );
};

export default NotificationDetails;

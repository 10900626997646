import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useStyles from "../styles";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";

// const NOTE_API = "/api/v1/notes";

const GET_ALL_LESSONNOTES = "/api/v1/lesson-notes";
const ALL_NOTES_ENDPOINT = "/api/v1/lesson-outlines";
const POST_NOTE = "/api/v1/lesson-outlines";

const EditLessonNote = () => {
  const classes = useStyles();
  const location = useLocation();
  const [noteValidator, showNoteValidator] = useValidator();
  const [lessonNotes, setLessonNotes] = useState([]);

  // --| State is passed from view lesson note, note-id is needed to get all lesson note by note id
  const { state } = location;
  const navigate = useNavigate();
  // const { id } = useParams();

  const [noteForm, setNoteForm] = useState({
    lesson_note_id: "",
    topic: "",
    training_method: "",
    learning_goals: "",
    learning_materials: "",
    lesson_outline: ""
  });

  const handleNoteChange = (e) => {
    const { name, value } = e.target;
    setNoteForm({ ...noteForm, [name]: value });
  };

  // --| Handle quill change is for the text area note
  const handleQuillChange = (e) => {
    setNoteForm({ ...noteForm, lesson_outline: e });
  };

  // --| get lesson note for all lesson note drop down
  const getAllLessonNotes = () => {
    httprequest
      .get(GET_ALL_LESSONNOTES)
      .then((res) => {
        setLessonNotes(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // --| Fetch lesson note by Id
  const fetchLessonNoteById = () => {
    httprequest
      .get(`${ALL_NOTES_ENDPOINT}/${state?.lesson_outline_id}`)
      .then((res) => {
        setNoteForm({ lesson_outline: res?.data?.data?.lesson_outline,
          lesson_note_id: state?.lesson_note_id,
          topic: state?.topic,
          training_method: state?.training_method,
          learning_goals: state?.learning_goals,
          learning_materials: state?.learning_materials });
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handleEditNote = (versionId) => {
    if (noteValidator.allValid()) {
      // setIsLoading(true);
      httprequest
        .patch(`${POST_NOTE}/${versionId}`, noteForm)
        .then((res) => {
          toast.success(res?.data?.message);
          // setIsLoading(false);
          setTimeout(() => {
            navigate(`/lesson-notes/${state?.lesson_note_id}`);
          }, 1000);
        })
        .catch((err) => {
          // setIsLoading(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showNoteValidator(true);
    }
  };

  useEffect(() => {
    fetchLessonNoteById();
  }, []);

  useEffect(() => {
    setNoteForm({
      ...noteForm,
      lesson_note_id: state?.lesson_note_id,
      topic: state?.topic,
      training_method: state?.training_method,
      learning_goals: state?.learning_goals,
      learning_materials: state?.learning_materials
    });
  }, []);

  useEffect(() => {
    getAllLessonNotes();
    const qlContainer = document.querySelector(".ql-container");
    if (qlContainer) {
      qlContainer.style.minHeight = "140px";
      qlContainer.style.borderRadius = "0px 0px 10px 10px";
    }

    const qlToolbar = document.querySelector(".ql-toolbar.ql-snow");
    if (qlToolbar) {
      qlToolbar.style.borderRadius = "10px 10px 0px 0px";
    }
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/lesson-notes/:id"
                link_one_name="View Lesson Notes"
                link_two={`/lesson-notes/${state?.lesson_note_id}`}
                link_two_name="View all Lesson Outlines "
                active="Edit a Lesson Outline"
                description="Edit a Lesson outline for a subject in your school and remove at your convenience."
              />
            </div>
          </div>
          <div className="page-content">
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  label="Lesson Notes"
                  children={
                    <select onChange={handleNoteChange} name="lesson_note_id" value={noteForm?.lesson_note_id}>
                      <option value="">--- Select ---</option>
                      {lessonNotes?.map((lessonnote) => {
                        return (
                          <option key={lessonnote?.id} value={lessonnote?.id}>
                            {lessonnote?.class?.name} | {lessonnote?.subject?.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup label="Topic" inputType="text" inputName="topic" onChange={handleNoteChange} value={noteForm?.topic} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup label="Method of Teaching" inputType="text" inputName="training_method" onChange={handleNoteChange} value={noteForm?.training_method} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Learning Objective and Goals"
                  inputType="text"
                  inputName="learning_goals"
                  value={noteForm?.learning_goals}
                  onChange={handleNoteChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup label="Learning Materials" inputType="text" inputName="learning_materials" onChange={handleNoteChange} value={noteForm?.learning_materials} />
              </Grid>
            </Grid>
            <div className={`${classes.quillposition} margin-top-40`}>
              <ReactQuill
                theme="snow"
                onChange={handleQuillChange}
                value={noteForm?.lesson_outline}
                style={{
                  minHeight: "200px",
                  borderRadius: 20,
                  textAlign: "left",
                  "& qlContainer": { height: "150px" }
                }}
              />
            </div>
            <div className={`${classes.savebtn}`}>
              {lessonNotes && (
                <div className="flex gap-10 flex-wrap-items-426">
                  <Button
                    variant="primary"
                    buttonSize="small"
                    color="btndefault"
                    buttonName="Save"
                    onClick={() => handleEditNote(state?.version_id)}
                  />
                </div>
              )}
            </div>
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default EditLessonNote;

import AdminProfile from "./AdminProfile";
import Layout from "../../components/Layout";
import { useUserState } from "../../context/UserContext";
import UserProfile from "./UserProfile";
import StudentProfile from "./StudentProfile";
// import Box from "@mui/material/Box";
// import useStyles from "./styles";

const Profile = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <AdminProfile />
        ) : userRole.role === "teacher" ? <UserProfile /> : <StudentProfile />}
      </>
    }
    />
  );
};

export default Profile;

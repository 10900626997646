import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    // height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingTop: "10px"
  },

  createFormContainer: {
    backgroundColor: "white",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    padding: "0px 20px 30px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px 0px 0px",
      marginLeft: "0px"
    }
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  flexOnly: {
    display: "flex",
    gap: "16px",
    width: "100%",
    justifyContent: "center",
    marginBlock: "12px 32px"
  }

}));

import { Search } from "@mui/icons-material";
import useStyles from "./styles";

const CardSearch = ({ onChange, placeholder, value }) => {
  const styles = useStyles();

  return (
    <div className={styles.searchcontainer}>
      <Search className={styles.searchicon} />
      <input
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        className={styles.search}
        value={value}
      />{" "}
    </div>

  );
};
export { CardSearch };

import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";
const lightenRate = 45;

export default makeStyles((theme) => ({
  overallcont: {
    minWidth: 242,
    border: "1px solid #E4E7EC",
    borderRadius: 8,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: tinycolor(theme.palette.primary.main)
        .lighten(lightenRate)
        .toHexString(),
      border: `1px solid ${theme.palette.primary.main}`
    },
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    }
  },
  bottomcont: {
    display: "flex",
    padding: "0px 24px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  leavecard: {
    borderRadius: "10px",
    paddingInline: "15px",
    margin: "15px",
    cursor: "pointer",
    boxShadow: "0px 4px 4px 0px #00000040",
    transition: "transform 0.3s ease-in-out",
    "& a": {
      textDecoration: "none"
    },
    ":hover": {
      transform: "translateY(-5px)",
      boxShadow: "0px 8px 8px 0px #00000040"
    }
  },
  leavecardinfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "9px 15px",
    "& h5": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#40444B",
      marginBlock: "0px"
    },
    "& p": {
      color: "#667185"
    }
  },
  datecont: {
    paddingBlock: "9px"
  }
}));

/* eslint-disable new-cap */
import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";
// import { Fade } from "@mui/material";

export default makeStyles(theme => ({
  logotype: {
    color: "white",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  logo: {
    height: "40px"
  },
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    height: 64,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: "white !important"
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "space-between",
    cursor: "pointer"
  },
  sitemapimage: {
    padding: "8px 9px 4px 9px",
    background: "#F0F2F5",
    borderRadius: "50%"
  },
  hide: {
    display: "none"
  },
  grow: {
    flexGrow: 1
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    // backgroundColor: Fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer"
      // backgroundColor: Fade(theme.palette.common.black, 0.08)
    }
  },
  searchFocused: {
    // backgroundColor: Fade(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400
    },
    [theme.breakpoints.down("sm")]: {
      width: 250
    }
  },
  searchIcon: {
    width: 36,
    right: 10,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.ash,
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer"
    }
  },
  searchIconOpened: {
    left: -10
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
    backgroundColor: `${theme.palette.color.grey50}!important`,
    borderRadius: 6,
    boxSizing: "border-box",
    paddingLeft: "12px!important"
  },
  messageContent: {
    display: "flex",
    flexDirection: "column"
  },
  headerMenu: {
    marginTop: theme.spacing(7),
    borderRadius: 12
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  headerMenuItem: {
    "-webkit-touch-callout": "none", // iOS Safari
    "-webkit-user-select": "none",   // Safari
    "-khtml-user-select": "none",    // Konqueror HTML
    "-moz-user-select": "none",      // Old versions of Firefox
    "-ms-user-select": "none",       // Internet Explorer/Edge
    "user-select": "none",           // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox
    "touch-action": "none",          // Disable touch actions like long press
    "context-menu": "none",          // Disable context menu
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light
      // color: "white",
    }
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
    color: `${theme.palette.primary.main}!important`
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },
    padding: theme.spacing(0.5)
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2)
  },
  headerIcon: {
    fontSize: 28,
    color: theme.palette.primary.main
  },
  headerIconCollapse: {
    color: theme.palette.primary.main
  },
  profileMenu: {
    minWidth: 265
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2)
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
    backgroundColor: theme.palette.color.primary
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.color.grey100
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    backgroundColor: theme.palette.color.grey100,
    "&:hover": {
      cursor: "pointer"
    }
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.grey100,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light
    }
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none"
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2)
  },
  purchaseBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    marginRight: theme.spacing(3)
  },
  notificationbadge: {
    borderRadius: 12,
    backgroundColor: tinycolor(theme.palette.primary.main).lighten(45).toHexString(),
    color: theme.palette.primary.main,
    fontSize: 12,
    letterSpacing: "-0.1px",
    padding: "3px 8px"
  },
  alertbadge: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: 12,
    letterSpacing: "-0.1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -6,
    right: -4
  },
  notifyitems: {
    width: "300px",
    "-webkit-touch-callout": "none", // iOS Safari
    "-webkit-user-select": "none",   // Safari
    "-khtml-user-select": "none",    // Konqueror HTML
    "-moz-user-select": "none",      // Old versions of Firefox
    "-ms-user-select": "none",       // Internet Explorer/Edge
    "user-select": "none",           // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox
    "touch-action": "none",          // Disable touch actions like long press
    "context-menu": "none",          // Disable context menu
    "& p": {
      fontWeight: 400,
      fontSize: 12,
      marginBlock: 0
    },
    "& h4": {
      fontWeight: 400,
      fontSize: 14,
      width: "225px",
      overflow: "hidden"
    }
  },
  notificationtime: {
    justifyItems: "center",
    // width: "100%",
    position: "relative",
    "& p": {
      textAlign: "right",
      marginTop: 16
    },
    "& img": {
      position: "absolute",
      right: 0,
      top: 0
    }
  },
  notifyheader: {
    "& h3": {
      fontSize: 18,
      fontWeight: 700,
      marginBlock: 8
    },
    "& p": {
      fontSize: 12,
      fontWeight: 400,
      padding: "4px 8px",
      borderRadius: 16,
      backgroundColor: tinycolor(theme.palette.primary.main).lighten(30).toHexString()
    }
  },
  notifyfooter: {
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#0065FF"
    }
  },
  flexresponsive: {
    flex: "0 0 75%"
  },
  initials: {
    backgroundColor: "#F0F2F5",
    color: "black",
    fontSize: 22,
    width: 30,
    height: 30,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

  }
}));

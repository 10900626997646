import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import BreadCrumb from "../../../components/BreadCrumb";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";

//  --| Dummy Data
import StudentData from "../../../dummy_data/studentData.json";
import InputGroup from "../../../components/FormComponent/InputGroup";

const EmployeeCreateClass = () => {
  const [searchResult, setSearchResult] = useState([]);
  const classes = useStyles();
  const [value, setValue] = useState("2");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const navigate = useNavigate();
  const [broadcastModal, setBroadcastModal] = useState(false);
  // const [viewstudent, setViewStudent] = useState(false);
  // const [currentRow, setCurrentRow] = useState({});

  const handleAddClassModal = () => {
    setBroadcastModal(!broadcastModal);
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    // The data will be from the knowledgebase get api endpint
    setSearchResult(StudentData);
  }, []);

  const columns = [
    {
      name: "Student Name",
      selector: row => row?.student_name
    },
    {
      name: "Class",
      selector: row => row?.class_level
    },
    {
      name: "Gender",
      selector: row => row?.gender
    },
    {
      name: "Parent/Guardian",
      selector: row => row?.parent_guardian
    },
    {
      name: "Contact",
      selector: row => row?.phone
    },
    {
      name: "Admission Date",
      selector: row => row?.admission_date
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to="/classes/employeecreateclass/studentreport">
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
        </div>
      )
    }
  ];
  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = StudentData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                        data?.title
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                        data?.label
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                          data?.type
                            ?.toLowerCase()
                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <div className="breadcrumb-container">
        <div>
          <BreadCrumb
            link_one="/classes"
            link_one_name="My Classes"
            active="My Students"
            description="See the details of the students in your care"
          />
        </div>
        <div className="flex gap-10">
          <Button onClick={handleAddClassModal} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="Send Broadcast to parents" />
          <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Class Attendance" />
        </div>
      </div>
      <TabContext value={value}>
        <div className="tablist-container">
          <Tabs
            indicatorColor="none"
            className="overide-tablist"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="JSS 1" value="1" />
            <Tab label="JSS 2" value="2" />
            <Tab label="JSS 3" value="3" />
            <Tab label="SSS 1" value="4" />
            <Tab label="SSS 2" value="5" />
          </Tabs>
        </div>
      </TabContext>
      <Table data={searchResult} columns={columns}
        subHeader={true} pagination subHeaderComponent={
          <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
        }
      />
      {/* Send Broadcast Modal */}
      <Modal
        title="Send Broadcast to parents."
        subtitle={
          <div>Send a broadcast message to the parents of the students in this class
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Broadcast To:"
                  margin="normal"
                  placeHolder="Parent Email"
                  inputType="text"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextAreaGroup
                  label="Broadcast Message"
                  margin="normal"
                  placeholder="Enter the message you will like to share with the parents."
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <label className={`${classes.label}`}>Additional Information</label>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} />

                  <div className={classes.uploadflex}>
                    <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                    <div>
                      <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                      <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                    </div>
                  </div>
                  <div className={classes.rightpicker}>
                    <div className={classes.uploadbutton}>Upload</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
              <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
            </div>
          </>
        }
        isopen={broadcastModal}
        closeModal={handleAddClassModal}
      />
    </>
  );
};

export default EmployeeCreateClass;

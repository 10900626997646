import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useUserState } from "../../../context/UserContext";
import Modal from "../../../components/Modal/index";
import { Grid, Pagination } from "@mui/material";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
import SchoolEventCard from "./component/SchoolEventCard";
import EventBanner from "../../../assets/images/eventbanner.jpg";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import useValidator from "../../../hooks/useValidator";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CardSearch } from "../../../components/CardSearch";
import { VenueType } from "../../../utils/DropDown";
import Select from "react-select";
import PageLoader from "../../../components/PageLoader";

// API ENDPOINTS
const ALL_EVENTS = "/api/v1/events";
const UPLOAD_ENDPOINT = "/api/v1/upload";
const ROLES_ENDPOINT = "/api/v1/roles";

const AdminEvents = () => {
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  const [eventvalidator, showEventValidator] = useValidator();
  const [editeventvalidator, showEditEventValidator] = useValidator();
  const [deleteModal, setDeleteModal] = useState(false);
  const { profile } = useUserState();
  const [value, setValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState("");
  const [addEventModal, setAddEventModal] = useState(false);
  const [isEventsFetching, setIsEventsFetching] = useState(false);
  const [allevents, setAllEvents] = useState([]);
  const [multipleAccess, setMultipleAccess] = useState([]);
  const [uploadForm, setUploadForm] = useState({});
  const [isDeleting, setIsEventDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // --| Card Pagination
  const itemsPerPage = 6;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [eventForm, setEventForm] = useState({
    title: "",
    venue: "",
    access: [],
    event_type: "",
    description: "",
    location: "",
    date: "",
    time: "",
    banner: {}
  });
  const [editeventForm, setEditEventForm] = useState({
    title: "",
    venue: "",
    access: "",
    event_type: "",
    description: "",
    location: "",
    date: "",
    time: "",
    banner: {}
  });

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setEventForm({ ...eventForm, [name]: value });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1); // Reset to first page on tab change
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditEventForm({ ...editeventForm, [name]: value });
  };

  const handleAddEventModal = () => {
    setAddEventModal(!addEventModal);
  };

  const handleCloseAddEventModal = () => {
    setAddEventModal(false);
  };

  const handleEditEventModal = (row) => {
    setEditEventModal(!editEventModal);
    setEditEventForm({
      ...editeventForm, title: row?.title,
      description: row?.description, access: row?.access, event_type: row?.event_type, venue: row?.venue, location: row?.location, date: row?.date, time: row?.time, upload_id: row?.upload_id
    });
    setCurrentRow(row);
  };

  const navigateEventDetail = (id) => {
    navigate(`/events/${id}`);
  };

  const handleCloseEditEventModal = () => {
    setEditEventModal(false);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // FOR FILE UPLOAD
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleRemoveFile = () => setSelectedFile("");

  const getAllRoles = () => {
    httprequest.get(ROLES_ENDPOINT).then((res) => {
      setMultipleAccess(res?.data?.data?.map((role) => {
        return {
          label: role?.role_name,
          value: role?.role_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllEvents = () => {
    setIsEventsFetching(true);
    httprequest
      .get(ALL_EVENTS)
      .then((res) => {
        setIsEventsFetching(false);
        setAllEvents(res?.data?.data);
        // getCurrentItems(res?.data?.data);
        // setTotalPages(Math.ceil(res?.data?.data.length / itemsPerPage));
        // setAllSearchEvent(res?.data?.data);
      })
      .catch((err) => {
        setIsEventsFetching(false);
        toast.error(err?.response?.data);
      });
  };

  // Create Event
  const handleCreateEvent = async () => {
    if (eventvalidator.allValid()) {
      setIsCreating(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await httprequest.post(
        `${UPLOAD_ENDPOINT}`, formData
      );
      if (response.status === 200 && response.data.data) {
        const upload_id = response.data.data?.upload_id;
        const file_url = response.data.data?.file_url;

        const updatedEventForm = { ...eventForm, banner: {
          upload_id, file_url
        } };

        httprequest.post(ALL_EVENTS, updatedEventForm).then((res) => {
          setIsCreating(false);
          toast.success(res?.data?.message);
          getAllEvents();
          setEventForm({ ...eventForm, title: "", venue: "", access: "", event_type: "", description: "", location: "", date: "", time: "", banner: {} });
          handleCloseAddEventModal();
        }).catch((err) => {
          setIsCreating(false);
          toast.error(err?.response?.data?.message);
        });
      } else {
        toast.error("There is a problem uploading the file");
      }
    } else {
      showEventValidator(true);
    }
  };

  const handleMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setEventForm({ ...eventForm, access: selectedValue });
  };

  const handleEditMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setEditEventForm({ ...editeventForm, access: selectedValue });
  };

  // Edit Event
  const handleEditEvent = async () => {
    if (editeventvalidator.allValid()) {
      setEditLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await httprequest.patch(
        `${UPLOAD_ENDPOINT}/${currentRow?.banner?.upload_id}`, formData
      );
      if (response.status === 200 && response.data.data) {
        const upload_id = response.data.data?.upload_id;
        const file_url = response.data.data?.file_url;

        const updatedEventForm = { ...eventForm, banner: {
          upload_id, file_url
        } };

        httprequest.patch(`${ALL_EVENTS}/${currentRow?.id}`, updatedEventForm).then((res) => {
          setEditLoading(false);
          toast.success(res?.data?.message);
          setEditEventForm({ ...eventForm, title: "", venue: "", access: "", event_type: "", description: "", location: "", date: "", time: "", banner: {} });
          handleCloseEditEventModal();
        }).catch((err) => {
          setEditLoading(false);
          toast.error(err?.response?.data?.message);
        });
      } else {
        toast.error("There is a problem uploading the file");
      }
    } else {
      showEditEventValidator(true);
    }
  };

  // Delete Event
  const handleDeleteEvent = () => {
    setIsEventDeleting(true);
    httprequest.delete(`${ALL_EVENTS}/${currentRow?.id}`).then((res) => {
      setIsEventDeleting(false);
      toast.success(res?.data?.message);
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsEventDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  useEffect(() => {
    getAllEvents();
    getAllRoles();
  }, []);

  const filteredEvents = allevents.filter((e) => {
    const matchesSearch = e.title?.toLowerCase().includes(searchQuery.toLowerCase()) || e?.description?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesTab =
      (value === "") ||
      (value === "upcoming" && e.status === "upcoming") ||
      (value === "completed" && e.status === "completed");

    return matchesSearch && matchesTab;
  });

  const totalPages = Math.ceil(filteredEvents.length / itemsPerPage);

  const paginatedEvents = filteredEvents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleEventSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const { title, venue, event_type, description, location, date, time } = eventForm;

  const RenderEvent = () => (
    <Grid container spacing={2}>
      {paginatedEvents?.map((event) => {
        const eventImage = event?.banner?.file_url || EventBanner;

        return (
          <Grid item lg={4} md={6} sm={6} xs={12} key={event.id}>
            <SchoolEventCard
              eventImage={eventImage}
              eventName={event.title}
              eventDesc={event.description}
              attendees={`${event.attendees} Attendees`}
              link={event.id}
              status={event.status}
              viewClick={() => navigateEventDetail(event.id)}
              editClick={() => handleEditEventModal(event)}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  const NoEventFound = () => {
    return (
      <NotFounditem img={nosearch}
        title="No Event Found"
        subtitle="The Events that matches this query cannot be found"
      />
    );
  };

  return (
    <>
      <PageTitle
        title="Events"
        subtitle="Schedule events and send reminders to your employees to keep them in the loop."
        button={
          <div className="flex gap-10" data={profile}>
            <Button
              variant="primary"
              buttonSize="small"
              color="btndefault"
              onClick={handleAddEventModal}
              buttonName="Create Events"
            />
          </div>
        }
      />
      {/* Create Event Modal */}
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            <Tabs indicatorColor="none" className="overide-tablist" onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="All Events" value="" />
              <Tab label="Upcoming Events" value="upcoming" />
              <Tab label="Completed Events" value="completed" />
            </Tabs>
          </div>
          <>

            <CardSearch placeholder="Search your events" onChange={handleEventSearch} />
            <TabPanel value="" className="tabpanel-overide">
              {isEventsFetching ? (
                <PageLoader />
              ) : paginatedEvents?.length < 1 ? <NoEventFound /> : <RenderEvent />}
            </TabPanel>
            <TabPanel value="upcoming" className="tabpanel-overide">
              {isEventsFetching ? (
                <PageLoader />
              ) : paginatedEvents?.length < 1 ? <NoEventFound /> : <RenderEvent />}
            </TabPanel>
            <TabPanel value="completed" className="tabpanel-overide">
              {isEventsFetching ? (
                <PageLoader />
              ) : paginatedEvents?.length < 1 ? <NoEventFound /> : <RenderEvent />}
            </TabPanel>
          </>
        </TabContext>
        <ToastContainer />
        <div className="pagination-container">
          <Pagination count={Math.ceil(totalPages)} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
        </div>
      </div>
      <Modal
        title="Create Event"
        subtitle={
          <div>Create an event and send invitations to your work colleagues to keep them in the loop.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  margin="normal"
                  placeHolder="Name of Event"
                  inputType="text"
                  inputName="title"
                  onChange={handleEventChange}
                  value={title}
                />
                {/* {eventvalidator.message("title", title, "required")} */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Description"
                  margin="normal"
                  placeHolder="Event Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleEventChange}
                  value={description}
                />
                {/* {eventvalidator.message("title", title, "required")} */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="multiple-select">
                  <p>Send Invite to</p>
                  <Select isMulti name="access"
                    closeMenuOnSelect={true} options={multipleAccess} onChange={handleMultipleAccessChange}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Event Type" children={
                  <select onChange={handleEventChange} name="event_type" value={event_type}>
                    <option value="">---Select ---</option>
                    {VenueType?.map((data) => {
                      return (
                        <option value={data.key} key={data?.key}>{data?.text}</option>
                      );
                    })}
                  </select>}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Venue"
                  margin="normal"
                  inputType="text"
                  inputName="venue"
                  value={venue}
                  placeHolder="Event Venue"
                  onChange={handleEventChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Location"
                  margin="normal"
                  inputType="text"
                  inputName="location"
                  value={location}
                  placeHolder="Location"
                  onChange={handleEventChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Date"
                  margin="normal"
                  inputType="date"
                  inputName="date"
                  inputMin={new Date().toISOString().split("T")[0]}
                  value={date}
                  onChange={handleEventChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Time"
                  margin="normal"
                  inputType="time"
                  inputName="time"
                  value={time}
                  onChange={handleEventChange}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

                  <div className={classes.uploadflex}>
                    <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                    {selectedFile?.name ? (
                      <div>
                        <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                      </div>
                    ) :
                      <div>
                        <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                        <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                        </h6>
                      </div>}
                  </div>
                  <div className={classes.rightpicker}>
                    {selectedFile?.name ? (
                      <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                    ) : null}
                  </div>
                </div>
                {/* {eventvalidator.message("file", selectedFile, "required")} */}
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
              <Button variant="primary" buttonName="Create Event" buttonSize="full" color="btndefault" onClick={handleCreateEvent} isLoading={isCreating} />
            </div>
          </>
        }
        isopen={addEventModal}
        closeModal={handleCloseAddEventModal}
      />

      {/* EDIT EVENT MODal */}
      <Modal
        title="Edit Event"
        subtitle={
          <div>Edit an event and send invitations to your work colleagues to keep them in the loop.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  margin="normal"
                  placeHolder="Name of Event"
                  inputType="text"
                  inputName="title"
                  onChange={handleEditChange}
                  defaultValue={editeventForm?.title}
                />
                {/* {eventvalidator.message("title", title, "required")} */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Description"
                  margin="normal"
                  placeHolder="Event Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleEditChange}
                  value={editeventForm?.description}
                />
                {/* {eventvalidator.message("title", title, "required")} */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="multiple-select">
                  <p>Edit Invite to</p>
                  <Select isMulti name="access"
                    closeMenuOnSelect={true} options={multipleAccess} onChange={handleEditMultipleAccessChange}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Event Type" children={
                  <select onChange={handleEditChange} name="event_type" value={editeventForm?.event_type}>
                    <option value="">---Select ---</option>
                    {VenueType?.map((data) => {
                      return (
                        <option value={data.key} key={data?.key}>{data?.text}</option>
                      );
                    })}
                  </select>}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Venue"
                  margin="normal"
                  inputType="text"
                  inputName="venue"
                  value={editeventForm?.venue}
                  placeHolder="Event Venue"
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Location"
                  margin="normal"
                  inputType="text"
                  inputName="location"
                  value={editeventForm?.location}
                  placeHolder="Location"
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Date"
                  margin="normal"
                  inputType="date"
                  inputName="date"
                  inputMin={new Date().toISOString().split("T")[0]}
                  value={editeventForm?.date}
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Time"
                  margin="normal"
                  inputType="time"
                  inputName="time"
                  value={editeventForm?.time}
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

                  <div className={classes.uploadflex}>
                    <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                    {selectedFile?.name ? (
                      <div>
                        <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                      </div>
                    ) :
                      <div>
                        <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                        <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                        </h6>
                      </div>}
                  </div>
                  <div className={classes.rightpicker}>
                    {selectedFile?.name ? (
                      <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                    ) : null}
                  </div>
                </div>
                {/* {eventvalidator.message("file", selectedFile, "required")} */}
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button variant="primary" buttonName="Edit Event" buttonSize="full" color="btndefault" onClick={handleEditEvent} isLoading={editLoading} />
            </div>
          </>
        }
        isopen={editEventModal}
        closeModal={handleCloseEditEventModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Event"
        subtitle={
          <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.event_name}</span>. from one of your Events? Removing it will permanently clear this Event.`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="dangeroutline"
                buttonSize="full"
                color="btndanger"
                buttonName="Cancel"
                // onClick={handleCloseDeleteModal}
              />
              <Button variant="danger" buttonName="Remove Event" buttonSize="full" color="btndefault" onClick={handleDeleteEvent} isLoading={isDeleting} />
            </div>
          </>
        }
        isopen={deleteModal}
        closeModal={handleDeleteModal}
      />
    </>
  );
};

export default AdminEvents;

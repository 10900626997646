import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox, Box } from "@mui/material";
import { useState, useEffect } from "react";
// import BreadCrumb from "../../../components/BreadCrumb";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import { FilterSelect } from "../../../components/Table/TableActions";
import { ToastContainer, toast } from "react-toastify";
import nosearch from "../../../assets/images/no-search.svg";
import NotFounditem from "../../error/NotFound";
import { httprequest } from "../../../data/api";
import { useUserState } from "../../../context/UserContext";
import PageTitle from "../../../components/PageTitle";

const STUDENT_ATTENDANCE = "/api/v1/student/attendance";
const STUDENT_CLASS = "/api/v1/grade/classes";

const StudentAttendance = () => {
  const classes = useStyles();
  const { userRole } = useUserState();
  // eslint-disable-next-line no-console
  console.log(userRole?.student_id, "student_id");
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [studentAttendanceData, setStudentAttendanceData] = useState([]);
  const [classRecord, setClassRecord] = useState([]);
  const [currentStudentClass, setCurrentStudentClass] = useState({});
  const [terms, setTerms] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");

  const getStudentAttendance = (params) => {
    httprequest.get(`${STUDENT_ATTENDANCE}`, { params }).then((res) => {
      setStudentAttendanceData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // eslint-disable-next-line no-console
  console.log("studentAttendanceData", studentAttendanceData);

  const getStudentClass = async () => {
    try {
      const res = await httprequest.get(`${STUDENT_CLASS}`);
      const classData = res?.data?.data;
      setClassRecord(classData);

      const currentClass = classData.find(clas => clas.is_current_class);
      setSelectedClass(currentClass?.class?.id);
      setTerms(currentClass?.terms);
      setCurrentStudentClass(currentClass);
      // eslint-disable-next-line no-console

      const params = {
        class_id: currentClass?.class?.id,
        term_id: currentClass?.terms?.[0]?.id
      };
      getStudentAttendance(params);
    } catch (err) {
      toast.error(err?.response?.message);
    }
  };
  // eslint-disable-next-line no-console
  console.log(selectedClass);

  const handleClassChange = (event, newClassId) => {
    setSelectedClass(newClassId);
    const currentClass = classRecord.find(clas => clas.class.id === newClassId
    );
    const params = {
      class_id: currentClass?.class?.id,
      term_id: currentClass?.terms?.[0]?.id
    };
    getStudentAttendance(params);

    setTerms(currentClass?.terms);
    setSelectedTerm(currentClass?.terms?.[0]?.id);
  };

  useEffect(() => {
    if (classRecord.length > 0) {
      const currentClass = classRecord.find(clas => clas.is_current_class);
      setCurrentStudentClass(currentClass);
      const params = {
        class_id: currentClass?.class?.id,
        term_id: currentClass?.terms?.[0]?.id
      };
      // eslint-disable-next-line no-console
      console.log(currentClass);
      getStudentAttendance(params);
      // setValue(currentClass?.class?.id);
      // setTerms(currentClass?.terms);
    }
  }, [classRecord]);

  useEffect(() => {
    getStudentAttendance();

    const fetchData = async () => {
      await getStudentClass();
    };
    fetchData();
  }, []);
  // Filter attendance data based on selected class and term
  const filteredAttendance = studentAttendanceData && studentAttendanceData.class?.id === selectedClass && studentAttendanceData.term?.id === selectedTerm
    ? studentAttendanceData.attendance
    : [];

  // eslint-disable-next-line no-console
  console.log(filteredAttendance);

  const handleFilterByTerm = (event) => {
    const selectedTermValue = event.target.value;
    setSelectedTerm(selectedTermValue);
    // eslint-disable-next-line no-console
    console.log(selectedTerm, "selectedTerm");
    const params = {
      class_id: currentStudentClass?.class?.id,
      term_id: selectedTermValue
    };
    getStudentAttendance(params);
  };

  const classesList = classRecord.map((data) => data.class);
  // eslint-disable-next-line no-console
  console.log(classesList, "classesList");
  const formattedTerms = terms?.map((item) => ({ name: item?.name, value: item?.id }));

  return (
    <>
      <div>
        <PageTitle
          title="Attendance"
          subtitle="View all my attendance"
        />
        {/* <BreadCrumb
          link_one="/class-activities/classes"
          link_one_name="My Class"
          active="Attendance"
          description="See the attendance details of my student."
        /> */}
      </div>
      <div className={`${classes.attcontent} page-content-mh-83`}>
        <TabContext value={selectedClass}>
          <div className="flex align-items-center justify-content-between flex-wrap-items-600 margin-top-10-v600">
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleClassChange}
                aria-label="class tabs"
              >
                {classesList.map((classInfo) => (
                  <Tab
                    key={classInfo.id}
                    value={classInfo.id}
                    label={classInfo.name}
                  />
                ))}
              </Tabs>
            </div>
            <div className="tablist-container">
              <FilterSelect onChange={handleFilterByTerm}
                optionsArray={formattedTerms} selectValue={selectedTerm} selectName={formattedTerms?.name}
                selectIcon="ei:calendar"
              />
            </div>
          </div>
          <TabPanel
            sx={{
              border: "1px solid #98A2B3",
              borderRadius: "8px",
              marginTop: "16px"
            }}
            value={selectedClass}
          >
            {filteredAttendance?.length === 0 ? (
              <div className={classes.notfound}>
                <NotFounditem
                  img={nosearch}
                  title="No Attendance Found"
                  subtitle="No attendance has been added."
                />
              </div>
            ) : (
              <Box sx={{ overflowX: "auto" }}>
                <TableContainer
                  component={Paper}
                  sx={{ display: "table", width: "auto" }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            minWidth: 100,
                            borderBottom: "1px solid #F5F6F7",
                            position: "sticky",
                            left: 0,
                            backgroundColor: "white",
                            zIndex: 3,
                            "@media (max-width: 500px)": {
                              minWidth: "75px"
                            }
                          }}
                        >
                          Day
                        </TableCell>
                        {filteredAttendance.map((week, weekIndex) => (
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom: "none",
                              "&:not(:last-child)": {
                                borderRight: "0.5px solid #6C737F"
                              }
                            }}
                            key={`week-header-${weekIndex}`}
                          >
                          Week {week.week}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {daysOfWeek.map((day, dayIndex) => (
                        <TableRow key={`day-row-${dayIndex}`}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                              zIndex: 2
                            }}
                          >
                            {day}
                          </TableCell>
                          {filteredAttendance.map((week, weekIndex) => {
                            const dayRecord = week.record.find(
                              (record) => record.day.toLowerCase() === day.toLowerCase()
                            );

                            return (
                              <TableCell
                                align="center"
                                sx={{
                                  borderBottom: "0px",
                                  "&:not(:last-child)": {
                                    borderRight: "0.5px solid #6C737F"
                                  }
                                }}
                                key={`day-${weekIndex}-${dayIndex}`}
                              >
                                <Checkbox
                                  {...label}
                                  checked={dayRecord?.present === true || dayRecord?.present === false}
                                  sx={{
                                    color: "inherit",
                                    "&.Mui-checked": {
                                      color:
                                      dayRecord?.present === true
                                        ? "#3FAC64"
                                        : dayRecord?.present === false
                                          ? "#D42620"
                                          : "inherit"
                                    }
                                  }}
                                />
                              </TableCell>
                            );
                          })}

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </TabPanel>
        </TabContext>
        {filteredAttendance.length > 0 && (
          <div>
            <div
              className={`${classes.legends} margin-top-10-v600 flex justify-content-between align-items-center flex-wrap-items-600`}
            >
              <div className={`${classes.attcounter}`}>
                {/* Add any additional legend details here */}
              </div>
              <div className={`${classes.systemkey}`}>
                <p className="margin-bottom-10">System Key</p>
                <div
                  className={`${classes.attlegend} flex gap-10 align-items-center flex-wrap-items-v430`}
                >
                  <div className="flex gap-5 align-items-center">
                    <h6>Unselected</h6>
                    <Icon
                      icon="ci:checkbox-unchecked"
                      width="15"
                      height="15"
                      style={{ color: "#98A2B3" }}
                    />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>No Record</h6>
                    <Icon
                      icon="bxs:checkbox-checked"
                      width="17"
                      height="17"
                      style={{ color: "#98A2B3" }}
                    />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>Present</h6>
                    <Icon
                      icon="bxs:checkbox-checked"
                      width="17"
                      height="17"
                      style={{ color: "#3FAC64" }}
                    />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>Absent</h6>
                    <Icon
                      icon="bxs:checkbox-checked"
                      width="17"
                      height="17"
                      style={{ color: "#D42620" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default StudentAttendance;

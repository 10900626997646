import Layout from "../../../components/Layout";
// import useStyles from "../styles";
import BreadCrumb from "../../../components/BreadCrumb";
import Button from "../../../components/Button";
import { Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";

const ExamPaperReview = () => {
//   const classes = useStyles();

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/examination"
                link_one_name="Examination"
                link_two="/examination/jssone-examination-papers"
                link_two_name="JSS One Exam papers"
                active="Mathematics"
                description="See all examination question papers submitted pending approval."
              />
            </div>
          </div>
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <div className="left-container">
                <h4>Basic Science Examination Paper</h4>
                <h6>Update your personal details here</h6>
                <div className="flex gap-10">
                  <Button variant="primary" buttonSize="small" color="btndefault" buttonName="Approve" />
                  <Button variant="primaryoutline" buttonSize="small" color="btnfontprimary" buttonName="Reject" />
                </div>
              </div>
            </Grid>
            <Grid item lg={9} md={8} sm={12} xs={12}>
              <div className="right-container">
              </div>
            </Grid>
            <ToastContainer />
          </Grid>
        </>
      }
    />
  );
};

export default ExamPaperReview;

import Table from "../../../components/Table/Table";
import { Icon } from "@iconify/react";
import { TableSearch } from "../../../components/Table/TableActions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";

const GELL_ALL_SUBJECT_ENDPOINT = "/api/v1/teacher/subjects";

const AllSubject = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);

  const getAllSubjects = () => {
    httprequest.get(GELL_ALL_SUBJECT_ENDPOINT).then((res) => {
      setSubjectsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    // The data will be from the AllSubject get api endpint
    setSearchResult(getAllSubjects);
  }, []);

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject.name
    },
    {
      name: "Date Created",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: row => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to="/subjects/subjectInoformation">
            <button className="table-action-icon">
              <Icon icon="ri:newspaper-line" />
            </button>
          </Link>
        </div>
      )
    }
  ];
    // --| Filter All Subject table using name, email and class
  const handleSearchAllSubject = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = subjectsData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                data?.title
                                  ?.toLowerCase()
                                  .includes(word.toLowerCase()) ||
                                data?.label
                                  ?.toLowerCase()
                                  .includes(word.toLowerCase()) ||
                                  data?.type
                                    ?.toLowerCase()
                                    .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAllSubject} />
      }
      />
    </>
  );
};

export default AllSubject;

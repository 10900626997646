import { Grid } from "@mui/material";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "../../../components/Button";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import SelectGroup from "../../../components/FormComponent/SelectGroup";

const PROFILE_ENDPOINT = "api/v1/profile";
const PersonalInformation = ({ data }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const [file, setFile] = useState();
  const [personalDetails, setPersonalDetails] = useState({
    // first_name: "",
    // last_name: "",
    // role: "",
    // email: data?.email,
    gender: "",
    phone_number: "",
    address: "",
    country: ""
  });

  const fullName = `${data?.first_name} ${data?.last_name}`;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
  };

  const handlePersonalUpdate = () => {
    setIsLoading(true);
    httprequest.patch(PROFILE_ENDPOINT, personalDetails)
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        window.location.reload();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.message);
      });
  };

  useEffect(() => {
    setPersonalDetails({
      ...personalDetails,
      // first_name: data?.first_name,
      // last_name: data?.last_name,
      // role: data?.role,
      // email: data?.email,
      gender: data?.gender || "",
      phone_number: data?.phone_number || "",
      address: data?.address || "",
      country: data?.country || ""
    });
  }, [data]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <div className="left-container">
            <h4>Personal Information</h4>
            <h6>Update your personal details here</h6>
            <div className={classes.profilecontainer}>
              <div className={classes.imageviewer}>
                <div className={classes.pickercontainer}>
                  {/* <input type="file" name="profile_picture" value={file} className={classes.profilepicker} onChange={handleChange} /> */}
                  <CameraAltIcon className={classes.camera} />
                </div>
              </div>
            </div>
          </div>

        </Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="right-container">
            <Grid container spacing={2} className={classes.profileform}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup inputType="text" label="Full Name" name="" defaultValue={fullName} disabled />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SelectGroup label="Role" children={
                  <select onChange={handleChange} name="role" defaultValue={data?.role?.name} disabled>
                    <option>---Select Role---</option>
                    <option>teacher</option>
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup inputType="text" label="Email Address" defaultValue={data?.email} value={personalDetails?.email} disabled />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SelectGroup label="Gender" value={personalDetails?.gender} children={
                  <select onChange={handleChange} name="gender" defaultValue={data?.gender}>
                    <option>---Select Gender---</option>
                    <option>male</option>
                    <option>female</option>
                  </select>
                }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup inputType="text" label="Phone Number" inputName="phone_number" value={personalDetails?.phone_number} onChange={handleChange} />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup inputType="text" label="Country" inputName="country" value={personalDetails?.country} onChange={handleChange} />
              </Grid> */}
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputGroup inputType="text" label="Home Address" inputName="address" value={personalDetails?.address} onChange={handleChange} />
              </Grid>
            </Grid>
            <div className={classes.btnspace}>
              <Button variant="primary" color="btndefault" buttonSize="medium" buttonName="Save Changes" isLoading={isLoading} onClick={handlePersonalUpdate} />
            </div>
          </div>
        </Grid>
        <ToastContainer />
      </Grid>
    </>
  );
};

export default PersonalInformation;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const BASE_URL = process.env.REACT_APP_BASE_URL;

const httprequest = axios.create({
  baseURL: API_URL
});

const axiosrequest = axios.create({
  baseURL: API_URL
});

httprequest.interceptors.request.use(config => {
  const token = localStorage.getItem("shelf21-auth");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httprequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.data?.status === 401 || error?.response?.data?.serverMessage === "jwt expired") {
      localStorage.setItem("shelf21-auth", "");
      window.location.replace("/auth/login");
    }

    return Promise.reject(error);
  }
);

// --| This axios request is used for student application authentication as it has its own different login
axiosrequest.interceptors.request.use(config => {
  const token = localStorage.getItem("enrol-auth");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosrequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.data?.status === 401 || error?.response?.data?.serverMessage === "jwt expired") {
      localStorage.setItem("enrol-auth", "");
      window.location.replace("/auth/applicant");
    }

    return Promise.reject(error);
  }
);

export { API_URL, httprequest, axiosrequest, BASE_URL };

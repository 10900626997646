import useStyles from "../styles";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";

const PROFILE_ENDPOINT = "api/v1/profile";
const FinancialInfo = ({ data }) => {
  const classes = useStyles();
  const [bankDetails, setBankDetails] = useState({
    bank_name: "",
    account_number: "",
    account_name: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFinancialUpdate = () => {
    setIsLoading(true);
    httprequest.patch(PROFILE_ENDPOINT, { bank_details: { ...bankDetails } })
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        window.location.reload();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.message);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBankDetails({ ...bankDetails, [name]: value });
  };

  useEffect(() => {
    setBankDetails({
      ...bankDetails,
      bank_name: data?.bank_details?.bank_name,
      account_number: data?.bank_details?.account_number,
      account_name: data?.bank_details?.account_name
    });
  }, [data]);

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <div className="left-container">
          <h4>Financial Information</h4>
          <h6>See your personal Banking Information</h6>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <div className="right-container">
          <Grid container spacing={2} className={classes.profileform}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Bank Name" inputName="bank_name" value={bankDetails?.bank_name} onChange={handleChange} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Account Number" inputName="account_number" value={bankDetails?.account_number} onChange={handleChange} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Account Name" inputName="account_name" value={bankDetails?.account_name} onChange={handleChange} />
            </Grid>
          </Grid>
          <div className={classes.btnspace}>
            <Button variant="primary" color="btndefault" isLoading={isLoading} buttonSize="medium" buttonName="Save Changes" onClick={handleFinancialUpdate} />
          </div>
        </div>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default FinancialInfo;

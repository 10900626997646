import useStyles from "../styles";
import { useState } from "react";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Button from "../../../components/Button";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import { Countries } from "../../../utils/DropDown";
import SelectGroup from "../../../components/FormComponent/SelectGroup";

const PROFILE_ENDPOINT = "api/v1/profile";
const ContactDetails = ({ data }) => {
  const classes = useStyles();

  // eslint-disable-next-line no-console
  console.log("dara", data);

  const [isLoading, setIsLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    phone_number: data?.phone_number,
    country: data?.country,
    state: data?.state,
    address: data?.address
  });

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  const handleUpdateContact = () => {
    setIsLoading(true);
    httprequest.patch(PROFILE_ENDPOINT, contactDetails).then((res) => {
      setIsLoading(false);
      toast.success(res?.data?.message);
      window.location.reload();
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.message);
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <div className="left-container">
          <h4>Personal Information</h4>
          <h6>Update your personal details here</h6>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <div className="right-container">
          <Grid container spacing={2} className={classes.profileform}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Email" inputName="email" defaultValue={data?.email} disabled onChange={handleContactChange} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Phone Number" inputName="phone_number" defaultValue={data?.pnone_number} onChange={handleContactChange} value={contactDetails?.phone_number} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Address" inputName="address" defaultValue={data?.address} onChange={handleContactChange} value={contactDetails?.address} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <SelectGroup label="Country" children={
                <select onChange={handleContactChange} name="country" defaultValue={contactDetails?.country}>
                  <option>---Select Country---</option>
                  {Countries.map((country, i) => {
                    return (
                      <option key={i} value={country.value}>{country.label}</option>
                    );
                  })}
                </select>
              }
              />
            </Grid>
          </Grid>
          <div className={classes.btnspace}>
            <Button variant="primary" color="btndefault" buttonSize="medium" buttonName="Save Changes" isLoading={isLoading} onClick={handleUpdateContact} />
          </div>
        </div>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default ContactDetails;

import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import useStyles from "../styles";
import Modal from "../../../components/Modal";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
import useValidator from "../../../hooks/useValidator";
import { useNavigate } from "react-router-dom";

const GET_ALL_LESSONNOTES = "/api/v1/lesson-notes";
const POST_NOTE = "/api/v1/lesson-outlines";
const POST_DRAFT = "/api/v1/lesson-outlines/draft";

const CreateLessonNote = () => {
  const [noteValue, setNoteValue] = useState("");
  const classes = useStyles();
  const [confirmCreateModal, setConfrimCreateModal] = useState(false);
  const [lessonNotes, setLessonNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noteValidator, showNoteValidator] = useValidator();
  const [noteForm, setNoteForm] = useState({
    lesson_note_id: "",
    topic: "",
    training_method: "",
    learning_goals: "",
    learning_materials: "",
    lesson_outline: ""
  });
  const navigate = useNavigate();

  // Creat Lesson Note Modal Trigger
  const handleConfirmCreateModal = () => {
    setConfrimCreateModal(!confirmCreateModal);
  };

  const handleNoteChange = (e) => {
    const { name, value } = e.target;
    setNoteForm({ ...noteForm, [name]: value });
  };

  // handle Quill input change
  const handleQuillChange = (e) => {
    setNoteValue(e);
  };

  // Get all Lesson Notes
  const getAllLessonNotes = () => {
    httprequest
      .get(GET_ALL_LESSONNOTES)
      .then((res) => {
        setLessonNotes(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Create Note Post request
  const handleCreateNote = () => {
    if (noteValidator.allValid()) {
      setIsLoading(true);
      httprequest
        .post(POST_NOTE, { ...noteForm, lesson_outline: noteValue })
        .then((res) => {
          setIsLoading(false);
          setConfrimCreateModal(false);
          navigate("/lesson-notes");
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showNoteValidator(true);
    }
  };

  // Handle Drafts New Form
  const handleDrafts = () => {
    setIsLoading(true);

    const newForm = new FormData();
    newForm.append("lesson_outline", noteValue);
    newForm.append("lesson_note_id", noteForm.lesson_note_id);
    newForm.append("training_method", noteForm.training_method);
    newForm.append("learning_goals", noteForm.learning_goals);
    newForm.append("learning_materials", noteForm.learning_materials);
    newForm.append("topic", noteForm.topic);
    httprequest
      .post(POST_DRAFT, newForm)
      .then((res) => {
        setIsLoading(false);
        navigate("/lesson-notes");
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllLessonNotes();
    const qlContainer = document.querySelector(".ql-container");
    if (qlContainer) {
      qlContainer.style.minHeight = "140px";
      qlContainer.style.borderRadius = "0px 0px 10px 10px";
    }

    const qlToolbar = document.querySelector(".ql-toolbar.ql-snow");
    if (qlToolbar) {
      qlToolbar.style.borderRadius = "10px 10px 0px 0px";
    }
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/lesson-notes"
                link_one_name="Lesson Notes"
                active="Create Lesson Outline"
                description="Create a lesson outline in a lesson note for a class"
              />
            </div>
          </div>
          <div className="page-content">
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  label="Lesson Notes"
                  children={
                    <select onChange={handleNoteChange} name="lesson_note_id">
                      <option value="">--- Select ---</option>
                      {lessonNotes?.map((lessonnote) => {
                        return (
                          <option key={lessonnote?.id} value={lessonnote?.id}>
                            {lessonnote?.class?.name} | {lessonnote?.subject?.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup label="Topic" inputType="text" inputName="topic" onChange={handleNoteChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup label="Method of Teaching" inputType="text" inputName="training_method" onChange={handleNoteChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Learning Objective and Goals"
                  inputType="text"
                  inputName="learning_goals"
                  onChange={handleNoteChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup label="Learning Materials" inputType="text" inputName="learning_materials" onChange={handleNoteChange} />
              </Grid>
            </Grid>
            <div className={`${classes.quillposition} margin-top-40`}>
              <ReactQuill
                theme="snow"
                onChange={handleQuillChange}
                value={noteValue}
                style={{
                  minHeight: "200px",
                  borderRadius: 20,
                  textAlign: "left",
                  "& qlContainer": { height: "150px" }
                }}
              />
            </div>
            <div className={`${classes.buttons}`}>
              <div className="flex gap-10 flex-wrap-items-426">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  buttonName="Save as Drafts"
                  onClick={handleDrafts}
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleConfirmCreateModal}
                  buttonName="Submit for Review"
                />
              </div>
            </div>
          </div>
          {/* Create Confirmation Modal */}
          <Modal
            title="Create Lesson Note"
            subtitle={
              <div>
                Clicking on create sends a confirmation to your manager for
                review
              </div>
            }
            modalContent={<></>}
            modalFooter={
              <>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  buttonName="Create Lesson Note"
                  isLoading={isLoading}
                  onClick={handleCreateNote}
                />
              </>
            }
            isopen={confirmCreateModal}
            closeModal={handleConfirmCreateModal}
          />
        </>
      }
    />
  );
};

export default CreateLessonNote;

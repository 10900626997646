import styles from "./AccessControl.module.css";
import { Icon } from "@iconify/react";

const AccessComponent = (props) => {
  return (
    <div className={`${styles.maincontainer}`}>
      <div className="flex gap-5 align-items-start">
        <Icon icon="f7:link-circle-fill" width="30" height="30" color="white" />
        <div>
          <h5 className={`${styles.forh5}`}>{props.name}</h5>
          <h6>{props.usecase}</h6>
        </div>
      </div>
      <div className="flex justify-items-space">
        <p>Email:</p>
        <p>{props.mailaddress}</p>
      </div>
      <div className="flex justify-items-space">
        <p>Phone No:</p>
        <p>{props.phonenumber}</p>
      </div>
      <div className="flex justify-items-space">
        <p>Access Control:</p>
        <p>{props.accesscontrol}</p>
      </div>
      <div className="flex justify-items-space">
        <p>Status</p>
        <p className={`${styles.forstatus}`}>{props.codestatus}</p>
      </div>
      <div className="flex gap-5 align-items-center">
        <p>Revoke Code</p>
        <Icon icon="icomoon-free:bin" width="10" height="10" color="red" />
      </div>
    </div>
  );
};

export default AccessComponent;

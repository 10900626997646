import Layout from "../../../components/Layout";
import { useState } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import Button from "../../../components/Button";
import { Grid } from "@mui/material";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import Modal from "../../../components/Modal";
import { ToastContainer } from "react-toastify";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { ClassLevel, Session, Term } from "../../../utils/DropDown";

const EmployeeExamPaperReview = () => {
  const classes = useStyles();
  const [submitmodal, setSubmitModal] = useState(false);

  const handleSubmitModal = () => {
    setSubmitModal(!submitmodal);
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/examination"
                link_one_name="Examination"
                link_two="/examination/employee-jssone"
                link_two_name="JSS One Exam papers"
                active="BAsic Science"
                description="See all examination question papers submitted pending approval."
              />
            </div>
            <div className="flex gap-10">
              <Button onClick={handleSubmitModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Submit Examination Paper" />
            </div>
          </div>

          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <div className="left-container">
                <h4>Basic Science Examination Paper</h4>
                <h6>Update your personal details here</h6>
                <div className="flex gap-10">
                  <Button variant="primary" buttonSize="small" color="btndefault" buttonName="Approve" />
                  <Button variant="primaryoutline" buttonSize="small" color="btnfontprimary" buttonName="Reject" />
                </div>
              </div>
            </Grid>
            <Grid item lg={9} md={8} sm={12} xs={12}>
              <div className="right-container">
              </div>
            </Grid>
            <ToastContainer />
          </Grid>
          {/* Submit Examination Modal */}
          <Modal
            title="Submit Exam Paper."
            subtitle={
              <div>Submit the examination paper for your subject.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Name of Subject"
                      margin="normal"
                      placeHolder="Enter the name of the subject"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup label="Class" children={
                      <select>
                        <option value="">---Select Labels ---</option>
                        {ClassLevel?.map((label) => {
                          return (
                            <option key={label?.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Number of Student"
                      margin="normal"
                      placeHolder="Enter the total number of Students"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup label="Session" children={
                      <select>
                        <option value="">---Select Labels ---</option>
                        {Session?.map((label) => {
                          return (
                            <option key={label?.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup label="Session" children={
                      <select>
                        <option value="">---Select Labels ---</option>
                        {Term?.map((label) => {
                          return (
                            <option key={label?.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Date"
                      margin="normal"
                      placeHolder=""
                      inputType="date"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information</label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Submit Examination Paper" />
                </div>
              </>
            }
            isopen={submitmodal}
            closeModal={handleSubmitModal}
          />
        </>
      }
    />
  );
};

export default EmployeeExamPaperReview;

import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";
const lightenRate = 45;

export default makeStyles((theme) => ({
  overallcont: {
    minWidth: 242,
    border: "1px solid #E4E7EC",
    borderRadius: 8,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: tinycolor(theme.palette.primary.main)
        .lighten(lightenRate)
        .toHexString(),
      border: `1px solid ${theme.palette.primary.main}`
    },
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    }
  },
  typeinfo: {
    "& p": {
      marginBlock: "0px",
      fontWeight: "800",
      color: "#C5CAE4",
      fontSize: "10px",
      marginBottom: "10px"
    },
    "& h4": {
      color: "#171717",
      fontWeight: "800",
      fontSize: "24px",
      marginBlock: "0px"
    }
  }
}));

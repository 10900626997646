import { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";

const CLASS_SUBJECT_ENDPOINT = "api/v1/class-subject";
const SubjectsInClass = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [subClassData, setSubClassData] = useState({});
  const { id } = useParams();

  const getClassSubjectData = () => {
    httprequest.get(`${CLASS_SUBJECT_ENDPOINT}/${id}`).then((res) => {
      setSubClassData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getClassSubjectData();
  }, []);

  const columns = [
    {
      name: "Subject Name",
      selector: row => row?.subject_name || "---------"
    },
    {
      name: "Subject Teacher",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.teachers?.map((teacher) => teacher?.teacher_name || "---------")
    },
    {
      name: "Created At",
      selector: row => row?.created_at?.substring(0, 10) || "---------"
    },
    {
      name: "Updated At",
      selector: row => row?.updated_at?.substring(0, 10) || "---------"
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/subjects/${row?.subject_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
        </div>
      )
    }
  ];

  // --| Filter Subjects Class using name, email and class
  const handleSubjectClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = subClassData?.filter((data) => valArray?.every(
      (word) => data?.class_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
        data?.class_type
          ?.toLowerCase()
          .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        <TableSearch placeholder="Search here..." searchTableFunc={handleSubjectClass} />
      }
      />
      <ToastContainer />
    </>
  );
};

export default SubjectsInClass;

import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import useStyles from "../styles";
import DetailsText from "../../leavemgt/components/DetailsText";
import { useParams } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
// import { pdfjs, Document, Page } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const GET_A_LIBRARY_ITEM = "/api/v1/library/";

const ViewLibraryBook = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [aLibItem, setaLibItem] = useState({});

  // const [numPages, setNumPages] = useState();
  // eslint-disable-next-line no-unused-vars
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  const handleGetLibraryItem = () => {
    httprequest.get(`${GET_A_LIBRARY_ITEM}${id}`).then((res) => {
      setaLibItem(res?.data?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(aLibItem, "aLibItem");

  useEffect(() => {
    handleGetLibraryItem();
  }, []);

  return (
    <Layout
      children={
        <>
          <div>
            <BreadCrumb
              link_one="/library"
              link_one_name="School Library"
              active={aLibItem?.title}
              description={aLibItem?.description}
            />
          </div>
          <hr className={classes.pagedivider} />
          <div className="page-content padding-top-30">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title={`${aLibItem?.type} Name`} subtitle={aLibItem?.title} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Created at" subtitle={aLibItem?.created_at?.substring(0, 10)} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Updated at" subtitle={aLibItem?.updated_at?.substring(0, 10)} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Library Item Type" subtitle={aLibItem?.type} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Description" subtitle={aLibItem?.description} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText
                  title="File URL"
                  subtitle={
                    <Button buttonSize="fluid" buttonName="View PDF" variant="primary" color="btndefault" />
                  }
                />
              </Grid>
              {/* <div>
                <Document file="https://res.cloudinary.com/dk701zyrg/raw/upload/v1726242333/32936b7f16d5bd231726242333020.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                  // eslint-disable-next-line no-console
                  onLoadError={(error) => console.log("Failed to load PDF file:", error)}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <p>
                    Page {pageNumber} of {numPages}
                </p>
              </div> */}
            </Grid>
          </div>
        </>
      }
    />
  );
};

export default ViewLibraryBook;

import data from "./lottie-loading.json";
import Lottie from "react-lottie";
import styles from "./pageloader.module.css";

const PageLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className={styles.loaderpage}>
      <Lottie options={defaultOptions}
        height={250}
        width={250}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export default PageLoader;

import Layout from "../../components/Layout";
import { Grid } from "@mui/material";
import { useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
// import { Document, Page, pdfjs } from "react-pdf";

const Resource = () => {
//   const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [pages, setPages] = useState(null);

  return (
    <Layout children={
      <>
        <BreadCrumb link_one="/resources" link_one_name="Resources" active="View the pdf of the current resource" />
        <Grid container spacing={4}>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <Document
              file={{ url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" }}
              onLoadSuccess={onDocumentLoadSuccess}
              // eslint-disable-next-line no-console
              onLoadError={(error) => console.log("Inside Error", error)}
            >
              <Page pageNumber={pageNumber} style={{ display: "none" }} />
            </Document> */}
          </Grid>
        </Grid>

      </>
    }
    />
  );
};

export default Resource;

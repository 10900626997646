import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pickercontainer: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    maxHeight: 70,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20,
    marginTop: 15
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 0,
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    marginTop: 0
  },
  remove: {
    color: "red",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "6px 8px",
    boxSizing: "border-box",
    marginLeft: 3,
    zIndex: 50,
    backgroundColor: "white",
    border: "1px solid #98A2B3",
    borderRadius: 4,
    display: "flex",
    fontWeight: "bold"
  },
  eventdetailsheader: {
    height: 200,
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px 8px 0px 0px",
    position: "relative"
  },
  libcardcont: {
    position: "relative",
    maxWidth: "475px",
    height: "290px",
    borderRadius: "12px",
    border: "1px solid #EDEDF2",
    textDecoration: "none"
  },
  imageheader: {
    "& img": {
      width: "100%",
      borderRadius: "12px 12px 0px 0px"
    }
  },
  liblink: {
    textDecoration: "none"
  },
  libcard: {
    padding: "16px",
    "& h6": {
      fontWeight: "700",
      fontSize: "16px",
      marginBlock: "0px",
      textAlign: "left",
      textDecoration: "none"
    },
    "& p": {
      fontWeight: "400",
      fontSize: "16px",
      height: "50px",
      maxWidth: "405px",
      marginBlock: "8px",
      textAlign: "left",
      textDecoration: "none"
    },
    "& a": {
      fontWeight: "500",
      fontSize: "16px",
      color: "#0A17A7",
      textAlign: "left !important",
      textDecoration: "none"
    }
  },
  loweritems: {
    height: "50px"
  },
  notificationheader: {
    padding: "16px",
    backgroundColor: "#F5F6F7",
    border: "1px solid #E4E7EC",
    borderRadius: "12px 12px 0px 0px",
    textAlign: "left",
    height: "102px",
    maxWidth: "650px",
    "& h3": {
      fontWeight: 700,
      fontSize: 16,
      color: "#101928",
      marginBlock: "0px"
    },
    "& p": {
      fontWeight: 400,
      fontSize: 14,
      color: "#455367",
      marginBlock: "0px"
    }
  },
  notificationhead: {
    padding: "16px",
    backgroundColor: "#F5F6F7",
    border: "1px solid #E4E7EC",
    borderRadius: "12px 12px 0px 0px",
    textAlign: "left",
    height: "102px",
    maxWidth: "650px",
    "& h3": {
      fontWeight: 700,
      fontSize: 16,
      color: "#101928",
      marginBlock: "0px"
    },
    "& p": {
      fontWeight: 400,
      fontSize: 14,
      color: "#455367",
      marginBlock: "0px"
    }
  },
  notificationcontent: {
    padding: "24px 16px",
    border: "1px solid #E4E7EC",
    borderRadius: "0px 0px 12px 12px",
    textAlign: "left",
    height: "92px",
    maxWidth: "650px",
    "& h4": {
      fontWeight: 700,
      fontSize: 16,
      color: "#1D2739"
    },
    "& p": {
      marginBlock: "0px",
      color: "#475367",
      fontSize: 14
    },
    "& h6": {
      fontWeight: 400,
      fontSize: 14,
      color: "#101928",
      marginBlock: 0
    }
  },
  notificationcontents: {
    padding: "24px 16px",
    border: "1px solid #E4E7EC",
    borderRadius: "0px 0px 12px 12px",
    textAlign: "left",
    height: "92px",
    maxWidth: "650px",
    "& h4": {
      fontWeight: 700,
      fontSize: 16,
      color: "#1D2739"
    },
    "& p": {
      marginBlock: "0px",
      color: "#475367",
      fontSize: 14
    },
    "& h6": {
      fontWeight: 400,
      fontSize: 14,
      color: "#101928",
      marginBlock: 0
    }
  },
  flexresponsive: {
    flex: "0 0 75%"
  },
  viewmore: {
    "& p": {
      color: "#0065FF !important",
      fontSize: "12px !important",
      cursor: "pointer"
    }
  }
}));

import PageTitle from "../../components/PageTitle";
import StudentPersonalInfo from "./components/StudentPersonalInfo";

const Subjects = () => {
  return (
    <>
      <PageTitle
        title="Eugune's Adofure Profile"
        subtitle="See all the examination question papers submitted pending approval. "
      />
      <StudentPersonalInfo />
    </>
  );
};

export default Subjects;

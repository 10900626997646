import { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table/Table";
import { TableSearch, FilterSelect } from "../../../components/Table/TableActions";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
import PageLoader from "../../../components/PageLoader";

const ALL_STUDENT_GRADE = "/api/v1/grade";
const STUDENT_CLASS = "/api/v1/grade/classes";
const Grades = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [classRecord, setClassRecord] = useState([]);
  const [value, setValue] = useState("");
  const [gradeData, setGradeData] = useState([]);
  const [terms, setTerms] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [currentStudentClass, setCurrentStudentClass] = useState({});
  const [selectedTerm, setSelectedTerm] = useState("");

  const getStudentGrade = (params) => {
    setIsPageLoading(true);
    httprequest.get(`${ALL_STUDENT_GRADE}`, { params })
      .then((res) => {
        setIsPageLoading(false);
        setGradeData(res?.data?.data?.grades);
        setSearchResult(res?.data?.data?.grades);
      }).catch((err) => {
        setIsPageLoading(false);
        toast.error(err?.response?.data);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const currentClass = classRecord.find(clas => clas.class.id === newValue
    );
    const params = {
      class_id: currentClass?.class?.id,
      term_id: currentClass?.terms?.[0]?.id
    };
    getStudentGrade(params);
    setTerms(currentClass?.terms);
  };

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject
    },
    {
      name: "First Test",
      selector: row => row?.CA_score
    },
    {
      name: "Second Test",
      selector: row => row?.CA_score
    },
    {
      name: "Exam Score",
      selector: row => row?.exam_score
    },
    {
      name: "Grade",
      selector: row => row?.grade
    }
  ];

  const getStudentClass = async () => {
    try {
      setIsPageLoading(true);
      const res = await httprequest.get(`${STUDENT_CLASS}`);
      setIsPageLoading(false);
      const classData = res?.data?.data;
      setClassRecord(classData);
    } catch (err) {
      setIsPageLoading(false);
      toast.error(err?.response?.data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getStudentClass();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (classRecord.length > 0) {
      const currentClass = classRecord.find(clas => clas.is_current_class);
      setCurrentStudentClass(currentClass);
      const params = {
        class_id: currentClass?.class?.id,
        term_id: currentClass?.terms?.[0]?.id
      };
      getStudentGrade(params);
      setValue(currentClass?.class?.id);
      setTerms(currentClass?.terms);
    }
  }, [classRecord]);

  const handleSearchGrade = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = gradeData?.filter((data) => valArray?.every(
      (word) => data?.subject
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                    data?.grade
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase()) ||
                                    data?.category
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const handleFilterByTerm = (event) => {
    const selectedTermValue = event.target.value;
    setSelectedTerm(selectedTermValue);
    const params = {
      class_id: currentStudentClass?.class?.id,
      term_id: selectedTermValue
    };
    getStudentGrade(params);
  };

  const formattedTerms = terms?.map((item) => ({ name: item?.name, value: item?.id }));

  return (
    <>
      <PageTitle
        title="Grades"
        subtitle="See how well your child is doing in school"
      />
      {isPageLoading ? (<PageLoader />) :
        <div className="page-content">
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                {classRecord && classRecord.map((item) => {
                  return (
                    <Tab key={item?.class?.id} label={item?.class?.name} value={item?.class?.id} />
                  );
                })}
              </Tabs>
            </div>
            <>
              <TabPanel value={value}>
                <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                  <>
                    <TableSearch placeholder="Search here..." searchTableFunc={handleSearchGrade} />
                    <FilterSelect onChange={handleFilterByTerm}
                      optionsArray={formattedTerms} selectValue={selectedTerm} selectName={formattedTerms?.name}
                      selectIcon="ei:calendar"
                    />
                  </>
                }
                />
              </TabPanel>
            </>
          </TabContext>
        </div> }
    </>
  );
};

export default Grades;

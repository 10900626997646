import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
// import Button from "../../components/Button";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import Product from "./components/Product";
import Service from "./components/Service";

const PurchaseOrder = () => {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState("service");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/manage/procurements"
              link_one_name="Procurement"
              active="Add Purchase Order"
              description="Create a new Purchase Order"
            />
          </div>
          {/* <div className="flex gap-10">
            <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Add Contractor" />
          </div> */}
        </div>
        <div className="page-content">
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Service" value="service" />
                <Tab label="Product" value="product" />
              </Tabs>
            </div>
            <TabPanel value="service" className="tabpanel-overide">
              <Service />
            </TabPanel>
            <TabPanel value="product" className="tabpanel-overide"> <Product /></TabPanel>
          </TabContext>
        </div>
      </>
    }
    />
  );
};

export default PurchaseOrder;

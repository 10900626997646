import Layout from "../../components/Layout";
// import { useUserState } from "../../context/UserContext";
import AdminEvents from "./admin/AdminEvents";
// import UserEvents from "./user/UserEvents";

const Events = () => {
//   const { userRole } = useUserState;

  return (
    <Layout children={
      <>
        <AdminEvents />
      </>
    }
    />
  );
};

export default Events;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  subcontainer: {
    position: "relative",
    margin: "16px",
    height: "190px"
  },
  maincont: {
    "& h4": {
      color: "#667185",
      fontWeight: "400",
      fontSize: "14px",
      marginBottom: "8px"
    }
  },
  maincontainer: {
    backgroundColor: "#F5F6F7",
    border: "1px solid #E4E7EC",
    borderRadius: "12px",
    textAlign: "left",
    // maxWidth: "300px",
    "& h5": {
      fontWeight: 500,
      fontSize: 14,
      color: "#000000",
      marginBlock: "0px"
    },
    "& p": {
      fontWeight: 400,
      fontSize: 12,
      color: "#667185",
      marginBlock: "0px"
    },
    "& h6": {
      fontWeight: 500,
      fontSize: 12,
      color: "#667185",
      marginBlock: "6px 10px"
    },
    "& img": {
      borderRadius: "4px"
    }
  },
  integratebtn: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    cursor: "pointer",
    "& button": {
      cursor: "pointer"
    }
    // "@media (max-width: 1200px)": {
    //   width: "90%"
    // }
  },
  notificationcontent: {
    padding: "24px 16px",
    border: "1px solid #E4E7EC",
    borderRadius: "0px 0px 12px 12px",
    textAlign: "left",
    height: "92px",
    maxWidth: "650px",
    "& h4": {
      fontWeight: 700,
      fontSize: 16,
      color: "#1D2739"
    },
    "& p": {
      marginBlock: "0px",
      color: "#475367",
      fontSize: 14
    },
    "& h6": {
      fontWeight: 400,
      fontSize: 14,
      color: "#101928",
      marginBlock: 0
    }
  }
}));

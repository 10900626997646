// styles
import useStyles from "./styles";

// --| Widget Title takes title, subtitle and link as props, only usable in the dashboard component
const WidgetTitle = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.widgetTitleContainer}>
      <div className={classes.typo}>
        <h6 className={classes.title}>
          {props.title}
        </h6>
        <h6 className={classes.subtitle}>{props.subtitle}</h6>
      </div>
      {props.link && props.link}
    </div>
  );
};
export default WidgetTitle;

import Layout from "../../components/Layout";
// import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "../../components/Button";
import { useState } from "react";
// import Table from "../../components/Table/Table";
// import { TableSearch } from "../../components/Table/TableActions";
// import Badge from "../../components/Badge/Badge";
import Modal from "../../components/Modal";
// import { Icon } from "@iconify/react";
// import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import BreadCrumb from "../../components/BreadCrumb";

// --| Dummy Date
// import ProcurementMenuData from "../../dummy_data/procurementmenu.json";
import InputGroup from "../../components/FormComponent/InputGroup";
import ProcMenuTable from "../../components/procumenttable";

const ProcurementMenu = () => {
  // const [searchResult, setSearchResult] = useState([]);
  const [addInfoModal, setAddInfoModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  // const [currentRow, setCurrentRow] = useState({});
  // const [deleteModal, setDeleteModal] = useState(false);

  const handleAddInfoModal = () => {
    setAddInfoModal(!addInfoModal);
  };

  const handleCloseAddInfoModal = () => {
    setAddInfoModal(false);
  };
  const handleDownloadModal = () => {
    setDownloadModal(!downloadModal);
  };
  const handleCloseDownloadModal = () => {
    setDownloadModal(false);
  };

  // const handleDeleteModal = (row) => {
  //   setCurrentRow(row);
  //   setDeleteModal(!deleteModal);
  // };

  // const handleCloseDeleteModal = () => {
  //   setDeleteModal(false);
  // };
  // On Page Load, set search result data to be the initial data
  // useEffect(() => {
  //   // The data will be from the knowledgebase get api endpint
  //   setSearchResult(ProcurementMenuData);
  // }, []);

  // const columns = [
  //   {
  //     name: "Contractor",
  //     selector: row => row?.contractor
  //   },
  //   {
  //     name: "Services",
  //     selector: row => row?.services
  //   },
  //   {
  //     name: "Address",
  //     selector: row => row?.address
  //   },
  //   {
  //     name: "Phone Number",
  //     selector: row => row?.phone
  //   },
  //   {
  //     name: "Date",
  //     selector: row => row?.date
  //   },
  //   {
  //     name: "Status",
  //     center: true,
  //     cell: (row) => (<Badge status={row?.status} />)
  //   },
  //   {
  //     name: "",
  //     center: true,
  //     sortable: false,
  //     cell: (row) => (
  //       <div key={row?.id} className="table-action-container">
  //         <button
  //           // onClick={() => {
  //           //   handleInventoryModal(row);
  //           // }}
  //           className="table-action-icon"
  //         >
  //           <Icon icon="fluent:eye-16-regular" />
  //         </button>
  //         <Link to={`/inventory/${row?.id}`} className="table-action-icon">
  //           <button className="table-action-icon">
  //             <Icon icon="jam:shield-close-f" />
  //           </button>
  //         </Link>
  //         <button
  //           onClick={() => handleDeleteModal(row)}
  //           className="table-action-icon delete-icon"
  //         >
  //           <Icon icon="mingcute:delete-2-line" />
  //         </button>
  //       </div>
  //     )
  //   }
  // ];
  // --| Filter Teacher table using name, email and class
  // const handleSearchTeacher = (e) => {
  //   const { value } = e.target;

  //   const valArray = value.split(" ");
  //   // --| Filter data by partial match onchange in the search input box
  //   const result = ProcurementMenuData?.filter((data) => valArray?.every(
  //     (word) => data?.name
  //       ?.toLowerCase()
  //       .includes(word.toLowerCase()) ||
  //                     data?.title
  //                       ?.toLowerCase()
  //                       .includes(word.toLowerCase()) ||
  //                     data?.label
  //                       ?.toLowerCase()
  //                       .includes(word.toLowerCase()) ||
  //                       data?.type
  //                         ?.toLowerCase()
  //                         .includes(word.toLowerCase())
  //   )
  //   );
  //   setSearchResult(result);
  // };

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/manage/procurements"
              link_one_name="Procurement"
              active="Eugene Adavore"
              description="See all the procurements made by this contractor."
            />
          </div>
          <div className="flex gap-10">
            <Button onClick={handleAddInfoModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Add Contractor" />
          </div>
        </div>
        {/* <PageTitle
          button={
            <div className="flex gap-10">
              <Button onClick={handleAddInfoModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Add Contractor" />
            </div>
          }
        /> */}
        {/* <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
          <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
        }
        /> */}
        {/* Delete Modal */}
        {/* <Modal
          title="Delete Contractor"
          subtitle={
            <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.contractor}</span>. as one of your contractors? Removing him will permanently clear all his records and contracts.`
            </div>}
          modalContent={
            <>

            </>
          }
          modalFooter={
            <>
              <div className="flex gap-10">
                <Button
                  variant="dangeroutline"
                  buttonSize="full"
                  color="btndanger"
                  buttonName="Cancel"
                />
                <Button variant="danger" buttonName="Remove Contractor" buttonSize="full" color="btndefault" />
              </div>
            </>
          }
          isopen={deleteModal}
          closeModal={handleCloseDeleteModal}
        /> */}
        {/* Download Receipt Modal */}
        <Modal
          title="Procurement Receipt"
          subtitle={
            <div>See the receipt for the procurement made.
            </div>}
          modalContent={
            <>
            </>
          }
          modalFooter={
            <>
              <Button variant="primary" buttonName="Download Receipt" buttonSize="full" color="btndefault" />
            </>
          }
          isopen={downloadModal}
          closeModal={handleCloseDownloadModal}
        />
        <Modal
          title="Add a Contractor"
          subtitle={
            <div>Add a document to your knowledge base for your employees to have access to.
            </div>}
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Name of Contractor"
                    margin="normal"
                    placeHolder="Sam Ayodeji"
                    inputType="text"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Email Address"
                    margin="normal"
                    placeHolder="Samayodeji@gmail.com"
                    inputType="text"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Service Offered"
                    margin="normal"
                    placeHolder="Sales of Stationaries"
                    inputType="text"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Address"
                    margin="normal"
                    placeHolder="15 Iya Abubakar, Jabi. Abuja"
                    inputType="text"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Phone Number"
                    margin="normal"
                    placeHolder="+234 708 669 1614"
                    inputType="text"
                  />
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <Button variant="primary" buttonName="Add Contractor" buttonSize="full" color="btndefault" />
            </>
          }
          isopen={addInfoModal}
          closeModal={handleCloseAddInfoModal}
        />
        <ProcMenuTable onClick={handleDownloadModal} title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
        <ProcMenuTable title="500 Laptops and Library materials" subtitle="Eugene Adavore" address="15 Iya Abubarkar, Jabi. Nigeria." amount="N1,038,391" />
      </>
    }
    />
  );
};

export default ProcurementMenu;

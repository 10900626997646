import AdminDashboard from "./AdminDashboard";
import EmployeeDashboard from "./EmployeeDashboard";
import StudentDashboard from "./StudentDashboard";
import { useUserState } from "../../context/UserContext";
import { getCurrentRole } from "../../data/roles";
import Permission from "../error/Permission";

const Dashboard = () => {
  const { userRole } = useUserState();
  const currentRole = getCurrentRole(userRole);

  return (
    currentRole === "admin" ? (<AdminDashboard />) : currentRole === "teacher" ? <EmployeeDashboard /> :
      currentRole === "student" ? <StudentDashboard /> :
        <Permission />

  );
};

export default Dashboard;

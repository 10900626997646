import AdminClasses from "./admin/AllClasses";
import UserClasses from "./user/EmployeeAllClasses";
import Layout from "../../components/Layout";
import { useUserState } from "../../context/UserContext";
import Permission from "../error/Permission";

const Profile = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <AdminClasses />
        ) : userRole?.role === "teacher" ? <UserClasses /> : <Permission />}
      </>
    }
    />
  );
};

export default Profile;

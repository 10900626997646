import Layout from "../../../components/Layout";
import Button from "../../../components/Button";
import { Grid } from "@mui/material";
import BreadCrumb from "../../../components/BreadCrumb";
import SchoolCards from "../../../components/SchoolCards";
import { useNavigate } from "react-router-dom";

const ViewSchoolPlans = () => {
  const navigate = useNavigate();

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/schoolplans"
              link_one_name="School plans"
              active="All School Plans"
              description="See the details of the students in your care"
            />
          </div>
          <div className="flex gap-10">
            <Button onClick={() => {
              navigate("/schoolplans/view-school-plans/school-calender");
            }} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="School Calender"
            />
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <SchoolCards title="Termly Planning" paragraphs="Here you can plan for the entire term here." role="Teacher" />
          </Grid>
        </Grid>
      </>
    }
    />
  );
};

export default ViewSchoolPlans;

import styles from "./ProcMenuTable.module.css";
import { Icon } from "@iconify/react";

const ProcMenuTable = (props) => {
  return (
    <>
      <div className={`${styles.mainitems}`}>
        <div className={`${styles.headeritems}`}>
          <h3 className={`${styles.noMargin}`}>{props.title}</h3>
          <Icon icon="ph:dots-three-outline-vertical-fill" />
        </div>
        <div className={`${styles.forPricing}`}>
          <div>
            <h4 className={`${styles.noMargin}`}>{props.subtitle}</h4>
            <p className={`${styles.noMargin}`}>{props.address}</p>
          </div>
          <div>
            <h3 className={`${styles.forAmount}`}>{props.amount}</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcMenuTable;

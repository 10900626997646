import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";
const lightenRate = 45;

export default makeStyles((theme) => ({
  overallcont: {
    minWidth: 242,
    border: "1px solid #E4E7EC",
    borderRadius: 8,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: tinycolor(theme.palette.primary.main)
        .lighten(lightenRate)
        .toHexString(),
      border: `1px solid ${theme.palette.primary.main}`
    },
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    }
  },
  bottomcont: {
    display: "flex",
    padding: "0px 24px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  noticecard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "9px 15px",
    "& h5": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#40444B",
      marginBlock: "0px"
    },
    "& p": {
      color: "#667185"
    }
  }
}));

import { Link } from "react-router-dom";
import img from "../../assets/images/payfail.png";
import "./style.css";
const Failed = () => {
  return (
    <div className="pay_redirect">
      <div>
        <img src={img} alt="Background" />
        <p>Oops, Payment Failed</p>
        <span>Check your email for your applicant’s login credentials and try again</span>
        <Link to="/auth/login">Click here to login</Link>
      </div>
    </div>
  );
};

export default Failed;

import useStyles from "./styles";
import { useState, useRef } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../Button";
import { Grid } from "@mui/material";

const BulkEmployee = ({ createClick, isLoading }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div className={classes.pickercontainer}>
        <input ref={fileInputRef} onChange={handleFileChange} type="file" className={classes.picker} accept=".csv" />

        <div className={classes.uploadflex}>
          {/* <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div> */}
          <div>
            <h2 className={classes.uploadtitle}>Upload CSV</h2>
            <h6 className={classes.uploadsubtitle}>Download a sample csv file</h6>
          </div>
        </div>
        {selectedFile && (
          <div className={classes.selectedfile}>
            <p>Selected file: <strong>{selectedFile.name}</strong></p>
            <Icon icon="line-md:minus-circle" onClick={handleRemoveFile} width="25" height="25" style={{ color: "#D42620" }} />
          </div>
        )}
      </div>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.btn}>
            <Button variant="primary" buttonName="Create Bulk Employee" buttonSize="full" color="btndefault" onClick={createClick} isLoading={isLoading} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BulkEmployee;

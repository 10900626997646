import useStyles from "../styles";
import PropTypes from "prop-types";

const RenderNotifications = ({ title, created_at, employees_name, employees_role, message, readImg, viewMore }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={`${classes.notificationhead} flex gap-10 justify-items-space flex-wrap-items`}>
        <div>
          <h3>{title}</h3>
          <p>{created_at?.substring(0, 10)}</p>
        </div>
        <div className="margin-left">{readImg}</div>
      </div>
      <div className={`${classes.notificationcontents}`}>
        <div className={`${classes.contentheader} flex gap-10 align-items-center`}>
          {/* <div><img src={UserImage} alt="" /></div> */}
          <h4>{employees_name}</h4>
        </div>
        <p>{employees_role}</p>
        <div className="justify-items-space flex gap-10 align-items-end">
          <h6 className={`${classes.flexresponsive}`}>
            {message}
          </h6>
          <div className={`${classes.viewmore}`}>
            <p onClick={viewMore}>View More</p>
          </div>
        </div>
      </div>
    </div>
  );
};
RenderNotifications.PropTypes = {
  title: PropTypes.string,
  created_at: PropTypes.string,
  employees_name: PropTypes.string,
  employees_role: PropTypes.string,
  message: PropTypes.string,
  readImg: PropTypes.any
};

export default RenderNotifications;

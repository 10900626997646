import Layout from "../../components/Layout";
import Table from "../../components/Table/Table";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableSearch } from "../../components/Table/TableActions";
import BreadCrumb from "../../components/BreadCrumb";
import { Icon } from "@iconify/react";
import InputGroup from "../../components/FormComponent/InputGroup";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";

const INVENTORY_CATEGORY_ENDPOINT = "/api/v1/inventory/category";

const InventoryCategory = () => {
  const [inventoryCategoryModal, setInventoryCategoryModal] = useState(false);
  const [editInventoryCategoryModal, setEditCategoryModal] = useState(false);
  const [deleteInvCategoryModal, setDeleteInvCategoryModal] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isCategoryFetching, setIsCategoryFetching] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [inventoryCategoryValidator, showInventoryCategoryValidator] = useValidator();
  const [editInventoryValidator, showEditInventoryValidator] = useValidator();
  const [inventoryCategory, setInventoryCategory] = useState({
    category: ""
  });

  const [editInvCategory, setEditInvCategory] = useState({
    category: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInventoryCategory({ ...inventoryCategory, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditInvCategory({ ...editInvCategory, [name]: value });
  };

  const handleDeleteModal = (row) => {
    setDeleteInvCategoryModal(true);
    setCurrentDelete(row);
  };

  const handleCloseDeleteModal = () => {
    setDeleteInvCategoryModal(false);
    setCurrentDelete({});
  };

  const handleInvCategoryModal = () => {
    setInventoryCategoryModal(!inventoryCategoryModal);
  };

  const handleEditInvModal = (row) => {
    setCurrentRow(row);
    setEditInvCategory({ ...editInvCategory, category: row?.category });
    setEditCategoryModal(true);
  };

  const handleCloseEditOptionsModal = () => {
    setCurrentRow({});
    setEditInvCategory({ ...editInvCategory, category: "" });
    setEditCategoryModal(false);
  };

  const getAllInvCategory = () => {
    setIsCategoryFetching(true);
    httprequest.get(INVENTORY_CATEGORY_ENDPOINT).then((res) => {
      setIsCategoryFetching(false);

      setCategoryData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsCategoryFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleCreateInvCategory = () => {
    if (inventoryCategoryValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(INVENTORY_CATEGORY_ENDPOINT, inventoryCategory).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllInvCategory();
        setInventoryCategory({ ...inventoryCategory, category: "" });
        handleInvCategoryModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showInventoryCategoryValidator(true);
    }
  };

  const handleDeleteInvCategory = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${INVENTORY_CATEGORY_ENDPOINT}/${currentDelete?.id}`).then(() => {
      setIsDeleteLoading(false);
      toast.success("Invemtory Category Deleted Successfully");
      getAllInvCategory();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleEditInvCategory = () => {
    if (editInventoryValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${INVENTORY_CATEGORY_ENDPOINT}/${currentRow?.id}`, editInvCategory).then(() => {
        setIsEditLoading(false);
        toast.success("Invoice Category Edited");
        getAllInvCategory();
        setEditInvCategory({ ...editInvCategory, category: "" });
        handleCloseEditOptionsModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditOptionsModal();
      });
    } else {
      showEditInventoryValidator(true);
    }
  };

  // --| Filter Inventory Category table using type, number of days, who can access
  const handleSearchInvCategory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = categoryData?.filter((data) => valArray?.every(
      (word) => data?.category
        ?.toLowerCase()
        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAllInvCategory();
  }, []);

  const columns = [
    {
      name: "Code",
      selector: (row) => row?.code
    },
    {
      name: "Category Name",
      selector: (row) => row?.category
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: " ",
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => {
            handleEditInvModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const { category } = inventoryCategory;

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/manage/inventory"
                link_one_name="Inventory"
                active="Inventory Categories"
                description="See the inventory categories of your school for better classification."
              />
            </div>
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={handleInvCategoryModal}
              buttonName="Add Inventory Category"
            />
          </div>

          <Table
            data={searchResult}
            columns={columns}
            subHeader={true}
            isLoading={isCategoryFetching}
            subHeaderComponent={<TableSearch placeholder="Search here..." searchTableFunc={handleSearchInvCategory} />}
            pagination
          />
          <Modal
            title="Add New Inventory Category"
            subtitle="Create a category for your inventory to classify them"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup inputName="category" type="text" label="Category Name" value={category} onChange={handleChange} />
                    {inventoryCategoryValidator.message("Category", category, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleCreateInvCategory}
                  variant="primary"
                  buttonName="Create Inventory Category"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isLoading}
                />
              </>
            }
            isopen={inventoryCategoryModal}
            closeModal={handleInvCategoryModal}
          />
          <Modal
            title="Delete Current Inventory Category"
            subtitle={`Are you sure you want to delete this  ${currentDelete?.category} inventory category?. If you delete it, it becomes unavaiable for the whole school.`}
            modalContent={
              <>
                <div>
                </div>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  variant="danger"
                  buttonName="Delete Inventory Category"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleDeleteInvCategory}
                  isLoading={isDeleteLoading}
                />
              </>
            }
            isopen={deleteInvCategoryModal}
            closeModal={handleCloseDeleteModal}
          />

          {/* Modal to edit inventory category */}
          <Modal
            title="Edit Inventory Category"
            subtitle="Edit your current inventory category"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup inputName="category" type="text" label="Category Name" defaultValue={editInvCategory?.category} onChange={handleEditChange} />
                    {editInventoryValidator.message("Category Name", editInvCategory?.category, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleEditInvCategory}
                  variant="primary"
                  buttonName="Edit Inventory Category"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isEditLoading}
                />
              </>
            }
            isopen={editInventoryCategoryModal}
            closeModal={handleCloseEditOptionsModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default InventoryCategory;

import { httprequest } from "../data/api";

const UPLOAD_ENDPOINT = "/api/v1/upload";

const useUpload = (selectedFile) => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  const response = httprequest.post(
    `${UPLOAD_ENDPOINT}`, formData
  );

  return response;
};

const useEditUpload = (selectedFile, id) => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  const response = httprequest.patch(
    `${UPLOAD_ENDPOINT}/${id}`, formData
  );

  return response;
};

export { useUpload, useEditUpload };

import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import Badge from "../../../components/Badge";
import useStyle from "./styles";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import ErrorImg from "../../../assets/images/noaccesscode.svg";
import PageLoader from "../../../components/PageLoader";
import Button from "../../../components/Button";

const ACCESS_CODE_DETAILS_ENDPOINT = "/api/v1/access_code";

const VisitorInfo = () => {
  const classes = useStyle();
  const { id } = useParams();
  const navigate = useNavigate();
  const [accessDetails, setAccessDetails] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [errFound, setErrFound] = useState("");

  const getAccessCodeDetails = () => {
    setIsFetching(true);
    httprequest.get(`${ACCESS_CODE_DETAILS_ENDPOINT}/${id}`).then((res) => {
      setIsFetching(false);
      setAccessDetails(res?.data?.data);
    }).catch((err) => {
      setIsFetching(false);
      setErrFound(err?.response?.status);
      toast.error(err?.response?.data?.message);
    });
  };

  useEffect(() => {
    getAccessCodeDetails();
  }, []);

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/accesscontrol/codes"
              link_one_name="Access Control"
              active="Access Code Details"
              description="See the details of the visitor assigned to your child"
            />
          </div>
        </div>
        <div className="page-content">
          {isFetching ? (
            <PageLoader />
          ) : (
            <div className="padding-top-56">
              {errFound ? (
                <>
                  <div>
                    <div className={classes.forerrorimg}>
                      <img src={ErrorImg} alt="Error Image" />
                    </div>
                    <div>
                      <p className={classes.nocodeheader}>No Access Code Found</p>
                      <p className={classes.nocodesentence}>This user does not have any access to any student in this school.</p>
                      <div className={classes.centerbackbutton}>
                        <Button
                          variant="primary"
                          color="btndefault"
                          buttonSize="fluid"
                          buttonName="Go back to view all codes"
                          onClick={() => navigate("/accesscontrol/codes")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Grid container spacing={4}>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className={`${classes.border} left-container`}>
                      <h4>Visitor's Info</h4>
                      <div className={classes.profilecontainer}>
                        <div className={classes.imageviewer} style={{ backgroundImage: `url(${accessDetails?.display_pic?.file_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                          {/* <img src={accessDetails?.display_pic?.filr_url} alt="" /> */}
                          <div className={classes.pickercontainer}>
                            <input
                              type="file"
                              name="profile_picture"
                              className={classes.profilepicker}
                              // onChange={handleProfileChange}
                            />
                            <CameraAltIcon className={classes.camera} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={8} md={8} sm={12} xs={12}>
                    <div className={`${classes.border} right-container`}>
                      <div className={classes.forcode}>
                        <p>{accessDetails?.access_code}</p>
                      </div>
                      <div className={classes.accesscredentials}>
                        <div className="flex justify-items-space align-items-center">
                          <p className={classes.accessproperty}>Visitor's Name:</p>
                          <p className={classes.accessvalue}>{accessDetails?.visitor_name}</p>
                        </div>
                        <div className="flex justify-items-space align-items-center">
                          <p className={classes.accessproperty}>Status:</p>
                          <div className={classes.accessvalue}>
                            <Badge status={accessDetails?.status} />
                          </div>
                        </div>
                        <div className="flex justify-items-space align-items-center">
                          <p className={classes.accessproperty}>Email Address:</p>
                          <p className={classes.accessvalue}>{accessDetails?.email || "No Email Address"}</p>
                        </div>
                        <div className="flex justify-items-space align-items-center">
                          <p className={classes.accessproperty}>Student Name:</p>
                          <p className={classes.accessvalue}>{accessDetails?.student?.name}</p>
                        </div>
                        <div className="flex justify-items-space align-items-center">
                          <p className={classes.accessproperty}>Access Type:</p>
                          <p className={classes.accessvalue}>{accessDetails?.type}</p>
                        </div>
                        {accessDetails?.type === "one-time" && (
                          <>
                            <div className="flex justify-items-space align-items-center">
                              <p className={classes.accessproperty}>Access Start Date:</p>
                              <p className={classes.accessvalue}>{`${accessDetails?.start_date?.substring(0, 10)} Time: ${accessDetails?.start_date?.substring(11, 16)}`}</p>
                            </div>
                            <div className="flex justify-items-space align-items-center">
                              <p className={classes.accessproperty}>Access End Date:</p>
                              <p className={classes.accessvalue}>{`${accessDetails?.end_date?.substring(0, 10)} Time: ${accessDetails?.end_date?.substring(11, 16)}`}</p>
                            </div>
                          </>
                        )}
                        <div className="flex justify-items-space align-items-center">
                          <p className={classes.accessproperty}>Issued By:</p>
                          <p className={classes.accessvalue}>{accessDetails?.issuer?.name}</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}

            </div>
          )}

        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default VisitorInfo;

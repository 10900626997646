import useStyles from "../styles";
import { useState } from "react";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import Button from "../../../components/Button";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";

const PROFILE_ENDPOINT = "api/v1/profile";
const OrganisationDetails = ({ data }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState({
    school_name: data?.school_name,
    mission: data?.mission,
    vision: data?.vision,
    staff_count: data?.staff_count
  });

  const handleOrgChange = (e) => {
    const { name, value } = e.target;
    setOrgDetails({ ...orgDetails, [name]: value });
  };

  const updateOrg = () => {
    setIsLoading(true);
    httprequest.patch(PROFILE_ENDPOINT, orgDetails).then((res) => {
      setIsLoading(false);
      toast.success(res?.data?.message);
      window.location.reload();
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <div className="left-container">
          <h4>Organisation Details</h4>
          <h6>Update your personal details here</h6>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <div className="right-container">
          <Grid container spacing={2} className={classes.profileform}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputGroup inputType="text" label="Organisation Name" inputName="school_name" onChange={handleOrgChange} defaultValue={orgDetails?.school_name} value={orgDetails?.school_name} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAreaGroup label="Mission" defaultValue={orgDetails?.mission} inputName="mission" onChange={handleOrgChange} value={orgDetails?.mission} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAreaGroup label="Vision" defaultValue={orgDetails?.vision} inputName="vision" onChange={handleOrgChange} value={orgDetails?.vision} />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Total Staff" inputName="staff_count" defaultValue={orgDetails?.staff_count} onChange={handleOrgChange} value={orgDetails?.staff_count} />
            </Grid>
          </Grid>
          <div className={classes.btnspace}>
            <Button variant="primary" color="btndefault" buttonSize="medium" buttonName="Save Changes" onClick={updateOrg} isLoading={isLoading} />
          </div>
        </div>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default OrganisationDetails;

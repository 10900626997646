import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Button from "../../../components/Button";

// GET ALL ASSIGNMENT BY CLASS
const GET_ALL_ASSIGNMENT = "/api/v1/assignment";
const ASSIGNMENT_BY_CLASS_ENDPOINT = "/api/v1/assignment/class";
const CLASSES_ENDPOINT = "/api/v1/class";

const Assignment = () => {
  const [value, setValue] = useState("1");
  const [assignmentsdata, setAssignmentsData] = useState([]);
  const [assignmentbyclass, setAssignmentByClass] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [classesData, setClassData] = useState([]);
  const [assignmentresultbyclass, setAssignmentResultByClass] = useState([]);
  const navigate = useNavigate();

  const getAllAssignmentByClass = () => {
    httprequest.get(ASSIGNMENT_BY_CLASS_ENDPOINT).then((res) => {
      setAssignmentsData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllClasses = () => {
    httprequest.get(CLASSES_ENDPOINT).then((res) => {
      const physicalClasses = res?.data?.data?.filter((data) => data?.class_type === "physical");
      setClassData(physicalClasses);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // --| THIS IS TO GET AN ASSIGNMENT BY CLASS
  const getAssignmentByClass = () => {
    httprequest.get(`${ASSIGNMENT_BY_CLASS_ENDPOINT}/${value}`).then((res) => {
      setAssignmentByClass(res?.data?.data);
      setAssignmentResultByClass(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllAssignment = () => {
    httprequest.get(GET_ALL_ASSIGNMENT).then((res) => {
      setAssignmentsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject?.name
    },
    {
      name: "Class",
      selector: row => row?.class?.name
    },
    {
      name: "Teacher",
      selector: row => row?.created_by?.name
    },
    {
      name: "Date Created",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Submission Date",
      selector: row => row?.submission_date?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/assesments/assignments/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            // onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }

  ];

  useEffect(() => {
    getAllAssignmentByClass();
    getAllAssignment();
    getAllClasses();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // --| Filter Assignment table using name, email and class
  const handleSearchAssignmentByClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = assignmentbyclass?.filter((data) => valArray?.every(
      (word) => data?.subject?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
            data?.class?.name?.toLowerCase()
              .includes(word.toLowerCase()) ||
            data?.created_by?.name?.toLowerCase()
              .includes(word.toLowerCase())
    )
    );
    setAssignmentByClass(result);
  };

  // --| Filter Assignment table using name, email and class
  const handleSearchAssignment = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = assignmentsdata?.filter((data) => valArray?.every(
      (word) => data?.subject?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
            data?.class?.name?.toLowerCase()
              .includes(word.toLowerCase()) ||
            data?.created_by?.name?.toLowerCase()
              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    if (value !== "1") {
      getAssignmentByClass();
    }
  }, [value]);

  return (
    <Layout children={
      <>
        <PageTitle
          title="See All Assignments"
          subtitle="See all assignment in my school"
          button={
            <div className="flex gap-10">
              <div className="flex gap-10">
                <Button onClick={() => {
                  navigate("/assesments/assignment/create");
                }} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="Create Assignment"
                />
              </div>
            </div>
          }
        />
        <div className="page-content">
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label=""
              >
                <Tab label="All Assignments" value="1" />
                {classesData?.map((data, i) => {
                  return (
                    <Tab key={i} value={data?.id} label={data?.class_name} />
                  );
                })}
              </Tabs>
            </div>
            <TabPanel value="1" className="tabpanel-overide">
              <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAssignment} />
              }
              />
            </TabPanel>
            {classesData?.map((data, i) => {
              return (
                <TabPanel className="tabpanel-overide" key={i} value={data?.id}>
                  <Table data={assignmentresultbyclass} columns={columns} subHeader={true} pagination subHeaderComponent={
                    <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAssignmentByClass} />
                  }
                  />
                </TabPanel>
              );
            })}
          </TabContext>
        </div>
      </>
    }
    />
  );
};

export default Assignment;

import { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";

const CLASS_STUDENT_ENDPOINT = "/api/v1/students";
const StudentInClass = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [studentClassData, setStudentClassData] = useState({});
  const { id } = useParams();

  const getStudentClass = () => {
    httprequest.get(`${CLASS_STUDENT_ENDPOINT}?class_id=${id}`).then((res) => {
      setStudentClassData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(studentClassData, "studentClassData");

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getStudentClass();
  }, []);

  const columns = [
    {
      name: "Class Name",
      selector: row => row?.class?.name || "---------"
    },
    {
      name: "Student Name",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.first_name + " " + row?.last_name || "---------"
    },
    {
      name: "Gender",
      selector: row => row?.gender || "---------"
    },
    {
      name: "Student Email",
      selector: row => row?.email || "---------"
    },
    {
      name: "Guardian",
      selector: row => row?.guardian?.name || "---------"
    },
    {
      name: "Admission Date",
      selector: row => row?.admission_date?.substring(0, 10) || "---------"
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/students/${row?.student_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
        </div>
      )
    }
  ];

  // --| Filter Student Class using name, email and class
  const handleStudentClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = studentClassData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
        data?.first_name
          ?.toLowerCase()
          .includes(word.toLowerCase()) ||
                    data?.student_email
                      ?.toLowerCase()
                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        <TableSearch placeholder="Search here..." searchTableFunc={handleStudentClass} />
      }
      />
      <ToastContainer />
    </>
  );
};

export default StudentInClass;

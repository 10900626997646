import defaultTheme from "./default";

import { createTheme } from "@mui/material/styles";

export const overrides = () => {
  return ({
    background: {
      light: "#E6F0FF"
    }
  });
};

const themes = (primary) => {
  return {
    default: createTheme({ ...defaultTheme(primary), ...overrides })
  };
};

export default themes;
